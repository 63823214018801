import { cn } from '@/utils/classname';
import { ReactNode } from 'react';

interface ButtonState {
  buttonState: 'default' | 'active';
}

interface ButtonIcon {
  buttonIcon: ReactNode;
  onClick?: () => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
  className?: string;
  disabled?: boolean;
  analyticsEventObject?: string;
}

export interface IconButtonProps extends ButtonState, ButtonIcon {}

const CommonIconButtonContent = ({
  buttonIcon,
  onClick,
  onMouseLeave,
  onMouseEnter,
  className,
  disabled,
  analyticsEventObject,
}: ButtonIcon & { className?: string }) => (
  <button
    className={cn(
      'transition',
      'duration-300',
      'ease-out',
      'h-[30px]',
      'w-[30px]',
      'flex',
      'justify-center',
      'items-center',
      'bg-none',
      'border-1',
      'rounded',
      'border-solid',
      'fill-none',
      'disabled:color-neutral-400',
      'disabled:border-neutral-400',
      className,
    )}
    data-analytics-event-object={analyticsEventObject}
    disabled={disabled}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {buttonIcon}
  </button>
);

const DefaultIconButton = ({
  analyticsEventObject,
  buttonIcon,
  onClick,
  onMouseLeave,
  onMouseEnter,
  className,
  disabled,
}: ButtonIcon) => (
  <CommonIconButtonContent
    analyticsEventObject={analyticsEventObject}
    buttonIcon={buttonIcon}
    className={`${className} color-neutral-100 border-neutral-100 bg-none`}
    disabled={disabled}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  />
);

const ActiveIconButton = ({
  analyticsEventObject,
  buttonIcon,
  onClick,
  onMouseLeave,
  onMouseEnter,
  className,
  disabled,
}: ButtonIcon) => (
  <CommonIconButtonContent
    analyticsEventObject={analyticsEventObject}
    buttonIcon={buttonIcon}
    className={`${className} color-neutral-100 border-neutral-100 bg-primary-400`}
    disabled={disabled}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  />
);

const IconButtonComponents = {
  default: DefaultIconButton,
  active: ActiveIconButton,
};

export const IconButton = ({
  buttonState,
  buttonIcon,
  onClick,
  onMouseLeave,
  onMouseEnter,
  className,
  disabled,
  analyticsEventObject,
}: IconButtonProps) => {
  const IconButtonComponent = IconButtonComponents[buttonState];
  return (
    <IconButtonComponent
      analyticsEventObject={analyticsEventObject}
      buttonIcon={buttonIcon}
      className={className}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};
