import { Skeleton } from '@skand/ui';

export const BlockLoading = () => {
  return (
    <div className="flex-1 overflow-hidden line-height-1">
      <Skeleton className="mb-3 h-512px w-full" />
      <Skeleton className="mb-3 h-512px w-full" />
    </div>
  );
};
