import { canPolicyActionEdit } from '@/constants/policy';
import { useFetchProjectPermissions } from '@/hooks/useFetchProjectPermissions';
import { useFetchShareLinks } from '@/hooks/useFetchShareLinks';
import { useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import { ShareLink, setSelectedShareLink } from '@/stores/shareLink';
import { cn } from '@/utils/classname';
import { search } from '@/utils/search';
import { Button, Input } from '@skand/ui';
import { useMemo, useState } from 'react';
import { BlockLoading } from '../BlockLoading';
import { EmptyResourceMessage } from '../EmptyResourceMessage';
import { FindIcon } from '../IconButton';
import { ShareLinkCard } from './ShareLinkCard';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export const ShareLinkTab = () => {
  const projectId = useExplore(state => state.projectId);
  const setLeftSideBarII = useLayout(state => state.setLeftSideBarII);
  const isShowingLeftSideBarII = useLayout(state => state.isShowingLeftSideBarII);
  const showLeftSideBarII = useLayout(state => state.showLeftSideBarII);
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);

  const [searchKey, setSearchKey] = useState('');
  const { shareLinks, response } = useFetchShareLinks();

  const filtered = useMemo(
    () => shareLinks.filter(shareLink => search(shareLink.name, searchKey)),
    [searchKey, shareLinks],
  );

  const { getProjectPermission } = useFetchProjectPermissions();
  const permission = getProjectPermission(projectId);
  const canEdit = canPolicyActionEdit(permission);

  return (
    <div className={cn('flex flex-col px-1 gap-3 h-full')}>
      <div className={cn('border-neutral-500', 'border-1', 'border-b-solid', 'pb-3')}>
        <p className={cn('typo-heading-4 color-neutral-800')}>Share link</p>
      </div>
      <div className={cn('flex gap-2')}>
        <div className="w-full">
          <Input
            data-testid="input"
            label="Search for share links"
            onChange={event => setSearchKey(event)}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
        <Button className="px-5" size="s">
          Filter
        </Button>
      </div>
      <Button
        className={cn('w-full')}
        data-analytics-event-object={ANALYTICS_EVENT_OBJECT.CREATE_SHARE_LINK}
        disabled={!canEdit}
        filled
        onClick={() => {
          setLeftSideBarII('shareLinkContentPanel');
          setSelectedShareLink(null);
          if (!isShowingLeftSideBarII) {
            showLeftSideBarII();
          } else {
            hideLeftSideBarII();
          }
        }}
        primary
        size="s"
      >
        Create share link
      </Button>

      {response.isLoading ? (
        <BlockLoading />
      ) : !filtered.length ? (
        <EmptyResourceMessage
          emptyResourceContent={{
            emptyDesc: 'Use the Create button to create a new share link.',
            emptyTitle: 'No share links available',
          }}
        />
      ) : (
        <div className="overflow-auto">
          {filtered.map((shareLink: ShareLink) => (
            <ShareLinkCard disabled={!canEdit} key={shareLink.id} shareLink={shareLink} />
          ))}
        </div>
      )}
    </div>
  );
};
