import { DND_ITEM_TYPE } from '@/constants/annotation';
import {
  AnnotationTemplateSelectField,
  AnnotationTemplateSelectOption,
} from '@/graphql/codegen/graphql';
import { WithMode, addOption, changeOption, moveOption, removeOption } from '@/stores/template';
import { cn } from '@/utils/classname';
import { Input } from '@skand/ui';
import { useEffect, useMemo, useRef } from 'react';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { Color } from './Color';

export interface DragItem {
  index: number;
  option: null | AnnotationTemplateSelectOption;
}

export interface OptionProps {
  field: WithMode<AnnotationTemplateSelectField>;
  index: number;
  option: null | AnnotationTemplateSelectOption;
}

const Option = ({ field, index, option }: OptionProps) => {
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLLIElement>(null);

  const [, drop] = useDrop<DragItem>({
    accept: DND_ITEM_TYPE.OPTION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Time to actually perform the action
      moveOption(field, dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag, preview] = useDrag({
    type: DND_ITEM_TYPE.OPTION,
    item: () => {
      return { option, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(dragRef);
  drop(preview(previewRef));

  return (
    <li className="mt-3 flex items-center gap-3 first:mt-0" key={index} ref={previewRef}>
      <div
        className="i-skand-reorder cursor-grab color-neutral-400 hover:color-primary-400"
        ref={dragRef}
      />
      <div className="flex-1">
        <Input
          onChange={value => changeOption(field, index, { value })}
          value={option?.value ?? ''}
        />
      </div>
      <div
        className="i-skand-close cursor-pointer color-neutral-400 hover:color-primary-400"
        onClick={() => removeOption(field, index)}
      />

      <Color field={field} index={index} option={option} />

      <div
        className="i-skand-close cursor-pointer color-neutral-400 hover:color-primary-400"
        onClick={() => changeOption(field, index, { color: undefined })}
      />
    </li>
  );
};

export interface OptionEditProps {
  field: WithMode<AnnotationTemplateSelectField>;
}

export const OptionEdit = ({ field }: OptionEditProps) => {
  const options = useMemo(() => field.options ?? [], [field]);

  useEffect(() => {
    if (options.length === 0) {
      addOption(field);
    }
  }, [field, options]);

  return (
    <div>
      <ul className="list-none">
        {options.map((option, index) => {
          return <Option field={field} index={index} key={index} option={option} />;
        })}
      </ul>

      <button
        className={cn(
          'b-0 b-none bg-transparent uppercase color-neutral-800 typo-button-xs',
          options.length !== 0 && 'mt-3',
        )}
        onClick={() => addOption(field)}
      >
        + Add value
      </button>
    </div>
  );
};
