import { LOG_IN } from '@/constants/paths';
import { cn } from '@/utils/classname';
import { Button } from '@skand/ui';
import { useParams } from 'react-router-dom';

interface ErrorParams {
  errorCode: string;
}

export const Error404 = () => {
  const params = useParams<ErrorParams>();
  let message;

  if (params?.errorCode === '403') {
    message = 'You do not have permission to access this page.';
  } else if (params?.errorCode === '404') {
    message = 'The page you are looking for does not exist.';
  } else {
    message = 'Something went wrong, please try again later.';
  }

  return (
    <div className={cn('bg-neutral-800', 'w-full h-full', 'flex justify-center items-center')}>
      <div
        className={cn(
          'bg-neutral-100',
          'w-fit',
          'flex flex-col gap-2 p-6 rounded-md',
          'border-neutral-400 border-1 border-solid',
        )}
      >
        <p className={cn('text-neutral-800 typo-text-l	')}>Oops!</p>
        <p className={cn('text-neutral-500 typo-text-s	')}>{message}</p>
        <Button
          className={cn('mt-2')}
          filled
          onClick={() => {
            window.location.href = LOG_IN;
          }}
          primary
        >
          Return to Homepage
        </Button>
      </div>
    </div>
  );
};
