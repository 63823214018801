import { Annotation } from '@/stores/viewer';
import { cn } from '@/utils/classname';

export interface AnnotationNodeProps {
  annotation: Annotation;
}

export const AnnotationNode = ({ annotation }: AnnotationNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap cursor-pointer')}>
          {annotation.name}
        </p>
        <div
          className={cn(
            annotation.photo ? 'i-skand-annotation2dfilled' : 'i-skand-annotation2doutlined',
          )}
        />
        <div
          className={cn(
            annotation.sketch3D ? 'i-skand-annotation3dfilled' : 'i-skand-annotation3doutlined',
          )}
        />
      </div>
    </div>
  );
};
