export const EXPLORE_ROOT = '/explore';
export const LOG_IN = '/logIn';
export const ROOT = '/';
export const DEBUG = '/debug';
export const DEBUG_TEMPLATE = '/debug/template';

export const EXPLORE_BETA_COMPAT = '/beta/explore'; // for beta backwards compatibility
export const PROJECT_ROOT = `/project`;
export const DATASET_ROOT = '/datasets';

export const ERROR_404 = '/error';
export const EMPTY_RESOURCE = '/empty-resource';

export const MANAGE_ROOT = '/manage';
export const MANAGE_SUBSCRIPTION = `${MANAGE_ROOT}/subscription`;
