import { Pagination } from '@/components/Pagination';
import { NODES_TABLE_DEFAULT_PAGE_INDEX } from '@/constants/node';
import { SystemNodeKindInput } from '@/graphql/codegen/graphql';
import { useSystemNodes } from '@/hooks/useSystemNodes';
import { SystemNode } from '@/stores/systemNodes';
import { cn } from '@/utils/classname';
import { Input } from '@skand/ui';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { TableDense } from '../../TableDense';
import { Breadcrumb } from '../Breadcrumb';

const columnHelper = createColumnHelper<SystemNode>();

export const Finder = ({
  parentNodeId,
  onChangeParentNodeId,
  nodeKinds,
}: {
  parentNodeId: null | string;
  onChangeParentNodeId: (id: null | string) => void;
  nodeKinds?: SystemNodeKindInput[];
}) => {
  const [pageIndex, setPageIndex] = useState(NODES_TABLE_DEFAULT_PAGE_INDEX);
  const [search, setSearch] = useState('');

  const {
    systemNodes,
    pageCount,
    response: { isFetching },
  } = useSystemNodes({
    parentNodeId,
    pageIndex,
    pageSize: 4,
    searchTerm: search || undefined,
    nodeKinds: nodeKinds ?? [SystemNodeKindInput.FileNode, SystemNodeKindInput.FolderNode],
  });

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: () => 'name',
        cell: ({ getValue, row }) => {
          return (
            <span
              className={cn(
                'flex items-center gap-2',
                row.original.type === 'folder'
                  ? 'underline typo-link-s cursor-pointer'
                  : 'typo-text-s',
              )}
            >
              <span
                onClick={() => {
                  if (row.original.type === 'folder') {
                    setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
                    onChangeParentNodeId(row.original.id);
                  }
                }}
              >
                {getValue()}
              </span>
            </span>
          );
        },
        enableSorting: false,
      }),
    ];
  }, [onChangeParentNodeId]);

  const table = useReactTable({
    columns,
    data: systemNodes,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    manualPagination: true,
  });

  return (
    <>
      <Breadcrumb
        before={
          <span className="color-neutral-800 typo-text-s">Your files will be saved to:&nbsp;</span>
        }
        isFetching={isFetching}
        onChangeParentNodeId={id => {
          setPageIndex(NODES_TABLE_DEFAULT_PAGE_INDEX);
          onChangeParentNodeId(id);
        }}
        parentNodeId={parentNodeId}
      />

      <div className="mt-3 flex-1 b-1 b-neutral-400 rounded-2 b-solid p-3">
        <Input
          onChange={setSearch}
          placeholder="Search for folders"
          tail={<div className="i-skand-search ml-2 text-3 color-neutral-400" />}
          value={search}
        />

        {isFetching ? (
          <>
            <div className="h-3" />
            <p className="text-center color-neutral-400 typo-text-s">Loading...</p>
          </>
        ) : systemNodes.length === 0 ? (
          <>
            <div className="h-3" />
            <p className="text-center color-neutral-400 typo-text-s">
              You are uploading files to the current folder.
            </p>
          </>
        ) : (
          <>
            <TableDense className="mt-3" table={table} />
            <div className="mt-3 flex justify-center">
              <Pagination
                currentPage={pageIndex + 1}
                isFetching={isFetching}
                isNextPageDisabled={pageCount ? pageIndex + 1 === pageCount : true}
                isPreviousPageDisabled={pageIndex === 0}
                onPageChange={newPage => setPageIndex(newPage - 1)}
                totalPages={pageCount ?? -1}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
