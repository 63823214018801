import { cn } from '@/utils/classname';
import { ReactNode } from 'react';

export interface CardProps {
  title: string;
  children: ReactNode;
}

export const Card = ({ children, title }: CardProps) => {
  return (
    <div className={cn('b-neutral-400', 'b-1', 'b-solid', 'p-3', 'mt-2', 'rounded-2')}>
      <p className={cn('typo-text-m-em', 'color-neutral-800')}>{title}</p>
      {children}
    </div>
  );
};
