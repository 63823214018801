import { EXPLORE_ROOT } from '@/constants/paths';
import { cn } from '@/utils/classname';
import { isEmpty } from '@/utils/empty';
import { Project } from '@/utils/project';
import { Button } from '@skand/ui';
import { GroupInfoModal } from './GroupInfoModal';

export interface ProjectInfoModalProps {
  projects: Solid<Project, 'location'>[];
  closeModal: () => void;
  setProject: (project: Solid<Project, 'location'>[]) => void;
  multipleProjects: Solid<Project, 'location'>[] | null;
}

export const ProjectInfoModal = ({
  projects,
  closeModal,
  setProject,
  multipleProjects,
}: ProjectInfoModalProps) => {
  if (projects.length > 1) {
    return <GroupInfoModal projects={projects} setProject={setProject} />;
  } else {
    const project = projects[0];
    return (
      <div
        className={cn(
          'flex',
          'w-363px',
          'bg-neutral-100',
          'p-3',
          'rounded-2',
          'border-1px',
          'border-solid',
          'border-neutral-400',
          'flex-col',
          'cursor-pointer',
        )}
        onClick={ev => ev.stopPropagation()}
        onDoubleClick={ev => ev.stopPropagation()}
        onDrag={ev => ev.stopPropagation()}
        onWheel={ev => ev.stopPropagation()}
      >
        <button
          className={cn('cursor-pointer bg-transparent b-none absolute right-12px')}
          onClick={closeModal}
        >
          <div className={cn('text-12px text-right text-gray-400 i-skand-close')} />
        </button>
        <div className={cn(' w-full flex flex-col')}>
          <div className={cn('flex', 'flex-row', 'items-center')}>
            {!isEmpty(multipleProjects) && (
              <div
                className={cn(
                  'i-skand-dropdown cursor-pointer',
                  'color-neutral-400',
                  'text-3',
                  'mr-2',
                  'rotate-90',
                )}
                onClick={() => setProject(multipleProjects)}
              />
            )}
            <span className="text-14 color-neutral-700 typo-text-medium-em">{project.name}</span>
          </div>
          <div className={cn('flex', 'w-full', 'justify-between', 'mt-2')}>
            <span className="pr-24px color-neutral-500 typo-text-s">Address</span>
            <span className="text-end color-neutral-700 typo-text-s">{project.address}</span>
          </div>
          <div className={cn('flex', 'w-full', 'justify-between', 'mt-2')}>
            <span className="color-neutral-500 typo-text-s">Created Date</span>
            <span className="text-end color-neutral-700 typo-text-s">
              {project.createdAt.toLocaleDateString()}
            </span>
          </div>
        </div>

        <Button
          className={cn('cursor-pointer w-full mt-2')}
          filled
          onClick={() => {
            window.location.href = `${EXPLORE_ROOT}?project=${project.id}`;
          }}
          primary
          size="s"
        >
          Launch in 3D
        </Button>
      </div>
    );
  }
};
