import { FindIcon } from '@/components/IconButton';
import { cn } from '@/utils/classname';
import { search } from '@/utils/search';
import { Button, Input, Toast } from '@skand/ui';
import { useMemo, useState } from 'react';
import { OptionsFilter } from './OptionsFilter';
import { Template, TemplateField } from './Template';
import { TemplateFilterKey } from './TemplateFilterKey';

export interface FieldsFilterMenuProps {
  template: Template;
  filterKeys: Set<TemplateFilterKey>;
  setFilterKeys: (values: Set<TemplateFilterKey>) => void;
}

export const FieldsFilterMenu = ({
  template,
  filterKeys,
  setFilterKeys,
}: FieldsFilterMenuProps) => {
  const templateFilterKeys = useMemo(() => {
    const result: TemplateFilterKey[] = [];
    for (const key of filterKeys) {
      if (key.templateId === template.id) {
        result.push(key);
      }
    }
    return result;
  }, [filterKeys, template.id]);

  const [expandedFields, setExpandedFields] = useState<Set<TemplateField>>(new Set());
  const [searchKey, setSearchKey] = useState('');

  const filtered = useMemo(
    () =>
      template.fields.filter(field => {
        const fieldMatch = search(field.name, searchKey);
        const optionMatch = field.options.some(option => search(option.value, searchKey));
        return fieldMatch || optionMatch;
      }),
    [searchKey, template.fields],
  );

  // Clear the filters for the template
  const clearSelection = () => {
    for (const key of templateFilterKeys) {
      filterKeys.delete(key);
    }
    setFilterKeys(new Set(filterKeys));
  };

  return (
    <div
      className={cn(
        'absolute',
        'w-full',
        'h-full',
        'top-0',
        'left-100%',
        'border-neutral-400',
        'border-1',
        'border-solid',
        'rounded-lg',
        'bg-neutral-100',
        'p-3',
        'flex flex-col',
      )}
    >
      <div className="w-full">
        <Input
          data-testid="input"
          label="Search"
          onChange={setSearchKey}
          tail={<FindIcon />}
          value={searchKey}
        />
      </div>
      <div className={cn('w-238px mt-12px')}>
        <Toast
          clickToDismiss
          dismiss={() => {}}
          message="Only dropdown fields can be filtered."
          props={{
            className: 'shadow-none',
          }}
          type="info"
        />
      </div>

      <div className="flex justify-end gap-2">
        <div
          className={cn('typo-text-xs', 'color-neutral-500', 'mt-3 mb-3', 'cursor-pointer')}
          onClick={() => setExpandedFields(new Set(template.fields))}
        >
          <p>EXPAND ALL</p>
        </div>
        <div
          className={cn('typo-text-xs', 'color-neutral-500', 'mt-3 mb-3', 'cursor-pointer')}
          onClick={() => setExpandedFields(new Set())}
        >
          <p>COLLAPSE ALL</p>
        </div>
      </div>
      <div className="h-full flex flex-col gap-2 overflow-y-auto">
        {filtered.map(field => (
          <OptionsFilter
            expandedFields={expandedFields}
            field={field}
            filterKeys={filterKeys}
            key={field.id}
            setExpandedFields={setExpandedFields}
            setFilterKeys={setFilterKeys}
            template={template}
            templateFilterKeys={templateFilterKeys}
          />
        ))}
      </div>
      <div
        className={cn(
          'flex',
          'items-center justify-between gap-2',
          'mt-2',
          ' bottom-0',
          'sticky',
          'bg-white',
        )}
      >
        <Button
          className={cn('w-full')}
          disabled={templateFilterKeys.length === 0}
          onClick={clearSelection}
          size="s"
        >
          Clear selection
        </Button>
      </div>
    </div>
  );
};
