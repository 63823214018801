import { Annotation } from '@/stores/viewer';
import { cn } from '@/utils/classname';

export interface AnnotationNodeProps {
  annotation: Annotation;
}

export const AnnotationNode = ({ annotation }: AnnotationNodeProps) => {
  return (
    <div className={cn('flex items-center py-1 w-[500px]')}>
      <div className="w-3/6 flex overflow-x-scroll">
        <div className={cn('flex   gap-2 text-neutral-600')}>
          <div
            className={cn(
              annotation.photo ? 'i-skand-annotation2dfilled' : 'i-skand-annotation2doutlined',
            )}
          />
          <div
            className={cn(
              annotation.sketch3D ? 'i-skand-annotation3dfilled' : 'i-skand-annotation3doutlined',
            )}
          />
          <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap cursor-pointer')}>
            {annotation.name}
          </p>
        </div>
      </div>
    </div>
  );
};
