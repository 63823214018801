export const DeprojectionIcon = () => {
  return (
    <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M5.75293 15.3739L0.953125 17.5802V3.15948L5.75293 0.952148L14.2486 3.15948L19.0484 0.952148V15.3739L14.2486 17.5802L5.75293 15.3739Z"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.42857"
      />
      <path
        d="M14.1562 17.5805V2.90869"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.952382"
      />
      <path
        d="M5.84375 0.952148V15.1349"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.952382"
      />
      <path
        d="M7.14258 12.857L9.99972 10.4761L12.8569 12.857"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.952382"
      />
      <path
        d="M10 10.4761V20.4761"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.952382"
      />
    </svg>
  );
};
