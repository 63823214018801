export const ArrowIcon = () => {
  return (
    <svg fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.01038 14.4956H14.4957V6.01032"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M5.65687 4.2426C5.26635 3.85208 4.63319 3.85208 4.24266 4.2426C3.85214 4.63312 3.85214 5.26629 4.24266 5.65681L5.65687 4.2426ZM15.2028 13.7885L5.65687 4.2426L4.24266 5.65681L13.7886 15.2028L15.2028 13.7885Z"
        fill="currentColor"
      />
    </svg>
  );
};
