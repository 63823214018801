import { cn } from '@/utils/classname';
import { useState } from 'react';
import { Color } from 'three';
import { ColorPickerMenu } from '../ColorPickerMenu';

export interface ColorPaletteProps {
  palette?: [Color, Color, Color, Color, Color, Color, Color];
  selected?: Color;
  selectColor: (color: Color) => void;
}

export const ColorPalette = ({
  palette = [
    new Color('#D04A6B'),
    new Color('#F69C64'),
    new Color('#4CB176'),
    new Color('#3F8AE2'),
    new Color('#8A1AE3'),
    new Color('#8A858E'),
    new Color('#FFFFFF'),
  ],
  selected,
  selectColor,
}: ColorPaletteProps) => {
  const [colors, setColors] = useState<[Color, Color, Color, Color, Color, Color, Color]>(palette);
  const [customColor, setCustomColor] = useState(new Color('#FF0000'));
  const [showColorPicker, setShowColorPicker] = useState(false);

  // Push a new color to the palette
  const handlePushColor = () => {
    for (let i = 1; i < 7; i++) {
      colors[i - 1] = colors[i];
    }
    colors[6] = customColor;
    setColors([...colors]);
    setShowColorPicker(false);
  };

  return (
    <div className={cn('w-250px border-1px flex flex-row justify-around')}>
      {colors.map((color, index) => (
        <div
          className={cn(
            'h-12px',
            'w-12px',
            'mr-4',
            'b-rounded-50',
            'b-solid',
            'cursor-pointer',
            color.getHex() === 0xffffff || color.getHex() === selected?.getHex()
              ? 'b-1.5px'
              : 'b-0',
            color.getHex() === 0xffffff ? 'b-neutral-400' : 'b-primary-400',
          )}
          key={`color-${index}`}
          onClick={() => selectColor(color)}
          style={{ background: `#${color.getHexString()}` }}
        />
      ))}
      <div
        className={cn(
          'i-skand-close',
          '[transform:rotate(45deg)]',
          'h-12px',
          'w-12px',
          'color-neutral-400',
          'cursor-pointer',
        )}
        onClick={() => setShowColorPicker(!showColorPicker)}
      />
      {showColorPicker && (
        <ColorPickerMenu
          closeMenu={handlePushColor}
          color={customColor}
          selectColor={setCustomColor}
        />
      )}
    </div>
  );
};
