import { useShareLink } from '@/stores/shareLink';
import { AnnotationGroup, Layer, LayerGroup, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { useMemo } from 'react';
import { Graph } from '@skand/math';

export interface LayerGroupNodeProps {
  group: LayerGroup;
}

export const LayerGroupNode = ({ group }: LayerGroupNodeProps) => {
  const layers = useViewer(state => state.layers);
  const layerGroups = useViewer(state => state.layerGroups);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const visibleLayerIds = useShareLink(state => state.visibleLayerIds);
  const selectedSceneEntityIds = useShareLink(state => state.selectedSceneEntityIds);
  const isEnabled = selectedSceneEntityIds.includes(group.sceneEntityId);

  const [members] = useMemo(() => {
    const entities = [...layerGroups, ...layers, ...annotationGroups];
    const graph = new Graph<Layer | LayerGroup | AnnotationGroup>();
    for (const entity of entities) {
      graph.addNode(entity);
      if (entity.parent) {
        graph.addEdge(entity.parent, entity);
      }
    }

    const members: (Layer | LayerGroup)[] = [];

    graph.bfs(group, node => {
      switch (node.type) {
        case 'layerGroup':
        case 'layer':
          members.push(node);
          break;
      }
    });

    return [members];
  }, [layerGroups, layers, annotationGroups, group]);

  const isVisible = members.some(layer => visibleLayerIds.has(layer.id));

  const handleToggle = () => {
    if (isVisible) {
      useShareLink.setState(prev => {
        const visibleLayerIds = new Set(prev.visibleLayerIds);
        for (const layer of members) {
          visibleLayerIds.delete(layer.id);
        }
        return { visibleLayerIds };
      });
    } else {
      useShareLink.setState(prev => {
        const visibleLayerIds = new Set(prev.visibleLayerIds);
        for (const layer of members) {
          visibleLayerIds.add(layer.id);
        }
        return { visibleLayerIds };
      });
    }
  };

  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className="i-skand-layers w-5 text-3 color-neutral-600" />
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap ')}>{group.name}</p>

        {isEnabled && (
          <div
            className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2"
            style={{ boxShadow: '-8px 0px 8px -2px rgba(255,255,255,1)' }}
          >
            <div
              className={cn(isVisible ? 'i-skand-show' : 'i-skand-hide')}
              onClick={handleToggle}
            />
          </div>
        )}
      </div>
    </div>
  );
};
