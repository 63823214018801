import { Layer, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, Dropdown, DropdownItem } from '@skand/ui';
import { ModelNode, PointCloudStyle, Tileset, TilesetStyle } from '@skand/viewer-component-v2';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InputSlider } from '../InputSlider';
import { ClassificationSettings } from './ClassificationSettings';
import { ElevationSettings } from './ElevationSettings';
import { IntensitySettings } from './IntensitySettings';
import { RGBSettings } from './RGBSettings';

const COLORING_MODES = {
  rgb: 'RGB',
  intensity: 'Intensity',
  classification: 'Classification',
  elevation: 'Elevation',
} as const;

export interface PointCloudSettingsMenuProps {
  layer: Layer;
}

export const PointCloudTab = ({ layer }: PointCloudSettingsMenuProps) => {
  const tileset = (layer.sceneNode as ModelNode).getModel() as Tileset;

  const viewerSettings = useViewer(state => state.viewer3DSettings);
  const tilesetSettings = useViewer(state => state.tilesetSettings.get(layer.id));

  const initialStyle = useMemo(() => tileset.getStyle(), [tileset]);
  const initialMSSE = useMemo(() => tileset.getMSSE(), [tileset]);

  const style = tilesetSettings?.style ?? initialStyle;
  const msse = tilesetSettings?.msse ?? initialMSSE;

  const [dropdown, setDropdown] = useState<HTMLElement | null>(null);

  // Update the tileset style
  useEffect(() => {
    tileset.setStyle(style);
    tileset.setMSSE(msse);
  }, [msse, style, tileset]);

  // Update the style in the viewer state
  const setStyle = useCallback(
    (style: TilesetStyle) => {
      useViewer.setState(prev => {
        const tilesetSettings = new Map(prev.tilesetSettings);
        tilesetSettings.set(layer.id, { style, msse });
        return { tilesetSettings };
      });
    },
    [layer.id, msse],
  );

  // Update the point cloud density in the viewer state
  const setDensity = useCallback(
    (density: number) => {
      // Invert the density to determine MSSE
      const msse = viewerSettings.basePointCloudMSSE + 33 - density;
      useViewer.setState(prev => {
        const tilesetSettings = new Map(prev.tilesetSettings);
        tilesetSettings.set(layer.id, { style, msse });
        return { tilesetSettings };
      });
    },
    [layer.id, style, viewerSettings.basePointCloudMSSE],
  );

  // Coloring settings menu
  const ColorSettings = useMemo(() => {
    switch (style.type) {
      case 'rgb':
        return <RGBSettings setStyle={setStyle} style={style} />;
      case 'classification':
        return <ClassificationSettings setStyle={setStyle} style={style} />;
      case 'intensity':
        return <IntensitySettings setStyle={setStyle} style={style} />;
      case 'elevation':
        return <ElevationSettings setStyle={setStyle} style={style} tileset={tileset} />;
    }
  }, [setStyle, style, tileset]);

  // Handle selecting a color mode
  const handleColorSelect = (type: PointCloudStyle) => {
    setStyle({ ...style, type });

    dropdown?.focus();
    dropdown?.blur();
  };

  // Reset to the initial style.
  const handleReset = () => {
    useViewer.setState(prev => {
      const tilesetSettings = new Map(prev.tilesetSettings);
      tilesetSettings.set(layer.id, {
        style: tileset.getDefaultStyle(),
        msse: viewerSettings.basePointCloudMSSE + 17,
      });
      return { tilesetSettings };
    });
  };

  return (
    <div className={cn('flex flex-col max-h-120')}>
      <InputSlider
        max={10}
        min={1}
        setValue={pointSize => setStyle({ ...style, pointSize })}
        step={1}
        title="Point Size"
        value={style.pointSize}
      />
      <InputSlider
        max={32}
        min={1}
        setValue={setDensity}
        step={1}
        title="Point Density"
        value={viewerSettings.basePointCloudMSSE + 33 - msse}
      />

      <p className={cn('typo-text-small text-neutral-800 mb-2')}>Point cloud color</p>

      <Dropdown className={cn('z-10')} label={COLORING_MODES[style.type]} ref={setDropdown}>
        {Object.keys(COLORING_MODES).map((mode, index) => (
          <DropdownItem key={index} onClick={() => handleColorSelect(mode as PointCloudStyle)}>
            {COLORING_MODES[mode as PointCloudStyle]}
          </DropdownItem>
        ))}
      </Dropdown>
      <div className="overflow-y-scroll">{ColorSettings}</div>
      <div className={cn('flex gap-2 flex-col mt-3')}>
        <Button className="w-full" onClick={handleReset} size="s">
          Reset Styling
        </Button>
      </div>
    </div>
  );
};
