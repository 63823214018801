import { cn } from '@/utils/classname';

export const Spinner = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        'h-3 w-3 animate-spin rounded-full',
        'b-2 b-b-neutral-400 b-l-neutral-400 b-r-neutral-400 b-t-neutral-500 b-solid',
        className,
      )}
    />
  );
};
