import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { useFetchPermissionPolicies } from './useFetchPermissionPolicies';
import { useCallback, useMemo } from 'react';
import { ACTION_TYPE, OBJECT_ID, OBJECT_TYPE } from '@/constants/policy';
import { useQuery } from '@tanstack/react-query';
import { useFetchSceneEntities } from './useFetchSceneEntities';
import { LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES } from '@/graphql/queries';
import { useExplore } from '@/stores/explore';
import { request } from '@/graphql/request';
import { useFetchProjectPermissions } from './useFetchProjectPermissions';
import { useIsSuperAdmin } from './useIsSuperAdmin';

export const useFetchSceneEntityPermissions = () => {
  const projectId = useExplore(state => state.projectId);
  const { getProjectPermission } = useFetchProjectPermissions();
  const isSuperAdmin = useIsSuperAdmin();

  const sceneEntitiesResult = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES, {
        projectId: projectId as string,
        rendererTypes: [],
      }),
    queryKey: useFetchSceneEntities.getSceneEntityQueryKey(projectId),
  });

  const sceneEntityIds = useMemo(() => {
    const ids: string[] = [];
    for (const sceneEntity of sceneEntitiesResult.data
      ?.listSceneEntitiesByProjectIdAndRendererTypes ?? []) {
      if (sceneEntity?.id) {
        ids.push(sceneEntity.id);
      }
    }
    return ids;
  }, [sceneEntitiesResult.data?.listSceneEntitiesByProjectIdAndRendererTypes]);

  const policies = useFetchPermissionPolicies(PolicyObjectTypeInput.SceneEntity, sceneEntityIds);
  const projectPermission = useMemo(
    () => getProjectPermission(projectId),
    [projectId, getProjectPermission],
  );

  const allSceneEntitiesPermission = useMemo(() => {
    if (isSuperAdmin) return ACTION_TYPE.ADMIN;
    if (
      projectPermission === ACTION_TYPE.ADMIN ||
      policies.some(
        policy =>
          policy.actionType === ACTION_TYPE.ADMIN &&
          policy.objectId === OBJECT_ID.ALL &&
          (policy.objectType === OBJECT_TYPE.ALL || policy.objectType === OBJECT_TYPE.SCENE_ENTITY),
      )
    ) {
      return ACTION_TYPE.ADMIN;
    } else if (
      projectPermission === ACTION_TYPE.EDIT ||
      policies.some(
        policy =>
          policy.actionType === ACTION_TYPE.EDIT &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.SCENE_ENTITY,
      )
    ) {
      return ACTION_TYPE.EDIT;
    } else if (
      projectPermission === ACTION_TYPE.READ ||
      policies.some(
        policy =>
          policy.actionType === ACTION_TYPE.READ &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.SCENE_ENTITY,
      )
    ) {
      return ACTION_TYPE.READ;
    } else if (
      projectPermission === ACTION_TYPE.LIST ||
      policies.some(
        policy =>
          policy.actionType === ACTION_TYPE.LIST &&
          policy.objectId === OBJECT_ID.ALL &&
          policy.objectType === OBJECT_TYPE.SCENE_ENTITY,
      )
    ) {
      return ACTION_TYPE.LIST;
    } else {
      return null;
    }
  }, [policies, isSuperAdmin, projectPermission]);

  const getSceneEntityPermission = useCallback(
    (sceneEntityId: string | null) => {
      const findActionType = (actionType: PolicyActionTypeInput) => {
        if (!sceneEntityId) return false;
        return policies.some(
          policy => policy.objectId === sceneEntityId && policy.actionType === actionType,
        );
      };
      if (
        allSceneEntitiesPermission === ACTION_TYPE.ADMIN ||
        projectPermission === ACTION_TYPE.ADMIN ||
        findActionType(PolicyActionTypeInput.Admin)
      ) {
        return ACTION_TYPE.ADMIN;
      } else if (
        allSceneEntitiesPermission === ACTION_TYPE.EDIT ||
        projectPermission === ACTION_TYPE.EDIT ||
        findActionType(PolicyActionTypeInput.Edit)
      ) {
        return ACTION_TYPE.EDIT;
      } else if (
        allSceneEntitiesPermission === ACTION_TYPE.READ ||
        projectPermission === ACTION_TYPE.READ ||
        findActionType(PolicyActionTypeInput.Read)
      ) {
        return ACTION_TYPE.READ;
      } else if (
        allSceneEntitiesPermission === ACTION_TYPE.LIST ||
        projectPermission === ACTION_TYPE.LIST ||
        findActionType(PolicyActionTypeInput.List)
      ) {
        return ACTION_TYPE.LIST;
      } else {
        return null;
      }
    },
    [allSceneEntitiesPermission, policies, projectPermission],
  );

  return {
    allSceneEntitiesPermission,
    getSceneEntityPermission,
  };
};
