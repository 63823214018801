import { useEffect, useRef } from 'react';
import Stats from 'stats.js';

export const StatisticsMonitor = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;
    const stats = new Stats();
    stats.showPanel(0);

    stats.dom.style.position = 'absolute';
    stats.dom.style.left = '70px';
    stats.dom.style.top = '6px';
    containerRef.current.appendChild(stats.dom);

    const animate = () => {
      stats.begin();
      stats.end();
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  }, [containerRef]);
  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        right: '0',
        zIndex: '100',
        cursor: 'pointer',
      }}
    />
  );
};
