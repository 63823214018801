import { queryClient } from '@/graphql/client';
import { CREATE_PROJECT_GROUP } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccount } from '@/hooks/useAccount';
import { useFetchProjects } from '@/hooks/useFetchProjects';
import { useProjectStore } from '@/stores/project';
import { Project } from '@/utils/project';
import { search } from '@/utils/search';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, Input, cn } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { ProjectItem } from './ProjectItem';
import { ProjectItemSelected } from './ProjectItemSelected';

export interface CreateGroupProps {
  projects: Project[];
  active: boolean;
  setActive: (value: boolean) => void;
  handleResetGroupSearchAndDialogState: () => void;
}

export const CreateGroup = ({
  projects,
  active,
  setActive,
  handleResetGroupSearchAndDialogState,
}: CreateGroupProps) => {
  const projectIds = useProjectStore(state => state.createGroup.projectIds);
  const accountId = useAccount().data?.accountByContext?.id;

  const [name, setName] = useState('');
  const [searchKey, setSearchKey] = useState('');

  const ungroupedProjects = useMemo(() => projects.filter(project => !project.group), [projects]);
  const selectedProjects = useMemo(
    () => ungroupedProjects.filter(project => projectIds.includes(project.id)),
    [projectIds, ungroupedProjects],
  );
  const filteredProjects = useMemo(
    () => ungroupedProjects.filter(project => search(project.name, searchKey)),
    [searchKey, ungroupedProjects],
  );

  // Create group mutation
  const createGroup = useMutation({
    mutationFn: () =>
      request(CREATE_PROJECT_GROUP, {
        accountId: accountId as string,
        name,
        projectIds,
        description: '',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProjects.getProjectGroupQueryKey());
      queryClient.invalidateQueries(useFetchProjects.getProjectQueryKey());
      handleResetGroupSearchAndDialogState();
      setName('');
      setSearchKey('');
      useProjectStore.setState({ createGroup: { projectIds: [] } });
    },
  });

  // Handle cancel
  const handleCancel = () => {
    setActive(false);
    setName('');
    setSearchKey('');
    useProjectStore.setState({ createGroup: { projectIds: [] } });
  };

  // Handle group creation
  const handleCreate = () => {
    if (accountId) {
      createGroup.mutate();
    }
  };

  return (
    <Dialog.Root onOpenChange={setActive} open={active}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <div
          className={cn(
            'fixed left-0 top-0 z-50 h-full w-full flex items-center justify-center',
            ' bg-black bg-opacity-30',
          )}
        >
          <Dialog.Content
            className={cn(
              'justify-between flex flex-col shadow-lg z-50 border-1 border-neutral-400 rounded-md border-solid bg-white ',
              'h-90dvh w-[640px] p-6  ',
            )}
          >
            <Dialog.Title className="color-neutral-800 typo-text-l">
              Create a project group
            </Dialog.Title>

            <div className="mt-6 flex-none">
              <Input onChange={setName} placeholder="Group name" value={name} />
            </div>

            <div className="mt-3 overflow-auto b-1 b-neutral-400 rounded-2 b-solid p-3">
              <Input
                onChange={setSearchKey}
                placeholder="Search for project name"
                value={searchKey}
              />

              <div className="mt-3 flex b-b-1 b-b-neutral-500 b-b-solid pb-2">
                <span className="flex-[0_0_50%] uppercase color-neutral-800 typo-button-xs">
                  selected projects
                </span>
                <span className="flex-[0_0_50%] uppercase color-neutral-800 typo-button-xs">
                  date created
                </span>
              </div>

              {selectedProjects.length === 0 ? (
                <div className="mt-3 h-9 flex items-center justify-center b-1 b-neutral-300 rounded-1 b-dashed">
                  <div className="i-skand-add text-3 color-neutral-400" />
                </div>
              ) : (
                selectedProjects.map(project => (
                  <ProjectItemSelected key={project.id} project={project} />
                ))
              )}

              <div className="mt-3 flex b-y-1 b-y-neutral-500 b-y-solid py-2">
                <span className="flex-[0_0_50%] uppercase color-neutral-800 typo-button-xs">
                  ungrouped projects
                </span>
                <span className="flex-[0_0_50%] uppercase color-neutral-800 typo-button-xs">
                  date created
                </span>
              </div>

              {filteredProjects.length > 0 ? (
                filteredProjects.map(project => <ProjectItem key={project.id} project={project} />)
              ) : (
                <>
                  {searchKey.length > 0 && (
                    <div className="mt-3 h-5 flex items-center justify-center">
                      <p className="text-neutral-800 typo-text-xs">No matching record found</p>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="mt-auto flex gap-3 pt-3">
              <Dialog.Close asChild>
                <Button className="flex-1 hover:cursor-pointer" onClick={handleCancel} size="s">
                  Cancel
                </Button>
              </Dialog.Close>
              <Button
                className="flex-1 hover:cursor-pointer"
                filled
                onClick={handleCreate}
                primary
                size="s"
              >
                Create
              </Button>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
