import {
  MarkerClustererF,
  MarkerClustererProps,
  MarkerF,
  MarkerProps,
} from '@react-google-maps/api';
import { toLatLng } from './Utils';
import { Vector3 } from 'three';

export interface MarkerGroupProps {
  positions: Vector3[];
  markerIcon: MarkerProps['icon'];
  clusterStyles: MarkerClustererProps['styles'];
  onMarkerSelect: (index: number) => void;
}

export const MarkerGroup = ({
  positions,
  markerIcon,
  clusterStyles,
  onMarkerSelect,
}: MarkerGroupProps) => {
  return (
    positions.length > 0 && (
      <MarkerClustererF
        averageCenter
        minimumClusterSize={1000}
        onUnmount={clusterer => clusterer.clearMarkers()}
        styles={clusterStyles}
      >
        {clusterer => (
          <>
            {positions.map((position, index) => (
              <MarkerF
                clusterer={clusterer}
                key={`marker-${index}`}
                onClick={() => onMarkerSelect(index)}
                options={{
                  icon: markerIcon,
                }}
                position={toLatLng(position)}
              />
            ))}
          </>
        )}
      </MarkerClustererF>
    )
  );
};
