export const MapIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M11.7254 22.0569L6.89539 24.2449V9.94388L11.7254 7.75488L20.2744 9.94388L25.1044 7.75488V22.0569L20.2744 24.2449L11.7254 22.0569Z"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.685"
      />
      <path
        d="M20.2743 24.2444V9.94336"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7253 7.75488V22.0569"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
