import { cn } from '@/utils/classname';
import { colorNeutral100, colorNeutral400, colorPrimary300 } from '@skand/variables/variables';
import { CSSProperties, ChangeEventHandler, InputHTMLAttributes } from 'react';

export interface SliderProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  max?: number;
  min?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  step?: number;
  value: number;
}

export const Slider = ({
  className,
  max = 9,
  min = 0,
  onChange,
  step = 1,
  style,
  value,
  ...props
}: SliderProps) => {
  const progressNum = ((value - min) / (max - min)) * 100;
  const progress = `${progressNum}%`;

  return (
    <input
      className={cn(
        'appearance-none',
        'bg-neutral-100',
        'border-1',
        'border-double',
        'border-transparent',
        'cursor-pointer',
        'h-6px',
        'rounded-full',

        'disabled:cursor-not-allowed',

        '[&::-webkit-slider-thumb]:appearance-none',
        '[&::-webkit-slider-thumb]:bg-neutral-100',
        '[&::-webkit-slider-thumb]:border-1',
        '[&::-webkit-slider-thumb]:border-neutral-500',
        '[&::-webkit-slider-thumb]:border-solid',
        '[&::-webkit-slider-thumb]:h-3',
        '[&::-webkit-slider-thumb]:rounded-full',
        '[&::-webkit-slider-thumb]:w-3',

        className,
      )}
      max={max}
      min={min}
      onChange={onChange}
      step={step}
      style={
        {
          '--right-color': colorPrimary300,
          '--left-color': colorNeutral100,
          '--right-border': colorNeutral400,
          '--progress': progress,
          backgroundImage: `linear-gradient(to right, var(--right-color) var(--progress), var(--left-color) var(--progress)),
                            linear-gradient(to right, var(--right-color) var(--progress), var(--right-border) var(--progress))`,
          backgroundOrigin: 'border-box',
          backgroundClip: 'content-box, border-box',
          ...style,
        } as CSSProperties
      }
      type="range"
      value={value}
      {...props}
    />
  );
};
