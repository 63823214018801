import { SceneEntity } from '@/components/SceneEntityTree';
import { AnnotationGroup, Layer, LayerGroup } from '@/stores/viewer';

// Get all scene entity id's including decendants
export const getSceneEntityIds = (node: SceneEntity) => {
  const childIds: string[] = [];
  const stack: SceneEntity[] = [node];

  while (stack.length > 0) {
    const current = stack.pop();
    if (!current) continue;
    if ('sceneEntityId' in current.entity) {
      childIds.push(current.entity.sceneEntityId);
    }

    for (let i = current.children.length - 1; i >= 0; i--) {
      stack.push(current.children[i]);
    }
  }

  return childIds;
};

// Get all scene entity id's including ascendant
export const getAllSceneEntityIds = (array: (Layer | LayerGroup | AnnotationGroup)[]) => {
  const sceneEnIds: string[] = [];
  const stack: (Layer | LayerGroup | AnnotationGroup)[] = [...array]; // Start with the initial array of objects

  while (stack.length > 0) {
    const current = stack.pop();
    if (!current) continue;
    sceneEnIds.push(current.sceneEntityId);

    // Traverse through the nested parent objects
    let parent = current.parent;
    while (parent) {
      sceneEnIds.push(parent.sceneEntityId);
      parent = parent.parent;
    }
  }

  return sceneEnIds;
};
