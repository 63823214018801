import { useLayout } from '@/stores/layout';
import { cn } from '@/utils/classname';
import { AnnotationEdit } from './AnnotationEdit';
import { useViewer } from '@/stores/viewer';
import { MeasurementView } from './MeasurementView';

export const RightSideBar = () => {
  const isShowing = useLayout(state => state.isShowingRightSideBar);
  const targetMeasurement = useViewer(state => state.targetMeasurement);

  return (
    (isShowing || targetMeasurement) && (
      <div
        className={cn(
          'h-full',
          'w-400px',
          'bg-neutral-100',
          'absolute',
          'right-0',
          'p-y-18px',
          'pl-18px',
          'pr-18px',
          'flex',
          'flex-col',
          'overflow-hidden',
        )}
      >
        {targetMeasurement ? <MeasurementView /> : <AnnotationEdit />}
      </div>
    )
  );
};
