import { MoreMenu } from '@/components/MoreMenu';
import { DATASET_ROOT, EXPLORE_ROOT } from '@/constants/paths';
import { canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import { DELETE_PROJECT, UPDATE_PROJECT } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchProjectPermissions } from '@/hooks/useFetchProjectPermissions';
import { useFetchProjects } from '@/hooks/useFetchProjects';
import { setEditProjectProject, setMoveProjectProject, useProjectStore } from '@/stores/project';
import { cn } from '@/utils/classname';
import { Project, ProjectGroup } from '@/utils/project';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, CheckBox, Menu, MenuItem } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { ConfirmationModalState, DeleteConfirmationModal } from '../DeleteConfirmationModal';
import { MoveProject } from '../MoveProject';

interface ProjectRowProps {
  project: Project;
  groups: ProjectGroup[];
  isSelected: boolean;
  onSelect: (selected: boolean) => void;
  style?: React.CSSProperties;
}

export const ProjectRow = ({ project, groups, isSelected, onSelect, style }: ProjectRowProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [rowWidth, setRowWidth] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });
  const setOpenEditProject = (open: boolean) => setEditProjectProject(open ? project : null);

  const { getProjectPermission } = useFetchProjectPermissions();
  const projectPermission = getProjectPermission(project.id);

  const hasProjectGroup = !!project.group;

  const moveProject = useProjectStore(state => state.moveProject.project);
  const openMoveProject = project.id === moveProject?.id;
  const setOpenMoveProject = (open: boolean) => setMoveProjectProject(open ? project : null);

  const unGroupProjects = useMutation({
    mutationFn: () =>
      request(UPDATE_PROJECT, {
        projectId: project.id,
        projectGroupId: null,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProjects.getProjectGroupQueryKey());
      queryClient.invalidateQueries(useFetchProjects.getProjectQueryKey());
      setMoveProjectProject(null);
    },
  });

  const removeProject = useMutation({
    mutationFn: () => request(DELETE_PROJECT, { projectId: project.id }),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProjects.getProjectGroupQueryKey());
      queryClient.invalidateQueries(useFetchProjects.getProjectQueryKey());
    },
  });

  const handleDeleteProject = useCallback(() => {
    setOpenConfirmationModal({
      isOpen: true,
      title: 'Delete Project',
      description: `Are you sure you want to delete the project ${project.name}?`,
      actionButton: 'Delete Project',
      actionFunction: () => {
        removeProject.mutate();
        setOpenConfirmationModal(state => {
          return { ...state, isOpen: false };
        });
      },
    });
  }, [removeProject, project]);

  useEffect(() => {
    if (container) {
      const observer = new ResizeObserver(entries => {
        setRowWidth(entries[0].contentRect.width);
      });
      observer.observe(container);
      return () => container && observer.unobserve(container);
    }
  }, [container]);

  return (
    <div
      className={cn('flex', 'flex-1', 'items-center', 'p-x-2')}
      key={project.id}
      ref={setContainer}
      style={style}
    >
      <DeleteConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      <div className={cn('flex', 'flex-row', 'py-4', 'text-left', 'w-35%')}>
        <label className="flex items-center">
          <CheckBox
            checked={isSelected}
            className="mr-4 text-3 color-neutral-500"
            onChange={e => {
              onSelect(e.target.checked);
            }}
          />
        </label>
        <a
          className="cursor-pointer truncate color-neutral-800 no-underline typo-text-s hover:underline"
          href={`${DATASET_ROOT}/projects/${project.id}`}
          title={project.name}
        >
          {project.name}
        </a>
      </div>

      <div className={cn('py-4', 'text-left', 'w-10%', rowWidth < 480 && 'hidden')}>
        <span className="color-neutral-800 typo-text-s">
          {project.createdAt.toLocaleDateString()}
        </span>
      </div>

      <div className="flex-1 py-4 pl-5 pr-5 text-left">
        <span className={cn('color-neutral-800', 'typo-text-s', rowWidth < 640 && 'hidden')}>
          {project.address}
        </span>
      </div>

      <div>
        <div className="flex items-center justify-end gap-3">
          <Button
            onClick={() => {
              window.location.href = `${EXPLORE_ROOT}?project=${project.id}`;
            }}
            primary
            size="s"
          >
            Launch in 3D
          </Button>

          <MoreMenu>
            <Menu className="z-1 w-110px">
              <MenuItem
                onClick={() => {
                  window.location.href = `${DATASET_ROOT}/projects/${project.id}`;
                }}
              >
                Manage Project
              </MenuItem>
              <MenuItem onClick={() => setOpenEditProject(true)}>View details</MenuItem>
              {canPolicyActionEdit(projectPermission) && (
                <>
                  {hasProjectGroup && (
                    <MenuItem onClick={() => unGroupProjects.mutate()}>Ungroup</MenuItem>
                  )}
                  <Dialog.Root onOpenChange={setOpenMoveProject} open={openMoveProject}>
                    <Dialog.Trigger asChild>
                      <MenuItem>Move to</MenuItem>
                    </Dialog.Trigger>
                  </Dialog.Root>
                  <MenuItem onClick={handleDeleteProject}>Delete</MenuItem>
                </>
              )}
            </Menu>
          </MoreMenu>

          <Dialog.Root onOpenChange={setOpenMoveProject} open={openMoveProject}>
            <Dialog.Portal>
              <Dialog.Overlay />
              {openMoveProject && <MoveProject groups={groups} projectIds={[project.id]} />}
            </Dialog.Portal>
          </Dialog.Root>
        </div>
      </div>
    </div>
  );
};
