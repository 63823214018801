import { Model3D, Tileset } from '@skand/viewer-component-v2';
import { useEffect, useState } from 'react';
import { InputSlider } from '../InputSlider';

export interface OpacityControlProps {
  model: Model3D;
}

export const OpacityControl = ({ model }: OpacityControlProps) => {
  const getOpacity = () => {
    if (model instanceof Tileset) {
      return model.getStyle().alpha;
    } else {
      return model.getOpacity();
    }
  };

  const [opacity, setOpacity] = useState(getOpacity());

  // Set the opacity of the model
  useEffect(() => {
    if (model instanceof Tileset) {
      const style = model.getStyle();
      style.alpha = opacity;
      model.setStyle(style);
    } else {
      model.setOpacity(opacity);
    }
  }, [model, opacity]);

  return (
    <InputSlider
      max={1}
      min={0}
      setValue={value => setOpacity(value)}
      step={0.01}
      title="Opacity"
      value={opacity}
    />
  );
};
