import { useLayout } from '../stores/layout';
import { cn } from '../utils/classname';
import { AnnotationTabAddMoveGroup, AnnotationTabHistoryView } from './AnnotationViews';
import { ShareLinkSettingsPanel } from './ShareLinkTab/LeftSideBarViews';
import {
  ShareLinkContentPanel,
  ShareLinkSettingsPanel as LegacyShareLinkSettingsPanel,
} from './LegacyShareLinkTab/LeftSideBarViews';
import { SHARE_LINK_REVAMP } from '@/utils/split';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMemo } from 'react';

export const LeftSideBarSecond = () => {
  const isShowing = useLayout(state => state.isShowingLeftSideBarII);
  const leftSideBarII = useLayout(state => state.leftSideBarII);
  const treatment = useTreatments([SHARE_LINK_REVAMP]);

  const shareLinkRevampFlag = treatment[SHARE_LINK_REVAMP].treatment === 'on';

  const shareLinkSettingsPanel = useMemo(() => {
    if (shareLinkRevampFlag) {
      return <ShareLinkSettingsPanel />;
    }
    return <LegacyShareLinkSettingsPanel />;
  }, [shareLinkRevampFlag]);

  return (
    isShowing && (
      <div
        className={cn(
          'absolute',
          'bg-neutral-100',
          'border-l-1',
          'border-l-neutral-400',
          'border-l-solid',
          'h-full',
          'left-400px',
          'w-400px',
          'p-y-18px',
          'p-x-3',
          'z-20',
        )}
      >
        {leftSideBarII == 'annotationHistory' && <AnnotationTabHistoryView />}
        {leftSideBarII == 'annotationGroup' && <AnnotationTabAddMoveGroup />}
        {leftSideBarII == 'shareLinkContentPanel' && <ShareLinkContentPanel />}
        {leftSideBarII == 'shareLinkSettingsPanel' && shareLinkSettingsPanel}
      </div>
    )
  );
};
