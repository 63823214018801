import {
  CircleDrawable,
  Draw2D,
  GestureControl,
  LineDrawable,
  PolygonDrawable,
} from '@skand/viewer-component-v2';
import { Vector2, Color } from 'three';
import { NavigationController } from './NavigationController';
import { Transform2 } from '../Transform2';
import PenIcon from '@/assets/pen.svg';

/**
 * Context menu handler callback.
 */
export type ContextMenuHandler = (position: Vector2 | null, removeVertex: () => void) => void;

/**
 * Callback to be invoked when the sketch is submitted.
 */
export type DrawSubmitCallback = (vertices: Vector2[], closed: boolean, color: Color) => void;

/**
 * Draw tool controller for the photo editor.
 */
export class DrawController {
  private draw2D: Draw2D;
  private gestures: GestureControl;
  private transform: Transform2;
  private navigationController: NavigationController;

  private vertices: Vector2[];
  private closed: boolean;
  private color: Color;
  private highlightColor: Color;
  private cursorPosition: Vector2;
  private points: CircleDrawable[];
  private edges: LineDrawable[];
  private polygon: PolygonDrawable;
  private selectedVertex: Vector2 | null;
  private hoveringVertex: Vector2 | null;
  private dragging: boolean;
  private modifier: boolean;
  private snapped: boolean;
  private state: 'idle' | 'run' | 'submit';

  private contextMenuHandler: ContextMenuHandler;
  private onSubmitCallback: DrawSubmitCallback;

  constructor(draw2D: Draw2D, transform: Transform2, navigationController: NavigationController) {
    this.draw2D = draw2D;
    this.gestures = new GestureControl(draw2D.getCanvas());
    this.transform = transform;
    this.navigationController = navigationController;

    this.vertices = [];
    this.closed = false;

    this.color = new Color(1, 1, 1);
    this.highlightColor = new Color(0x0040ff);
    this.cursorPosition = new Vector2();

    this.points = [];
    this.edges = [];
    this.polygon = {
      type: 'polygon',
      vertices: [],
      color: this.color,
      opacity: 0.5,
      fill: true,
      zIndex: 1,
    };
    this.selectedVertex = null;
    this.hoveringVertex = null;

    this.dragging = false;
    this.modifier = false;
    this.snapped = false;
    this.state = 'idle';

    this.contextMenuHandler = () => {};
    this.onSubmitCallback = () => {};
  }

  /**
   * Refresh the points, edges, and fill drawables.
   */
  private refreshDrawables() {
    this.points.forEach(point => this.draw2D.removeDrawable(point));
    this.edges.forEach(edge => this.draw2D.removeDrawable(edge));
    this.draw2D.removeDrawable(this.polygon);

    this.points = [];
    this.edges = [];
    this.polygon.vertices = [];

    // Update edge list
    const endPoints = this.vertices.slice();
    if (this.closed) {
      endPoints.push(this.vertices[0]);
      this.draw2D.addDrawable(this.polygon);
    } else if (this.state === 'run') {
      endPoints.push(this.cursorPosition);
    }
    for (let i = 0; i < endPoints.length - 1; i++) {
      const edge: LineDrawable = {
        type: 'line',
        start: this.transform.imageToCanvasSpace(endPoints[i]),
        end: this.transform.imageToCanvasSpace(endPoints[i + 1]),
        color: this.color,
        opacity: 1.0,
        width: 3,
        zIndex: 1,
      };
      this.edges.push(edge);
      this.draw2D.addDrawable(edge);
    }

    // Update points and polygon
    for (const vertex of this.vertices) {
      const point: CircleDrawable = {
        type: 'circle',
        position: this.transform.imageToCanvasSpace(vertex),
        color: vertex === this.selectedVertex ? this.highlightColor : this.color,
        radius: 5,
        opacity: 1.0,
        fill: true,
        zIndex: 1,
      };
      this.polygon.vertices.push(this.transform.imageToCanvasSpace(vertex));
      this.points.push(point);
      this.draw2D.addDrawable(point);
    }
  }

  /**
   * Update the context menu.
   */
  private updateContextMenu() {
    if (this.vertices.length && this.selectedVertex) {
      const target = this.selectedVertex;
      this.contextMenuHandler(this.transform.imageToCanvasSpace(target), () => {
        this.removeVertex(target);
        this.setSelectedVertex(this.vertices[this.vertices.length - 1] ?? null);
        this.updateContextMenu();
        this.gestures.focus();
      });
    } else {
      this.contextMenuHandler(null, () => {});
    }
  }

  /**
   * Add a new vertex.
   *
   * @param position
   */
  private addVertex(position: Vector2) {
    this.vertices.push(position);
    this.refreshDrawables();
    this.setSelectedVertex(position);
  }

  /**
   * Remove a vertex.
   *
   * @param position
   */
  private removeVertex(position: Vector2) {
    const index = this.vertices.findIndex(query => query === position);
    if (index < 0) return;
    if (this.closed) {
      // Make the preceding vertex the tail of the new polyline
      let prevIndex = index - 1;
      if (prevIndex < 0) prevIndex = this.vertices.length - 1;
      for (let i = 0; i < this.vertices.length - prevIndex - 1; i++) {
        const tail = this.vertices[this.vertices.length - 1];
        this.vertices.pop();
        this.vertices.unshift(tail);
      }
      this.openPolygon();
    } else {
      // Otherwise, just remove the vertex directly
      this.vertices.splice(index, 1);
    }
    this.refreshDrawables();
    this.setSelectedVertex(null);
    this.updateContextMenu();
  }

  /**
   * Set the selected vertex.
   *
   * @param vertex
   */
  private setSelectedVertex(vertex: Vector2 | null) {
    this.selectedVertex = vertex;
    this.hoveringVertex = vertex;
    this.points.forEach(point => (point.color = this.color));
    if (vertex) {
      const index = this.vertices.indexOf(vertex);
      this.points[index].color = this.highlightColor;
      this.gestures.setCursor('grab');
    } else {
      this.gestures.setCursor(`url(${PenIcon}), pointer`);
    }
  }

  /**
   * Start the draw tool, register controls.
   *
   * @param vertices
   * @param closed
   */
  public start(vertices: Vector2[] = [], closed = false) {
    this.stop();
    this.state = 'run';
    this.gestures.setCursor(`url(${PenIcon}), pointer`);

    // Set initial state
    vertices.map(vertex => this.addVertex(vertex));
    if (closed) {
      this.closePolygon();
    } else {
      this.openPolygon();
    }

    // Handle placing, deleting, and selecting a vertex
    this.gestures.register('tap', gesture => {
      const position = this.transform.canvasToImageSpace(gesture.position);
      if (gesture.button === 0) {
        if (this.hoveringVertex && !this.modifier) {
          // Select a vertex for dragging
          this.setSelectedVertex(this.hoveringVertex);
          this.updateContextMenu();

          this.dragging = true;
          this.navigationController.stop();
          this.gestures.setCursor('grabbing');
        } else if (this.modifier && this.snapped) {
          // Close the shape if the modifier is on
          this.closePolygon();
          this.updateContextMenu();
        } else if (!this.closed) {
          // Add a new vertex, if not already closed
          this.addVertex(position);
          this.updateContextMenu();
        }
      } else if (gesture.button === 2 && this.hoveringVertex) {
        // Delete the hovered over vertex
        this.removeVertex(this.hoveringVertex);

        // Update selected vertex if it was deleted
        if (this.hoveringVertex === this.selectedVertex) {
          this.setSelectedVertex(this.vertices[this.vertices.length - 1] ?? null);
        }
        this.hoveringVertex = null;
        this.updateContextMenu();
      }
    });

    // Handle releasing a vertex
    this.gestures.register('release', () => {
      if (this.dragging) {
        this.dragging = false;
        this.navigationController.start();
        this.gestures.setCursor('grab');
      }
    });

    // Handle selecting a vertex or snapping to fill the polygon
    this.gestures.register('hover', gesture => {
      const position = this.transform.canvasToImageSpace(gesture.position);
      this.cursorPosition = position;

      // Hover over a vertex to select it
      if (this.dragging && this.selectedVertex) {
        this.selectedVertex.copy(position);
        this.refreshDrawables();
        this.gestures.setCursor('grabbing');
      } else {
        this.hoveringVertex = null;
        for (const vertex of this.vertices) {
          const distance = vertex.distanceTo(position);
          if (distance < 25 / this.transform.getZoom()) {
            this.hoveringVertex = vertex;
            break;
          }
        }

        if (this.hoveringVertex) {
          this.gestures.setCursor('grab');
        } else {
          this.gestures.setCursor(`url(${PenIcon}), pointer`);
        }
      }

      // Snap to the first vertex when modifier is on
      if (this.modifier && this.vertices.length >= 3) {
        const first = this.vertices[0];
        const distance = first.distanceTo(position);
        if (distance < 50 / this.transform.getZoom()) {
          position.copy(first);
          this.snapped = true;
        } else {
          this.snapped = false;
        }
        this.gestures.setCursor('crosshair');
      } else {
        this.snapped = false;
      }

      // Update the cursor
      this.cursorPosition.copy(position);
    });

    // Handle keyboard shortcuts for enabling the modifier, cancelling, and undo
    this.gestures.register('keytap', gesture => {
      const { key } = gesture;
      if (key === 'Meta' || key === 'Control' || key === 'Shift') {
        this.modifier = true;
        this.gestures.setCursor('crosshair');
      }
      if (key === 'C' || key === 'c' || key === 'Escape') {
        this.clear();
      }
      if (key === 'U' || key === 'u') {
        this.undo();
      }
    });

    // Handle disabling modifier
    this.gestures.register('keyrelease', gesture => {
      const { key } = gesture;
      if (key === 'Meta' || key === 'Control' || key === 'Shift') {
        this.modifier = false;
        this.gestures.setCursor(`url(${PenIcon}), pointer`);
      }
    });

    // Update the context menu when zooming in
    this.gestures.register('zoom', () => {
      this.updateContextMenu();
    });
  }

  /**
   * Stop the draw tool, unregister controls.
   */
  public stop() {
    this.state = 'idle';
    this.clear();
    this.setColor(new Color(1, 1, 1));
    this.gestures.unregisterAll();
    this.gestures.setCursor('default');
    this.updateContextMenu();
  }

  /**
   * Save the current sketch.
   */
  public submit() {
    this.state = 'submit';
    this.gestures.unregisterAll();
    this.setSelectedVertex(null);
    this.refreshDrawables();
    this.contextMenuHandler(null, () => {});
    this.gestures.setCursor('default');
    this.onSubmitCallback(this.vertices, this.closed, this.color);
  }

  /**
   * Clear the existing drawables.
   */
  public clear() {
    this.setSelectedVertex(null);
    this.vertices = [];
    this.closed = false;
    this.dragging = false;
    this.modifier = false;
    this.snapped = false;

    this.updateContextMenu();
    this.refreshDrawables();
  }

  /**
   * Remove the last vertex placed.
   */
  public undo() {
    if (this.vertices.length === 0) return;
    this.removeVertex(this.vertices[this.vertices.length - 1]);
    this.setSelectedVertex(this.vertices[this.vertices.length - 1] ?? null);
    this.updateContextMenu();
  }

  /**
   * Convert the sketch into a closed polygon.
   */
  public closePolygon() {
    if (this.vertices.length >= 3) {
      this.closed = true;
      this.refreshDrawables();
    }
  }

  /**
   * Convert the sketch back into a polyline.
   */
  public openPolygon() {
    if (this.vertices.length >= 3) {
      this.closed = false;
      this.refreshDrawables();
    }
  }

  /**
   * Check if the polygon can be closed.
   *
   * @returns
   */
  public canClosePolygon() {
    return !this.closed && this.vertices.length >= 3;
  }

  /**
   * Set the current color of the sketch.
   *
   * @param color
   */
  public setColor(color: Color) {
    this.color = color;
    for (const point of this.points) {
      point.color = color;
    }
    for (const edge of this.edges) {
      edge.color = color;
    }
    this.polygon.color = color;
  }

  /**
   * Get the current color of the sketch.
   *
   * @returns
   */
  public getColor() {
    return this.color;
  }

  /**
   * Set the context menu handler callback.
   *
   * @param handler
   */
  public setContextMenuHandler(handler: ContextMenuHandler) {
    this.contextMenuHandler = handler;
  }

  /**
   * Set the callback for when the drawing is submitted.
   *
   * @param callback
   */
  public setSubmitCallback(callback: DrawSubmitCallback) {
    this.onSubmitCallback = callback;
  }

  /**
   * Check if the draw controls are active.
   *
   * @returns
   */
  public isActive() {
    return this.state !== 'idle';
  }

  /**
   * Apply translation and zoom to the current sketch.
   */
  public applyTransform() {
    // Update points and polygon vertices
    for (let i = 0; i < this.vertices.length; i++) {
      const point = this.points[i];
      const vertex = this.vertices[i];
      point.position = this.transform.imageToCanvasSpace(vertex);
      this.polygon.vertices[i] = this.transform.imageToCanvasSpace(vertex);
    }

    // Update edge list
    const endPoints = this.vertices.slice();
    if (this.closed) {
      endPoints.push(endPoints[0]);
    } else if (this.state === 'run') {
      endPoints.push(this.cursorPosition);
    }
    for (let i = 0; i < endPoints.length - 1; i++) {
      const edge = this.edges[i];
      edge.start = this.transform.imageToCanvasSpace(endPoints[i]);
      edge.end = this.transform.imageToCanvasSpace(endPoints[i + 1]);
    }
  }
}
