export const ANNOTATION_SHAPE_TYPE = {
  POINT: 'POINT',
  POLYLINE: 'POLYLINE',
  POLYGON: 'POLYGON',
};
export type AnnotationShapeType = keyof typeof ANNOTATION_SHAPE_TYPE;

export const ANNOTATION_FIELD_TYPE = {
  FILE: 'FILE',
  TEXT: 'TEXT',
  DATE: 'DATE',
  SELECT: 'SELECT',
  URL: 'URL',
  IMAGE: 'IMAGE',
} as const;
export type AnnotationFieldType = keyof typeof ANNOTATION_FIELD_TYPE;

export const ANNOTATION_TEMPLATE_CREATED_BY = {
  SYSTEM: 'SYSTEM',
  USER: 'USER',
};
export type AnnotationTemplateCreatedBy = keyof typeof ANNOTATION_TEMPLATE_CREATED_BY;

export const DND_ITEM_TYPE = {
  CHIP: 'CHIP',
  FIELD: 'FIELD',
  OPTION: 'OPTION',
};
export type DndItemType = keyof typeof DND_ITEM_TYPE;
