import { UNGROUPED_GROUP_ID } from '@/constants/project';
import { useProjectStore } from '@/stores/project';
import { cn } from '@/utils/classname';
import { Project, ProjectGroup } from '@/utils/project';
import { Fragment, useCallback, useState } from 'react';
import { GroupRow } from './GroupRow';
import { ProjectRow } from './ProjectRow';
import { Sort } from './Sort';
import { SortKey } from './SortByModal';
import { EditProject } from '../EditProject';
import { ViewportList } from 'react-viewport-list';

interface GroupTableProps {
  children?: React.ReactNode;
  groups: ProjectGroup[];
  handleCollapseAllGroups: () => void;
  handleExpandAllGroups: () => void;
  isAllGroupsFolded: boolean;
  isAllGroupsUnFolded: boolean;
  isSearchResultsEmpty: boolean;
  projectPageSelectedProjects: string[];
  projects: Project[];
  searchKey: string;
  setProjectPageSelectedProjects: (selected: string[]) => void;
  ungroupedProjects: Project[];
}

export const GroupTable = ({
  projects,
  groups,
  setProjectPageSelectedProjects,
  searchKey,
  projectPageSelectedProjects,
  isAllGroupsFolded,
  isAllGroupsUnFolded,
  isSearchResultsEmpty,
  ungroupedProjects,
  handleCollapseAllGroups,
  handleExpandAllGroups,
}: GroupTableProps) => {
  const foldState = useProjectStore(state => state.table.foldState);
  const editProject = useProjectStore(state => state.editProject.project);
  const openEditProject = !!editProject?.id;

  const [sortKey, setSortKey] = useState<SortKey>('name');

  const handleProjectSelect = useCallback(
    (projectId: string, selected: boolean) => {
      if (selected) {
        setProjectPageSelectedProjects([...projectPageSelectedProjects, projectId]);
      } else {
        setProjectPageSelectedProjects(
          projectPageSelectedProjects.filter((id: string) => id !== projectId),
        );
      }
    },
    [setProjectPageSelectedProjects, projectPageSelectedProjects],
  );

  const sortTemplates = useCallback(
    (a: Project | ProjectGroup, b: Project | ProjectGroup) => {
      if (sortKey === 'name') {
        return (a.name || '').localeCompare(b.name || '');
      } else if (sortKey === 'createdAt') {
        return a.createdAt.getTime() - b.createdAt.getTime();
      } else if (sortKey === 'updatedAt') {
        return a.updatedAt.getTime() - b.updatedAt.getTime();
      }

      return 0;
    },
    [sortKey],
  );

  const sortedMatchingProjects = useCallback(
    (filteredProjects: Project[]) => {
      const sortedProjects = filteredProjects.sort(sortTemplates);

      return (
        <ViewportList items={sortedProjects}>
          {(project: Project, index: number) => (
            <ProjectRow
              groups={groups}
              isSelected={projectPageSelectedProjects.includes(project.id)}
              key={index}
              onSelect={(selected: boolean) => handleProjectSelect(project.id, selected)}
              project={project}
            />
          )}
        </ViewportList>
      );
    },
    [projectPageSelectedProjects, sortTemplates, handleProjectSelect, groups],
  );

  const sortedMatchingUngroupedProjects = useCallback(
    (filteredUngroupedProjects: Project[]) => {
      const sortedProjects = filteredUngroupedProjects.sort(sortTemplates);

      return (
        <ViewportList items={sortedProjects}>
          {(project: Project, index: number) => (
            <ProjectRow
              groups={groups}
              isSelected={projectPageSelectedProjects.includes(project.id)}
              key={index}
              onSelect={(selected: boolean) => handleProjectSelect(project.id, selected)}
              project={project}
            />
          )}
        </ViewportList>
      );
    },
    [projectPageSelectedProjects, sortTemplates, handleProjectSelect, groups],
  );

  const sortedProjectGroups = useCallback(() => {
    return groups.sort(sortTemplates).map(group => {
      const filteredProjects = group.projects.filter(project => projects.includes(project));
      const shouldDisplayGroup = filteredProjects.length > 0 || searchKey.trim().length === 0;
      return (
        <Fragment key={group.id}>
          {shouldDisplayGroup && <GroupRow group={group} />}
          <div
            className={cn(
              'b-neutral-200',
              'b-solid',
              'p-x-2',
              'mb-4',
              'rounded-br-2',
              'rounded-bl-2',
              !shouldDisplayGroup && 'hidden',
            )}
          >
            {!foldState[group.id] && sortedMatchingProjects(filteredProjects)}
          </div>
        </Fragment>
      );
    });
  }, [foldState, groups, projects, searchKey, sortTemplates, sortedMatchingProjects]);

  return (
    <div className={cn('mt-6 w-full border-collapse flex flex-col flex-1 mb-2')}>
      <div className={cn('flex', 'flex-row', 'pb-3', 'justify-between')}>
        <div>
          <span
            className={cn('color-neutral-800 ', 'typo-text-xs', 'cursor-pointer', {
              'opacity-50': isAllGroupsUnFolded || isSearchResultsEmpty,
            })}
            onClick={() => {
              !isSearchResultsEmpty && handleExpandAllGroups();
            }}
          >
            EXPAND ALL
          </span>
          <span
            className={cn('color-neutral-800 ', 'typo-text-xs', 'ml-3', 'cursor-pointer', {
              'opacity-50': isAllGroupsFolded || isSearchResultsEmpty,
            })}
            onClick={() => {
              !isSearchResultsEmpty && handleCollapseAllGroups();
            }}
          >
            COLLAPSE ALL
          </span>
        </div>
        <Sort onChangeSortKey={setSortKey} />
      </div>

      <div className={cn('flex', 'flex-col', 'h-full')}>
        {sortedProjectGroups()}
        {ungroupedProjects.length > 0 && <GroupRow />}
        <div
          className={cn(
            'b-neutral-200',
            'b-x-solid b-b-solid',
            'p-x-2',
            'mb-4',
            'flex-col',
            'rounded-br-2',
            'rounded-bl-2',
            ungroupedProjects.length > 0 && !foldState[UNGROUPED_GROUP_ID] ? 'flex' : 'hidden',
          )}
        >
          {foldState[UNGROUPED_GROUP_ID] !== true &&
            sortedMatchingUngroupedProjects(ungroupedProjects)}
        </div>
      </div>

      {openEditProject && <EditProject />}
    </div>
  );
};
