import { cn } from '@/utils/classname';

export interface CheckboxProps {
  checked: boolean;
  setToggleCheckbox?: (checked: boolean) => void;
  disabled?: boolean;
  small?: boolean;
  checkedPartial?: boolean;
  placeHolderView?: boolean;
}

export const Checkbox = ({
  checked,
  disabled,
  checkedPartial = false,
  small = false,
  setToggleCheckbox,
  placeHolderView = false,
}: CheckboxProps) => {
  return (
    <div
      className={cn(
        'cursor-pointer rounded-sm overflow-hidden',
        disabled && 'bg-neutral-300',
        disabled && placeHolderView && 'bg-neutral-400',
      )}
      onClick={() => {
        if (!disabled && setToggleCheckbox) {
          setToggleCheckbox(!checked);
        }
      }}
    >
      {checkedPartial ? (
        <PartiallyCheckedSvg placeHolderView={placeHolderView} small={small} />
      ) : checked ? (
        <CheckedSvg placeHolderView={placeHolderView} small={small} />
      ) : (
        <UncheckedSvg disabled={disabled} placeHolderView={placeHolderView} small={small} />
      )}
    </div>
  );
};

const CheckedSvg = ({ small, placeHolderView }: { small: boolean; placeHolderView: boolean }) => (
  <div
    className={cn(
      placeHolderView ? 'i-skand-done' : 'i-skand-boxchecked',
      placeHolderView ? 'text-neutral-100' : 'color-neutral-600',
      small && 'w-12px h-12px',
    )}
  />
);

const PartiallyCheckedSvg = ({
  small,
  placeHolderView,
}: {
  small: boolean;
  placeHolderView: boolean;
}) => (
  <div
    className={cn(
      placeHolderView ? 'text-neutral-400' : 'text-neutral-600',
      small && 'flex justify-center items-center',
    )}
  >
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <rect fill="currentColor" height="12" rx="2" width="12" />
      <path d="M2 6H5H10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);

const UncheckedSvg = ({
  small,
  disabled,
  placeHolderView,
}: {
  disabled?: boolean;
  small: boolean;
  placeHolderView: boolean;
}) => (
  <div
    className={cn(
      !disabled && 'i-skand-boxunchecked',
      placeHolderView ? 'bg-neutral-400' : 'color-neutral-600',
      small && 'w-12px h-12px',
    )}
  />
);
