import {
  BIMIcon,
  DXFIcon,
  IFCIcon,
  ImageIcon,
  Mesh3DIcon,
  OBJIcon,
  PointCloudIcon,
  TerrainIcon,
} from '@/components/IconButton';
import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { useShareLink } from '@/stores/shareLink';
import { AnnotationGroup, Layer, LayerGroup, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { useMemo } from 'react';
import { Graph } from '@skand/math';

export interface LayerNodeProps {
  layer: Layer;
}

export const LayerNode = ({ layer }: LayerNodeProps) => {
  const layers = useViewer(state => state.layers);
  const layerGroups = useViewer(state => state.layerGroups);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const visibleLayerIds = useShareLink(state => state.visibleLayerIds);
  const selectedSceneEntityIds = useShareLink(state => state.selectedSceneEntityIds);
  const isEnabled = selectedSceneEntityIds.includes(layer.sceneEntityId);

  // Layer format icon
  const FormatTypeIcon = useMemo(() => {
    switch (layer.formatType) {
      case LAYER_FORMAT_TYPE.IFC:
        return <IFCIcon />;
      case LAYER_FORMAT_TYPE.OBJ:
        return <OBJIcon />;
      case LAYER_FORMAT_TYPE.POINT_CLOUD:
        return <PointCloudIcon />;
      case LAYER_FORMAT_TYPE.MESH_3D:
        return <Mesh3DIcon />;
      case LAYER_FORMAT_TYPE.ORTHO_2D:
      case LAYER_FORMAT_TYPE.TERRAIN:
        return <TerrainIcon />;
      case LAYER_FORMAT_TYPE.IMAGES:
        return <ImageIcon />;
      case LAYER_FORMAT_TYPE.DXF:
        return <DXFIcon />;
      case LAYER_FORMAT_TYPE.BIM_CAD_MODEL:
        return <BIMIcon />;
    }
  }, [layer.formatType]);

  const [members] = useMemo(() => {
    const entities = [...layerGroups, ...layers, ...annotationGroups];
    const graph = new Graph<Layer | LayerGroup | AnnotationGroup>();
    for (const entity of entities) {
      graph.addNode(entity);
      if (entity.parent) {
        graph.addEdge(entity.parent, entity);
      }
    }

    const members: Layer[] = [];

    graph.bfs(layer, node => {
      switch (node.type) {
        case 'layer':
          members.push(node);
          break;
      }
    });

    return [members];
  }, [layerGroups, layers, annotationGroups, layer]);

  const isVisible = members.some(layer => visibleLayerIds.has(layer.id));

  const handleToggle = () => {
    if (isVisible) {
      useShareLink.setState(prev => {
        const visibleLayerIds = new Set(prev.visibleLayerIds);
        for (const layer of members) {
          visibleLayerIds.delete(layer.id);
        }
        return { visibleLayerIds };
      });
    } else {
      useShareLink.setState(prev => {
        const visibleLayerIds = new Set(prev.visibleLayerIds);
        for (const layer of members) {
          visibleLayerIds.add(layer.id);
        }
        return { visibleLayerIds };
      });
    }
  };

  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        {FormatTypeIcon}
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{layer.name}</p>
        <div
          className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2"
          style={{ boxShadow: '-8px 0px 8px -2px rgba(255,255,255,1)' }}
        >
          {isEnabled && (
            <div
              className={cn(isVisible ? 'i-skand-show' : 'i-skand-hide')}
              onClick={handleToggle}
            />
          )}
        </div>
      </div>
    </div>
  );
};
