export const PhotoGroupIcon = ({ className }: React.SVGAttributes<React.ReactSVGElement>) => {
  return (
    <svg
      className={className}
      fill="none"
      height="14"
      viewBox="0 0 12 14"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.21715L2.88174 6.2666L5.0771 8.60725L7.33519 6.2666L9.1542 8.21715V10.4928H1V8.21715Z"
        fill="#726F74"
      />
      <path
        d="M8.97433 10.6227H1.2592C1.13375 10.6227 1.0083 10.4926 1.0083 10.3626V1.26007C1.0083 1.13004 1.13375 1 1.2592 1H8.97433C9.09978 1 9.22523 1.13004 9.22523 1.26007V10.3626C9.22523 10.4926 9.09978 10.6227 8.97433 10.6227Z"
        stroke="#726F74"
        strokeMiterlimit="10"
      />
      <path
        d="M2.49121 11.8626H10.2063C10.3318 11.8626 10.4572 11.7326 10.4572 11.6025V2.5"
        stroke="#726F74"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M3.63721 13.0003H11.3523C11.4778 13.0003 11.6032 12.8703 11.6032 12.7402V3.6377"
        stroke="#726F74"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
    </svg>
  );
};
