import { ANNOTATION_FIELD_TYPE, AnnotationFieldType } from '@/constants/annotation';
import { cn } from '@/utils/classname';

const FIELD_TYPE_MAP = {
  [ANNOTATION_FIELD_TYPE.DATE]: ['Date', 'i-skand-date text-3'],
  [ANNOTATION_FIELD_TYPE.FILE]: ['Attachment', 'i-skand-add text-3'],
  [ANNOTATION_FIELD_TYPE.SELECT]: ['Dropdown', 'i-skand-dropdown text-3'],
  [ANNOTATION_FIELD_TYPE.TEXT]: ['Text', 'i-skand-textfield text-3'],
  [ANNOTATION_FIELD_TYPE.URL]: ['Link', 'i-skand-link text-4'],
  [ANNOTATION_FIELD_TYPE.IMAGE]: ['Image', 'i-skand-image text-3'],
};

export interface FieldTypeProps {
  type: AnnotationFieldType;
}

export const FieldType = ({ type }: FieldTypeProps) => {
  const [text, classes] = FIELD_TYPE_MAP[type];

  return (
    <div className="flex items-center">
      <span className="color-neutral-600 typo-text-s">{text}</span>
      <div className={cn(classes, 'color-neutral-400 ml-2')} />
    </div>
  );
};
