import { cn } from '@/utils/classname';
import { flexRender, useReactTable } from '@tanstack/react-table';
import { HTMLAttributes } from 'react';

export interface TableDenseProps<T> extends HTMLAttributes<HTMLTableElement> {
  table: ReturnType<typeof useReactTable<T>>;
}

export const TableDense = <T,>({
  table,
  className,
  headerRowClassName,
  tableBodyClassName,
  contentRowClassName,
  isScrollable = false,
}: {
  headerRowClassName?: string;
  tableBodyClassName?: string;
  contentRowClassName?: string;
  isScrollable?: boolean;
} & TableDenseProps<T>) => {
  return (
    <table className={cn('w-full border-collapse table-auto', className)}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => {
          return (
            <tr
              className={cn('b-b-1 b-b-neutral-500 b-b-solid text-left', headerRowClassName)}
              key={headerGroup.id}
            >
              {headerGroup.headers.map(header => {
                const isSortable = header.column.getCanSort();
                const sort = header.column.getIsSorted();

                return (
                  <th
                    className="pb-2 uppercase color-neutral-800 typo-button-xs"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}

                      {isSortable && (
                        <div
                          className={cn(
                            'i-skand-dropdown ml-2',
                            sort === false ? 'color-neutral-400' : 'color-neutral-800',
                            sort === 'asc' && 'rotate-180',
                          )}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>

      <tbody
        className={tableBodyClassName}
        style={{ scrollbarGutter: isScrollable ? 'stable' : 'auto' }}
      >
        {table.getRowModel().rows.map(row => (
          <tr className={contentRowClassName} key={row.id}>
            {row.getVisibleCells().map(cell => (
              <td className="pt-2 color-neutral-800 typo-text-s" key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
