import { useMemo } from 'react';
import { TemplateFilterKey } from './TemplateFilterKey';
import { Template } from './Template';
import { cn } from '@/utils/classname';
import { Checkbox } from '@/components/Checkbox';

export interface TemplateRowProps {
  template: Template;
  activeTemplate: Template | null;
  setActiveTemplate: (template: Template | null) => void;
  filterKeys: Set<TemplateFilterKey>;
  setFilterKeys: (values: Set<TemplateFilterKey>) => void;
}

export const TemplateRow = ({
  template,
  activeTemplate,
  setActiveTemplate,
  filterKeys,
  setFilterKeys,
}: TemplateRowProps) => {
  // Get the filter keys for this template
  const templateFilterKeys = useMemo(() => {
    const result: TemplateFilterKey[] = [];
    for (const key of filterKeys) {
      if (key.templateId === template.id) {
        result.push(key);
      }
    }
    return result;
  }, [filterKeys, template.id]);

  // Handle toggling template filter
  const handleToggleTemplate = () => {
    // Clear the keyset for this template
    for (const key of templateFilterKeys) {
      filterKeys.delete(key);
    }

    // If no keys were set, add all of them
    const nextKeys: TemplateFilterKey[] = [];
    if (templateFilterKeys.length === 0) {
      // Add existence key, for cases where the template has noselect fields
      nextKeys.push({ type: 'exist', templateId: template.id });
      for (const field of template.fields) {
        for (const option of field.options) {
          nextKeys.push({
            type: 'select',
            templateId: template.id,
            fieldId: field.id,
            optionId: option.id,
          });
        }
      }
    }

    // Update the master set
    for (const key of nextKeys) {
      filterKeys.add(key);
    }
    setFilterKeys(new Set(filterKeys));
  };

  // Check if template is partially checked (only some fields + option pairs are in the filter keys)
  const [checked, fullyChecked, exists] = useMemo(() => {
    const allTemplateFilterKeys = new Set<string>();
    for (const field of template.fields) {
      for (const option of field.options) {
        allTemplateFilterKeys.add(field.id + option.id);
      }
    }

    const setTemplateFilterKeys = new Set<string>();
    let exists = false;
    for (const key of filterKeys) {
      if (key.templateId !== template.id) continue;
      switch (key.type) {
        case 'exist':
          exists = true;
          break;
        case 'select':
          setTemplateFilterKeys.add(key.fieldId + key.optionId);
          break;
      }
    }

    return [
      setTemplateFilterKeys.size > 0,
      allTemplateFilterKeys.size === setTemplateFilterKeys.size,
      exists,
    ];
  }, [filterKeys, template.fields, template.id]);

  return (
    <div
      className={cn(
        'flex',
        'flex-col',
        'justify-start',
        'items-center',
        'py-1',
        'pr-3',
        activeTemplate === template && 'bg-neutral-200 rounded-sm',
      )}
      key={template.id}
    >
      <div className={cn('w-full flex flex-row items-center justify-start gap-3')}>
        <Checkbox
          checked={(!checked && exists) || checked}
          checkedPartial={checked && !fullyChecked}
          setToggleCheckbox={handleToggleTemplate}
          small
        />
        <div
          className={cn('flex-1 flex flex-row cursor-pointer items-center justify-start')}
          onClick={() => setActiveTemplate(activeTemplate === template ? null : template)}
        >
          <p
            className={cn(
              'color-neutral-800',
              activeTemplate === template ? 'typo-text-small-em' : 'typo-text-small',
            )}
          >
            {template.name}
          </p>
          <div
            className={cn(
              'color-neutral-400 w-3 h-3 text-3 ml-auto',
              'cursor-pointer',
              'i-skand-dropdown rotate-270',
            )}
            onClick={() => setActiveTemplate(activeTemplate === template ? null : template)}
          />
        </div>
      </div>
    </div>
  );
};
