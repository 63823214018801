import { cn } from '@/utils/classname';
import { Input, toast, Toast } from '@skand/ui';
import { FindIcon } from '@/components/IconButton';
import { useMemo, useState } from 'react';
import { AnnotationGroup, Layer, LayerGroup, PhotoGroup, useViewer } from '@/stores/viewer';
import { search } from '@/utils/search';
import { DraggableMenu } from '@/components/DraggableMenu';
import { useMutation } from '@tanstack/react-query';
import { REMOVE_PARENT_SCENE_ENTITY, SET_PARENT_SCENE_ENTITY } from '@/graphql/mutations';
import { SceneNode } from '@skand/viewer-component-v2';
import { request } from '@/graphql/request';
import { queryClient } from '@/graphql/client';
import { useExplore } from '@/stores/explore';
import {
  RemoveParentSceneEntityMutationVariables,
  SetParentSceneEntityMutationVariables,
} from '@/graphql/codegen/graphql';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { Graph } from '@skand/math';

interface LinkLayerMenuProps {
  target: LayerGroup | Layer | AnnotationGroup | PhotoGroup;
  closeMenu: () => void;
}

export const LinkLayerMenu = ({ target, closeMenu }: LinkLayerMenuProps) => {
  const projectId = useExplore(state => state.projectId);
  const layerGroups = useViewer(state => state.layerGroups);
  const layers = useViewer(state => state.layers);

  const [enabledChange, setEnabledChange] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [enabledLinkToLayerInfo, setEnabledLinkToLayerInfo] = useState(false);

  // Compute the relevant layers and layer groups
  const filtered = useMemo(() => {
    // Construct the graph
    const entities = [...layerGroups, ...layers];
    const graph = new Graph<Layer | LayerGroup>();
    for (const entity of entities) {
      graph.addNode(entity);
      if (entity.parent) {
        graph.addEdge(entity.parent, entity);
      }
    }

    // Traverse the graph
    const exclude: Set<Layer | LayerGroup> = new Set();
    if (target.type !== 'annotationGroup' && target.type !== 'photoGroup') {
      graph.bfs(target, node => {
        exclude.add(node);
      });
    }

    return entities.filter(entity => search(entity.name, searchKey) && !exclude.has(entity));
  }, [layerGroups, layers, target, searchKey]);

  // Mutation to link to a parent
  const setParentSceneEntity = useMutation({
    mutationFn: (variables: SetParentSceneEntityMutationVariables) =>
      request(SET_PARENT_SCENE_ENTITY, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: `Successfully linked entity '${target.name}'.`,
        lifespan: 5000,
      });
    },
  });

  // Mutation to unlink from a parent
  const removeParentSceneEntity = useMutation({
    mutationFn: (variables: RemoveParentSceneEntityMutationVariables) =>
      request(REMOVE_PARENT_SCENE_ENTITY, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: `Successfully unlinked entity '${target.name}'.`,
        lifespan: 5000,
      });
    },
  });

  // Handle linking the group to a parent layer
  const handleLinkLayer = async (layer: Layer | LayerGroup) => {
    if (!(target.sceneNode instanceof SceneNode) || !projectId) return;
    if (layer.sceneNode instanceof SceneNode) {
      layer.sceneNode.add(target.sceneNode);
    }
    const position = target.sceneNode.getLocalPosition();
    const rotation = target.sceneNode.getLocalRotation();
    await setParentSceneEntity.mutateAsync({
      sceneEntityId: target.sceneEntityId,
      parentSceneEntityId: layer.sceneEntityId,
      projectId,
      position: {
        x: position.x,
        y: position.y,
        z: position.z,
      },
      rotation: {
        x: rotation.x,
        y: rotation.y,
        z: rotation.z,
        w: rotation.w,
      },
    });
    closeMenu();
  };

  // Handle unlinking the group from its parent
  const handleUnlinkLayer = async () => {
    if (!(target.sceneNode instanceof SceneNode) || !projectId) return;
    if (target.parent?.sceneNode instanceof SceneNode) {
      target.parent.sceneNode.remove(target.sceneNode);
    }

    // Store world position and rotation, since it is a root node
    const position = target.sceneNode.getPosition();
    const rotation = target.sceneNode.getRotation();
    await removeParentSceneEntity.mutateAsync({
      sceneEntityId: target.sceneEntityId,
      projectId,
      position: {
        x: position.x,
        y: position.y,
        z: position.z,
      },
      rotation: {
        x: rotation.x,
        y: rotation.y,
        z: rotation.z,
        w: rotation.w,
      },
    });
    closeMenu();
  };

  return (
    <DraggableMenu closeMenu={closeMenu}>
      <div className="w-full">
        <div className={cn('flex', 'flex-row', 'items-center', 'justify-between')}>
          <div className={cn('flex', 'items-center')}>
            <p className={cn('color-neutral-700 typo-text-s')}>Link to layer</p>
            <span
              className={cn(
                'i-skand-info',
                'color-neutral-500',
                'ml-3',
                'text-3.5',
                'cursor-pointer',
              )}
              onClick={() => setEnabledLinkToLayerInfo(!enabledLinkToLayerInfo)}
            />
          </div>
        </div>
        {enabledLinkToLayerInfo && (
          <div className={cn('absolute right--34 top-8')}>
            <Toast
              clickToDismiss
              dismiss={() => setEnabledLinkToLayerInfo(false)}
              message="When linked with a layer, annotations will move with 3D models."
              type="info"
            />
          </div>
        )}

        {target.parent && (
          <div className={cn('w-full', 'flex', 'justify-between', 'items-center')}>
            <div className={cn('flex', 'items-center')}>
              <span className={cn('color-neutral-600', 'typo-text-s')}>{target.parent.name}</span>

              <span
                className={cn(
                  'i-skand-closesmall',
                  'cursor-pointer',
                  'color-neutral-400',
                  'typo-link-s ml-3',
                )}
                onClick={handleUnlinkLayer}
              />
            </div>
            <span
              className={cn('cursor-pointer', 'color-neutral-800', 'typo-link-s underline')}
              onClick={() => setEnabledChange(true)}
            >
              Change
            </span>
          </div>
        )}
        {(!target.parent || enabledChange) && (
          <div>
            <div className={cn('w-full, mt-1')}>
              <Input label="Search" onChange={setSearchKey} tail={<FindIcon />} />
            </div>
            {filtered.length ? (
              <div
                className={cn(
                  'flex',
                  'flex-col',
                  'mt-2',
                  'max-h-304px',
                  'b-rounded-1',
                  'overflow-scroll',
                )}
              >
                {filtered.map((layer, index) => (
                  <div
                    className={cn(
                      'flex',
                      'flex-row',
                      'items-center',
                      'justify-between',
                      'text-neutral-600',
                      'typo-text-small',
                      'hover:bg-neutral-200',
                      'p-y-1',
                      'cursor-pointer',
                    )}
                    key={`layer-${index}`}
                    onClick={() => handleLinkLayer(layer)}
                  >
                    {layer.name}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </DraggableMenu>
  );
};
