import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import { downloadBlob } from '@/utils/download';
import { useEffect, useMemo, useState } from 'react';
import { cn } from '@/utils/classname';
import { Progress } from '@skand/ui';

export interface ThumbnailProps {
  fileId: string;
}

export const Thumbnail = ({ fileId }: ThumbnailProps) => {
  const fetchThumbnailUrl = useFetchImageUrls([fileId], true);

  const [blob, setBlob] = useState<null | Blob>(null);
  const [progress, setProgress] = useState(0);

  // Download the image blob
  useEffect(() => {
    setBlob(null);
    fetchThumbnailUrl().then(urls => {
      const url = urls.get(fileId);
      if (url) {
        downloadBlob(url, setProgress).then(setBlob);
      }
    });
  }, [fetchThumbnailUrl, fileId]);

  // Create the image url
  const imageUrl = useMemo(() => {
    if (blob) {
      return window.URL.createObjectURL(blob);
    }
  }, [blob]);

  return (
    <div
      className={cn(
        'mt-1',
        'h-120px',
        'w-full',
        'flex',
        'items-center',
        'justify-center',
        'b-1',
        'b-neutral-400',
        'rounded-1',
        'b-solid',
        'bg-zinc-800',
      )}
    >
      {imageUrl ? (
        <img alt="" className="h-full w-full object-cover" src={imageUrl} />
      ) : (
        <Progress className="w-153px" progress={progress * 100} />
      )}
    </div>
  );
};
