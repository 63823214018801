import { IfcElementNode } from '@skand/viewer-component-v2';

export const findElementById = (
  id: IfcElementNode['id'],
  elementTree: IfcElementNode[],
): IfcElementNode | null => {
  for (const element of elementTree) {
    if (element.id === id) {
      return element;
    }
    if (element.children) {
      const elementInChildren = findElementById(id, element.children);
      if (elementInChildren !== null) {
        return elementInChildren;
      }
    }
  }
  return null;
};
