export const ResetIcon = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 20 18" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2663 15.4002C11.9598 16.3002 10.4523 16.8002 8.74374 16.8002C4.42213 16.8002 0.904541 13.3002 0.904541 9.0002C0.904541 4.7002 4.42213 1.2002 8.74374 1.2002C13.0653 1.2002 16.5829 4.7002 16.5829 9.0002"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.685"
      />
      <path
        d="M16.4824 12.2003L15.1759 10.5003L13.8694 8.80029H16.4824H19.196L17.8895 10.5003L16.4824 12.2003Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.685"
      />
    </svg>
  );
};
