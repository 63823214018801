import { ANNOTATION_FIELD_TYPE } from '@/constants/annotation';
import { AnnotationTemplate, AnnotationTemplateImageField } from '@/graphql/codegen/graphql';
import { FILE_SIGNED_GET_OBJECT_DOWNLOAD_URL_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useImageFile } from '@/hooks/useImageFile';
import { editAnnotationUpdateField, useExplore } from '@/stores/explore';
import { cn } from '@/utils/classname';
import { downloadBlob, downloadUrl } from '@/utils/download';
import { isEmpty } from '@/utils/empty';
import { getShareLinkToken } from '@/utils/shareLink';
import { Button, ImagePreview } from '@skand/ui';
import { useSimpleUploader } from '@skand/uploader';
import { useMutation } from '@tanstack/react-query';
import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProgressBar } from '../ProgressBar';

export interface AnnotationFieldsProps {
  template: AnnotationTemplate;
}

export const ThumbnailLegacy = ({
  field,
  disabled = false,
}: {
  field: AnnotationTemplateImageField;
  disabled: boolean;
}) => {
  const isReadOnly = getShareLinkToken() !== null || disabled;

  const { addFiles, upload, off, on } = useSimpleUploader({
    isDisabled: isReadOnly,
  });
  const inputFile = useRef<HTMLInputElement>(null);
  const fileId = useExplore(state => {
    return (
      state.annotationDraft?.fields?.find(item => item && item.fieldId === field.id)?.fileId ??
      undefined
    );
  });
  const [isUploading, setIsUploading] = useState(false);
  const [blob, setBlob] = useState<null | Blob>(null);
  const [progress, setProgress] = useState(0);
  const [enabledViewer, setEnabledViewer] = useState(false);
  const [isThumbnailHovered, setIsThumbnailHovered] = useState(false);

  const { file } = useImageFile(fileId ?? null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const files = [...(e.target.files ?? [])];
      addFiles(files);
      upload();
    },
    [addFiles, upload],
  );

  useEffect(() => {
    const onAddFiles = () => {
      setIsUploading(true);
    };

    const onProgress = ({ progress }: { progress: number }) => {
      setProgress(progress);
    };

    const onCreateSuccess = ({ fileId }: { file: File; fileId: string }) => {
      editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.IMAGE, prev => {
        return { ...prev, fileId };
      });

      setIsUploading(false);
    };

    on('addFiles', onAddFiles);
    on('createSuccess', onCreateSuccess);
    on('progress', onProgress);
    return () => {
      off('addFiles', onAddFiles);
      off('createSuccess', onCreateSuccess);
      off('progress', onProgress);
    };
  }, [field, off, on]);

  useEffect(() => {
    if (file?.originalUrl) {
      downloadBlob(file?.originalUrl, setProgress).then(setBlob);
    } else {
      setBlob(null);
      setProgress(0);
    }
  }, [file?.originalUrl]);

  const imageUrl = useMemo(() => {
    if (blob) {
      return window.URL.createObjectURL(blob);
    }
  }, [blob]);

  const { mutateAsync: getFile } = useMutation({
    mutationFn: (fileId: string) => request(FILE_SIGNED_GET_OBJECT_DOWNLOAD_URL_BY_ID, { fileId }),
  });

  const handleDownload = useCallback(async () => {
    if (fileId) {
      const response = await getFile(fileId);
      const url = response.file?.signedGetObjectDownloadUrl;
      if (url) downloadUrl(url, file?.fileName ?? undefined);
    }
  }, [file?.fileName, fileId, getFile]);

  const handleRemove = useCallback(() => {
    editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.IMAGE, prev => {
      return { ...prev, fileId: undefined };
    });
  }, [field]);

  const handleChangeImage = () => {
    if (!isEmpty(inputFile) && !isEmpty(inputFile.current)) inputFile.current.click();
  };

  if (isUploading) {
    return (
      <div
        className="mt-3 w-full flex items-center b-1 b-neutral-400 rounded-1 b-solid px-3 py-2"
        key={field.name}
      >
        <div className="flex-1">
          <p className="color-neutral-800 typo-text-xxs">{field.name}</p>
          <div className="h-4 py-1 pr-2">
            <ProgressBar progress={progress / 100} />
          </div>
        </div>

        <div className="flex-0 i-skand-image text-3 color-neutral-400" />
      </div>
    );
  }

  return (
    <div>
      <p className="pb-4px pt-12px leading-14px color-neutral-800 typo-text-xs">{field.name}</p>
      <div
        className="relative h-200px w-full overflow-hidden b-1 b-neutral-400 rounded-1 b-solid"
        key={field.name}
        onMouseEnter={() => setIsThumbnailHovered(true)}
        onMouseLeave={() => setIsThumbnailHovered(false)}
      >
        {imageUrl ? (
          <>
            <div
              className={cn('absolute', 'right-2', 'top-2', 'flex', 'items-center', 'gap-2', 'z-2')}
            >
              <div
                className={cn(
                  'border-solid border-neutral-500',
                  'rounded-4px',
                  'border-1px',
                  'flex',
                  'justify-center',
                  'items-center',
                  'h-20px',
                  'w-20px',
                  'bg-neutral-100',
                )}
                onClick={handleDownload}
              >
                <div className="i-skand-download cursor-pointer text-12px color-neutral-500" />
              </div>
              {!isReadOnly && (
                <div
                  className={cn(
                    'border-solid border-neutral-500',
                    'rounded-4px',
                    'border-1px',
                    'h-20px',
                    'w-20px',
                    'flex',
                    'justify-center',
                    'items-center',
                    'bg-neutral-100',
                  )}
                  onClick={handleRemove}
                >
                  <div className="i-skand-close cursor-pointer text-12px color-neutral-500" />
                </div>
              )}
            </div>

            <img
              className={cn(
                'h-auto',
                'w-full',
                'max-h-200px',
                'object-cover',
                isThumbnailHovered && 'opacity-30',
              )}
              src={imageUrl}
            />
          </>
        ) : (
          <div className="h-200px w-full flex items-center justify-center bg-neutral-200">
            <div className="w-[75%]">
              <ProgressBar progress={progress} />
            </div>
          </div>
        )}

        {isThumbnailHovered && !isUploading && progress === 1 && (
          <div
            className={cn(
              'gap-2',
              'flex',
              'absolute',
              'top-50% left-50%',
              'translate-x--50% translate-y--50%',
            )}
          >
            {!isReadOnly && (
              <Button onClick={handleChangeImage} size="xs">
                CHANGE IMAGE
              </Button>
            )}
            <Button
              className="p-x-2"
              filled
              onClick={() => {
                setEnabledViewer(true);
              }}
              primary
              size="xs"
            >
              PREVIEW
            </Button>
          </div>
        )}
        {enabledViewer && !isEmpty(file) && !isEmpty(file.originalUrl) && (
          <ImagePreview
            close={() => {
              setEnabledViewer(false), setIsThumbnailHovered(false);
            }}
            downloadable
            imageFileName={file?.fileName ?? `image-${Date.now()}`}
            url={file.originalUrl}
            zoomable
          />
        )}

        <input
          accept="image/*"
          className="hidden"
          onChange={handleChange}
          ref={inputFile}
          type="file"
        />
      </div>
    </div>
  );
};
