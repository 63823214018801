import {
  BIMIcon,
  DXFIcon,
  IFCIcon,
  ImageIcon,
  Mesh3DIcon,
  OBJIcon,
  PointCloudIcon,
  TerrainIcon,
} from '@/components/IconButton';
import { ModelNode, SceneNode } from '@skand/viewer-component-v2';
import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { Layer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { useMemo } from 'react';
export interface LayerNodeProps {
  layer: Layer;
  setTargetLayer: (layer: Layer) => void;
}

export const LayerNode = ({ layer, setTargetLayer }: LayerNodeProps) => {
  // Layer format icon
  const FormatTypeIcon = useMemo(() => {
    switch (layer.formatType) {
      case LAYER_FORMAT_TYPE.IFC:
        return <IFCIcon />;
      case LAYER_FORMAT_TYPE.OBJ:
        return <OBJIcon />;
      case LAYER_FORMAT_TYPE.POINT_CLOUD:
        return <PointCloudIcon />;
      case LAYER_FORMAT_TYPE.MESH_3D:
        return <Mesh3DIcon />;
      case LAYER_FORMAT_TYPE.ORTHO_2D:
      case LAYER_FORMAT_TYPE.TERRAIN:
        return <TerrainIcon />;
      case LAYER_FORMAT_TYPE.IMAGES:
        return <ImageIcon />;
      case LAYER_FORMAT_TYPE.DXF:
        return <DXFIcon />;
      case LAYER_FORMAT_TYPE.BIM_CAD_MODEL:
        return <BIMIcon />;
    }
  }, [layer.formatType]);

  return (
    <div
      className={cn('flex justify-between items-center py-1')}
      onClick={() => setTargetLayer(layer)}
    >
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        {FormatTypeIcon}
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{layer.name}</p>
        <div
          className={cn(
            'bg-neutral-200 py-[1px] px-1 rounded-[50px] min-w-5 h-[13px] justify-center flex items-center ',
          )}
        >
          <p className={cn('typo-text-xs-em text-neutral-500 whitespace-nowrap pt-[1px]')}>
            {layer.sceneNode instanceof SceneNode || layer.sceneNode instanceof ModelNode
              ? layer.sceneNode.getChildren().length
              : 0}
          </p>
        </div>
      </div>
    </div>
  );
};
