import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { useFetchProject } from '@/hooks/useFetchProject';
import {
  ConfirmationModalState,
  DeleteConfirmationModal,
} from '@/pages/ProjectPage/DeleteConfirmationModal';
import { useExplore } from '@/stores/explore';
import {
  AnnotationGroup,
  Layer,
  LayerGroup,
  PhotoGroup,
  setFilteredLayerKeys,
  setFilteredTemplateKeys,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, Input, Menu, MenuItem, TreeNodeProps } from '@skand/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockLoading } from '../BlockLoading';
import { DraggableMenu } from '../DraggableMenu';
import { FindIcon } from '../IconButton';
import { FilterMenu, SceneEntity, SceneEntityTree } from '../SceneEntityTree';
import { AnnotationGroupNode } from './AnnotationGroupNode';
import { AnnotationNode } from './AnnotationNode';
import { CreateNode } from './CreateNode';
import { LayerGroupNode } from './LayerGroupNode';
import { LayerNode } from './LayerNode';
import {
  ClippingMenu,
  GroupSettingsMenu,
  IFCSettingsMenu,
  LinkLayerMenu,
  Mesh3DSettingsMenu,
  PointCloudSettingsMenu,
  AnnotationUploadMenu,
} from './Menus';
import { PhotoGroupNode } from './PhotoGroupNode';
import { PhotoNode } from './PhotoNode';

import { DATASET_ROOT } from '@/constants/paths';
import { canPolicyActionEdit } from '@/constants/policy';
import { useFetchSceneEntityPermissions } from '@/hooks/useFetchSceneEntityPermissions';
import { debounce } from 'lodash-es';
import { EmptyResourceMessage } from '../EmptyResourceMessage';
import { IfcElementNode } from '@skand/viewer-component-v2';
import { IFCPropertiesMenu } from './Menus/IFCPropertiesMenu';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export const SceneTab = () => {
  const layerGroups = useViewer(state => state.layerGroups);
  const layers = useViewer(state => state.layers);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const filteredTemplateKeys = useViewer(state => state.filteredTemplateKeys);
  const filteredLayerKeys = useViewer(state => state.filteredLayerKeys);
  const projectId = useExplore(state => state.projectId);

  const { fetch: fetchProject, isLoading } = useFetchProject();

  const [projectName, setProjectName] = useState('Scene');
  const [searchKey, setSearchKey] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const [enableCreateLayer, setEnableCreateLayer] = useState(false);
  const [enableCreateAnnotation, setEnableCreateAnnotation] = useState(false);
  const [enabledFilterMenu, setEnabledFilterMenu] = useState(false);
  const [enabledEditNodeId, setEnabledEditNodeId] = useState('');

  const { allSceneEntitiesPermission } = useFetchSceneEntityPermissions();
  const canEdit = canPolicyActionEdit(allSceneEntitiesPermission);

  const [linkLayerId, setLinkLayerId] = useState<
    LayerGroup['id'] | Layer['id'] | AnnotationGroup['id'] | PhotoGroup['id'] | null
  >(null);
  const [layerSettingsId, setLayerSettingsId] = useState<
    LayerGroup['id'] | Layer['id'] | AnnotationGroup['id'] | PhotoGroup['id'] | null
  >(null);
  const [layerClippingId, setLayerClippingId] = useState<Layer['id'] | null>(null);
  const [annotationUploadGroupId, setAnnotationUploadGroupId] = useState<
    AnnotationGroup['id'] | null
  >(null);

  const [selectedIfcElement, setSelectedIfcElement] = useState<IfcElementNode | null>(null);

  const targetPhoto = useViewer(state => state.targetPhoto);
  const enabled2D = useViewer(state => state.enabled2D);
  const annotationDraft = useExplore(state => state.annotationDraft);

  // Fetch project information
  useEffect(() => {
    fetchProject().then(project => {
      if (project) {
        setProjectName(project.name);
        document.title = project.name;
      }
    });
  }, [fetchProject]);

  useEffect(() => {
    return () => {
      document.title = 'Explore';
    };
  }, []);

  // Debounced search handler
  const handleSearch = useMemo(() => {
    return debounce(setSearchKey, 300);
  }, [setSearchKey]);

  // Clean up the handler
  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  // Define Tree node component
  const TreeNode = useCallback(
    ({ data, isLeaf, depth, setOpen, isOpen }: TreeNodeProps<SceneEntity>) => {
      const isAnnotationSelected = annotationDraft?.annotationId === data.entity.id;
      const isPhotoSelected = targetPhoto === data.entity && enabled2D;
      const isLayerSelected = layerSettingsId === data.entity.id;
      const selected = isAnnotationSelected || isPhotoSelected || isLayerSelected;

      return (
        <div className={cn(selected ? 'bg-neutral-200 rounded-1' : 'bg-neutral-100')}>
          <div
            className={cn('inline-flex flex-row items-center gap-2 py-1 pr-2 h-7')}
            style={{ paddingLeft: `${depth * 12}px` }}
          >
            <div
              className={cn(
                'color-neutral-400 w-3 h-3 text-3 flex-none',
                !isLeaf && 'i-skand-dropdown cursor-pointer',
                !isOpen && 'rotate-270',
              )}
              onClick={() => setOpen(!isOpen)}
            />
            {data.entity.type === 'layerGroup' && (
              <LayerGroupNode
                enabledEditNodeId={enabledEditNodeId}
                group={data.entity}
                openConfirmationModal={openConfirmationModal}
                setEnabledEditNodeId={setEnabledEditNodeId}
                setLinkLayerId={setLinkLayerId}
                setOpenConfirmationModal={setOpenConfirmationModal}
              />
            )}
            {data.entity.type === 'layer' && (
              <LayerNode
                clippingTargetId={layerClippingId}
                enabledEditNodeId={enabledEditNodeId}
                layer={data.entity}
                selected={selected}
                setEnabledEditNodeId={setEnabledEditNodeId}
                setLayerClippingId={setLayerClippingId}
                setLayerSettingsId={setLayerSettingsId}
                setLinkLayerId={setLinkLayerId}
                settingsTargetId={layerSettingsId}
              />
            )}
            {data.entity.type === 'annotation' && (
              <AnnotationNode
                annotation={data.entity}
                selected={selected}
                setOpenConfirmationModal={setOpenConfirmationModal}
              />
            )}
            {data.entity.type === 'annotationGroup' && (
              <AnnotationGroupNode
                enabledEditNodeId={enabledEditNodeId}
                group={data.entity}
                openConfirmationModal={openConfirmationModal}
                setAnnotationUploadGroupId={setAnnotationUploadGroupId}
                setEnabledEditNodeId={setEnabledEditNodeId}
                setLinkLayerId={setLinkLayerId}
                setOpenConfirmationModal={setOpenConfirmationModal}
              />
            )}
            {data.entity.type === 'photoGroup' && (
              <PhotoGroupNode
                enabledEditNodeId={enabledEditNodeId}
                group={data.entity}
                setEnabledEditNodeId={setEnabledEditNodeId}
                setLayerSettingsId={setLayerSettingsId}
                setLinkLayerId={setLinkLayerId}
                settingsTargetId={layerSettingsId}
              />
            )}
            {(data.entity.type === 'panorama' || data.entity.type === 'photo2D') && (
              <PhotoNode photo={data.entity} selected={selected} />
            )}
            {data.entity.type === 'create' && (
              <CreateNode
                cancel={() => {
                  setEnableCreateAnnotation(false);
                  setEnableCreateLayer(false);
                }}
                mode={data.entity.mode}
              />
            )}
          </div>
        </div>
      );
    },
    [
      annotationDraft?.annotationId,
      enabled2D,
      layerClippingId,
      layerSettingsId,
      openConfirmationModal,
      targetPhoto,
      enabledEditNodeId,
    ],
  );

  const RenderLayerSettingMenu = useCallback(
    (layer: LayerGroup | Layer | AnnotationGroup | PhotoGroup) => {
      if (layer.type === 'layer') {
        switch (layer.formatType) {
          case LAYER_FORMAT_TYPE.IFC:
            return (
              <IFCSettingsMenu
                layer={layer}
                selectedIfcElement={selectedIfcElement}
                setSelectedIfcElement={setSelectedIfcElement}
              />
            );
          case LAYER_FORMAT_TYPE.POINT_CLOUD:
            return <PointCloudSettingsMenu layer={layer} />;
          case LAYER_FORMAT_TYPE.MESH_3D:
          case LAYER_FORMAT_TYPE.DXF:
          case LAYER_FORMAT_TYPE.OBJ:
          case LAYER_FORMAT_TYPE.BIM_CAD_MODEL:
            return <Mesh3DSettingsMenu layer={layer} />;
        }
      } else {
        return <GroupSettingsMenu layer={layer} />;
      }
    },
    [selectedIfcElement],
  );

  const allSceneEntities = useMemo(
    () => [...layerGroups, ...layers, ...annotationGroups, ...photo2DGroups, ...panoramaGroups],
    [annotationGroups, layerGroups, layers, panoramaGroups, photo2DGroups],
  );
  const layerSettings = useMemo(
    () => allSceneEntities.find(entity => entity.id === layerSettingsId),
    [allSceneEntities, layerSettingsId],
  );
  const layerClipping = useMemo(
    () => allSceneEntities.find(entity => entity.id === layerClippingId),
    [allSceneEntities, layerClippingId],
  );
  const linkLayer = useMemo(
    () => allSceneEntities.find(entity => entity.id === linkLayerId),
    [allSceneEntities, linkLayerId],
  );

  const [isAddDropdownOpen, setIsAddDropdownOpen] = useState(false);

  return (
    <div className={cn('flex flex-col px-1 gap-3 h-full w-full relative')}>
      <div className={cn('border-gray', 'border-1', 'border-b-solid', 'pb-3')}>
        <p className={cn('typo-heading-4 color-neutral-800')}>{projectName}</p>
      </div>

      <DeleteConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />

      <div className={cn('flex gap-2 relative')}>
        <div className="w-full">
          <Input
            data-testid="input"
            label="Search"
            onChange={handleSearch}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
        <Button className="px-5" onClick={() => setEnabledFilterMenu(!enabledFilterMenu)} size="s">
          Filter
        </Button>

        {enabledFilterMenu && (
          <FilterMenu
            closeMenu={() => setEnabledFilterMenu(false)}
            layerFilterKeys={filteredLayerKeys}
            setLayerFilterKeys={setFilteredLayerKeys}
            setTemplateFilterKeys={setFilteredTemplateKeys}
            templateFilterKeys={filteredTemplateKeys}
          />
        )}
      </div>

      <DropdownMenu.Root onOpenChange={setIsAddDropdownOpen} open={isAddDropdownOpen}>
        <DropdownMenu.Trigger asChild>
          <Button
            active={isAddDropdownOpen}
            className={cn(
              'relative cursor-pointer',
              (enableCreateAnnotation || enableCreateLayer || !canEdit) && 'pointer-events-none',
            )}
            disabled={enableCreateAnnotation || enableCreateLayer || !canEdit}
            filled
            primary
            size="s"
          >
            Add
            <div className="i-skand-dropdown absolute right-2 top-2" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content asChild>
            <Menu className="mt-0 w-368px">
              <DropdownMenu.Item asChild>
                <MenuItem
                  className="cursor-pointer text-center outline-none"
                  onClick={() => {
                    setEnableCreateLayer(true);
                    setIsAddDropdownOpen(false);
                  }}
                >
                  New layer group
                </MenuItem>
              </DropdownMenu.Item>
              <DropdownMenu.Item asChild>
                <MenuItem
                  className="cursor-pointer text-center outline-none"
                  onClick={() => {
                    setEnableCreateAnnotation(true);
                    setIsAddDropdownOpen(false);
                  }}
                >
                  New annotation group
                </MenuItem>
              </DropdownMenu.Item>
            </Menu>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      {isLoading ? (
        <BlockLoading />
      ) : !allSceneEntities.length && !enableCreateAnnotation && !enableCreateLayer ? (
        <EmptyResourceMessage
          emptyResourceContent={{
            actionButton: () => {
              location.href = `${DATASET_ROOT}/projects/${projectId}`;
            },
            emptyDesc: 'Create layers via the Project page.',
            emptyTitle: 'No layers available',
            buttonLabel: 'Go to Project page',
          }}
        />
      ) : (
        <div
          className="flex-1 overflow-hidden"
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.LAYER_TREE}
        >
          <SceneEntityTree
            createAnnotationNode={enableCreateAnnotation}
            createLayerNode={enableCreateLayer}
            layerFilterKeys={filteredLayerKeys}
            refreshScene
            searchKey={searchKey}
            templateFilterKeys={filteredTemplateKeys}
          >
            {TreeNode}
          </SceneEntityTree>
        </div>
      )}

      {layerSettings && (
        <DraggableMenu
          closeMenu={() => {
            setLayerSettingsId(null), setSelectedIfcElement(null);
          }}
        >
          {RenderLayerSettingMenu(layerSettings)}
        </DraggableMenu>
      )}
      {layerSettings && selectedIfcElement && (
        <DraggableMenu closeMenu={() => setSelectedIfcElement(null)} x={675}>
          <IFCPropertiesMenu node={selectedIfcElement} />
        </DraggableMenu>
      )}
      {layerClipping && (
        <ClippingMenu closeMenu={() => setLayerClippingId(null)} layer={layerClipping as Layer} />
      )}
      {linkLayerId && (
        <LinkLayerMenu
          closeMenu={() => setLinkLayerId(null)}
          target={linkLayer as LayerGroup | Layer | AnnotationGroup}
        />
      )}
      {annotationUploadGroupId && (
        <AnnotationUploadMenu
          closeMenu={() => setAnnotationUploadGroupId(null)}
          groupId={annotationUploadGroupId}
        />
      )}
    </div>
  );
};
