export const ClippingToolIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2793_54950)">
        <path
          d="M8.50616 12.4H0.736206V8"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.50616 7.92H0.736206L5.88892 4H10.9598"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.469 7.1157L13.6973 4.39996V8.45802L10.469 11.6"
          stroke="currentColor"
          strokeDasharray="1.2 1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.4"
        />
        <path
          d="M15.2126 9.60599L8.66943 15.2V6.01594L15.2126 0.799988V9.60599Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2793_54950">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
