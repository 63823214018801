export const OrthographicIcon = () => {
  return (
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M0.5 0.5H1H17H17.5V1V17V17.5H17H1H0.5V17V1V0.5ZM1.5 11.8572V16.5H6.1428V11.8572H1.5ZM1.5 10.8572H6.1428V6.7143H1.5V10.8572ZM1.5 5.7143V1.5H6.1428V5.7143H1.5ZM7.1428 6.7143V10.8572H11.2858V6.7143H7.1428ZM11.2858 5.7143H7.1428V1.5H11.2858V5.7143ZM12.2858 6.7143V10.8572H16.5V6.7143H12.2858ZM16.5 5.7143H12.2858V1.5H16.5V5.7143ZM7.1428 11.8572V16.5H11.2858V11.8572H7.1428ZM16.5 16.5H12.2858V11.8572H16.5V16.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
