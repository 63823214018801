import { MoreMenu } from '@/components/MoreMenu';
import { queryClient } from '@/graphql/client';
import {
  DeleteShareLinkMutationVariables,
  UpdateShareLinkMutationVariables,
} from '@/graphql/codegen/graphql';
import { DELETE_SHARE_LINK, UPDATE_SHARE_LINK } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchShareLinkPermissions } from '@/hooks/useFetchShareLinkPermissions';
import { useFetchShareLinks } from '@/hooks/useFetchShareLinks';
import {
  ConfirmationModalState,
  DeleteConfirmationModal,
} from '@/pages/ProjectPage/DeleteConfirmationModal';
import { useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import { ShareLink, setSelectedShareLink, useShareLink } from '@/stores/shareLink';
import { cn } from '@/utils/classname';
import { Button, Menu, MenuItem } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

interface ShareLinkCardProps {
  shareLink: ShareLink;
  disabled?: boolean;
}

export const ShareLinkCard = ({ shareLink, disabled = false }: ShareLinkCardProps) => {
  const setLeftSideBarII = useLayout(state => state.setLeftSideBarII);
  const isShowingLeftSideBarII = useLayout(state => state.isShowingLeftSideBarII);
  const showLeftSideBarII = useLayout(state => state.showLeftSideBarII);
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);
  const selectedShareLink = useShareLink(state => state.selectedShareLink);
  const projectId = useExplore(state => state.projectId);
  const leftSideBarII = useLayout(state => state.leftSideBarII);

  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const updateShareLink = useMutation({
    mutationFn: (variables: UpdateShareLinkMutationVariables) => {
      return request(UPDATE_SHARE_LINK, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
      queryClient.invalidateQueries(useFetchShareLinkPermissions.getQueryKey(shareLink.id));
    },
  });

  const deleteShareLink = useMutation({
    mutationFn: (variables: DeleteShareLinkMutationVariables) => {
      return request(DELETE_SHARE_LINK, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
    },
  });

  const ActiveTag = () => (
    <div
      className={cn(
        'flex',
        'flex-row',
        'items-center',
        'w-47px',
        'h-18px',
        'rounded-4px',
        'border-solid',
        'border-success-400',
        'border-1px',
        'pt-2px',
        'pb-2px',
        'pl-4px',
        'pr-4px',
        'justify-between',
        'bg-success-200',
      )}
    >
      <div className={cn('w-6px', 'h-6px', 'rounded-50%', 'bg-success-300')} />

      <p className="color-neutral-800 typo-text-xs">Active</p>
    </div>
  );

  const InactiveTag = () => (
    <div
      className={cn(
        'flex',
        'flex-row',
        'items-center',
        'w-54px',
        'h-18px',
        'rounded-4px',
        'border-solid',
        'border-alert-400',
        'border-1px',
        'pt-2px',
        'pb-2px',
        'pl-4px',
        'pr-4px',
        'justify-between',
        'bg-alert-100',
      )}
    >
      <div className={cn('w-6px', 'h-6px', 'rounded-50%', 'bg-alert-300')} />

      <p className="color-neutral-800 typo-text-xs">Inactive</p>
    </div>
  );

  const handleToggleActive = async () => {
    if (shareLink.active) {
      updateShareLink.mutate({ shareLinkId: shareLink.id, isActivated: false });
    } else {
      updateShareLink.mutate({ shareLinkId: shareLink.id, isActivated: true });
    }
  };

  const handleShareLinkPanelAction = (panelType: string, currentPanelType: string) => {
    setLeftSideBarII(panelType);

    if (!isShowingLeftSideBarII || (isShowingLeftSideBarII && leftSideBarII === currentPanelType)) {
      setSelectedShareLink(shareLink);
      showLeftSideBarII();
    } else {
      setSelectedShareLink(null);
      hideLeftSideBarII();
    }
  };

  const handleEditSettings = () => {
    handleShareLinkPanelAction('shareLinkSettingsPanel', 'shareLinkContentPanel');
  };

  const handleEditContent = () => {
    handleShareLinkPanelAction('shareLinkContentPanel', 'shareLinkSettingsPanel');
  };

  const handleDeleteShareLink = () => {
    setOpenConfirmationModal({
      isOpen: true,
      title: 'Delete Share Link',
      description: `Are you sure you want to delete the share link ${shareLink.name}?`,
      actionButton: 'Delete Share Link',
      actionFunction: () => {
        deleteShareLink.mutate({ shareLinkId: shareLink.id });
        setOpenConfirmationModal(state => {
          return { ...state, isOpen: false };
        });
      },
    });
  };

  return (
    <>
      <DeleteConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      <div
        className={cn(
          selectedShareLink === shareLink
            ? 'border-primary-400 border-2'
            : 'border-neutral-400 border-1',
          'rounded-8px',
          'border-solid',
          'p-12px',
          'w-full',
          'mb-3',
        )}
      >
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mb-2')}>
          {shareLink.active ? <ActiveTag /> : <InactiveTag />}
          {!disabled && (
            <MoreMenu className="cursor-pointer">
              <Menu>
                <MenuItem className="cursor-pointer" onClick={handleToggleActive}>
                  {shareLink.active ? 'Deactivate' : 'Activate'}
                </MenuItem>
                <MenuItem className="cursor-pointer" onClick={handleDeleteShareLink}>
                  Delete
                </MenuItem>
              </Menu>
            </MoreMenu>
          )}
        </div>
        <p className="color-neutral-800 typo-text-medium-em">{shareLink.name}</p>
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
          <p className="color-neutral-600 typo-text-small-em">Date created</p>
          <p className="color-neutral-600 typo-text-small">
            {shareLink.createdAt.toLocaleDateString()}
          </p>
        </div>

        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
          <p className="color-neutral-600 typo-text-small-em">Date updated</p>
          <p className="color-neutral-600 typo-text-small">
            {shareLink.updatedAt.toLocaleDateString()}
          </p>
        </div>
        <div
          className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-16px', 'gap-3')}
        >
          <Button className={cn('w-164px')} onClick={handleEditSettings} size="s">
            Share settings
          </Button>
          <Button className={cn('w-164px')} onClick={handleEditContent} primary size="s">
            Edit content
          </Button>
        </div>
      </div>
    </>
  );
};
