import {
  Photo,
  enableViewer2D,
  setImageViewerTab,
  setTargetPhoto,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { MoreMenu } from '../MoreMenu';
import { Menu, MenuItem } from '@skand/ui';
import { useShareLink } from '@/stores/shareLink';
import { useLayout } from '@/stores/layout';
import { useTreatments } from '@splitsoftware/splitio-react';
import { SHARE_LINK_REVAMP } from '@/utils/split';
export interface PhotoNodeProps {
  photo: Photo;
  selected: boolean;
}

export const PhotoNode = ({ photo, selected }: PhotoNodeProps) => {
  const treatment = useTreatments([SHARE_LINK_REVAMP]);
  const enabledSelectMode = useViewer(state => state.enabledSelectMode);
  const switchTab = useLayout(state => state.switch);

  const shareLinkRevampFlag = treatment[SHARE_LINK_REVAMP].treatment === 'on';
  // Handle viewing the photo in the image viewer
  const handleViewImage = () => {
    setImageViewerTab(photo.type);
    setTargetPhoto(photo);
    enableViewer2D();
  };

  const handleCreateAnnotation = (photoId: string) => {
    switchTab('sharelink');
    useShareLink.setState({
      shareLinkTab: 'create',
      selectedImageIdByLayers: photoId,
      selectedShareLink: null,
    });
  };

  return (
    <>
      <div className="i-skand-image w-5 text-3 color-neutral-600" />
      <p
        className={cn(
          'group',
          'cursor-pointer',
          'flex-1 truncate',
          selected ? 'typo-text-small-em text-neutral-800' : 'typo-text-small text-neutral-600',
        )}
        onClick={handleViewImage}
        title={photo.name}
      >
        {photo.name}
      </p>
      {shareLinkRevampFlag && (
        <div className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2">
          <MoreMenu className={cn('cursor-pointer', enabledSelectMode && 'hidden')}>
            <Menu className="z-2">
              <MenuItem className="cursor-pointer" onClick={() => handleCreateAnnotation(photo.id)}>
                Create share link to this image
              </MenuItem>
            </Menu>
          </MoreMenu>
        </div>
      )}
    </>
  );
};
