import { Photo } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { ImageIcon } from '../../../IconButton/ImageIcon';

export interface PhotoNodeProps {
  photo: Photo;
}
export const PhotoNode = ({ photo }: PhotoNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 flex-1 truncate')}>{photo.name}</p>
        <ImageIcon />
      </div>
    </div>
  );
};
