import { Button, Progress, cn } from '@skand/ui';
import { hideUploadDetails, showUploadDetails, useUploadStore } from '@/stores/upload';
import { formatBytes } from '../utils';

export interface UploadOverviewProps {
  stage: 'initial' | 'preparing' | 'prepared' | 'uploading' | 'uploaded';
}

export const UploadOverview = ({ stage }: UploadOverviewProps) => {
  const progressOverall = useUploadStore(state => state.progressOverall);
  const isShowingUploadDetails = useUploadStore(state => state.showUploadDetails);
  const filesTotal = useUploadStore(state => state.filesTotal);
  const filesUploaded = useUploadStore(state => state.filesUploaded);
  const speedOverall = useUploadStore(state => state.speedOverall);

  const speedMeta = formatBytes(speedOverall, 1);
  const speed = `${speedMeta.amount} ${speedMeta.unit}/s`;

  return (
    <div
      className={cn(
        'flex items-center gap-2 justify-between',
        isShowingUploadDetails && 'pb-2 mb-2 b-b-1 b-b-neutral-500 b-b-solid',
      )}
    >
      <span className="flex-[2-2-auto] color-neutral-800 typo-text-s">
        {filesUploaded} / {filesTotal} file(s) has been uploaded
      </span>

      <div className="flex flex-[1-1-auto] items-center">
        {stage === 'preparing' && (
          <span className="color-neutral-500 typo-text-s">Preparing...</span>
        )}
        {stage === 'prepared' && (
          <span className="color-success-400 typo-text-s-em">Ready for upload</span>
        )}

        {stage === 'uploading' && (
          <>
            <span className="color-neutral-500 typo-text-s">Uploading</span>
            <Progress className="ml-2 w-80px" progress={progressOverall / 100} />
            <span className="ml-2 color-neutral-500 typo-text-s">{speed}</span>
          </>
        )}

        {stage === 'uploaded' && (
          <>
            <span className="color-success-400 typo-text-s">Upload complete</span>
            <span className="ml-2 color-neutral-500 typo-text-s">{speed}</span>
          </>
        )}
      </div>

      <Button
        className="flex flex-[1-1-auto] cursor-pointer items-center gap-1 gap-1"
        onClick={isShowingUploadDetails ? hideUploadDetails : showUploadDetails}
        size="xs"
      >
        {isShowingUploadDetails ? 'hide details' : 'show details'}
        <div
          className={cn(
            'i-skand-dropdown transition-transform',
            isShowingUploadDetails && 'transform-rotate-180',
          )}
        />
      </Button>
    </div>
  );
};
