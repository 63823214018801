import { Layer, LayerGroup } from '@/stores/viewer';
import { cn } from '@/utils/classname';

export interface LayerGroupNodeProps {
  group: LayerGroup;
  setTargetLayer: (group: LayerGroup | Layer) => void;
}

export const LayerGroupNode = ({ group, setTargetLayer }: LayerGroupNodeProps) => {
  return (
    <div
      className={cn('flex justify-between items-center py-1 gap-2')}
      onClick={() => setTargetLayer(group)}
    >
      <div className="i-skand-layers w-5 text-3 color-neutral-600" />
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
      </div>
    </div>
  );
};
