import {
  SystemNodeKindInput,
  ListSystemNodesByParentNodeIdQueryVariables,
} from '@/graphql/codegen/graphql';
import { LIST_SYSTEM_NODES_BY_PARENT_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from '@/utils/empty';
import { useMemo } from 'react';
import { createSystemNode } from '@/utils/transformers';
import { PaginatedSystemNodes } from '@/stores/systemNodes';

const getQueryKey = (
  parentNodeId: string,
  pageSize: number,
  pageIndex: number,
  searchTerm: string,
  nodeKinds: SystemNodeKindInput[],
) => [
  'LIST_SYSTEM_NODES_BY_PARENT_NODE_ID',
  parentNodeId,
  parentNodeId,
  `pageSize-${pageSize}`,
  `pageIndex-${pageIndex}`,
  `searchTerm-${searchTerm}`,
  `nodeKinds-${nodeKinds.join(',')}`,
];

export const useFetchSystemNodes = (
  queryParam: ListSystemNodesByParentNodeIdQueryVariables,
  options?: { keepPreviousData?: boolean },
) => {
  const queryResult = useQuery({
    queryKey: getQueryKey(
      queryParam.parentNodeId ?? 'null',
      queryParam.pageSize,
      queryParam.pageIndex,
      queryParam.searchTerm || '',
      (queryParam.nodeKinds ?? []) as SystemNodeKindInput[],
    ),
    queryFn: () =>
      request(LIST_SYSTEM_NODES_BY_PARENT_NODE_ID, {
        ...queryParam,
      }),
    keepPreviousData: options?.keepPreviousData,
  });

  return useMemo(() => {
    const paginatedNodes: PaginatedSystemNodes = {
      nodes: [],
      nodeCount: 0,
      pageCount: 0,
    };
    if (!isEmpty(queryResult.data) && !isEmpty(queryResult.data.listSystemNodesByParentNodeId)) {
      const result = queryResult.data.listSystemNodesByParentNodeId;
      if (!isEmpty(result.totalNumberOfFiles)) {
        paginatedNodes.nodeCount = result.totalNumberOfFiles;
      }
      if (!isEmpty(result.totalNumberOfPages)) {
        paginatedNodes.pageCount = result.totalNumberOfPages;
      }
      if (!isEmpty(result.data)) {
        for (const nodeQuery of result.data) {
          if (!isEmpty(nodeQuery)) {
            const node = createSystemNode(nodeQuery);
            if (!isEmpty(node)) {
              paginatedNodes.nodes.push(node);
            }
          }
        }
      }
    }
    return paginatedNodes;
  }, [queryResult.data]);
};

useFetchSystemNodes.getQueryKey = getQueryKey;
