import { cn } from '@/utils/classname';
import { downloadBlob } from '@/utils/download';
import { Modal, Progress } from '@skand/ui';
import { useEffect, useRef, useState } from 'react';
import { Slider } from '@/components/Slider';
import { useOnClickOutside } from '@/utils/useOnClickOutside';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { MinusIcon } from '@/components/IconButton';
export interface ImagePreviewProps {
  url: string;
  close: () => void;
  handleDownload: () => void;
  disableDownload?: boolean;
  width?: number;
}

export const ImagePreview = ({
  url,
  close,
  handleDownload,
  disableDownload = false,
  width,
}: ImagePreviewProps) => {
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [mouseEventType, setMouseEventType] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const isLoading = downloadProgress > 0 && downloadProgress < 1 && imageUrl === null;

  const imageRef = useRef<HTMLInputElement>(null);
  const enabledOutsideClick = useOnClickOutside(imageRef);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const [sliderValue, setSliderValue] = useState<number>(1);

  useEffect(() => {
    if (enabledOutsideClick) {
      close();
    }
  }, [close, enabledOutsideClick]);

  useEffect(() => {
    if (url) {
      const downloadImageBlob = async () => {
        const imgBlob = await downloadBlob(url, setDownloadProgress);
        setImageUrl(window.URL.createObjectURL(imgBlob));
      };
      downloadImageBlob();
    }
  }, [url]);

  const handleZoomIn = () => {
    if (transformComponentRef.current) {
      const { zoomIn } = transformComponentRef.current;
      zoomIn();
    }
  };

  const handleZoomOut = () => {
    if (transformComponentRef.current) {
      const { zoomOut } = transformComponentRef.current;
      zoomOut();
    }
  };
  return (
    <Modal className="overflow-hidden">
      <div
        className={cn(
          'flex',
          'flex-col',
          'h-600px',
          'relative',
          width ? `w-[${width}]px` : 'w-900px',
          isLoading && 'w-[400px] h-80',
        )}
        ref={imageRef}
      >
        <div
          className={cn(
            'flex',
            'flex-row',
            'items-center gap-2',
            'justify-end',
            'absolute',
            'right-2',
            'top-2',
            'z-2',
          )}
        >
          {!disableDownload && (
            <div
              className="h-28px w-28px flex items-center justify-center border-1px border-neutral-500 rounded-4px border-solid bg-neutral-100"
              onClick={handleDownload}
            >
              <div className="i-skand-download cursor-pointer text-12px color-neutral-600 hover:color-primary-400" />
            </div>
          )}

          <div
            className="h-28px w-28px flex items-center justify-center border-1px border-neutral-500 rounded-4px border-solid bg-neutral-100"
            onClick={close}
          >
            <div className="i-skand-close cursor-pointer text-12px color-neutral-600 hover:color-primary-400" />
          </div>
        </div>
        {isLoading && (
          <div
            className={cn(
              'flex',
              'h-full',
              'absolute',
              'w-full',
              'justify-center',
              'items-center',
              ' bg-neutral-200',
            )}
          >
            <Progress className="w-153px" progress={downloadProgress * 100} />
          </div>
        )}

        {imageUrl && (
          <TransformWrapper
            disablePadding
            initialScale={1}
            onPanningStart={(_, event) => {
              setMouseEventType(event.type);
            }}
            onPanningStop={(_, event) => {
              setMouseEventType(event.type);
            }}
            onTransformed={(_, state) => {
              // Getting current scale value

              setSliderValue(state.scale);
            }}
            ref={transformComponentRef}
            wheel={{
              smoothStep: 0.1,
            }}
          >
            <div
              className={cn(
                'flex',
                'flex-row',
                'absolute',
                'left-12px',
                'top-8px',
                'z-2',
                'gap-4px',
                'flex',
                'flex-row',
                'items-center',
                'justify-between',
              )}
            >
              <div
                className="h-20px w-20px flex items-center justify-center border-1px border-neutral-500 rounded-4px border-solid bg-neutral-100"
                onClick={() => handleZoomOut()}
              >
                <MinusIcon />
              </div>
              <Slider
                className="w-163px"
                max={8}
                min={1}
                onChange={e => {
                  if (transformComponentRef.current) {
                    const { centerView } = transformComponentRef.current;
                    centerView(parseFloat(e.target.value));
                  }
                }}
                step={0.1}
                value={sliderValue}
              />
              <div
                className="h-20px w-20px flex items-center justify-center border-1px border-neutral-500 rounded-4px border-solid bg-neutral-100"
                onClick={() => handleZoomIn()}
              >
                <div className="i-skand-add cursor-pointer text-3 color-neutral-600" />
              </div>
            </div>
            <TransformComponent
              contentStyle={{
                height: '600px',
                width: width ? width : '900px',
              }}
              wrapperStyle={{
                cursor: mouseEventType === 'mousedown' ? 'move' : 'pointer',
                height: '600px',
                width: width ? width : '900px',
              }}
            >
              <img className={cn('w-full', 'h-full', 'object-contain')} src={imageUrl} />
            </TransformComponent>
          </TransformWrapper>
        )}
      </div>
      <div />
    </Modal>
  );
};
