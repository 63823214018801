import { ANNOTATION_SHAPE_TYPE } from '@/constants/annotation';
import { EXPLORE_ROOT } from '@/constants/paths';
import { canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import {
  AnnotationSelectField,
  AnnotationTemplate,
  AnnotationTemplateSelectField,
  CreateAnnotationInput,
  CreateAnnotationMutationVariables,
  DeleteAnnotationMutationVariables,
  UpdateAnnotationMutationVariables,
} from '@/graphql/codegen/graphql';
import { CREATE_ANNOTATION, DELETE_ANNOTATION, UPDATE_ANNOTATION } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useFetchAnnotationHistory } from '@/hooks/useFetchAnnotationHistory';
import { useFetchAnnotations, useRefetchAnnotations } from '@/hooks/useFetchAnnotations';
import { useFetchProcesses } from '@/hooks/useFetchProcesses';
import { useFetchSceneEntityPermissions } from '@/hooks/useFetchSceneEntityPermissions';
import {
  ConfirmationModalState,
  DeleteConfirmationModal,
} from '@/pages/ProjectPage/DeleteConfirmationModal';
import {
  cancelEditAnnotation,
  editAnnotationSetColor,
  editAnnotationSetGroup,
  editAnnotationSetName,
  editAnnotationSetTemplate,
  saveEditAnnotation,
  setCurrentAnnotation,
  useExplore,
} from '@/stores/explore';
import {
  enableViewer2D,
  setTargetAnnotation2D,
  setTargetAnnotation3D,
  setTargetPhoto,
  startDraw2D,
  startDraw3D,
  useViewer,
} from '@/stores/viewer';
import { PolygonSketch2 } from '@/utils/Viewer2D/Editor/Sketch2';
import { createRandomName, findAnnotationColorFromTemplate } from '@/utils/annotation';
import { cn } from '@/utils/classname';
import { getShareLinkToken } from '@/utils/shareLink';
import { createAnnotation as transformCreateAnnotation } from '@/utils/transformers';
import { Button, Input, Menu, MenuItem, Select, toast } from '@skand/ui';
import { Sketch } from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Color, Matrix4, Quaternion, Vector3 } from 'three';
import { AnnotaionFields } from '../AnnotationFields';
import { AnnotationMetadata } from '../AnnotationMetadata';
import { IconButton } from '../IconButton';
import { Label } from '../Label';
import { ColorInput } from './ColorInput';
import { Thumbnail } from './Thumbnail';
import { isDraftUntouched } from './utils';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { useFetchUsers } from '@/hooks/useFetchUsers';
import { User } from '@/utils/user';
import { AnnotationVersion } from '@/utils/annotationVersion';

export const AnnotationEdit = () => {
  const shareLinkToken = getShareLinkToken();
  const fetchUsers = useFetchUsers();

  const targetProcess = useViewer(state => state.targetProcess);
  const api3D = useViewer(state => state.api3D);
  const api2D = useViewer(state => state.api2D);
  const annotationDraft = useExplore(state => state.annotationDraft);
  const annotationOriginal = useExplore(state => state.annotationOriginal);
  const projectId = useExplore(state => state.projectId);
  const enabledDraw2D = useViewer(state => state.enabledDraw2D);
  const enabledDraw3D = useViewer(state => state.enabledDraw3D);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);

  const [updatedBy, setUpdatedBy] = useState<User['firstName'] | null>(null);
  const [firstAnnotationVersion, setFirstAnnotationVersion] = useState<AnnotationVersion | null>(
    null,
  );

  const annotation = useMemo(() => {
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        if (annotation.id === annotationDraft?.annotationId) {
          return annotation;
        }
      }
    }
    return undefined;
  }, [annotationDraft, annotationGroups]);

  const fetchAnnotationHistory = useFetchAnnotationHistory(annotation?.id ?? null);

  useEffect(() => {
    fetchAnnotationHistory().then(result => {
      setFirstAnnotationVersion(result[0]);
    });
  }, [fetchAnnotationHistory]);

  useEffect(() => {
    fetchUsers().then(users => {
      const user = users.find(user => user.id === annotation?.updatedBy);
      if (user) {
        setUpdatedBy(`${user.firstName} ${user.lastName}`);
      } else {
        setUpdatedBy('System');
      }
    });
  }, [annotation?.updatedBy, fetchUsers]);

  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const groupPermission = getSceneEntityPermission(annotation?.group.sceneEntityId ?? null);

  const canEdit = useMemo(() => {
    if (getShareLinkToken() !== null) return false;
    if (annotationDraft?.mode === 'create') return true;
    if (annotationDraft?.mode === 'preview') return false;
    return canPolicyActionEdit(groupPermission);
  }, [annotationDraft, groupPermission]);

  const canSave = useMemo(() => {
    return canEdit || annotationDraft?.mode === 'preview'; // User cannot edit but cann save if they are previewing
  }, [annotationDraft, canEdit]);

  const [loading, setLoading] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [showMetadata, setShowMetadata] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  // Fetch annotation templates
  const { templates } = useAnnotationTemplates();
  const templateOptions = useMemo(() => {
    const options =
      templates.map(template => {
        return {
          key: template?.id || '',
          name: template?.name || '',
        };
      }) ?? [];

    return options;
  }, [templates]);

  // Annotation group options
  const groupOptions = useMemo(() => {
    const options = [];
    for (const group of annotationGroups) {
      const access = getSceneEntityPermission(group.sceneEntityId);
      if (annotationDraft?.mode === 'update' || canPolicyActionEdit(access)) {
        options.push({
          key: group.id,
          name: group.name,
        });
      }
    }
    return options;
  }, [annotationDraft?.mode, annotationGroups, getSceneEntityPermission]);

  // On close, show the annotations
  useEffect(() => {
    return () => {
      setTargetAnnotation2D(null);
      setTargetAnnotation3D(null);
    };
  }, []);

  const [randomName, setRandomName] = useState<string>(createRandomName);
  useEffect(() => {
    return () => setRandomName(createRandomName());
  }, []);
  const isRandomNameEnabled = annotationDraft?.mode === 'create';

  const { refetch: refetchAnnotations } = useRefetchAnnotations();

  // Mutation for creating an annotation
  const createAnnotation = useMutation({
    mutationFn: (variables: CreateAnnotationMutationVariables) =>
      request(CREATE_ANNOTATION, variables),
    onSuccess: data => {
      if (annotationDraft?.groupId) {
        queryClient.invalidateQueries(useFetchAnnotations.getQueryKey(annotationDraft.groupId));
        refetchAnnotations([annotationDraft.groupId]);
      }
      queryClient.invalidateQueries(useFetchProcesses.getQueryKey(projectId));
      saveEditAnnotation(data.createAnnotation?.annotationId ?? undefined);
      toast({ message: 'Successfully created.', lifespan: 5000, clickToDismiss: true });
    },
    onError: error => {
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  });

  // Mutation for updating an annotation
  const updateAnnotation = useMutation({
    mutationFn: (variables: UpdateAnnotationMutationVariables) =>
      request(UPDATE_ANNOTATION, variables),
    onSuccess: data => {
      if (annotationDraft?.groupId) {
        queryClient.invalidateQueries(useFetchAnnotations.getQueryKey(annotationDraft.groupId));
        refetchAnnotations([annotationDraft.groupId]);
      }
      queryClient.invalidateQueries(
        useFetchAnnotationHistory.getQueryKey(annotationDraft?.annotationId ?? null),
      );
      useViewer.setState(state => {
        for (const group of state.annotationGroups) {
          group.loadState = 'pending';
        }
        return { annotationGroups: [...state.annotationGroups] };
      });
      saveEditAnnotation(data.updateAnnotation?.annotationId ?? undefined);
      toast({ message: 'Successfully updated.', lifespan: 5000, clickToDismiss: true });
    },
    onError: error => {
      toast({
        type: 'warn',
        message: (error as Error).message,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  });

  // Mutation for deleting an annotation
  const deleteAnnotation = useMutation({
    mutationFn: (variables: DeleteAnnotationMutationVariables) =>
      request(DELETE_ANNOTATION, variables),
    onSuccess: () => {
      destroy2DSketches();
      if (annotationDraft?.groupId) {
        queryClient.invalidateQueries(useFetchAnnotations.getQueryKey(annotationDraft.groupId));
        refetchAnnotations([annotationDraft.groupId]);
      }
      useViewer.setState(state => {
        for (const group of state.annotationGroups) {
          group.loadState = 'pending';
        }
        return { annotationGroups: [...state.annotationGroups] };
      });
    },
  });

  // Edit 2D annotation
  const handleEdit2DAnnotation = () => {
    if (annotation && annotation.sketch2D && annotation.photo) {
      setTargetPhoto(annotation.photo);
      setTargetAnnotation2D(annotation);
      enableViewer2D();
      startDraw2D(
        annotation.sketch2D.getVertices(),
        annotation.sketch2D instanceof PolygonSketch2,
        new Color(annotationDraft?.color ?? '#FFFFFF'),
        true,
      );
    }
  };

  // Edit 3D annotation
  const handleEdit3DAnnotation = () => {
    if (annotation && annotation.sketch3D) {
      const sketch3D = annotation.sketch3D.getModel() as Sketch;
      const transform = new Matrix4().compose(
        sketch3D.getPosition(),
        sketch3D.getRotation(),
        sketch3D.getScale(),
      );
      const vertices = sketch3D.getVertices().map(vertex => vertex.applyMatrix4(transform));
      setTargetAnnotation3D(annotation);
      startDraw3D(
        vertices,
        sketch3D.getType() === 'polygon',
        new Color(annotationDraft?.color ?? '#FFFFFF'),
        true,
      );
    }
  };

  // Change annotation template
  const handleChangeTemplate = useCallback(
    (templateId: string) => {
      const template = templates.find(item => item?.id === templateId);
      if (!template) return;

      editAnnotationSetTemplate(template);
      const color = findAnnotationColorFromTemplate(annotationDraft, template);
      if (color) editAnnotationSetColor(color);
    },
    [annotationDraft, templates],
  );

  // Save the annotation
  const handleSave = async () => {
    setLoading(false);
    if (annotationDraft?.mode === 'create') {
      const annotation: CreateAnnotationInput = {
        name: annotationDraft.name ?? randomName,
        annotation2d: annotationDraft.annotation2d,
        annotation3d: annotationDraft.annotation3d,
        color: annotationDraft.color,
        projectId,
        templateId: annotationDraft.templateId,
        groupId: annotationDraft.groupId,
        fields: annotationDraft.fields,
      };
      if (targetProcess?.kind === 'annotation') {
        annotation.processId = targetProcess?.id;
      }
      await createAnnotation.mutateAsync({
        annotation,
      });

      // Cache the select fields
      const selectFields = annotationDraft.fields?.filter(field => field?.type === 'SELECT');
      setCurrentAnnotation(
        annotationDraft.color,
        annotationDraft.template,
        annotationDraft.groupId,
        selectFields as AnnotationSelectField[],
      );

      // Create a temporary annotation while the group is refetching
      const group = annotationGroups.find(group => group.id === annotation.groupId);
      if (api3D && api2D && group) {
        const temporary = await transformCreateAnnotation(
          {
            ...annotation,
            annotationId: 'TEMPORARY-ANNOTATION-ID',
            id: 'TEMPORARY-ANNOTATION-VERSION-ID',
          },
          group,
          annotationDraft.template as AnnotationTemplate,
          photo2DGroups,
          api3D,
          api2D,
          undefined,
        );
        if (temporary) {
          await temporary.sketch3D?.show();
          temporary.sketch2D?.show();
          group.annotations.push(temporary);
        }
      }
    } else if (annotationDraft?.mode === 'update' || annotationDraft?.mode === 'preview') {
      await updateAnnotation.mutateAsync({
        annotation: {
          annotation2d: annotationDraft.annotation2d,
          annotation3d: annotationDraft.annotation3d,
          annotationId: annotationDraft.annotationId,
          color: annotationDraft.color,
          fields:
            annotationDraft.fields?.map(field => ({
              ...field,
              __typename: undefined,
              file: undefined,
            })) ?? [],
          name: annotationDraft.name,
        },
      });
    }
    setLoading(true);
  };

  // Handle flying to the annotation
  const handleFlyTo = () => {
    if (api3D && annotation?.sketch3D) {
      api3D.navigation.lookAt(annotation.sketch3D);
    }
  };

  // Handle sharing a link to the annotation
  const handleShareLink = async () => {
    if (annotation && projectId) {
      const base = window.location.origin + EXPLORE_ROOT;
      const params = new URLSearchParams([
        ['project', projectId],
        ['annotation', annotation.id],
      ]);
      if (shareLinkToken) {
        params.append('shareLinkToken', shareLinkToken);
      }
      const url = `${base}?${params.toString()}`;
      await navigator.clipboard.writeText(url);
      toast({
        type: 'success',
        message: 'Annotation URL copied to clipboard.',
        lifespan: 5000,
      });
    }
  };

  // Handle deleting the annotation
  const handleDelete = () => {
    if (annotation) {
      setOpenConfirmationModal({
        isOpen: true,
        title: 'Delete Annotation',
        description: `Are you sure you want to delete the annotation ${annotation.name}?`,
        actionButton: 'Delete Annotation',
        actionFunction: async () => {
          await deleteAnnotation.mutateAsync({
            annotationId: annotation.id,
          });
          cancelEditAnnotation();
          setOpenConfirmationModal(state => {
            return { ...state, isOpen: false };
          });
        },
      });
    }
  };

  const destroy2DSketches = () => {
    for (const group of annotationGroups) {
      for (const anno of group.annotations) {
        if (anno.sketch2D && anno.photo === annotation?.photo) {
          anno.sketch2D.destroy();
        }
      }
    }
  };

  const isDirty = useMemo(() => {
    if (annotationDraft?.mode === 'create') return true;
    if (!annotationDraft || !annotationOriginal) return true;
    return !isDraftUntouched(annotationDraft, annotationOriginal);
  }, [annotationDraft, annotationOriginal]);

  const metadata = useMemo(() => {
    if (annotation?.sketch3D) {
      const sketch = annotation.sketch3D.getModel() as Sketch;
      const worldTransform = new Matrix4().compose(
        sketch.getPosition(),
        sketch.getRotation(),
        sketch.getScale(),
      );
      const points = sketch.getVertices().map(vertex => vertex.applyMatrix4(worldTransform));
      const isClosed = sketch.getType() === 'polygon';
      return { points, isClosed };
    } else if (
      annotationDraft?.annotation3d?.positions &&
      annotationDraft?.annotation3d?.shapeType
    ) {
      const group = annotationGroups.find(group => group.id === annotationDraft?.groupId);
      const transform = new Matrix4().compose(
        group?.sceneNode?.getPosition() ?? new Vector3(),
        group?.sceneNode?.getRotation() ?? new Quaternion(),
        group?.sceneNode?.getScale() ?? new Vector3(1, 1, 1),
      );
      const points = annotationDraft?.annotation3d?.positions?.map(point =>
        new Vector3(point?.x ?? 0, point?.y ?? 0, point?.z ?? 0).applyMatrix4(transform),
      );
      return {
        points,
        isClosed: annotationDraft.annotation3d?.shapeType === ANNOTATION_SHAPE_TYPE.POLYGON,
      };
    }
  }, [annotation, annotationDraft, annotationGroups]);

  const getColorField = () => {
    const templateField = annotationDraft?.template?.fields?.find(
      item => item?.name === annotationDraft?.template?.colorFromField,
    );
    const annotationField = annotationDraft?.fields?.find(
      field => field?.fieldId === templateField?.id,
    );
    if (!templateField) return annotationDraft?.color?.toUpperCase();
    else {
      const options = (templateField as AnnotationTemplateSelectField).options;
      const selected = options?.find(item => item?.id === annotationField?.optionId);
      return selected?.color?.toUpperCase();
    }
  };

  // Handle viewing the associated 2D image (if it exists)
  const handleViewAssociatedImage = () => {
    if (annotation && annotation.sketch2D && annotation.photo) {
      setTargetPhoto(annotation.photo);
      enableViewer2D();
    }
  };

  return (
    <>
      <div
        className={cn(
          'flex',
          'justify-between',
          'items-end',
          'border-neutral-500',
          'border-1',
          'border-b-solid',
          'pb-3',
          'flex-none',
        )}
      >
        <p className={cn('typo-heading-4 color-neutral-800')}>Annotation</p>

        {metadata && (
          <Button
            active={showMetadata}
            className="min-w-128px"
            onClick={() => setShowMetadata(state => !state)}
            primary
            size="s"
          >
            Metadata
          </Button>
        )}
      </div>

      <div className={cn('mt-3', 'flex', 'flex-row', 'justify-between')}>
        {annotationDraft?.mode === 'update' && !shareLinkToken && (
          <div
            className={cn(
              'flex',
              'w-124px',
              'h-24px',
              'justify-between',
              'b-rounded',
              'b-solid',
              'color-neutral-800',
              'typo-button-xs',
              'px-2',
              'py-1',
              canEdit ? 'cursor-pointer b-primary-400' : 'b-neutral-400',
            )}
            onClick={() => {
              if (canEdit) {
                setShowEditMenu(!showEditMenu);
              }
            }}
          >
            <div>Edit annotation</div>
            <div className="i-skand-dropdownarrow mt-0.5" />
          </div>
        )}
        {annotation && (
          <div className={cn('flex w-82.5px')}>
            <IconButton
              analyticsEventObject={ANALYTICS_EVENT_OBJECT.FLY_TO_ANNOTATION}
              buttonIcon={
                <div
                  className={cn(
                    'i-skand-flyto',
                    'text-18px',
                    'text-neutral-400 hover:text-neutral-600',
                  )}
                />
              }
              buttonState="default"
              className={cn('border-0', 'bg-transparent', 'cursor-pointer')}
              disabled={enabledPanoramaWalkthrough}
              onClick={handleFlyTo}
            />
            <IconButton
              analyticsEventObject={ANALYTICS_EVENT_OBJECT.SHARE_ANNOTATION}
              buttonIcon={
                <div
                  className={cn(
                    'i-skand-sharelink',
                    'text-18px',
                    'text-neutral-400 hover:text-neutral-600',
                  )}
                />
              }
              buttonState="default"
              className={cn('border-0', 'bg-transparent', 'cursor-pointer', 'text-neutral-400')}
              onClick={handleShareLink}
            />
            {!shareLinkToken && canEdit && (
              <IconButton
                analyticsEventObject={ANALYTICS_EVENT_OBJECT.DELETE_ANNOTATION}
                buttonIcon={
                  <div
                    className={cn(
                      'i-skand-trashbin',
                      'text-18px',
                      'text-neutral-400 hover:text-neutral-600',
                    )}
                  />
                }
                buttonState="default"
                className={cn('border-0', 'bg-transparent', 'cursor-pointer')}
                onClick={handleDelete}
              />
            )}
          </div>
        )}
        <DeleteConfirmationModal
          openConfirmationModal={openConfirmationModal}
          setOpenConfirmationModal={setOpenConfirmationModal}
        />
      </div>
      {showEditMenu && (
        <Menu className="absolute top-23 z-1">
          <MenuItem
            className="cursor-pointer"
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.EDIT_ANNOTATION_2D}
            disabled={
              enabledDraw2D ||
              enabledDraw3D ||
              !(annotation && annotation.sketch2D && annotation.photo)
            }
            onClick={handleEdit2DAnnotation}
          >
            Edit 2D Annotation
          </MenuItem>
          <MenuItem
            className="cursor-pointer"
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.EDIT_ANNOTATION_3D}
            disabled={enabledDraw2D || enabledDraw3D || !(annotation && annotation.sketch3D)}
            onClick={handleEdit3DAnnotation}
          >
            Edit 3D Annotation
          </MenuItem>
        </Menu>
      )}

      <div className="w-364px flex-1 overflow-auto pr-10px">
        {targetProcess?.kind === 'annotation' &&
          (annotationDraft?.mode === 'create' ||
            targetProcess.annotationIds.has(annotationDraft?.annotationId as string)) && (
            <Label
              ellipsis={false}
              labelTitle={`This annotation is associated with the current active process (${targetProcess.id}).`}
              labelType="info"
              textLength={150}
            />
          )}
        <div className="mt-3">
          <Input
            disabled={!canEdit}
            hint={annotationDraft?.name ? undefined : 'Required'}
            label="Name"
            onChange={editAnnotationSetName}
            placeholder={isRandomNameEnabled ? randomName : undefined}
            value={annotationDraft?.name ?? ''}
          />
        </div>
        {annotationDraft?.mode !== 'create' && (
          <div className={cn('flex flex-col', 'p-x-3 p-y-2 bg-neutral-200', 'mt-3', 'b-rounded')}>
            <div className={cn('flex flex-row')}>
              <p className="color-neutral-500 typo-text-s">
                Created By:
                <span className="mx-1 color-neutral-600 typo-text-s">
                  {firstAnnotationVersion?.createdBy?.firstName ?? 'System'}{' '}
                  {firstAnnotationVersion?.createdBy?.lastName ?? ''}
                </span>
              </p>
              <p className="color-neutral-500 typo-text-s">
                on
                <span className="ml-1 color-neutral-600 typo-text-s">
                  {firstAnnotationVersion?.createdAt.toLocaleDateString()}
                </span>
              </p>
            </div>
            <div className={cn('flex flex-row')}>
              <p className="color-neutral-500 typo-text-s">
                Updated By:<span className="mx-1 color-neutral-600 typo-text-s">{updatedBy}</span>{' '}
              </p>
              <p className="color-neutral-500 typo-text-s">
                on
                <span className="ml-1 color-neutral-600 typo-text-s">
                  {annotation?.updatedAt.toLocaleDateString()}
                </span>
              </p>
            </div>
          </div>
        )}

        <div className="mt-3">
          <Select
            disabled={annotationDraft?.mode !== 'create' || !canEdit}
            hint={annotationDraft?.templateId ? undefined : 'Required'}
            label="Template"
            onChange={handleChangeTemplate}
            options={templateOptions}
            style={{ display: 'block' }}
            value={annotationDraft?.templateId}
            width="full"
          />
        </div>
        <div className="mt-3">
          <Select
            disabled={annotationDraft?.mode !== 'create' || !canEdit || groupOptions.length === 0}
            hint={annotationDraft?.groupId ? undefined : 'Required'}
            label="Annotation group"
            onChange={editAnnotationSetGroup}
            options={groupOptions}
            style={{ display: 'block' }}
            value={annotationDraft?.groupId}
            width="full"
          />
        </div>

        {annotationDraft?.annotation2d?.imageFileId && (
          <div className="mt-3">
            <p className="color-neutral-800 typo-text-s">Associated 2D image</p>
            <div className="cursor-pointer" onClick={handleViewAssociatedImage}>
              <Thumbnail fileId={annotationDraft.annotation2d.imageFileId} />
            </div>
          </div>
        )}

        <div className="mt-3">
          <ColorInput
            disabled={!!annotationDraft?.template?.colorFromField || !canEdit}
            hint={
              annotationDraft?.template?.colorFromField
                ? `The colour of this annotation is determined by the ${annotationDraft.template.colorFromField} field.`
                : ''
            }
            onChange={editAnnotationSetColor}
            value={getColorField()}
          />
        </div>
        {annotationDraft?.template && <AnnotaionFields disabled={!canEdit} />}
        {metadata && showMetadata && (
          <div className={cn('b-b-1', 'b-b-solid', 'b-b-neutral-500', 'mt-3')} />
        )}
        {metadata && showMetadata && (
          <AnnotationMetadata isClosed={metadata.isClosed} points={metadata.points} />
        )}
      </div>

      <div
        className={cn(
          'flex',
          'flex-row',
          'justify-stretch',
          'gap-2',
          'sticky',
          'bottom-0',
          'flex-0',
          'pt-3',
        )}
      >
        <Button className={cn('flex-1')} onClick={cancelEditAnnotation} primary size="s">
          Close
        </Button>
        <Button
          className={cn('flex-1')}
          disabled={
            loading ||
            (isRandomNameEnabled ? false : !annotationDraft?.name) ||
            !annotationDraft?.templateId ||
            !annotationDraft?.groupId ||
            groupOptions.length === 0 ||
            !isDirty ||
            !!shareLinkToken ||
            !canSave
          }
          filled
          onClick={handleSave}
          primary
          size="s"
        >
          {annotationDraft?.mode === 'create'
            ? 'Create'
            : annotationDraft?.mode === 'update'
            ? 'Update'
            : annotationDraft?.mode === 'preview'
            ? 'Revert'
            : 'Save'}
        </Button>
      </div>
    </>
  );
};
