export const DrawIcon = () => {
  return (
    <svg fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M11.34 16.7831C11.599 16.7211 11.817 16.5491 11.937 16.3111L14.597 11.0921C14.775 10.7391 14.709 10.3131 14.429 10.0341L10.287 5.89214C10.008 5.61214 9.581 5.54614 9.23 5.72314L4.008 8.38314C3.773 8.50414 3.601 8.72114 3.538 8.98114L1 19.3201L11.34 16.7831Z"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.685"
      />
      <path
        d="M8.74143 11.5801L1.00043 19.3201"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.685"
      />
      <path
        clipRule="evenodd"
        d="M11.2599 2.1865L12.1799 1.2665C12.5349 0.911501 13.1139 0.911501 13.4689 1.2645L19.0539 6.8505C19.4089 7.2065 19.4089 7.7855 19.0539 8.1405L18.1339 9.0605C17.7789 9.4165 17.1979 9.4165 16.8429 9.0605L11.2579 3.4755C10.9039 3.1225 10.9039 2.5415 11.2599 2.1865Z"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.685"
      />
    </svg>
  );
};
