import { LIST_MANUAL_PROCESSES_BY_PROJECT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useExplore } from '@/stores/explore';

import { isEmpty } from '@/utils/empty';
import { Process } from '@/utils/process';
import { getShareLinkToken } from '@/utils/shareLink';
import { createProcess } from '@/utils/transformers';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (projectId: string | null) => [
  'LIST_MANUAL_PROCESSES_BY_PROJECT_ID',
  projectId,
];

export const useFetchProcesses = () => {
  const projectId = useExplore(state => state.projectId);
  const shareLinkToken = getShareLinkToken();

  const queryResult = useQuery({
    enabled: !!projectId && !shareLinkToken,
    queryFn: () => request(LIST_MANUAL_PROCESSES_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: getQueryKey(projectId),
  });

  return useCallback(async () => {
    const processes: Process[] = [];
    if (!isEmpty(queryResult.data) && !isEmpty(queryResult.data.listManualProcessesByProjectId)) {
      for (const processQuery of queryResult.data.listManualProcessesByProjectId) {
        if (!isEmpty(processQuery)) {
          const process = createProcess(processQuery);
          if (process) {
            processes.push(process);
          }
        }
      }
    }
    return processes;
  }, [queryResult.data]);
};

useFetchProcesses.getQueryKey = getQueryKey;
