import { FIND_PROJECT_BY_PROJECT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useExplore } from '@/stores/explore';
import { isEmpty } from '@/utils/empty';
import { createProject } from '@/utils/transformers';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (projectId: string | null) => ['FIND_PROJECT_BY_PROJECT_ID', projectId];

export const useFetchProject = () => {
  const projectId = useExplore(state => state.projectId);

  const queryResult = useQuery({
    enabled: !!projectId,
    queryFn: () => request(FIND_PROJECT_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: getQueryKey(projectId),
  });

  const fetch = useCallback(async () => {
    if (!isEmpty(queryResult.data) && !isEmpty(queryResult.data.readProjectById)) {
      return createProject(queryResult.data.readProjectById, undefined);
    }
  }, [queryResult.data]);

  return {
    isLoading: queryResult.isLoading,
    fetch,
  };
};

useFetchProject.getQueryKey = getQueryKey;
