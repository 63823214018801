import { useMemo } from 'react';
import { FilterOptions, LayerFilterCategory, LayerFilterKey } from './LayerFilterKey';
import { Checkbox } from '@/components/Checkbox';
import { cn } from '@/utils/classname';
import { useViewer } from '@/stores/viewer';

export interface LayerFilterGroupProps {
  category: LayerFilterCategory;
  filterKeys: Set<LayerFilterKey>;
  setFilterKeys: (values: Set<LayerFilterKey>) => void;
}

export const LayerFilterGroup = ({
  category,
  filterKeys,
  setFilterKeys,
}: LayerFilterGroupProps) => {
  const targetProcess = useViewer(state => state.targetProcess);

  const options = FilterOptions[category];
  const checked = useMemo(
    () => options.every(([value]) => filterKeys.has(value)),
    [filterKeys, options],
  );
  const partiallyChecked = useMemo(
    () => options.some(([value]) => filterKeys.has(value)) && !checked,
    [checked, filterKeys, options],
  );

  // Toggle the entire group
  const toggleFilterGroup = () => {
    for (const [value] of options) {
      if (checked || partiallyChecked) {
        filterKeys.delete(value);
      } else {
        filterKeys.add(value);
      }
    }
    setFilterKeys(new Set(filterKeys));
  };

  // Toggle an individual filter
  const toggleFilter = (key: LayerFilterKey) => {
    if (filterKeys.has(key)) {
      filterKeys.delete(key);
    } else {
      filterKeys.add(key);
    }
    setFilterKeys(new Set(filterKeys));
  };

  return (
    <div className={cn('flex flex-col gap-2 mb-3')} key={category}>
      <div className={cn('flex', 'flex-row', 'items-center', 'gap-3')}>
        <Checkbox
          checked={checked}
          checkedPartial={partiallyChecked}
          setToggleCheckbox={() => toggleFilterGroup()}
          small
        />
        <p className={cn('color-neutral-800', 'typo-text-s-em')}>{category}</p>
      </div>
      {options.map(
        ([value, label]) =>
          (targetProcess || category !== 'Annotations' || value !== 'process') && (
            <div className={cn('flex', 'flex-row', 'items-center', 'ml-3', 'gap-3')} key={value}>
              <Checkbox
                checked={filterKeys.has(value)}
                setToggleCheckbox={() => toggleFilter(value)}
                small
              />
              <p className={cn('color-neutral-800', 'typo-text-s')}>{label}</p>
            </div>
          ),
      )}
    </div>
  );
};
