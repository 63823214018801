import { AnnotationTemplateSelectField } from '@/graphql/codegen/graphql';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useMemo, useState } from 'react';
import { cn } from '@/utils/classname';
import { TemplateFilterKey } from './TemplateFilterKey';
import { Template, TemplateField, TemplateFieldOption } from './Template';
import { TemplateRow } from './TemplateRow';
import { FieldsFilterMenu } from './FieldsFilterMenu';

export interface TemplateFilterTabProps {
  filterKeys: Set<TemplateFilterKey>;
  setFilterKeys: (values: Set<TemplateFilterKey>) => void;
}

export const TemplateFilterTab = ({ filterKeys, setFilterKeys }: TemplateFilterTabProps) => {
  const { templates } = useAnnotationTemplates();

  // Generate list of templates with select fields
  const selectTemplates = useMemo(() => {
    const groups: Template[] = [];
    for (const template of templates) {
      const fields: TemplateField[] = [];
      for (const entry of template.fields ?? []) {
        const field = entry as AnnotationTemplateSelectField;
        const options: TemplateFieldOption[] = [
          {
            id: null,
            value: 'Undefined',
          },
        ];
        for (const option of field.options ?? []) {
          options.push({
            id: option?.id as string,
            value: option?.value as string,
          });
        }
        fields.push({
          id: field.id as string,
          name: field.name as string,
          options: field.type === 'SELECT' ? options : [],
          type: field.type as string,
        });
      }
      groups.push({
        id: template.id,
        name: template.name as string,
        fields,
      });
    }
    return groups;
  }, [templates]);

  const [activeTemplate, setActiveTemplate] = useState<Template | null>(null);

  return (
    <div className={cn('h-full ')}>
      <div className="h-full flex flex-1 flex-col">
        {selectTemplates.map(template => (
          <TemplateRow
            activeTemplate={activeTemplate}
            filterKeys={filterKeys}
            key={template.id}
            setActiveTemplate={setActiveTemplate}
            setFilterKeys={setFilterKeys}
            template={template}
          />
        ))}
      </div>
      {activeTemplate && (
        <FieldsFilterMenu
          filterKeys={filterKeys}
          setFilterKeys={setFilterKeys}
          template={activeTemplate}
        />
      )}
    </div>
  );
};
