import { create } from 'zustand';

interface AnnotationState {
  annotationGroupIds: string[];
  activeAnnotationInformation: {
    activeAnnotationId: string;
    activeAnnotationGroupId: string;
    activeAnnotationTemplateId: string;
  };
  setAnnotationGroupIds: (ids: string[]) => void;
  setActiveAnnotationId: (id: string) => void;
  setActiveAnnotationGroupId: (id: string) => void;
  setActiveAnnotationTemplateId: (id: string) => void;
  clearActiveAnnotationInformation: () => void;
}

export const useAnnotationStore = create<AnnotationState>()(set => ({
  annotationGroupIds: [] as string[],
  activeAnnotationInformation: {
    activeAnnotationId: '',
    activeAnnotationGroupId: '',
    activeAnnotationTemplateId: '',
  },

  setAnnotationGroupIds: (ids: string[]) => set({ annotationGroupIds: ids }),
  setActiveAnnotationId: (id: string) =>
    set(state => ({
      activeAnnotationInformation: {
        ...state.activeAnnotationInformation,
        activeAnnotationId: id,
      },
    })),
  setActiveAnnotationGroupId: (id: string) =>
    set(state => ({
      activeAnnotationInformation: {
        ...state.activeAnnotationInformation,
        activeAnnotationGroupId: id,
      },
    })),
  setActiveAnnotationTemplateId: (id: string) =>
    set(state => ({
      activeAnnotationInformation: {
        ...state.activeAnnotationInformation,
        activeAnnotationTemplateId: id,
      },
    })),
  clearActiveAnnotationInformation: () =>
    set(state => ({
      activeAnnotationInformation: {
        ...state.activeAnnotationInformation,
        activeAnnotationId: '',
        activeAnnotationGroupId: '',
        activeAnnotationTemplateId: '',
      },
    })),
}));
