import { ReactSVGElement, SVGAttributes } from 'react';

export const NodeGroupIcon = ({ className }: SVGAttributes<ReactSVGElement>) => {
  return (
    <svg
      className={className}
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" fill="white" r="5.5" stroke="#726F74" />
      <circle cx="4.30005" cy="5.7998" fill="white" r="2" stroke="#726F74" />
      <circle cx="7.30005" cy="4.7998" fill="white" r="2" stroke="#726F74" />
      <circle cx="6.30005" cy="7.7998" fill="white" r="2" stroke="#726F74" />
    </svg>
  );
};
