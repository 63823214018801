import { cn, ColorPicker } from '@skand/ui';
import { useCallback, useState } from 'react';
import { ColorResult } from 'react-color';

export interface ColorInputProps {
  disabled?: boolean;
  hint?: string;
  onChange?: (color: string) => void;
  value?: string;
}

export const ColorInput = ({ onChange, value, hint, disabled }: ColorInputProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    (color: ColorResult) => {
      if (onChange) onChange(color.hex.toUpperCase());
    },
    [onChange],
  );

  return (
    <div>
      <div
        className={cn(
          'w-full',
          'b-1 b-solid',
          'p-x-3 p-y-2',
          'rounded-1',
          'typo-text-s',
          'color-neutral-600',
          'cursor-pointer',
          'flex flex-row flex-nowrap items-center justify-between',
          disabled
            ? 'bg-neutral-300 b-neutral-300  hover:b-neutral-300'
            : isOpen
            ? 'b-neutral-800 hover:b-neutral-800'
            : 'b-neutral-400 hover:b-neutral-800',
        )}
        onClick={() => {
          if (!disabled) setIsOpen(true);
        }}
      >
        <div className="flex-1">
          <p className="color-neutral-800 typo-text-xxs">Colour</p>
          <p className="color-neutral-600 typo-text-s">{value ?? 'Select colour'}</p>
        </div>
        <div
          className={cn('w-3 h-3', 'ml-4', 'rounded-full', 'ring-1 ring-inset ring-neutral-300')}
          style={{ backgroundColor: value ?? '#FFFFFF' }}
        />
      </div>
      {isOpen && (
        <ColorPicker
          closeMenu={() => setIsOpen(false)}
          color={value ?? '#FFFFFF'}
          colorPickerHeader="Custom colour"
          openingCoordinates={{ x: 0, y: 0 }}
          setColor={handleChange}
        />
      )}
      {hint && <span className={cn('mt-1 typo-text-xs color-neutral-600')}>{hint}</span>}
    </div>
  );
};
