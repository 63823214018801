// @unocss-include
import { create } from 'zustand';
import { clearTemplate } from './template';
import { getShareLinkToken } from '@/utils/shareLink';
import { isEmpty } from '@/utils/empty';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export type TabKey = 'files' | 'scene' | 'annotations' | 'sharelink' | 'templates' | 'process';

export interface TabItem {
  key: TabKey;
  title: string;
  iconClass: string;
  hide: boolean;
  dataAnalyticsEventObject?: string;
}

export const TAB_ITEMS: TabItem[] = [
  {
    key: 'scene',
    title: 'Layers',
    iconClass: 'i-skand-layers',
    hide: false,
    dataAnalyticsEventObject: ANALYTICS_EVENT_OBJECT.LAYER_TAB,
  },
  // {
  //   key: 'annotations',
  //   title: 'Annotations',
  //   iconClass: 'i-skand-annotation',
  // },
  {
    key: 'templates',
    title: 'Templates',
    iconClass: 'i-skand-templates',
    hide: !isEmpty(getShareLinkToken()),
    dataAnalyticsEventObject: ANALYTICS_EVENT_OBJECT.TEMPLATE_TAB,
  },
  {
    key: 'sharelink',
    title: 'Share link',
    iconClass: 'i-skand-sharelink',
    hide: !isEmpty(getShareLinkToken()),
    dataAnalyticsEventObject: ANALYTICS_EVENT_OBJECT.SHARE_LINK_TAB,
  },
  {
    key: 'process',
    title: 'Process',
    iconClass: 'i-skand-gear',
    hide: !isEmpty(getShareLinkToken()),
    dataAnalyticsEventObject: ANALYTICS_EVENT_OBJECT.PROCESS_TAB,
  },
];

interface LayoutState {
  isShowingLeftSideBarI: boolean;
  isShowingLeftSideBarII: boolean;
  isShowingRightSideBar: boolean;
  isShowingTemplatePanel: boolean;
  tab: TabKey;
  tabs: TabItem[];
  leftSideBarII: string;

  switch: (next: TabKey) => void;
  setLeftSideBarII: (val: string) => void;

  hideLeftSideBarI: () => void;
  hideLeftSideBarII: () => void;
  hideRightSideBar: () => void;
  showLeftSideBarII: () => void;
  showLeftSideBarI: () => void;
  showRightSideBar: () => void;

  showTemplatePanel: () => void;
  hideTemplatePanel: () => void;
}

export const useLayout = create<LayoutState>()((set, get) => ({
  isShowingLeftSideBarI: true,
  isShowingLeftSideBarII: false,
  isShowingRightSideBar: false,
  isShowingTemplatePanel: false,
  tab: TAB_ITEMS[0].key,
  tabs: TAB_ITEMS,
  switch: (next: TabKey) => {
    if (next !== 'templates') {
      get().hideTemplatePanel();
      clearTemplate();
    }
    set({ tab: next });
  },

  leftSideBarII: '',
  setLeftSideBarII: (val: string) => set({ leftSideBarII: val }),

  hideLeftSideBarI: () => set(state => ({ isShowingLeftSideBarI: state.isShowingLeftSideBarII })),
  hideLeftSideBarII: () => set({ isShowingLeftSideBarII: false }),
  hideRightSideBar: () => set({ isShowingRightSideBar: false }),
  showLeftSideBarI: () => set({ isShowingLeftSideBarI: true }),
  showLeftSideBarII: () => set(state => ({ isShowingLeftSideBarII: state.isShowingLeftSideBarI })),
  showRightSideBar: () => set({ isShowingRightSideBar: true }),
  showTemplatePanel: () => set({ isShowingTemplatePanel: true }),
  hideTemplatePanel: () => set({ isShowingTemplatePanel: false }),
}));
