import { FindIcon } from '@/components/IconButton';
import { VerticalSplitView } from '@/components/VerticalSplitView';
import { canPolicyActionEdit } from '@/constants/policy';
import { useFetchProjectPermissions } from '@/hooks/useFetchProjectPermissions';
import { useFetchProjects } from '@/hooks/useFetchProjects';
import { setMoveMultipleProjectsProjects, useProjectStore } from '@/stores/project';
import { cn } from '@/utils/classname';
import { Project, ProjectGroup } from '@/utils/project';
import { search } from '@/utils/search';
import * as Dialog from '@radix-ui/react-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, Input, Menu, MenuItem } from '@skand/ui';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { CreateGroup } from './CreateGroup';
import { CreateProject } from './CreateProject';
import { ExploreMap } from './ExploreMap/ExploreMap';
import { SearchProject } from './ExploreMap/SearchProject';
import { GroupTable } from './GroupTable';
import { MoveProject } from './MoveProject';
import { useProjectsPageActions } from '@/hooks/useProjectsPageActions';
import { ProjectsResultsDisplay } from './GroupTable/ProjectsResultsDisplay';
import { ProjectsSearchResultsSummary } from './ProjectsSearchResultsSummary';
import { persist } from '@/utils/Persist';
import { ViewType } from '@/utils/Persist/Store';

export enum ProjectDisplayState {
  Loading = 'loading',
  SearchResultsEmpty = 'searchResultsEmpty',
  ProjectsAndGroupsEmpty = 'projectsAndGroupsEmpty',
  Default = 'default',
}

export const ProjectPage = () => {
  const [projectPageSelectedProjects, setProjectPageSelectedProjects] = useState<string[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const hasSelectedProjects = !!projectPageSelectedProjects.length;

  const moveProject = useProjectStore(state => state.moveMultipleProjects.projects);
  const { fetch: fetchProjects, isLoading } = useFetchProjects();

  const [enabledCreateProjectDialog, setEnabledCreateProjectDialog] = useState(false);
  const [enabledCreateGroupDialog, setEnabledCreateGroupDialog] = useState(false);
  const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);

  const initialView = persist.get('projectView') || 'split';
  const [currentView, setCurrentView] = useState<ViewType>(initialView);

  const [groups, setGroups] = useState<ProjectGroup[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);

  const { allProjectsPermission, allGroupsPermission } = useFetchProjectPermissions();

  // Fetch projects and project groups
  useEffect(() => {
    fetchProjects().then(result => {
      setGroups(result.groups);
      setProjects(result.projects);
    });
  }, [fetchProjects]);

  // Filter projects by search key
  const filteredProjects = useMemo(
    () => projects.filter(project => search(project.name, searchKey)),
    [projects, searchKey],
  );

  // Filter projects that have location
  const locatedProjects = useMemo(
    () => filteredProjects.filter(project => project.location),
    [filteredProjects],
  ) as Solid<Project, 'location'>[];

  const {
    isAllGroupsFolded,
    isAllGroupsUnFolded,
    isSearchResultsEmpty,
    ungroupedProjects,
    handleCollapseAllGroups,
    handleExpandAllGroups,
  } = useProjectsPageActions(filteredProjects, searchKey);

  const openMoveProject = projectPageSelectedProjects === moveProject;
  const setOpenMoveProject = (open: boolean) => {
    setMoveMultipleProjectsProjects(open ? projectPageSelectedProjects : []);
  };

  // Debounced search handler and unfold groups
  const handleSearch = useMemo(() => {
    if (searchKey) {
      handleExpandAllGroups();
    }
    return debounce(setSearchKey, 300);
    // No need to include handleExpandAllGroups as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProjects, searchKey]);

  const handleResetGroupSearchAndDialogState = () => {
    handleExpandAllGroups();
    setSearchKey('');
    setEnabledCreateGroupDialog(false);
  };

  const getDisplayState = (
    isLoading: boolean,
    isSearchResultsEmpty: boolean,
    groups: ProjectGroup[],
    ungroupedProjects: Project[],
  ): ProjectDisplayState => {
    if (isLoading) {
      return ProjectDisplayState.Loading;
    }
    if (isSearchResultsEmpty) {
      return ProjectDisplayState.SearchResultsEmpty;
    }

    if (groups.length || ungroupedProjects.length) {
      return ProjectDisplayState.Default;
    }

    return ProjectDisplayState.ProjectsAndGroupsEmpty;
  };

  const resultsDisplayState = getDisplayState(
    isLoading,
    isSearchResultsEmpty,
    groups,
    ungroupedProjects,
  );

  // Clean up the handler
  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  // Toggle the view mode
  const toggleView = () => {
    switch (currentView) {
      case 'list':
        persist.set('projectView', 'split');
        setCurrentView('split');
        break;
      case 'split':
        persist.set('projectView', 'map');
        setCurrentView('map');
        break;
      case 'map':
        persist.set('projectView', 'list');
        setCurrentView('list');
        break;
    }
  };

  // Render the button label based on the current view
  const renderButtonLabel = () => {
    switch (currentView) {
      case 'list':
        return 'Split View';
      case 'split':
        return 'Map View';
      case 'map':
        return 'List View';
    }
  };

  // Render the view content based on the current view
  const renderViewContent = () => {
    switch (currentView) {
      case 'list':
        return renderListView();
      case 'map':
        return <ExploreMap projects={locatedProjects} />;
      case 'split':
        return renderSplitView();
    }
  };

  // Render the split view
  const renderSplitView = () => (
    <VerticalSplitView
      enabledSplit={true}
      leftPane={renderListView()}
      rightPane={<ExploreMap projects={locatedProjects} />}
    />
  );

  const renderListView = () => (
    <div className={cn(currentView === 'split' && 'pr-8px', 'flex flex-col h-full ')}>
      <div className="flex items-center b-b-1 b-b-neutral-500 b-b-solid p-b-3 p-t-8">
        <h1 className="color-neutral-800 typo-heading-3">Projects</h1>
        <div className="flex-1" />

        {canPolicyActionEdit(allProjectsPermission) && (
          <Dialog.Root onOpenChange={setOpenMoveProject} open={openMoveProject}>
            <Dialog.Trigger asChild>
              <Button className={cn('rounded-1 w-136px')} disabled={!hasSelectedProjects} size="s">
                Move project
              </Button>
            </Dialog.Trigger>

            <Dialog.Portal>
              <Dialog.Overlay />
              {openMoveProject && (
                <MoveProject groups={groups} projectIds={projectPageSelectedProjects} />
              )}
            </Dialog.Portal>
          </Dialog.Root>
        )}

        <CreateProject
          active={enabledCreateProjectDialog}
          groups={groups}
          handleResetGroupSearchAndDialogState={handleResetGroupSearchAndDialogState}
          setActive={setEnabledCreateProjectDialog}
        />
        <CreateGroup
          active={enabledCreateGroupDialog}
          handleResetGroupSearchAndDialogState={handleResetGroupSearchAndDialogState}
          projects={projects}
          setActive={setEnabledCreateGroupDialog}
        />

        <DropdownMenu.Root onOpenChange={setIsCreateDropdownOpen} open={isCreateDropdownOpen}>
          {(canPolicyActionEdit(allProjectsPermission) ||
            canPolicyActionEdit(allGroupsPermission)) && (
            <DropdownMenu.Trigger asChild>
              <Button
                active={isCreateDropdownOpen}
                className="ml-3 w-136px flex cursor-pointer items-center justify-between text-left"
                filled
                primary
                size="s"
              >
                Create <div className="i-skand-dropdown" />
              </Button>
            </DropdownMenu.Trigger>
          )}

          {currentView === 'list' && (
            <Button
              className="ml-2 w-136px cursor-pointer px-10"
              onClick={toggleView}
              primary
              size="s"
            >
              {renderButtonLabel()}
            </Button>
          )}
          <DropdownMenu.Portal>
            <DropdownMenu.Content asChild>
              <Menu className="my-0 w-136px px-0">
                {canPolicyActionEdit(allProjectsPermission) && (
                  <DropdownMenu.Item asChild>
                    <MenuItem
                      className="cursor-pointer rounded outline-none"
                      onClick={() => {
                        setEnabledCreateProjectDialog(true);
                        setIsCreateDropdownOpen(false);
                      }}
                    >
                      Project
                    </MenuItem>
                  </DropdownMenu.Item>
                )}
                {canPolicyActionEdit(allGroupsPermission) && (
                  <DropdownMenu.Item asChild>
                    <MenuItem
                      className="cursor-pointer rounded outline-none"
                      onClick={() => {
                        setEnabledCreateGroupDialog(true);
                        setIsCreateDropdownOpen(false);
                      }}
                    >
                      Group
                    </MenuItem>
                  </DropdownMenu.Item>
                )}
              </Menu>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>

      <div className="mt-3 flex items-center gap-2">
        <div className="min-w-360px color-neutral-800">
          <Input
            onChange={handleSearch}
            placeholder="Search for project or group name"
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
        {searchKey.trim() && filteredProjects.length > 0 && (
          <div className={cn('truncate')}>
            <ProjectsSearchResultsSummary
              filteredProjects={filteredProjects}
              searchKey={searchKey}
            />
          </div>
        )}
      </div>
      <ProjectsResultsDisplay
        resultsDisplayState={resultsDisplayState}
        searchKey={searchKey}
        setEnabledCreateProjectDialog={setEnabledCreateProjectDialog}
        setIsCreateDropdownOpen={setIsCreateDropdownOpen}
        setProjectPageSearchKey={setSearchKey}
      >
        <GroupTable
          groups={groups}
          handleCollapseAllGroups={handleCollapseAllGroups}
          handleExpandAllGroups={handleExpandAllGroups}
          isAllGroupsFolded={isAllGroupsFolded}
          isAllGroupsUnFolded={isAllGroupsUnFolded}
          isSearchResultsEmpty={isSearchResultsEmpty}
          projectPageSelectedProjects={projectPageSelectedProjects}
          projects={filteredProjects}
          searchKey={searchKey}
          setProjectPageSelectedProjects={setProjectPageSelectedProjects}
          ungroupedProjects={ungroupedProjects}
        />
      </ProjectsResultsDisplay>
    </div>
  );

  return (
    <div
      className={cn(
        'relative',
        'h-full',
        'w-full',
        'overflow-auto',
        'bg-neutral-100',
        currentView === 'map' ? 'pl-0 pr-0' : currentView === 'split' ? 'pl-6' : 'pl-6 pr-6',
      )}
    >
      {currentView !== 'list' && (
        <Button
          className={cn('absolute cursor-pointer right-5 top-7 z-1 px-10 w-136px')}
          onClick={toggleView}
          primary
          size="s"
        >
          {renderButtonLabel()}
        </Button>
      )}
      {currentView === 'map' && (
        <>
          <SearchProject
            projects={locatedProjects}
            searchKey={searchKey}
            setSearchKey={handleSearch}
          />

          {filteredProjects.length > 0 && searchKey.trim().length > 0 ? (
            <div
              className={cn(
                'absolute px-2 top-7 z-1 left-80 bg-white bg-opacity-80 px-1 rounded h-38px flex items-center  w-fit lg:max-w-80% md:max-w-60% sm:max-w-40%',
              )}
            >
              <ProjectsSearchResultsSummary
                filteredProjects={filteredProjects}
                searchKey={searchKey}
              />
            </div>
          ) : searchKey.trim().length > 0 ? (
            <div
              className={cn(
                'absolute px-2 top-7 z-1 left-80 bg-white bg-opacity-80 px-1 rounded h-38px flex items-center  w-fit lg:max-w-80% md:max-w-60% sm:max-w-40%',
              )}
            >
              <p
                className={cn(
                  'typo-text-small color-neutral-600 whitespace-nowrap flex flex-row gap-1 items-center justify-end',
                )}
              >
                No results found for projects with the term
                <span
                  className="inline-block max-w-245px truncate color-primary-400 typo-text-small-em"
                  title={searchKey}
                >
                  &#34;{searchKey}&#34;
                </span>
              </p>
            </div>
          ) : null}
        </>
      )}

      {renderViewContent()}
    </div>
  );
};
