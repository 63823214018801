import { useState, useEffect, useMemo, useCallback } from 'react';
import { Thumbnail } from './Thumbnail';
import { Photo, setTargetPhoto, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import { Panorama } from '@skand/viewer-component-v2';
import { QuickList, QuickListAPI, QuickListItemProps } from '@skand/ui';

interface RibbonListProps {
  photos: Photo[];
}

export const RibbonList = ({ photos }: RibbonListProps) => {
  const targetPhoto = useViewer(state => state.targetPhoto);

  const [, setReady] = useState(false);
  const [quickListAPI, setQuickListAPI] = useState<QuickListAPI | null>(null);

  const noUrlPhotos = useMemo(
    () => photos.filter(photo => !photo.url).map(photo => photo.id),
    [photos],
  );
  const fetchUrls = useFetchImageUrls(noUrlPhotos, false);

  const noThumbnailPhotos = useMemo(
    () => photos.filter(photo => !photo.thumbnailUrl).map(photo => photo.id),
    [photos],
  );
  const fetchThumbnailUrls = useFetchImageUrls(noThumbnailPhotos, true);

  // Fetch urls in bulk for photos in the sublist
  useEffect(() => {
    const fetchPhotoUrls = async () => {
      setReady(false);
      const urls: Map<string, string> = noUrlPhotos.length ? await fetchUrls() : new Map();
      const thumbnailUrls: Map<string, string> = noThumbnailPhotos.length
        ? await fetchThumbnailUrls()
        : new Map();

      for (const photo of photos) {
        const url = urls.get(photo.id);
        const thumbnailUrl = thumbnailUrls.get(photo.id);

        const widget = photo.widget?.getModel();
        if (url) {
          photo.url = url;
          if (widget instanceof Panorama && !photo.tileset) {
            widget.setUrl(url);
          }
        }
        if (thumbnailUrl) {
          photo.thumbnailUrl = thumbnailUrl;
          if (widget instanceof Panorama) {
            widget.setThumbnailUrl(thumbnailUrl);
          }
        }
      }
      setReady(true);
    };
    fetchPhotoUrls();
  }, [fetchThumbnailUrls, fetchUrls, noThumbnailPhotos, noUrlPhotos, photos]);

  // Scroll to selected photo
  useEffect(() => {
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (quickListAPI) {
      quickListAPI?.scrollTo(index * quickListAPI.itemSize);
    }
  }, [photos, quickListAPI, targetPhoto]);

  // Handler for keyboard input
  const onKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();
    const { key } = event;
    const index = photos.findIndex(photo => photo === targetPhoto);
    if (index < 0) {
      return;
    } else if (key === 'ArrowUp' && index > 0) {
      setTargetPhoto(photos[index - 1]);
    } else if (key === 'ArrowDown' && index < photos.length - 1) {
      setTargetPhoto(photos[index + 1]);
    }
  };

  // List item component wrapper
  const ListItem = useCallback(
    ({ index }: QuickListItemProps) => {
      const photo = photos[index];
      return (
        <div key={`photo-${index}`}>
          <Thumbnail photo={photo} />
        </div>
      );
    },
    [photos],
  );

  return (
    <div className={cn('h-full overflow-scroll w-full mt-2')} onKeyDown={onKeyDown} tabIndex={0}>
      <QuickList itemCount={photos.length} ref={setQuickListAPI}>
        {ListItem}
      </QuickList>
    </div>
  );
};
