import { AnnotationTemplate, AnnotationTemplateField } from '@/graphql/codegen/graphql';
import { create } from 'zustand';

export type WithMode<T> = T & { mode: 'update' | 'view' };

export interface TemplateState {
  accountLevelTemplate: null | AnnotationTemplate;
  mode: null | 'create' | 'update' | 'view';
  template: null | AnnotationTemplate;
  fields: WithMode<AnnotationTemplateField>[];

  /** next order */
  next: number;
}

export const useTemplate = create<TemplateState>()(() => {
  return {
    accountLevelTemplate: null,
    fields: [],
    mode: null,
    template: null,
    next: 0,
  };
});
