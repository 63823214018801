import { cn } from '@/utils/classname';
import { useState, useRef, useEffect } from 'react';
import { SortByModal, SortKey } from './SortByModal';
import { useOnClickOutside } from '@/utils/useOnClickOutside';

export const Sort = ({ onChangeSortKey }: { onChangeSortKey: (key: SortKey) => void }) => {
  const [isSortByModalOpen, setIsSortByModalOpen] = useState(false);
  const [currentSort, setCurrentSort] = useState<SortKey>('name');
  const sortModalRef = useRef<HTMLInputElement>(null);

  const enabledOutsideClick = useOnClickOutside(sortModalRef);

  useEffect(() => {
    if (enabledOutsideClick) {
      setIsSortByModalOpen(false);
    }
  }, [enabledOutsideClick]);

  const handleSortChange = (key: SortKey) => {
    onChangeSortKey(key);
    setCurrentSort(key);
  };

  const renderSortName = (currentSort: SortKey) => {
    switch (currentSort) {
      case 'name':
        return 'NAME';
      case 'createdAt':
        return 'DATE CREATED';
      case 'updatedAt':
        return 'MOST RECENT';
    }
  };

  return (
    <div className="mb-3 flex">
      <div className="flex-1" />

      <div className="relative">
        <div
          className={cn('flex', 'items-center', 'justify-between', 'gap-2')}
          onClick={() => setIsSortByModalOpen(!isSortByModalOpen)}
        >
          <p className={cn('color-neutral-800', 'typo-button-xs')}>
            SORTED BY: {renderSortName(currentSort)}
          </p>
          <div className={cn('i-skand-dropdown text-14px color-neutral-800')} />
        </div>

        {isSortByModalOpen && (
          <div ref={sortModalRef}>
            <SortByModal
              setIsSortByModalOpen={setIsSortByModalOpen}
              setSortKey={handleSortChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};
