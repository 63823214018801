import { LeftTabBar } from '@/components/LeftTabBar';
import { PROJECT_ROOT } from '@/constants/paths';
import { Button } from '@skand/ui';
import { Link, useParams } from 'react-router-dom';

interface Params {
  resourceType: string;
}

export const EmptyResourcePage = () => {
  const { resourceType } = useParams<Params>();

  let displayResourceType;
  switch (resourceType) {
    case 'project':
      displayResourceType = 'project';
      break;
    case 'users':
      displayResourceType = 'users';
      break;
    default:
      displayResourceType = 'resources';
  }

  return (
    <>
      <div className="h-full flex flex-col overflow-hidden bg-neutral-200">
        <div className="pointer-events-none w-full flex flex-1">
          <LeftTabBar isDisabled={true} />
        </div>
      </div>

      <div className="flex flex-1 flex-col items-center justify-center bg-neutral-100 color-neutral-800">
        <div className="flex flex-col gap-3">
          <p className="color-neutral-800 typo-text-l">No {displayResourceType} found</p>
          <p className="color-neutral-500 typo-text-m">
            Either you don&apos;t have access to this page or it doesn&apos;t exist.
          </p>
          <p className="color-neutral-500 typo-text-s">
            For further information and assistance, feel free to visit the{' '}
            <a className="color-neutral-500 underline typo-link-s" href="https://skand.cargo.site">
              Skand knowledge center
            </a>
            .
          </p>
          <Link to={PROJECT_ROOT}>
            <Button className="mt-6 w-full cursor-pointer" filled primary size="s">
              Return to Projects page
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
