import { SystemNodeKindInput } from '@/graphql/codegen/graphql';
import { Select } from '@skand/ui';
import { setTargetNodeId, setWillCreateSystemFolder, useUploadStore } from '@/stores/upload';
import { Finder } from './Finder';

export const TargetSection = () => {
  const targetNodeId = useUploadStore(state => state.targetNodeId);
  const willCreateSystemFolder = useUploadStore(state => state.willCreateSystemFolder);

  return (
    <>
      <div className="mt-6 flex items-center justify-between b-1 b-neutral-400 rounded-2 b-solid p-3">
        <span className="color-neutral-800 typo-text-m">Save files to:</span>
        <Select
          className="w-256px"
          onChange={(key: string) => {
            if (key === 'default') setWillCreateSystemFolder(true);
            else setWillCreateSystemFolder(false);
          }}
          options={[
            // { key: 'default', name: 'Project folder (Default)' },
            { key: 'select', name: 'Select from data management' },
          ]}
          value={willCreateSystemFolder ? 'default' : 'select'}
          width="full"
        />
      </div>

      {!willCreateSystemFolder && (
        <Finder
          nodeKinds={[SystemNodeKindInput.FolderNode]}
          onChangeParentNodeId={setTargetNodeId}
          parentNodeId={targetNodeId}
        />
      )}
    </>
  );
};
