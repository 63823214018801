import { IfcElementNode } from '@skand/viewer-component-v2';

// Get all guid's including decendants
export const getAllGuids = (nodes: IfcElementNode[]): string[] => {
  const guids: string[] = [];
  const queue: IfcElementNode[] = [...nodes];

  while (queue.length > 0) {
    const currentNode = queue.shift();
    if (!currentNode) continue; // Handle case where currentNode is undefined

    guids.push(currentNode.guid);

    for (const child of currentNode.children) {
      queue.push(child);
    }
  }

  return guids;
};
