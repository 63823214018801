import { Checkbox } from '@/components/Checkbox';
import { FindIcon } from '@/components/IconButton';
import {
  FilterMenu,
  LayerFilterKey,
  SceneEntity,
  SceneEntityTree,
  TemplateFilterKey,
} from '@/components/SceneEntityTree';
import { cn } from '@/utils/classname';
import { Button, Input, TreeNodeProps } from '@skand/ui';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AnnotationGroupNode } from './AnnotationGroupNode';
import { AnnotationNode } from './AnnotationNode';
import { LayerGroupNode } from './LayerGroupNode';
import { LayerNode } from './LayerNode';
import { PhotoGroupNode } from './PhotoGroupNode';
import { PhotoNode } from './PhotoNode';
import { getSceneEntityIds } from '@/utils/sceneTree';

interface ShareLinkContentProps {
  sceneEntityIds: string[];
  setSceneEntityIds: (value: string[]) => void;
  unselectableLayerTypes?: SceneEntity['entity']['type'][];
  disabled?: boolean;
}

export const ShareLinkContent = ({
  sceneEntityIds,
  setSceneEntityIds,
  unselectableLayerTypes = [],
  disabled = false,
}: ShareLinkContentProps) => {
  const [searchKey, setSearchKey] = useState('');
  const [enabledFilterMenu, setEnabledFilterMenu] = useState(false);

  const [layerFilterKeys, setLayerFilterKeys] = useState<Set<LayerFilterKey>>(new Set());
  const [templateFilterKeys, setTemplateFilterKeys] = useState<Set<TemplateFilterKey>>(new Set());

  // Debounced search handler
  const handleSearch = useMemo(() => {
    return debounce(setSearchKey, 300);
  }, [setSearchKey]);

  // Clean up the handler
  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  const handleToggleLayer = useCallback(
    (data: SceneEntity) => {
      // Remove sceneEntityId including decendants if already selected
      if ('sceneEntityId' in data.entity && sceneEntityIds.includes(data.entity.sceneEntityId)) {
        const ids = getSceneEntityIds(data);
        setSceneEntityIds(sceneEntityIds.filter(item => !ids.includes(item)));
      } else {
        // Add sceneEntityId including decendants
        setSceneEntityIds(Array.from(new Set([...sceneEntityIds, ...getSceneEntityIds(data)])));
      }
    },
    [sceneEntityIds, setSceneEntityIds],
  );

  // Define Tree node component
  const TreeNode = useCallback(
    ({ data, isLeaf, depth, setOpen, isOpen }: TreeNodeProps<SceneEntity>) => (
      <div className={cn('w-full h-full')}>
        <div
          className={cn('flex', 'flex-row', 'items-center', 'flex-1', 'mr-2', 'w-full')}
          style={{ marginLeft: `${depth * 0.5}rem` }}
        >
          {!unselectableLayerTypes.includes(data.entity.type) && (
            <Checkbox
              checked={
                'sceneEntityId' in data.entity && sceneEntityIds.includes(data.entity.sceneEntityId)
              }
              checkedPartial={
                'sceneEntityId' in data.entity &&
                sceneEntityIds.includes(data.entity.sceneEntityId) &&
                !getSceneEntityIds(data).every(element => sceneEntityIds.includes(element))
              }
              disabled={disabled}
              setToggleCheckbox={() => handleToggleLayer(data)}
              small
            />
          )}

          <div
            className={cn(
              !isLeaf ? 'i-skand-dropdownarrow cursor-pointer' : 'h-1em',
              'min-w-1em',
              'text-neutral-400',
              'text-3',
              'mr-2',
              'ml-2',
              !isOpen && 'rotate-270',
            )}
            onClick={() => setOpen(!isOpen)}
          />
          {data.entity.type === 'layerGroup' && <LayerGroupNode group={data.entity} />}
          {data.entity.type === 'layer' && <LayerNode layer={data.entity} />}
          {data.entity.type === 'annotation' && <AnnotationNode annotation={data.entity} />}
          {data.entity.type === 'annotationGroup' && <AnnotationGroupNode group={data.entity} />}

          {data.entity.type === 'photoGroup' && <PhotoGroupNode group={data.entity} />}
          {(data.entity.type === 'panorama' || data.entity.type === 'photo2D') && (
            <PhotoNode photo={data.entity} />
          )}
        </div>
      </div>
    ),
    [unselectableLayerTypes, sceneEntityIds, disabled, handleToggleLayer],
  );

  return (
    <div
      className={cn(
        'border-neutral-400',
        'border-1',
        'rounded-6px',
        'border-solid',
        'p-12px',
        'w-full',
        'mb-2',
        'mt-2',
        'flex',
        'flex-col',
        'flex-1',
        'h-full',
        'pb-8',
      )}
    >
      <div className={cn('flex gap-2 relative ')}>
        <div className={cn('w-full')}>
          <Input
            data-testid="input"
            label="Search"
            onChange={handleSearch}
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>
        <Button
          className={cn('px-5')}
          onClick={() => setEnabledFilterMenu(!enabledFilterMenu)}
          size="s"
        >
          Filter
        </Button>

        {enabledFilterMenu && (
          <FilterMenu
            className="top-12"
            closeMenu={() => setEnabledFilterMenu(false)}
            layerFilterKeys={layerFilterKeys}
            setLayerFilterKeys={setLayerFilterKeys}
            setTemplateFilterKeys={setTemplateFilterKeys}
            templateFilterKeys={templateFilterKeys}
          />
        )}
      </div>

      <div className={cn('flex-1', 'overflow-hidden')}>
        <SceneEntityTree
          layerFilterKeys={layerFilterKeys}
          searchKey={searchKey}
          templateFilterKeys={templateFilterKeys}
        >
          {TreeNode}
        </SceneEntityTree>
      </div>
    </div>
  );
};
