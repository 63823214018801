import { CircleDrawable, Draw2D } from '@skand/viewer-component-v2';
import { Color, Vector2 } from 'three';
import { Transform2 } from '../../Transform2';
import { Sketch2 } from './Sketch2';

/**
 * 2D point sketch object.
 */
export class PointSketch2 implements Sketch2 {
  private draw2D: Draw2D;
  private vertices: Vector2[];
  private circles: CircleDrawable[];
  private visible: boolean;
  private destroyHandler: (sketch: Sketch2) => void;

  constructor(
    draw2D: Draw2D,
    points: Vector2[],
    color: Color,
    destroyHandler: (sketch: Sketch2) => void,
  ) {
    this.draw2D = draw2D;
    this.vertices = points;
    this.circles = points.map(position => ({
      type: 'circle',
      radius: 5,
      color,
      position,
      opacity: 1.0,
      fill: true,
    }));
    this.visible = false;
    this.destroyHandler = destroyHandler;
  }

  show() {
    if (!this.visible) {
      this.visible = true;
      for (const circle of this.circles) {
        this.draw2D.addDrawable(circle);
      }
    }
  }

  hide() {
    if (this.visible) {
      this.visible = false;
      for (const circle of this.circles) {
        this.draw2D.removeDrawable(circle);
      }
    }
  }

  destroy() {
    this.destroyHandler(this);
  }

  isVisible() {
    return this.visible;
  }

  getVertices() {
    return this.vertices.map(vertex => vertex.clone());
  }

  applyTransform(transform: Transform2) {
    for (let i = 0; i < this.vertices.length; i++) {
      const circle = this.circles[i];
      circle.position = transform.imageToCanvasSpace(this.vertices[i]);
    }
  }

  /**
   * Test if a point is colliding with any of the circles.
   *
   * Algorithm: Point in circle test.
   *
   * @param point
   * @returns
   */
  isColliding(point: Vector2) {
    for (const { radius, position } of this.circles) {
      const dsq = position.distanceToSquared(point);
      if (dsq <= radius * radius) {
        return true;
      }
    }
    return false;
  }

  setColor(color: Color): void {
    for (const circle of this.circles) {
      circle.color = color;
    }
  }
}
