import { PARENT_NODES_CHAIN_BY_PARENT_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isEmpty } from '@/utils/empty';
import { createSystemNode } from '@/utils/transformers';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (parentNodeId?: null | string) => [
  'PARENT_NODES_CHAIN_BY_PARENT_NODE_ID',
  parentNodeId,
];

export const useFetchSystemNodeChain = (parentNodeId?: null | string) => {
  const response = useQuery({
    enabled: !!parentNodeId,
    queryFn: () => request(PARENT_NODES_CHAIN_BY_PARENT_NODE_ID, { parentNodeId: parentNodeId }),
    queryKey: getQueryKey(parentNodeId as string),
    keepPreviousData: true,
  });

  return useMemo(() => {
    const parentNodesChain = [];
    if (!isEmpty(response.data) && !isEmpty(response.data.parentNodesChainByParentNodeId)) {
      for (const nodeQuery of response.data.parentNodesChainByParentNodeId) {
        if (!isEmpty(nodeQuery)) {
          const node = createSystemNode(nodeQuery);
          if (!isEmpty(node)) {
            parentNodesChain.push(node);
          }
        }
      }
      return parentNodesChain;
    }
  }, [response.data]);
};

useFetchSystemNodeChain.getQueryKey = getQueryKey;
