import { UNIT, UploadSessionStage } from '@skand/uploader';

export const PREPARING_STAGES: UploadSessionStage[] = [
  'initial',
  'dataImportRequest_creating',
  'dataImportRequest_creating_success',
  'dataImportRequest_finding',
  'dataImportRequest_finding_success',
  'folderImportRequests_creating',
  'folderImportRequests_creating_success',
  'fileImportRequests_creating',
];

export const PREPARED_STAGES: UploadSessionStage[] = ['fileImportRequests_creating_success'];

export const UPLOADING_STAGES: UploadSessionStage[] = [
  'files_uploading',
  'files_uploading_success',
  'folderNodes_creating',
  'folderNodes_creating_success',
  'fileNodes_creating',
  'thumbnails_creating',
];

export const UPLOADED_STAGES: UploadSessionStage[] = ['thumbnails_creating_success'];

export const FAILURE_STAGES: UploadSessionStage[] = [
  'dataImportRequest_creating_failure',
  'dataImportRequest_finding_failure',
  'folderImportRequests_creating_failure',
  'fileImportRequests_creating_failure',
  'files_uploading_failure',
  'folderNodes_creating_failure',
  'fileNodes_creating_failure',
  'thumbnails_creating_failure',
];

export const CHUNK_SIZE_OPTION = {
  '5M': 5 * UNIT.MB,
  '50M': 50 * UNIT.MB,
  '500M': 500 * UNIT.MB,
  '5G': 5 * UNIT.GB,
} as const;
export type ChunkSizeOption = keyof typeof CHUNK_SIZE_OPTION;
