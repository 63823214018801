import { ANNOTATION_FIELD_TYPE } from '@/constants/annotation';
import { AnnotationFileField, AnnotationTemplateFileField } from '@/graphql/codegen/graphql';
import { useAccountId } from '@/hooks/useAccountId';
import { editAnnotationUpdateField } from '@/stores/explore';
import logger from '@/utils/logger';
import { Progress } from '@skand/ui';
import { useCreateNewSimpleUploadSession, useSimpleUploadSession } from '@skand/uploader';
import { ChangeEventHandler } from 'react';
import { simpleUploadSessionRequestService, toastError, uploaderFactory } from '../upload';

export const Upload = ({ field }: { field: AnnotationTemplateFileField }) => {
  const accountId = useAccountId();
  const { simpleUploadSession, createNewSimpleUploadSession } = useCreateNewSimpleUploadSession(
    uploaderFactory,
    simpleUploadSessionRequestService,
  );
  const { uploadProgress, stage } = useSimpleUploadSession(simpleUploadSession);

  const isUploading = stage !== 'initial';
  const progress =
    uploadProgress.bytesTotal === 0 ? 0 : uploadProgress.bytesUploaded / uploadProgress.bytesTotal;

  const uploadFilesAndUpdateFileId = async () => {
    try {
      await simpleUploadSession.start(accountId as string);
    } catch (e) {
      logger.error(e);
      toastError();
    }

    // retrieve file id from first file
    const fileNodes = simpleUploadSession.fileTree.mapFileNodes(fileNode => fileNode);
    const fileNode = fileNodes[0];
    if (!fileNode) return;

    editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.FILE, prev => {
      const oldFiles = (prev as AnnotationFileField).files ?? [];
      return {
        ...prev,
        files: [...oldFiles, { fileId: fileNode.fileId, name: fileNode.file.name }],
      };
    });

    createNewSimpleUploadSession();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const files = [...(e.target.files ?? [])];
    e.target.value = '';

    simpleUploadSession.addFiles(files);
    uploadFilesAndUpdateFileId();
  };

  if (!accountId) return null;

  if (isUploading) {
    return (
      <div className="h-4 py-1 pr-2">
        <Progress progress={progress} />
      </div>
    );
  }

  return (
    <label className="cursor-pointer color-neutral-600 underline typo-link-s">
      Choose attachment
      <input className="hidden" multiple onChange={handleChange} type="file" />
    </label>
  );
};
