import { useLayout } from '@/stores/layout';
import { setTargetMeasurement, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, ColorSelect } from '@skand/ui';
import { Color, Matrix4 } from 'three';
import { AnnotationMetadata } from '../AnnotationMetadata';

export const MeasurementView = () => {
  const targetMeasurement = useViewer(state => state.targetMeasurement);
  const hideRightSideBar = useLayout(state => state.hideRightSideBar);

  if (!targetMeasurement) return;
  const colorHex = targetMeasurement.sketch.getColor().getHexString();

  // Set the color of the sketch
  const handleColor = (colorHex: string) => {
    targetMeasurement.sketch.setColor(new Color(colorHex));
  };

  // Close the window
  const handleClose = () => {
    setTargetMeasurement(null);
    hideRightSideBar();
  };

  // Delete the measurement
  const handleDelete = async () => {
    await targetMeasurement.sketch.destroy();
    useViewer.setState(state => ({
      measurements: state.measurements.filter(m => m !== targetMeasurement),
    }));
    handleClose();
  };

  const worldTransform = new Matrix4().compose(
    targetMeasurement.sketch.getPosition(),
    targetMeasurement.sketch.getRotation(),
    targetMeasurement.sketch.getScale(),
  );
  const points = targetMeasurement.sketch
    .getVertices()
    .map(vertex => vertex.applyMatrix4(worldTransform));
  const isClosed = targetMeasurement.sketch.getType() === 'polygon';

  return (
    <>
      <div
        className={cn(
          'flex',
          'justify-between',
          'items-end',
          'border-neutral-500',
          'border-1',
          'border-b-solid',
          'pb-3',
          'flex-none',
        )}
      >
        <p className={cn('typo-heading-4 color-neutral-800')}>Measurement</p>
      </div>

      <div className="flex-1 overflow-auto">
        <div className="mt-3">
          <ColorSelect
            onChange={handleColor}
            style={{ display: 'block' }}
            value={`#${colorHex.toUpperCase()}`}
            width="full"
          />
        </div>
        <AnnotationMetadata isClosed={isClosed} points={points} />
      </div>

      <div
        className={cn(
          'flex',
          'flex-row',
          'justify-stretch',
          'gap-2',
          'sticky',
          'bottom-0',
          'flex-0',
          'pt-3',
        )}
      >
        <Button className={cn('flex-1')} onClick={handleClose} primary size="s">
          Close
        </Button>
        <Button className={cn('flex-1')} filled onClick={handleDelete} primary size="s">
          Delete
        </Button>
      </div>
    </>
  );
};
