import { useFetchSystemNodeChain } from '@/hooks/useFetchSystemNodeChain';
import { cn } from '@/utils/classname';
import { useMemo } from 'react';
import { SystemNode } from '@/stores/systemNodes';

export interface BreadcrumbProps {
  parentNodeId: string | null;
  onNavigate: (folderNodeId: string | null) => void;
}

export const Breadcrumbs = ({ parentNodeId, onNavigate }: BreadcrumbProps) => {
  const parentNodesChain = useFetchSystemNodeChain(parentNodeId as string);

  const folderNodes = useMemo(() => {
    if (!parentNodesChain || !parentNodeId) return [];
    return structuredClone(parentNodesChain).reverse() as SystemNode[];
  }, [parentNodeId, parentNodesChain]);

  return (
    <div>
      <nav className="flex">
        <ol className="inline-flex list-none items-center space-x-1">
          <li className="inline-flex items-center">
            <div
              className={cn(
                'color-neutral-400',
                parentNodeId
                  ? 'typo-text-s-em hover:cursor-pointer'
                  : 'no-underline hover:cursor-default typo-text-s',
              )}
              onClick={() => onNavigate(null)}
            >
              All folders
            </div>
          </li>
          {folderNodes?.length !== 0 &&
            folderNodes.map(folderNode => (
              <li key={folderNode.id}>
                <div className="flex items-center">
                  <span className="color-neutral-400 typo-text-s">&#47;</span>
                  <div
                    className={cn(
                      'ml-1 color-neutral-400',
                      parentNodeId !== folderNode.id
                        ? 'typo-text-s-em hover:cursor-pointer'
                        : 'no-underline hover:cursor-default typo-text-s',
                    )}
                    onClick={() => onNavigate(folderNode.id)}
                  >
                    {folderNode.name}
                  </div>
                </div>
              </li>
            ))}
        </ol>
      </nav>
    </div>
  );
};
