import { queryClient } from '@/graphql/client';
import { MOVE_PROJECTS_TO_GROUP } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchProjects } from '@/hooks/useFetchProjects';
import { setMoveProjectProject, useProjectStore } from '@/stores/project';
import { cn } from '@/utils/classname';
import { Project, ProjectGroup } from '@/utils/project';
import { search } from '@/utils/search';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, Input } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { dialog, island } from '../classes';

interface MoveProjectProps {
  groups: ProjectGroup[];
  projectIds: string[];
}

export const MoveProjectCore = (
  { groups, projectIds }: MoveProjectProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const closeDialogRef = useRef<HTMLButtonElement | null>(null);
  const project = useProjectStore(state => state.moveProject.project) as Project;

  const [groupId, setGroupId] = useState<null | string>(null);
  const [searchKey, setSearchKey] = useState('');

  const filtered = useMemo(
    () => groups.filter(group => search(group.name, searchKey)),
    [groups, searchKey],
  );

  const moveProject = useMutation({
    mutationFn: () =>
      request(MOVE_PROJECTS_TO_GROUP, {
        groupId: groupId as string,
        projectIds: projectIds,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProjects.getProjectGroupQueryKey());
      queryClient.invalidateQueries(useFetchProjects.getProjectQueryKey());
      setMoveProjectProject(null);
      setSearchKey('');
      closeDialogRef.current?.click();
    },
  });

  return (
    <Dialog.Content className={dialog} ref={ref} {...projectIds}>
      <Dialog.Title className="color-neutral-800 typo-text-l">
        Move project to new group
      </Dialog.Title>

      <p className="mt-6 flex-none color-neutral-800 typo-text-m">Available groups</p>

      <div className={cn(island, 'mt-3', 'flex-1')}>
        <div className="flex">
          <div className="flex-1">
            <Input onChange={setSearchKey} placeholder="Search for group name" value={searchKey} />
          </div>
        </div>

        <table className="mt-3 w-full border-collapse table-auto">
          <thead>
            <tr className="b-b-1 b-b-neutral-500 b-b-solid">
              <th className="pb-2 text-left uppercase color-neutral-800 typo-button-xs">name</th>
              <th className="pb-2 text-left uppercase color-neutral-800 typo-button-xs" colSpan={2}>
                date created
              </th>
            </tr>
          </thead>

          <tbody>
            {filtered.map(group => {
              const isCurrent = project && project.projectGroupId === group.id;
              const isSelected = group.id === groupId;
              const toggle = () =>
                setGroupId(prev => {
                  if (prev === group.id) return null;
                  return group.id;
                });

              return (
                <tr className="" key={group.id}>
                  <td className="pt-2 color-neutral-800 typo-text-s">{group.name}</td>
                  <td className="pt-2 color-neutral-800 typo-text-s">
                    {group.createdAt.toLocaleDateString()}
                  </td>
                  <td className="w-128px">
                    <Button
                      active={isSelected}
                      className="w-full hover:cursor-pointer"
                      disabled={isCurrent}
                      onClick={toggle}
                      primary
                      size="xs"
                    >
                      {isCurrent ? 'current group' : isSelected ? 'selected' : 'select'}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {searchKey.length > 0 && filtered.length === 0 && (
          <div className="mt-3 h-5 flex items-center justify-center">
            <p className="text-neutral-800 typo-text-xs">No matching record found</p>
          </div>
        )}
      </div>

      <div className="mt-auto flex gap-3 pt-3">
        <Dialog.Close asChild ref={closeDialogRef}>
          <Button
            className="flex-1 hover:cursor-pointer"
            onClick={() => {
              setMoveProjectProject(null);
              setSearchKey('');
            }}
            size="s"
          >
            Cancel
          </Button>
        </Dialog.Close>

        <Button
          className="flex-1 hover:cursor-pointer"
          disabled={groupId === null}
          filled
          onClick={() => moveProject.mutate()}
          primary
          size="s"
        >
          Move project
        </Button>
      </div>
    </Dialog.Content>
  );
};

export const MoveProject = forwardRef(MoveProjectCore);
