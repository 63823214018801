import { Slider } from '@skand/ui';
import { cn } from '@/utils/classname';

export interface InputSliderProps {
  title?: string;
  step?: number;
  value: number;
  setValue: (value: number) => void;
  min: number;
  max: number;
  className?: string;
}

export const InputSlider = ({
  title,
  setValue,
  step = 0.1,
  value,
  min,
  max,
  className,
}: InputSliderProps) => (
  <div className={cn('mt-2', className)}>
    <style>
      {`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type=number] {
          -moz-appearance: textfield;
        }
      `}
    </style>
    {title && (
      <div className={cn('flex flex-row mb-4px mt-10px')}>
        <p className={cn('typo-text-small text-neutral-800')}>{title}</p>
        <p className={cn('ml-1 typo-text-small text-neutral-400')}>{`(${min} - ${max})`}</p>
      </div>
    )}
    <div
      className={cn('flex gap-2 justify-between items-center')}
      onClick={e => e.stopPropagation()}
    >
      <Slider
        className="flex-1"
        max={max}
        min={min}
        onValueChange={e => {
          setValue(e[0]);
        }}
        step={step}
        value={[value]}
      />
      <input
        className={cn(
          'flex',
          'justify-center',
          'text-center',
          'border-solid',
          'border-neutral-400',
          'b-1px',
          'rounded-4px',
          'w-36px',
          'h-28px',
          'typo-text-small',
          'text-neutral-700',
        )}
        onChange={e => setValue(Math.max(min, Math.min(max, parseFloat(e.target.value))))}
        step={step}
        type="number"
        value={value}
      />
    </div>
  </div>
);
