import { IconButton, MoveIcon } from '@/components/IconButton';
import { cn } from '@/utils/classname';
import { Button, Draggable } from '@skand/ui';
import { SceneEntity } from '@/components/SceneEntityTree';

export interface SelectContextMenuProps {
  onMove: () => void;
  onDelete: () => void;
  onClear: () => void;
  visible: boolean;
  sceneEntities: SceneEntity[];
  disable: boolean;
}

export const SelectContextMenu = ({
  sceneEntities,
  visible,
  onMove,
  onDelete,
  onClear,
  disable,
}: SelectContextMenuProps) => {
  const disableOptions = disable || !sceneEntities.length;

  if (!visible) return null;

  return (
    <Draggable className="z-100">
      <div
        className={cn(
          'absolute',
          'z-[100] min-w-[281px] h-[44px]',
          'flex flex-row justify-center items-center',
          'border-1 border-neutral-300 border-solid rounded-2',
          'bg-neutral-100',
          'bottom-0 left-1/2 transform -translate-x-1/2',
          'gap-3',
          'px-3',
        )}
      >
        <p
          className={cn('typo-text-s', 'py-1', 'text-neutral-600 cursor-pointer whitespace-nowrap')}
        >
          {sceneEntities.length} annotations selected
        </p>

        <div className={cn('w-[1px]', 'bg-neutral-400', 'h-2/4')} />

        <IconButton
          buttonIcon={
            <div className={cn(!disableOptions ? 'text-neutral-700' : 'text-neutral-400', 'h-5')}>
              <MoveIcon />
            </div>
          }
          buttonState="default"
          className={cn(
            'h-20px',
            'w-5',
            'bg-neutral-100',
            'cursor-pointer',
            'border-none',
            'disabled:border-none',
          )}
          disabled={disableOptions}
          onClick={onMove}
        />

        <IconButton
          buttonIcon={
            <div
              className={cn(
                'i-skand-trashbin text-20px',
                !disableOptions ? 'text-neutral-700' : 'text-neutral-400',
              )}
            />
          }
          buttonState="default"
          className={cn(
            'h-20px',
            'w-5',
            'bg-neutral-100',
            'cursor-pointer',
            'border-none',
            'disabled:border-none',
          )}
          disabled={disableOptions}
          onClick={onDelete}
        />
        <Button className="w-[47px]" disabled={disableOptions} onClick={onClear} size="extraSmall">
          CLEAR
        </Button>
      </div>
    </Draggable>
  );
};
