import { LAYER_FORMAT_TYPE, LayerFormatType } from '@/constants/layer';
import { SceneEntity } from '../../SceneEntityTree';

export type LayerFilterKey =
  | LayerFormatType
  | SceneEntity['entity']['type']
  | 'sketch2D'
  | 'sketch3D'
  | 'process';

export type LayerFilterCategory =
  | 'Grouped layers'
  | 'Annotations'
  | 'Reality meshes'
  | 'GIS/CAD layers'
  | 'Images';

export const FilterOptions: Record<LayerFilterCategory, [LayerFilterKey, string][]> = {
  'Grouped layers': [
    ['annotationGroup', 'Annotation group'],
    ['layerGroup', 'Layer group'],
    ['photoGroup', 'Photo group'],
  ],
  Annotations: [
    ['sketch2D', '2D Annotation'],
    ['sketch3D', '3D Annotation'],
    ['process', 'Active Process'],
  ],
  'GIS/CAD layers': [
    [LAYER_FORMAT_TYPE.DXF, 'DXF'],
    [LAYER_FORMAT_TYPE.IFC, 'IFC'],
    [LAYER_FORMAT_TYPE.BIM_CAD_MODEL, 'KML'],
    [LAYER_FORMAT_TYPE.ORTHO_2D, 'Ortho'],
    [LAYER_FORMAT_TYPE.TERRAIN, 'Terrain'],
  ],
  'Reality meshes': [
    [LAYER_FORMAT_TYPE.MESH_3D, '3D mesh'],
    [LAYER_FORMAT_TYPE.OBJ, 'OBJ'],
    [LAYER_FORMAT_TYPE.POINT_CLOUD, 'Point cloud'],
  ],
  Images: [
    ['photo2D', '2D images'],
    ['panorama', 'Panoramas'],
  ],
};
