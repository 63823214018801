import { cn } from '@/utils/classname';
import { ActionButtons } from './ActionButtons';
import { DrawContextMenu } from '@/components/DrawContextMenu';
import { useEffect, useState } from 'react';
import { Vector2 } from 'three';
import { setViewer3DSettings, stopViewerScreenshot, useViewer } from '@/stores/viewer';
import { Minimap } from './Minimap';
import { InfoBar } from './InfoBar/InfoBar';
import { ScreenshotTool } from './ScreenshotTool';
import { MinimapIcon, IconButton } from '@/components/IconButton';
import { InputSlider } from '@/components/SceneTab/Menus/InputSlider';
import { StatisticsMenu } from './StatisticsMenu';
import { Draggable } from '@skand/ui';
import { StatisticsMonitor } from './StatisticsMonitor';

export const Overlay = () => {
  const api3D = useViewer(state => state.api3D);
  const enabledScreenshot = useViewer(state => state.enabledScreenshot);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const viewerSettings = useViewer(state => state.viewer3DSettings);
  const screenshotCallback = useViewer(state => state.screenshotCallback);

  const [contextMenuPosition, setContextMenuPosition] = useState<Vector2 | null>(null);
  const [removeVertex, setRemoveVertex] = useState<() => void>(() => {});
  const [enabledMinimap, setEnabledMinimap] = useState(false);
  const [minimapTooltip, setMinimapTooltip] = useState(false);

  // Register context menu handler
  useEffect(() => {
    api3D?.draw.setContextMenuHandler((position, removeVertex) => {
      setContextMenuPosition(position);
      setRemoveVertex(() => removeVertex);
    });
  }, [api3D]);

  // Handler for closing the minimap
  const handleDisableMinimap = () => {
    setEnabledMinimap(false);
    setMinimapTooltip(false);
  };

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'left-0',
        'top-0',
        'absolute',
        'z-2',
        'pointer-events-none',
      )}
    >
      {contextMenuPosition && api3D && (
        <DrawContextMenu
          canClose={api3D.draw.canClosePolygon()}
          closeOnClick={() => api3D.draw.closePolygon()}
          doneOnClick={() => api3D.draw.submit()}
          trashOnClick={removeVertex}
          undoOnClick={() => api3D.draw.undo()}
        />
      )}
      {enabledMinimap && <Minimap disableMinimap={handleDisableMinimap} />}
      <ActionButtons />
      <InfoBar />
      {!enabledMinimap && (
        <IconButton
          buttonIcon={<MinimapIcon />}
          buttonState="default"
          className={cn(
            'absolute',
            'right-0',
            'bottom-0',
            'pointer-events-auto',
            'rounded-tr-0',
            'rounded-bl-0',
            'rounded-br-0',
            minimapTooltip ? 'bg-neutral-400' : 'bg-transparent',
          )}
          onClick={() => setEnabledMinimap(true)}
          onMouseEnter={() => setMinimapTooltip(true)}
          onMouseLeave={() => setMinimapTooltip(false)}
        />
      )}
      {enabledScreenshot && (
        <ScreenshotTool onCancel={stopViewerScreenshot} onScreenshot={screenshotCallback} />
      )}
      {enabledPanoramaWalkthrough && (
        <div className="ml-14px flex flex-row gap-3">
          <span className="mt-3 typo-text-small-em">Opacity</span>
          <InputSlider
            className={cn('w-206px', 'pointer-events-auto')}
            max={1}
            min={0}
            setValue={value => setViewer3DSettings({ overlayOpacity: value })}
            step={0.01}
            value={viewerSettings.overlayOpacity}
          />
        </div>
      )}
      {viewerSettings.enabledStatistics && (
        <Draggable
          className={cn(
            'w-240px',
            'h-44',
            'p-x-2.5',
            'p-b-2.5',
            'b-rounded',
            'b-solid',
            'b-neutral-4',
            'bg-neutral-100',
            'pointer-events-auto',
          )}
        >
          <StatisticsMenu />

          <StatisticsMonitor />
        </Draggable>
      )}
    </div>
  );
};
