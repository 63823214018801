export const RENDERER_TYPE = {
  TILESET_3D: 'TILESET_3D',
  KML: 'KML',
  IMAGERY: 'IMAGERY',
  OBJ: 'OBJ',
  ORTHO_2D: 'ORTHO_2D',
  PANORAMIC_IMAGE: 'PANORAMIC_IMAGE',
  IMAGE_PROJECTION: 'IMAGE_PROJECTION',
  TERRAIN: 'TERRAIN',
  IFC: 'IFC',
  DXF: 'DXF',
  ANNOTATION: 'ANNOTATION',
};
export type RendererType = keyof typeof RENDERER_TYPE;
