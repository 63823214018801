/**
 * Download a blob with a listener for the percent progress.
 *
 * @param url
 * @param onProgress
 * @param abortController
 * @returns
 */
export const downloadBlob = (
  url: string,
  onProgress: (percent: number) => void,
  abortController?: AbortController,
) => {
  return new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let notifiedNotComputable = false;
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';

    xhr.onprogress = function (ev) {
      if (ev.lengthComputable) {
        onProgress(ev.loaded / ev.total);
      } else {
        if (!notifiedNotComputable) {
          notifiedNotComputable = true;
          onProgress(0);
        }
      }
    };
    xhr.onloadend = function () {
      if (!xhr.status.toString().match(/^2/)) {
        reject(new Error(`Download ${url} failed with status ${xhr.status} (${xhr.statusText})`));
      } else {
        if (!notifiedNotComputable) {
          onProgress(1);
        }
        resolve(new Blob([this.response]));
      }
    };

    xhr.onabort = ev => {
      reject(ev.type);
      notifiedNotComputable = true;
      onProgress(0);
    };

    xhr.send();

    if (abortController) {
      abortController.signal.addEventListener('abort', () => {
        xhr.abort();
      });
    }
  });
};

export const downloadUrl = (url: string, fileName?: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName ?? '';
  anchor.click();
};
