import { cn } from '@/utils/classname';
import { useCallback, useEffect, useState } from 'react';

export interface DualRangeSliderProps {
  max?: number;
  maxVal?: number;
  min?: number;
  minVal?: number;
  onChange?: (min: number, max: number) => void;
  step?: number;
}

export const DualRangeSlider = ({
  max = 9,
  maxVal = 9,
  min = 0,
  minVal = 0,
  onChange = () => {},
  step = 0.1,
}: DualRangeSliderProps) => {
  const [range, setRange] = useState<HTMLElement | null>(null);

  // Callback to compute percentages
  const computePercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  // Update the range bar
  useEffect(() => {
    if (range) {
      const minPercent = computePercent(minVal);
      const maxPercent = computePercent(maxVal);

      range.style.left = `${minPercent}%`;
      range.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, minVal, range, onChange, computePercent]);

  return (
    <div>
      <input
        className={cn(
          'z-3',
          'pointer-events-none',
          'h-0',
          'w-full',
          'outline-none',
          'absolute',
          'appearance-none',
          'bg-neutral-100',
          'border-1',
          'disabled:cursor-not-allowed',
          '[&::-webkit-slider-thumb]:appearance-none',
          '[&::-webkit-slider-thumb]:pointer-events-auto',
          '[&::-webkit-slider-thumb]:cursor-pointer',
          '[&::-webkit-slider-thumb]:h-13px',
          '[&::-webkit-slider-thumb]:w-13px',
          '[&::-webkit-slider-thumb]:mt-4px',
          '[&::-webkit-slider-thumb]:relative',
          '[&::-webkit-slider-thumb]:rounded-full',
          '[&::-webkit-slider-thumb]:border-1',
          '[&::-webkit-slider-thumb]:border-neutral-500',
          '[&::-webkit-slider-thumb]:border-solid',
          '[&::-webkit-slider-thumb]:bg-neutral-100',
        )}
        max={max}
        min={min}
        onChange={e => onChange(parseFloat(e.target.value), maxVal)}
        step={step}
        style={{ zIndex: minVal > max - 100 ? '5' : '3' }}
        type="range"
        value={minVal}
      />
      <input
        className={cn(
          'z-4',
          'pointer-events-none',
          'h-0',
          'w-full',
          'outline-none',
          'absolute',
          'appearance-none',
          'bg-neutral-100',
          'border-1',
          '[&::-webkit-slider-thumb]:appearance-none',
          '[&::-webkit-slider-thumb]:pointer-events-auto',
          '[&::-webkit-slider-thumb]:cursor-pointer',
          '[&::-webkit-slider-thumb]:h-13px',
          '[&::-webkit-slider-thumb]:w-13px',
          '[&::-webkit-slider-thumb]:mt-4px',
          '[&::-webkit-slider-thumb]:relative',
          '[&::-webkit-slider-thumb]:rounded-full',
          '[&::-webkit-slider-thumb]:border-1',
          '[&::-webkit-slider-thumb]:border-neutral-500',
          '[&::-webkit-slider-thumb]:border-solid',
          '[&::-webkit-slider-thumb]:bg-neutral-100',
        )}
        max={max}
        min={min}
        onChange={e => onChange(minVal, parseFloat(e.target.value))}
        step={step}
        type="range"
        value={maxVal}
      />

      <div className={cn('relative w-full')}>
        <div
          className={cn(
            'absolute b-rounded-3px h-5px bg-transparent border-1 border-neutral-400 border-solid w-full z-1 ',
          )}
        />
        <div
          className={cn('absolute b-rounded-3px h-5px bg-primary-300 w-full z-2 ')}
          ref={setRange}
        />
      </div>
    </div>
  );
};
