export const isSolid = <T, K extends keyof T>(object: T, ...keys: K[]): object is Solid<T, K> => {
  if (!object) return false;

  for (const key of keys) {
    if (object[key] === null) return false;
    if (object[key] === undefined) return false;
  }

  return true;
};

export const hasId = <T extends null | undefined | { id?: unknown }>(
  object: T,
): object is SolidId<T> => {
  if (!object) return false;
  return isSolid(object, 'id');
};
