import { Draw2D, PolygonDrawable } from '@skand/viewer-component-v2';
import { Color, Vector2 } from 'three';
import { Transform2 } from '../../Transform2';
import { Sketch2 } from './Sketch2';

/**
 * 2D polygon sketch object.
 */
export class PolygonSketch2 implements Sketch2 {
  private draw2D: Draw2D;
  private vertices: Vector2[];
  private fillShape: PolygonDrawable;
  private outline: PolygonDrawable;
  private visible: boolean;
  private destroyHandler: (sketch: Sketch2) => void;

  constructor(
    draw2D: Draw2D,
    vertices: Vector2[],
    color: Color,
    destroyHandler: (sketch: Sketch2) => void,
  ) {
    this.draw2D = draw2D;
    this.vertices = vertices;
    this.fillShape = {
      type: 'polygon',
      vertices,
      color,
      opacity: 0.5,
      fill: true,
    };
    this.outline = {
      type: 'polygon',
      vertices,
      color,
      opacity: 1.0,
      fill: false,
      border: 3,
    };
    this.visible = false;
    this.destroyHandler = destroyHandler;
  }

  show() {
    if (!this.visible) {
      this.visible = true;
      this.draw2D.addDrawable(this.fillShape);
      this.draw2D.addDrawable(this.outline);
    }
  }

  hide() {
    if (this.visible) {
      this.visible = false;
      this.draw2D.removeDrawable(this.fillShape);
      this.draw2D.removeDrawable(this.outline);
    }
  }

  destroy() {
    this.destroyHandler(this);
  }

  isVisible() {
    return this.visible;
  }

  getVertices() {
    return this.vertices.map(vertex => vertex.clone());
  }

  applyTransform(transform: Transform2) {
    this.fillShape.vertices = this.vertices.map(vertex => transform.imageToCanvasSpace(vertex));
    this.outline.vertices = this.vertices.map(vertex => transform.imageToCanvasSpace(vertex));
  }

  /**
   * Test if a point is colliding with the polygon.
   *
   * Algorithm: Cast a ray from the point and count the number of times it intersects
   * with the edge of the polygon. The point is in the polygon if this number is even.
   *
   * Let
   *  P' be the intersection point
   *  P be the clicked point
   *  D be the direction of the ray from P
   *  S, T be the endpoints of a polygon edge
   *  k, v be parametric equation scalars
   *
   * Ray:     P' = P + k * D
   * Segment: P' = S + v * (T - S)
   *
   * They intersect iff (P + k * D) = (S + v * (T - S))
   *
   * @param point
   * @returns
   */
  isColliding(point: Vector2) {
    // Use transformed vertices from the actual drawable
    const vertices = this.fillShape.vertices;
    let count = 0;
    for (let i = 0; i < vertices.length; i++) {
      const s = vertices[i];
      const t = vertices[(i + 1) % vertices.length];

      const v1 = point.clone().sub(s);
      const v2 = t.clone().sub(s);
      const v3 = new Vector2(0, 1);

      const cross21 = v2.cross(v1);
      const dot13 = v1.dot(v3);
      const dot23 = v2.dot(v3);
      const k = cross21 / dot23;
      const v = dot13 / dot23;
      if (k >= 0 && v >= 0 && v <= 1) {
        count++;
      }
    }
    return count % 2 !== 0;
  }

  setColor(color: Color): void {
    this.fillShape.color = color;
    this.outline.color = color;
  }
}
