import { LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useShareLink } from '@/stores/shareLink';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  PolicySubjectTypeInput,
  PolicyObjectTypeInput,
  PolicyActionTypeInput,
} from '@/graphql/codegen/graphql';
import { isEmpty } from '@/utils/empty';

const getQueryKey = (shareLinkId: string | null) => [
  'LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT',
  shareLinkId,
  PolicySubjectTypeInput.ShareLink,
  PolicyObjectTypeInput.SceneEntity,
  PolicyActionTypeInput.Read,
  null,
];

export const useFetchShareLinkPermissions = () => {
  const shareLinkId = useShareLink(state => state.selectedShareLink?.id);

  const response = useQuery({
    enabled: !!shareLinkId,
    queryFn: () =>
      request(LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT, {
        subjectId: shareLinkId as string,
        subjectType: PolicySubjectTypeInput.ShareLink,
        objectType: PolicyObjectTypeInput.SceneEntity,
        actionType: PolicyActionTypeInput.Read,
        objectId: null,
      }),
    queryKey: getQueryKey(shareLinkId as string),
  });

  const permissionObjectIDs = useMemo(() => {
    const permissionObjectIDs: string[] = [];
    if (
      !isEmpty(response.data) &&
      !isEmpty(response.data.listPermissionPoliciesWithAccountContext)
    ) {
      for (const permission of response.data.listPermissionPoliciesWithAccountContext) {
        if (permission && !isEmpty(permission.objectId)) {
          permissionObjectIDs.push(permission.objectId);
        }
      }
    }
    return permissionObjectIDs;
  }, [response.data]);

  return permissionObjectIDs;
};

useFetchShareLinkPermissions.getQueryKey = getQueryKey;
