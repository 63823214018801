export const PlaceIcon = () => {
  return (
    <svg fill="none" height="14" viewBox="0 0 11 14" width="11" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M6.54238 4.55476C6.54238 5.13436 6.07198 5.60476 5.49238 5.60476C4.91278 5.60476 4.44238 5.13436 4.44238 4.55476C4.44238 3.97446 4.91278 3.50476 5.49238 3.50476C6.07198 3.50476 6.54238 3.97446 6.54238 4.55476Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.49235 13.0112C5.49235 13.0112 1.18945 7.27956 1.18945 4.85476C1.18945 2.47056 3.13685 0.589661 5.49235 0.589661C7.84785 0.589661 9.79525 2.47056 9.79525 4.85476C9.79525 7.27956 5.49235 13.0112 5.49235 13.0112Z"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.1795"
      />
    </svg>
  );
};
