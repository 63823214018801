import { Draw2D, LineDrawable } from '@skand/viewer-component-v2';
import { Color, Vector2 } from 'three';
import { Transform2 } from '../../Transform2';
import { Sketch2 } from './Sketch2';

/**
 * 2D line sketch object.
 */
export class LineSketch2 implements Sketch2 {
  private draw2D: Draw2D;
  private vertices: Vector2[];
  private lines: LineDrawable[];
  private visible: boolean;
  private destroyHandler: (sketch: Sketch2) => void;

  constructor(
    draw2D: Draw2D,
    vertices: Vector2[],
    color: Color,
    destroyHandler: (sketch: Sketch2) => void,
  ) {
    this.draw2D = draw2D;
    this.vertices = vertices;
    this.lines = [];
    for (let i = 0; i < vertices.length - 1; i++) {
      this.lines.push({
        type: 'line',
        start: vertices[i],
        end: vertices[i + 1],
        color,
        opacity: 1.0,
        width: 3,
      });
    }
    this.visible = false;
    this.destroyHandler = destroyHandler;
  }

  show() {
    if (!this.visible) {
      this.visible = true;
      for (const line of this.lines) {
        this.draw2D.addDrawable(line);
      }
    }
  }

  hide() {
    if (this.visible) {
      this.visible = false;
      for (const line of this.lines) {
        this.draw2D.removeDrawable(line);
      }
    }
  }

  destroy() {
    this.destroyHandler(this);
  }

  isVisible() {
    return this.visible;
  }

  getVertices() {
    return this.vertices.map(vertex => vertex.clone());
  }

  applyTransform(transform: Transform2) {
    for (let i = 0; i < this.vertices.length - 1; i++) {
      const line = this.lines[i];
      line.start = transform.imageToCanvasSpace(this.vertices[i]);
      line.end = transform.imageToCanvasSpace(this.vertices[i + 1]);
    }
  }

  /**
   * Test if a point is colliding with any of the lines.
   *
   * Algorithm: For each segment, project the clicked point onto it. If the distance
   * between the projected and clicked points is less than some threshold T, and the
   * projected point lies within the segment, then we have a collision.
   *
   * @param point
   * @returns
   */
  isColliding(point: Vector2) {
    const T = 6;
    for (const line of this.lines) {
      const { start, end } = line;
      const A = point.clone().sub(start);
      const B = end.clone().sub(start);

      // Calculate the projection point
      const dotAB = A.dot(B);
      const dotBB = B.dot(B);
      const K = dotAB / dotBB;
      const proj = B.multiplyScalar(K).add(start);

      // Test if the projection point is between start and end
      const projStart = proj.clone().sub(start);
      const projEnd = end.clone().sub(proj);
      if (projStart.length() + projEnd.length() !== Math.sqrt(dotBB)) {
        continue;
      }

      // Test the distance between the clicked and projected points
      const D = proj.clone().sub(point);
      if (D.lengthSq() <= T * T) {
        return true;
      }
    }
    return false;
  }

  setColor(color: Color): void {
    for (const line of this.lines) {
      line.color = color;
    }
  }
}
