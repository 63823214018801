export const ACCESS_TOKEN_NAME = import.meta.env.PROD
  ? process.env.REACT_APP_ACCESS_TOKEN_NAME
  : import.meta.env.REACT_APP_ACCESS_TOKEN_NAME;

export const GRAPHQL_URL = import.meta.env.PROD
  ? process.env.REACT_APP_PLATFORM_GRAPHQL_URL
  : import.meta.env.REACT_APP_PLATFORM_GRAPHQL_URL;

export const USER_EMAIL_NAME = import.meta.env.PROD
  ? process.env.REACT_APP_USER_EMAIL_NAME
  : import.meta.env.REACT_APP_USER_EMAIL_NAME;

export const SPLIT_API_KEY = import.meta.env.PROD
  ? process.env.REACT_APP_SPLIT_API_KEY
  : import.meta.env.REACT_APP_SPLIT_API_KEY;

export const CESIUM_API_KEY = import.meta.env.PROD
  ? process.env.REACT_APP_CESIUM_API_KEY
  : import.meta.env.REACT_APP_CESIUM_API_KEY;

export const GOOGLE_MAP_API_KEY = import.meta.env.PROD
  ? process.env.REACT_APP_GOOGLE_MAP_API_KEY
  : import.meta.env.REACT_APP_GOOGLE_MAP_API_KEY;
