import { ANNOTATION_FIELD_TYPE } from '@/constants/annotation';
import { AnnotationTemplate, AnnotationTemplateFieldInput } from '@/graphql/codegen/graphql';
import { editAnnotationSetField, useExplore } from '@/stores/explore';
import { cn } from '@/utils/classname';
import { Input } from '@skand/ui';
import { useCallback } from 'react';

export interface AnnotationFieldsProps {
  template: AnnotationTemplate;
}

export const FieldInput = ({
  field,
  disabled = false,
}: {
  field: AnnotationTemplateFieldInput;
  disabled: boolean;
}) => {
  const text = useExplore(state => {
    const current = state.annotationDraft?.fields?.find(item => item && item.fieldId === field.id);
    return current?.text;
  });
  const handleChange = useCallback(
    (value: string) => {
      editAnnotationSetField(field, ANNOTATION_FIELD_TYPE.TEXT, { text: value });
    },
    [field],
  );

  return (
    <div className="mt-3" key={field.name}>
      <Input
        disabled={disabled}
        expandable
        label={field.name || ''}
        onChange={handleChange}
        tail={<div className={cn('i-skand-textfield', 'color-neutral-400', 'text-3', 'ml-1')} />}
        value={text ?? undefined}
      />
    </div>
  );
};
