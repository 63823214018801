import { EmptyResourceMessage } from '@/components/EmptyResourceMessage';
import { DATASET_ROOT } from '@/constants/paths';
import { useExplore } from '@/stores/explore';
import { Photo, setImageViewerTab, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Dropdown, DropdownItem, EmptyDesc } from '@skand/ui';
import { useRef } from 'react';
import { Controls } from './Controls';
import { RibbonList } from './RibbonList';

export const ImageViewerControls = () => {
  const filteredPhoto2Ds = useViewer(state => state.filteredPhoto2Ds);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const filteredPanoramas = useViewer(state => state.filteredPanoramas);
  const imageViewerTab = useViewer(state => state.imageViewerTab);
  const filteredPhotos = imageViewerTab === 'photo2D' ? filteredPhoto2Ds : filteredPanoramas;
  const dropdownRef = useRef<HTMLLabelElement>(null);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const projectId = useExplore(state => state.projectId);

  const getImagesCount = () => {
    let count = 0;

    if (imageViewerTab === 'photo2D') {
      for (const item of photo2DGroups) {
        count = count + item.photos.length;
      }
    } else {
      for (const item of panoramaGroups) {
        count = count + item.photos.length;
      }
    }

    return count;
  };

  const close = () => {
    dropdownRef.current?.focus();
    dropdownRef.current?.blur();
  };

  const handleDropdown = (item: Photo['type']) => {
    setImageViewerTab(item);
    close();
  };

  const ImagesAvailableHint = () => (
    <EmptyResourceMessage
      emptyResourceContent={{
        emptyTitle: 'No images in this layer group',
        emptyDescNode: (
          <div>
            <EmptyDesc asChild>
              {
                <span className="!typo-text-m">
                  There are {getImagesCount()} images available in this project.{' '}
                </span>
              }
            </EmptyDesc>
            <EmptyDesc asChild>
              <div className={cn('flex', 'flex-row', 'items-center', 'justify-center', 'mt-12px')}>
                <div className={cn('i-skand-show', 'cursor-pointer', 'text-16px', 'mr-8px')} />
                <span>Make sure the correct layer group is turned on.</span>
              </div>
            </EmptyDesc>
          </div>
        ),
      }}
    />
  );

  const renderRibbonListHint = () => {
    if (imageViewerTab === 'photo2D' && photo2DGroups.length !== 0) return <ImagesAvailableHint />;
    else if (imageViewerTab === 'panorama' && panoramaGroups.length !== 0)
      return <ImagesAvailableHint />;
    else
      return (
        <EmptyResourceMessage
          emptyResourceContent={{
            actionButton: () => {
              location.href = `${DATASET_ROOT}/projects/${projectId}`;
            },
            emptyDesc: 'Import images via the Project page',
            emptyTitle: 'No images available',
            buttonLabel: 'Go to Project page',
          }}
        />
      );
  };

  return (
    <div className="h-full min-w-400px flex flex-col bg-neutral-100 pl-18px pr-18px">
      <div className="mt-30px flex justify-between border-1 border-gray border-b-solid pb-8px">
        <p className={cn('typo-heading-4 color-neutral-800')}>Image viewer</p>
        <div className="w-121px">
          <Dropdown
            className="w-120px"
            disabled={enabledPanoramaWalkthrough}
            label={imageViewerTab === 'photo2D' ? '2D Images' : 'Panoramas'}
            ref={dropdownRef}
            width="full"
          >
            <DropdownItem onClick={() => handleDropdown('photo2D')}>2D images</DropdownItem>
            <DropdownItem onClick={() => handleDropdown('panorama')}>Panoramas</DropdownItem>
          </Dropdown>
        </div>
      </div>
      <Controls currentTab={imageViewerTab} />
      {!filteredPhotos.length ? renderRibbonListHint() : <RibbonList photos={filteredPhotos} />}
    </div>
  );
};
