import { ANNOTATION_FIELD_TYPE } from '@/constants/annotation';
import { AnnotationFileField, AnnotationTemplateFileField } from '@/graphql/codegen/graphql';
import { editAnnotationUpdateField } from '@/stores/explore';
import { getShareLinkToken } from '@/utils/shareLink';
import { useSimpleUploader } from '@skand/uploader';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { ProgressBar } from '../ProgressBar';

export const UploadLegacy = ({ field }: { field: AnnotationTemplateFileField }) => {
  const isReadOnly = getShareLinkToken() !== null;

  const { addFiles, upload, off, on } = useSimpleUploader({
    isDisabled: isReadOnly,
  });

  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const files = [...(e.target.files ?? [])];
      addFiles(files);
      upload();
    },
    [addFiles, upload],
  );

  useEffect(() => {
    const onAddFiles = () => {
      setIsUploading(true);
    };

    const onProgress = ({ progress }: { progress: number }) => {
      setProgress(progress);
    };

    const onCreateSuccess = ({ file, fileId }: { file: File; fileId: string }) => {
      editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.FILE, prev => {
        const oldFiles = (prev as AnnotationFileField).files ?? [];
        return { ...prev, files: [...oldFiles, { fileId, name: file.name }] };
      });

      setIsUploading(false);
    };

    on('addFiles', onAddFiles);
    on('createSuccess', onCreateSuccess);
    on('progress', onProgress);
    return () => {
      off('addFiles', onAddFiles);
      off('createSuccess', onCreateSuccess);
      off('progress', onProgress);
    };
  }, [field, off, on]);

  if (isUploading) {
    return (
      <div className="h-4 py-1 pr-2">
        <ProgressBar progress={progress / 100} />
      </div>
    );
  }

  return (
    <label className="cursor-pointer color-neutral-600 underline typo-link-s">
      Choose attachment
      <input className="hidden" multiple onChange={handleChange} type="file" />
    </label>
  );
};
