import { LIST_SHARE_LINKS_BY_PROJECT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useExplore } from '@/stores/explore';
import { useQuery } from '@tanstack/react-query';
import { createShareLink } from '@/utils/transformers';
import { useMemo } from 'react';
import { ShareLink } from '@/stores/shareLink';
import { ShareLink as ShareLinkQuery } from '@/graphql/codegen/graphql';
import { isEmpty } from '@/utils/empty';

const getQueryKey = (projectId: string | null) => ['LIST_SHARE_LINKS_BY_PROJECT_ID', projectId];

export const useFetchShareLinks = () => {
  const projectId = useExplore(state => state.projectId);

  const response = useQuery({
    enabled: !!projectId,
    queryFn: () => request(LIST_SHARE_LINKS_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: getQueryKey(projectId as string),
  });

  const shareLinks: ShareLink[] = useMemo(() => {
    const shareLinks: ShareLink[] = [];

    if (!isEmpty(response.data) && !isEmpty(response.data.listShareLinksByProjectId)) {
      for (const shareLinkQuery of response.data.listShareLinksByProjectId) {
        const shareLink = createShareLink(shareLinkQuery as ShareLinkQuery);
        if (shareLink) {
          shareLinks.push(shareLink);
        }
      }
    }
    return shareLinks;
  }, [response.data]);

  return { response, shareLinks };
};

useFetchShareLinks.getQueryKey = getQueryKey;
