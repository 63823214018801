import { initializeExplore } from '@/stores/explore';
import { useEffect } from 'react';
import { ProjectExploreScene } from './ProjectExploreScene';

export const ExplorePage = () => {
  useEffect(() => {
    initializeExplore();
  }, []);

  return <ProjectExploreScene />;
};
