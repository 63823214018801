import { cn } from '@/utils/classname';
import { useState } from 'react';
export interface RadioButtonProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  id: string;
  checked: boolean;
  disabled?: boolean;
  className?: string;
}

export const RadioButton = ({
  onChange,
  id,
  checked,
  label,
  value,
  disabled,
  className,
}: RadioButtonProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      className={cn(
        className
          ? className
          : cn(className, 'flex', 'flex-row', 'items-center', 'mb-12px', 'cursor-default'),
      )}
      onClick={() => !disabled && onChange(value)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <input
        checked={checked}
        className={cn('accent-neutral-600')}
        disabled={disabled}
        id={id}
        readOnly
        type="radio"
        value={value}
      />
      <span
        className={cn(
          'typo-text-small',
          'ml-12px',
          disabled ? 'text-neutral' : isHovered ? 'text-primary-400' : 'text-neutral-800',
        )}
      >
        {label}
      </span>
    </div>
  );
};
