import { useEffect, RefObject, useState } from 'react';
import { isEmpty } from './empty';

export const useOnClickOutside = (ref: RefObject<HTMLInputElement> | null) => {
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!isEmpty(ref)) {
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
          setClicked(true);
        } else {
          setClicked(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);
  return clicked;
};
