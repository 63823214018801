import { useFetchSystemNodeChain } from '@/hooks/useFetchSystemNodeChain';
import { Spinner } from './Spinner';

export interface BreadcrumbProps {
  disabled?: boolean;
  onChangeParentNodeId: (parentNodeId: null | string) => void;
  parentNodeId: null | string;
  before?: React.ReactNode;
  isFetching?: boolean;
}

export const Breadcrumb = ({
  before,
  disabled,
  onChangeParentNodeId,
  parentNodeId,
  isFetching,
}: BreadcrumbProps) => {
  const chain = useFetchSystemNodeChain(parentNodeId ?? undefined);
  const reversed = parentNodeId ? (chain ? [...chain].reverse() : []) : [];

  return (
    <div className="mt-3 flex items-center">
      {before}

      <span
        className="cursor-pointer color-neutral-400 underline typo-link-s"
        onClick={() => !disabled && onChangeParentNodeId(null)}
      >
        Root folder
      </span>

      {reversed?.map(item => {
        return (
          <span
            className="cursor-pointer color-neutral-400 typo-text-s"
            key={item?.id}
            onClick={() => onChangeParentNodeId(item?.id as string)}
          >
            &nbsp;/&nbsp;
            <span className="hover:underline">{item?.name}</span>
          </span>
        );
      })}

      {isFetching && <Spinner className="ml-1" />}
    </div>
  );
};
