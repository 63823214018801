import { ACCOUNT_BY_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { getShareLinkToken } from '@/utils/shareLink';

const queryKey = ['ACCOUNT_BY_CONTEXT'];

export const useAccountId = () => {
  const isReadOnly = getShareLinkToken() !== null;

  const response = useQuery({
    queryFn: () => request(ACCOUNT_BY_CONTEXT),
    enabled: !isReadOnly,
    queryKey,
  });

  return response.data?.accountByContext?.id;
};

useAccountId.queryKey = queryKey;
