import { ANNOTATION_FIELD_TYPE } from '@/constants/annotation';
import { AnnotationTemplateImageField } from '@/graphql/codegen/graphql';
import { useAccountId } from '@/hooks/useAccountId';
import { editAnnotationUpdateField } from '@/stores/explore';
import { startViewerScreenshot, stopViewerScreenshot, useViewer } from '@/stores/viewer';
import logger from '@/utils/logger';
import { Progress } from '@skand/ui';
import { useCreateNewSimpleUploadSession, useSimpleUploadSession } from '@skand/uploader';
import { ChangeEventHandler } from 'react';
import { simpleUploadSessionRequestService, toastError, uploaderFactory } from '../upload';

interface UploadProps {
  field: AnnotationTemplateImageField;
  disabled?: boolean;
}

export const Upload = ({ field, disabled = false }: UploadProps) => {
  const enabledScreenshot = useViewer(state => state.enabledScreenshot);
  const accountId = useAccountId();
  const { simpleUploadSession, createNewSimpleUploadSession } = useCreateNewSimpleUploadSession(
    uploaderFactory,
    simpleUploadSessionRequestService,
  );
  const { uploadProgress, stage } = useSimpleUploadSession(simpleUploadSession);

  const isUploading = stage !== 'initial';
  const progress =
    uploadProgress.bytesTotal === 0 ? 0 : uploadProgress.bytesUploaded / uploadProgress.bytesTotal;

  const uploadFilesAndUpdateFileId = async () => {
    try {
      await simpleUploadSession.start(accountId as string);
    } catch (e) {
      logger.error(e);
      toastError();
    }

    // retrieve file id from first file
    const fileNodes = simpleUploadSession.fileTree.mapFileNodes(fileNode => fileNode);
    const fileNode = fileNodes[0];
    if (!fileNode) return;

    editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.IMAGE, prev => {
      return { ...prev, fileId: fileNode.fileId };
    });

    createNewSimpleUploadSession();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = async e => {
    const files = [...(e.target.files ?? [])];
    e.target.value = '';

    simpleUploadSession.addFiles(files);
    uploadFilesAndUpdateFileId();
  };

  const handleScreenshot = () => {
    if (disabled) return;

    startViewerScreenshot(async (base64: string) => {
      stopViewerScreenshot();
      const resizedImageResponse = await fetch(base64);
      const blob = await resizedImageResponse.blob();
      const file = new File([blob], 'Screenshot', { type: blob.type });

      simpleUploadSession.addFiles([file]);
      await uploadFilesAndUpdateFileId();
    });
  };

  if (isUploading) {
    return (
      <div className="h-4 py-1 pr-2">
        <Progress progress={progress} />
      </div>
    );
  }

  return (
    <>
      <label className="cursor-pointer color-neutral-600 underline typo-link-s">
        Upload
        <input
          accept="image/*"
          className="hidden"
          disabled={enabledScreenshot || disabled}
          onChange={handleChange}
          type="file"
        />
      </label>{' '}
      <span className="color-neutral-600 typo-text-s">or</span>{' '}
      <span
        className="cursor-pointer color-neutral-600 underline typo-text-s"
        onClick={handleScreenshot}
      >
        Take from 3D Scene
      </span>
    </>
  );
};
