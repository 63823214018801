import { Annotation, useViewer, setTargetPhoto } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { RadioButton } from '@/components/RadioButton';

export interface AnnotationNodeProps {
  annotation: Annotation;
  setSelectedAnnotationId: (value: string) => void;
  selectedAnnotationId: string | null | undefined;
}

export const AnnotationNode = ({
  annotation,
  setSelectedAnnotationId,
  selectedAnnotationId,
}: AnnotationNodeProps) => {
  const api3D = useViewer(state => state.api3D);
  const api2D = useViewer(state => state.api2D);

  const handleAnnotation = (el: Annotation) => {
    if (api3D && el.sketch3D) {
      api3D.navigation.lookAt(el.sketch3D);
    }
    if (api2D && el.sketch2D && el.photo) {
      setTargetPhoto(el.photo);
      api2D.editor.lookAt(el.sketch2D);
    }
    setSelectedAnnotationId(annotation.id);
  };

  return (
    <div
      className={cn('flex justify-between items-center py-1')}
      onClick={() => handleAnnotation(annotation)}
    >
      <div className={cn('flex items-center gap-2 text-neutral-600 justify-center')}>
        <RadioButton
          checked={annotation.id === selectedAnnotationId}
          className={cn('flex', 'flex-row', 'items-center', 'cursor-default', 'mr-[-12px]')}
          id="default"
          onChange={() => {}}
          value={annotation.id}
        />

        <div className={cn('i-skand-annotation2doutlined')} />
        <div className={cn('i-skand-annotation3doutlined')} />
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap cursor-pointer')}>
          {annotation.name}
        </p>
      </div>
    </div>
  );
};
