import { cn } from '@/utils/classname';
import { Input } from '@skand/ui';

export interface PositionControlProps {
  longitude: number;
  latitude: number;
  height: number;
  setCartographicPosition: (longitude: number, latitude: number, height: number) => void;
  disabled?: boolean;
}

export const PositionControl = ({
  longitude,
  latitude,
  height,
  setCartographicPosition,
  disabled = false,
}: PositionControlProps) => {
  return (
    <div>
      <div className={cn('mt-4')}>
        <Input
          disabled={disabled}
          label="Latitude (-90 - 90)"
          onChange={value => setCartographicPosition(longitude, parseFloat(value), height)}
          step={0.00001}
          type="number"
          value={latitude}
        />
      </div>
      <div className={cn('mt-4')}>
        <Input
          disabled={disabled}
          label="Longitude (-180 - 180)"
          onChange={value => setCartographicPosition(parseFloat(value), latitude, height)}
          step={0.00001}
          type="number"
          value={longitude}
        />
      </div>
      <div className={cn('mt-4')}>
        <Input
          disabled={disabled}
          label="Height (m)"
          onChange={value => setCartographicPosition(longitude, latitude, parseFloat(value))}
          type="number"
          value={height}
        />
      </div>
    </div>
  );
};
