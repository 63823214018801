import { ExploreState, Store } from './Store';
import { isEmpty } from '../empty';
import { Color, Quaternion, Vector3 } from 'three';

/**
 * Fields in the URL that will be serialized.
 */
interface URLFields {
  project: ExploreState['project'];
  shareLinkToken: ExploreState['shareLinkToken'];
  layers: ExploreState['layers'];
  annotation: ExploreState['annotation'];
  globe: ExploreState['globe'];
  imageryBase: ExploreState['imageryBase'];
  navigation: ExploreState['navigation'];
  projection: ExploreState['projection'];
  showLabels: ExploreState['showLabels'];
  showMeasurements: ExploreState['showMeasurements'];
  backgroundColor: ExploreState['backgroundColor'];
  panoramaIconSize: ExploreState['panoramaIconSize'];
  cameraPosition: ExploreState['cameraPosition'];
  cameraRotation: ExploreState['cameraRotation'];
  orthoMatrix: ExploreState['orthoMatrix'];
  srs: ExploreState['srs'];
  image: ExploreState['image'];
  edl: ExploreState['edl'];
  globeClipping: ExploreState['globeClipping'];
  pointSizeAttenuation: ExploreState['pointSizeAttenuation'];
}

export class URLStore implements Store {
  private state: Partial<URLFields>;
  private canUpdate: boolean;

  constructor() {
    this.state = {};
    this.canUpdate = true;

    const params = new URLSearchParams(window.location.search.slice(1));
    for (const [key, value] of params) {
      const field = key as keyof URLFields;
      const object = this.deserialize(field, value);
      if (!isEmpty(object)) {
        this.state = {
          ...this.state,
          [field]: object,
        };
      }
    }
  }

  /**
   * Refresh the URL with the current state.
   */
  public refreshURL() {
    const params = new URLSearchParams(window.location.search.slice(1));
    const prevString = params.toString();

    // Remove any keys that are no longer in the state
    for (const key of params.keys()) {
      if (!(key in this.state)) {
        params.delete(key);
      }
    }

    // Set new fields
    for (const field of Object.keys(this.state)) {
      const value = this.serialize(field as keyof URLFields);
      if (!isEmpty(value)) {
        params.set(field, value);
      }
    }

    // Only replace state (throttled) if something has changed
    const currString = params.toString();
    if (prevString !== currString && this.canUpdate) {
      history.replaceState(null, '', `?${currString}`);
      this.canUpdate = false;
      setTimeout(() => {
        this.canUpdate = true;
      }, 310);
    }
  }

  private serialize(field: keyof URLFields): string | null {
    const value = this.state[field];
    if (isEmpty(value)) {
      return null;
    }
    switch (field) {
      case 'project':
        return value as string;
      case 'shareLinkToken':
        return value as string;
      case 'layers':
        return JSON.stringify(value);
      case 'annotation':
        return value as string;
      case 'globe':
        return value as string;
      case 'imageryBase':
        return value as string;
      case 'navigation':
        return value as string;
      case 'projection':
        return value as string;
      case 'showLabels':
        return JSON.stringify(value);
      case 'showMeasurements':
        return JSON.stringify(value);
      case 'backgroundColor':
        return (value as Color).getHexString();
      case 'panoramaIconSize':
        return `${value}`;
      case 'cameraPosition':
        return JSON.stringify((value as Vector3).toArray());
      case 'cameraRotation':
        return JSON.stringify((value as Quaternion).toArray());
      case 'orthoMatrix':
        return JSON.stringify(value);
      case 'srs':
        return `${value}`;
      case 'image':
        return value as string;
      case 'edl':
        return JSON.stringify(value);
      case 'pointSizeAttenuation':
        return JSON.stringify(value);
      case 'globeClipping':
        return JSON.stringify(value);
      default:
        return null;
    }
  }

  private deserialize<T extends keyof URLFields>(field: T, value: string): URLFields[T] | null {
    try {
      switch (field) {
        case 'project':
          return value as URLFields[T];
        case 'shareLinkToken':
          return value as URLFields[T];
        case 'layers':
          return JSON.parse(value) as URLFields[T];
        case 'annotation':
          return value as URLFields[T];
        case 'globe':
          return value as URLFields[T];
        case 'imageryBase':
          return value as URLFields[T];
        case 'navigation':
          return value as URLFields[T];
        case 'projection':
          return value as URLFields[T];
        case 'showLabels':
          return JSON.parse(value) as URLFields[T];
        case 'showMeasurements':
          return JSON.parse(value) as URLFields[T];
        case 'backgroundColor':
          return new Color(`#${value}`) as URLFields[T];
        case 'panoramaIconSize':
          return Number(value) as URLFields[T];
        case 'cameraPosition':
          return new Vector3().fromArray(JSON.parse(value)) as URLFields[T];
        case 'cameraRotation':
          return new Quaternion().fromArray(JSON.parse(value)) as URLFields[T];
        case 'orthoMatrix':
          return JSON.parse(value) as URLFields[T];
        case 'srs':
          return value as URLFields[T];
        case 'image':
          return value as URLFields[T];
        case 'edl':
          return JSON.parse(value) as URLFields[T];
        case 'pointSizeAttenuation':
          return JSON.parse(value) as URLFields[T];
        case 'globeClipping':
          return JSON.parse(value) as URLFields[T];
        default:
          return null;
      }
    } catch (e) {
      return null;
    }
  }

  get<T extends keyof ExploreState>(field: T): ExploreState[T] | null {
    const value = this.state[field as keyof URLFields];
    return (value ?? null) as ExploreState[T] | null;
  }

  set<T extends keyof ExploreState>(field: T, value: ExploreState[T]): void {
    this.state[field as keyof URLFields] = value as unknown as undefined;
  }

  clear<T extends keyof ExploreState>(field: T): void {
    delete this.state[field as keyof URLFields];
  }
}
