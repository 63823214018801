import { ANNOTATION_FIELD_TYPE } from '@/constants/annotation';
import { queryClient } from '@/graphql/client';
import {
  AnnotationFile,
  AnnotationFileField,
  AnnotationTemplate,
  AnnotationTemplateFileField,
} from '@/graphql/codegen/graphql';
import { request } from '@/graphql/request';
import {
  ConfirmationModalState,
  DeleteConfirmationModal,
} from '@/pages/ProjectPage/DeleteConfirmationModal';
import { editAnnotationUpdateField, useExplore } from '@/stores/explore';
import { cn } from '@/utils/classname';
import { downloadUrl } from '@/utils/download';
import { isEmpty } from '@/utils/empty';
import { getShareLinkToken } from '@/utils/shareLink';
import { Toast, VideoPreview, ImagePreview } from '@skand/ui';
import { QueryClientProvider, RequestContextProvider } from '@skand/uploader';
import { useCallback, useState } from 'react';
import { Upload } from './Upload';
import { useFetchFileUrls } from '@/hooks/useFetchFileUrls';
import { useTreatments } from '@splitsoftware/splitio-react';
import { ADVANCED_UPLOADER } from '@/utils/split';
import { UploadLegacy } from './UploadLegacy';

export interface FileInfoProps {
  fileId: string;
  fileName: string;
}

export interface MediaFileInfoProps {
  fileId: AnnotationFile['fileId'];
  extension: string;
  previewUrl: string;
  downloadUrl: string;
}

export interface AnnotationFieldsProps {
  template: AnnotationTemplate;
}

const getFileExtension = (url: string) => {
  return url.split(/[#?]/)[0].split('.').pop()?.trim() ?? '';
};

const isImageExtension = (ext: string) => ['JPG', 'PNG', 'JPEG'].includes(ext.toUpperCase());
const isVideoExtension = (ext: string) => ['MP4', 'MPEG-4', 'MOV'].includes(ext.toUpperCase());
const isPdfExtension = (ext: string) => ['PDF'].includes(ext.toUpperCase());

export const FieldFile = ({
  field,
  disabled = false,
}: {
  field: AnnotationTemplateFileField;
  disabled: boolean;
}) => {
  const [enabledImagePreview, setEnabledImagePreview] = useState(false);
  const [enabledVideoPreview, setEnabledVideoPreview] = useState(false);
  const [previewDisabledTooltip, setPreviewDisabledTooltip] = useState(false);

  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const [fileUrl, setFileUrl] = useState<string | undefined>();
  const [selectedFileInfo, setSelectedFileInfo] = useState<FileInfoProps>();

  const treatments = useTreatments([ADVANCED_UPLOADER]);
  const isAdvancedUploaderEnabled = treatments[ADVANCED_UPLOADER].treatment === 'on';

  const isReadOnly = getShareLinkToken() !== null || disabled;

  const files = useExplore(state => {
    const fileField = state.annotationDraft?.fields?.find(
      item => item && item.fieldId === field.id,
    );
    const files = (fileField?.files ?? []).filter(
      (file): file is Solid<AnnotationFile, 'fileId' | 'name'> => !!file?.fileId && !!file.name,
    );
    return files;
  });
  const filesWithUrls = useFetchFileUrls(files.map(file => file.fileId));

  const handleDownload = (fileId: string, fileName: string) => {
    if (!isEmpty(filesWithUrls)) {
      const file = filesWithUrls.find(file => file.fileId === fileId);
      if (!isEmpty(file)) {
        downloadUrl(file.downloadUrl, fileName);
      }
    }
  };

  const handlePreview = (fileId: string, fileName: string) => {
    if (!isEmpty(filesWithUrls)) {
      const file = filesWithUrls.find(file => file.fileId === fileId);
      if (!isEmpty(file)) {
        setSelectedFileInfo({
          fileId,
          fileName,
        });
        setFileUrl(file.downloadUrl);
        const extension = getFileExtension(file.downloadUrl);
        if (!isEmpty(extension) && isImageExtension(extension)) {
          setEnabledImagePreview(true);
        }
        if (!isEmpty(extension) && isVideoExtension(extension)) {
          setEnabledVideoPreview(true);
        }
      }
    }
  };

  const togglePreview = (fileId: string) => {
    if (isEmpty(filesWithUrls)) {
      return false;
    }

    const file = filesWithUrls.find(file => file.fileId === fileId);
    if (isEmpty(file)) {
      return false;
    }

    const fileExtension = getFileExtension(file.downloadUrl);
    return (
      isImageExtension(fileExtension) ||
      isVideoExtension(fileExtension) ||
      isPdfExtension(fileExtension)
    );
  };

  // Handle deleting the annotation group
  const handleRemove = useCallback(
    (fileId: string, name: string) => {
      setOpenConfirmationModal({
        isOpen: true,
        title: 'Delete Attachment',
        description: `Are you sure you want to delete the attachment ${name}?`,
        actionButton: 'Delete Attachment',
        actionFunction: async () => {
          editAnnotationUpdateField(field, ANNOTATION_FIELD_TYPE.FILE, prev => {
            const oldFiles = (prev as AnnotationFileField).files ?? [];
            return { ...prev, files: oldFiles.filter(item => item?.fileId !== fileId) };
          });
          setOpenConfirmationModal((state: ConfirmationModalState) => {
            return { ...state, isOpen: false };
          });
        },
      });
    },
    [field],
  );

  return (
    <div
      className="relative mt-3 w-full flex items-center b-1 b-neutral-400 rounded-1 b-solid px-3 py-2"
      key={field.name}
    >
      <div className="flex-1">
        <p className="color-neutral-800 typo-text-xxs">{field.name}</p>
        <div>
          {files.map(({ fileId, name }) => {
            let mediaFile = undefined;
            let isPdf = false;
            if (!isEmpty(filesWithUrls)) {
              mediaFile = filesWithUrls.find(file => file.fileId === fileId);
              if (mediaFile) {
                const extension = getFileExtension(mediaFile.downloadUrl);
                isPdf = !isEmpty(extension) && isPdfExtension(extension);
              }
            }

            return (
              <div className="mt-2 flex items-center gap-2" key={fileId}>
                <div
                  className="cursor-pointer color-neutral-600 underline typo-link-s"
                  onClick={() => handleDownload(fileId, name)}
                >
                  {name ? name.slice(0, 25) + '...' : ''}
                </div>

                {isPdf ? (
                  <a href={mediaFile?.previewUrl} rel="noreferrer" target="_blank">
                    <div
                      className={cn(
                        'i-skand-show',
                        'cursor-pointer',
                        'text-3.5',
                        'color-neutral-600',
                        'hover:color-primary-400',
                      )}
                    />
                  </a>
                ) : (
                  <button
                    className={cn('bg-transparent', 'border-none', 'w-14px')}
                    disabled={!togglePreview(fileId)}
                    onClick={() => handlePreview(fileId, name)}
                  >
                    <div
                      className={cn(
                        'i-skand-show',
                        'cursor-pointer',
                        'text-3.5',
                        togglePreview(fileId) ? 'color-neutral-600' : 'color-neutral-400',
                        togglePreview(fileId) && 'hover:color-primary-400',
                      )}
                      onMouseEnter={() => !togglePreview(fileId) && setPreviewDisabledTooltip(true)}
                      onMouseLeave={() =>
                        !togglePreview(fileId) && setPreviewDisabledTooltip(false)
                      }
                    />
                  </button>
                )}

                <div
                  className="i-skand-download cursor-pointer text-3 color-neutral-600 hover:color-primary-400"
                  onClick={() => handleDownload(fileId, name)}
                />
                {!isReadOnly && (
                  <div
                    className="i-skand-close cursor-pointer text-2 color-neutral-600 hover:color-primary-400"
                    onClick={() => fileId && handleRemove(fileId, name)}
                  />
                )}
              </div>
            );
          })}
        </div>

        {previewDisabledTooltip && (
          <div className={cn('absolute')}>
            <Toast
              clickToDismiss
              dismiss={() => {}}
              message="This file type can't be previewed."
              type="info"
            />
          </div>
        )}

        {!isReadOnly && (
          <div className={cn(files.length !== 0 && 'mt-2')}>
            {isAdvancedUploaderEnabled ? (
              <Upload field={field} />
            ) : (
              <QueryClientProvider client={queryClient}>
                <RequestContextProvider value={request}>
                  <UploadLegacy field={field} />
                </RequestContextProvider>
              </QueryClientProvider>
            )}
          </div>
        )}
      </div>

      <div className="flex-0 i-skand-add text-3 color-neutral-400" />
      {enabledVideoPreview && !isEmpty(fileUrl) && !isEmpty(selectedFileInfo) && (
        <VideoPreview
          close={() => {
            setEnabledVideoPreview(false);
          }}
          url={fileUrl}
        />
      )}

      {enabledImagePreview && !isEmpty(fileUrl) && !isEmpty(selectedFileInfo) && (
        <ImagePreview
          close={() => {
            setEnabledImagePreview(false);
          }}
          downloadable
          imageFileName={selectedFileInfo.fileName ?? `image-${Date.now()}`}
          url={fileUrl}
          zoomable
        />
      )}

      <DeleteConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
    </div>
  );
};
