import { queryClient } from '@/graphql/client';
import { CreateSystemFolderNodeInput } from '@/graphql/codegen/graphql';
import {
  CREATE_PROJECT_FILE_NODES_FROM_FILES,
  CREATE_PROJECT_FOLDER_NODE,
  CREATE_SYSTEM_FOLDER_NODE,
} from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useSystemNodes } from '@/hooks/useSystemNodes';
import { useMutation } from '@tanstack/react-query';

export const useCreateProjectNodeFolder = () => {
  const { mutateAsync: createProjectNodeFolder } = useMutation({
    mutationFn: (variables: { projectId: string; parentNodeId: null | string; name: string }) =>
      request(CREATE_PROJECT_FOLDER_NODE, variables),
  });

  return createProjectNodeFolder;
};

export const useCreateProjectNodeFiles = () => {
  const { mutateAsync: createProjectNodeFiles } = useMutation({
    mutationFn: (variables: {
      projectId: string;
      parentNodeId: null | string;
      nodeIds: string[];
    }) => request(CREATE_PROJECT_FILE_NODES_FROM_FILES, variables),
  });

  return createProjectNodeFiles;
};

export const useCreateSystemNodeFolder = () => {
  const { mutateAsync: createSystemNodeFolder } = useMutation({
    mutationFn: (input: CreateSystemFolderNodeInput) =>
      request(CREATE_SYSTEM_FOLDER_NODE, { input }),
    onSuccess(_, variables) {
      queryClient.invalidateQueries(useSystemNodes.getQueryKey(variables.parentNodeId));
    },
  });

  return createSystemNodeFolder;
};
