export const OrbitIcon = () => {
  return (
    <svg viewBox="0 0 513 513" width="100%" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M407.001068,150.112839 
    C407.669312,158.845108 408.587372,167.140854 408.942261,175.460602 
    C409.490356,188.309921 405.879395,200.529144 401.887543,212.489685 
    C396.076385,229.901413 388.291199,246.512054 377.953949,261.725708 
    C367.336029,277.352570 356.179901,292.613708 345.283966,308.000000 
    C346.783020,308.000000 349.179352,308.000000 351.575684,308.000000 
    C360.057831,308.000000 364.772461,311.194519 366.759491,318.288116 
    C368.560486,324.717621 364.796082,332.602020 358.113617,336.294952 
    C357.443787,336.665100 356.628204,336.969086 355.879669,336.970490 
    C336.415863,337.007080 316.951965,336.999481 297.000000,336.999481 
    C297.000000,320.594574 296.860138,304.486084 297.090393,288.382904 
    C297.154419,283.904907 297.801788,279.289612 299.079742,275.005249 
    C301.668427,266.326965 314.313629,264.506836 320.146332,269.718353 
    C323.681702,272.877197 327.981354,275.836609 325.607269,282.081604 
    C326.073578,282.195892 326.539917,282.310181 327.006256,282.424469 
    C330.225525,278.548157 333.625366,274.806458 336.632477,270.772034 
    C348.984619,254.200089 359.951263,236.763428 368.540009,217.924789 
    C376.519623,200.422150 381.426178,182.265991 380.041626,162.752426 
    C379.188660,150.730667 369.136597,140.028061 357.189575,137.915756 
    C340.322906,134.933640 324.619110,139.092743 309.245544,145.069885 
    C299.386871,148.902893 289.869171,153.613007 279.944733,158.043289 
    C291.787598,167.137100 303.256744,175.943970 315.257996,185.159393 
    C315.876465,180.833908 316.495514,176.912964 316.985718,172.975967 
    C317.649200,167.647156 323.884003,163.490204 330.343353,163.056778 
    C335.974792,162.678864 343.024109,167.790222 343.949158,172.754547 
    C344.542267,175.937378 344.935760,179.206085 344.954712,182.439026 
    C345.052429,199.086655 345.000000,215.735138 345.000000,232.754532 
    C343.418091,232.841904 341.987152,232.988480 340.556091,232.989746 
    C322.891815,233.005478 305.227509,233.005692 287.563232,232.997421 
    C279.576721,232.993683 272.971344,222.199722 275.776672,214.574127 
    C278.448334,207.312042 282.946198,203.592117 291.201263,204.016510 
    C287.455475,200.828247 283.846893,197.458786 279.931305,194.494812 
    C274.265198,190.205780 268.414398,186.156311 262.574921,182.101761 
    C252.847458,175.347549 252.447647,175.081833 243.634628,183.011795 
    C228.907501,196.263306 214.064178,209.453705 200.238556,223.615631 
    C190.721054,233.364609 182.827988,244.701553 174.238190,255.352386 
    C172.546432,257.450043 172.212936,259.572144 173.969498,261.992737 
    C181.182739,271.932678 187.615036,282.529541 195.591263,291.803986 
    C206.906418,304.960785 218.880783,317.631439 231.391586,329.658783 
    C247.100723,344.760895 264.645630,357.680481 283.431061,368.781342 
    C300.484802,378.858795 318.331543,387.229797 337.845337,391.337799 
    C348.450592,393.570435 359.117645,394.313660 369.827179,391.099976 
    C381.019928,387.741302 386.491638,378.008881 388.017975,368.375183 
    C390.792206,350.865509 385.274048,334.863953 379.493317,318.812744 
    C377.721802,313.893738 376.399689,308.582153 376.173584,303.393616 
    C375.865417,296.321014 381.530151,292.733337 388.161682,291.114014 
    C392.298553,290.103821 399.406433,292.849518 402.269165,298.665527 
    C409.064240,312.470612 413.978546,326.846405 415.965454,342.130157 
    C416.849670,348.931854 418.070343,355.808868 417.854736,362.610992 
    C417.319977,379.482178 412.267426,394.913544 399.611755,406.733521 
    C389.517609,416.161133 377.227539,420.913025 363.341888,422.069916 
    C333.077087,424.591400 306.127991,414.325500 280.277710,400.500519 
    C267.400452,393.613586 255.372147,385.141876 242.927811,377.438751 
    C241.669754,376.659973 240.190094,376.239166 239.284744,375.853241 
    C238.818420,379.917664 239.298370,383.958740 237.753799,386.956848 
    C236.105286,390.156708 232.984604,393.266205 229.742172,394.860809 
    C215.971710,401.632965 201.429443,406.124573 186.122604,408.056091 
    C169.352646,410.172241 152.836685,410.070099 137.528442,401.798004 
    C120.811638,392.764740 111.852264,378.268097 108.973267,359.662720 
    C105.306938,335.969177 111.351692,313.876770 120.337425,292.438171 
    C125.295235,280.609589 131.517853,269.311127 137.417587,257.268829 
    C129.848114,242.463562 122.472878,226.415039 119.131897,208.960098 
    C117.390579,199.862610 116.186096,190.507004 116.179977,181.267044 
    C116.169266,165.104553 121.148407,150.180145 132.410904,138.338364 
    C141.291687,129.000809 152.784058,123.804214 165.674927,121.992905 
    C194.568130,117.933083 220.795242,126.636673 246.084473,139.166824 
    C250.502869,141.356018 253.626099,140.578735 257.172180,138.330597 
    C279.074341,124.445023 302.548096,113.936295 328.039398,109.296295 
    C344.428497,106.313110 361.205658,106.357704 376.984619,113.963486 
    C392.406128,121.396950 401.946899,133.618317 407.001068,150.112839 
  M227.545654,365.031006 
    C221.415863,359.573456 214.970306,354.427002 209.222656,348.592957 
    C197.601013,336.796692 186.113007,324.847260 175.055344,312.525360 
    C168.900955,305.667328 163.785019,297.884277 158.073700,290.618225 
    C155.968552,287.939972 153.445328,288.411621 152.176910,291.451538 
    C150.083008,296.469757 147.794876,301.417297 145.934174,306.520630 
    C140.093445,322.539856 134.658829,338.612793 137.960037,356.164276 
    C140.769974,371.103912 149.938553,378.716797 165.228683,379.975342 
    C182.013870,381.356934 197.600601,376.579559 212.995224,370.593079 
    C218.014206,368.641327 223.416382,367.675018 228.569534,366.278015 
    C228.785568,366.569153 228.416504,366.071838 227.545654,365.031006 
  M177.478729,205.013474 
    C187.162399,195.335678 196.802002,185.613144 206.555084,176.005829 
    C209.710388,172.897705 213.218582,170.150955 216.459595,167.125610 
    C218.409744,165.305237 220.155823,163.266266 221.993607,161.325531 
    C220.003555,160.217545 218.119690,158.818069 216.006470,158.048157 
    C200.532639,152.410477 184.836777,147.763260 168.108429,151.056564 
    C158.434494,152.961075 151.642960,158.523071 147.917328,167.949127 
    C143.426727,179.310593 145.098465,190.721069 147.603210,201.932831 
    C149.644226,211.068893 153.117630,219.884964 156.306732,229.950195 
    C163.623825,221.307266 170.303574,213.417160 177.478729,205.013474 z"
        fill="currentColor"
        opacity="1.000000"
        stroke="none"
      />
    </svg>
  );
};
