import { useLayout } from '../stores/layout';
import { cn } from '../utils/classname';
import { SceneTab } from './SceneTab';
import { TemplateTab } from './TemplateTab';
import { ShareLinkTab } from './ShareLinkTab';
import { ShareLinkTab as LegacyShareLinkTab } from './LegacyShareLinkTab';
import { ProcessTab } from './ProcessTab';
import { useTreatments } from '@splitsoftware/splitio-react';
import { SHARE_LINK_REVAMP } from '@/utils/split';
import { useMemo } from 'react';

export const LeftSideBarFirst = () => {
  const isShowing = useLayout(state => state.isShowingLeftSideBarI);
  const tab = useLayout(state => state.tab);
  const treatment = useTreatments([SHARE_LINK_REVAMP]);

  const shareLinkRevampFlag = treatment[SHARE_LINK_REVAMP].treatment === 'on';

  const shareLinkSettingsPanel = useMemo(() => {
    if (shareLinkRevampFlag) {
      return <ShareLinkTab />;
    }
    return <LegacyShareLinkTab />;
  }, [shareLinkRevampFlag]);

  return (
    isShowing && (
      <div
        className={cn(
          'h-full',
          'w-400px',
          'bg-neutral-100',
          'absolute',
          'left-0',
          'p-y-18px',
          'p-x-3',
          'overflow-visible',
        )}
      >
        {tab === 'scene' && <SceneTab />}
        {tab === 'templates' && <TemplateTab />}
        {tab === 'sharelink' && shareLinkSettingsPanel}
        {tab === 'process' && <ProcessTab />}
      </div>
    )
  );
};
