import { GoogleMap, MarkerF, useJsApiLoader, Libraries } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from '@/constants/env';
import { isEmpty } from '@/utils/empty';

export interface SiteMapProps {
  setPosition: (latlng: google.maps.LatLngLiteral) => void;
  position: google.maps.LatLngLiteral;
}

const containerStyle: React.CSSProperties = {
  border: '1px solid #fff',
  flex: '0 0 auto',
  height: '15rem',
  width: '100%',
};
const libraries: Libraries = ['places'];

export const SiteMap = ({ position, setPosition }: SiteMapProps) => {
  // Setup the Google Maps API
  const { isLoaded } = useJsApiLoader({
    id: 'siteMap',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  const handleClickOnMap = (event: google.maps.MapMouseEvent) => {
    if (!isEmpty(event) && !isEmpty(event.latLng)) {
      setPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    }
  };

  if (isLoaded)
    return (
      <div className="w-full">
        <GoogleMap
          center={position}
          mapContainerStyle={containerStyle}
          onClick={handleClickOnMap}
          options={{ fullscreenControl: false, mapTypeControl: false, streetViewControl: false }}
          zoom={15}
        >
          <MarkerF position={position} />
        </GoogleMap>
      </div>
    );
};
