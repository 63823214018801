export const SuperheroIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1727_9306)">
        <path
          d="M4.25128 5.82935C5.26513 5.82935 6.08702 4.97442 6.08702 3.9198C6.08702 2.86519 5.26513 2.01025 4.25128 2.01025C3.23742 2.01025 2.41553 2.86519 2.41553 3.9198C2.41553 4.97442 3.23742 5.82935 4.25128 5.82935Z"
          fill="currentColor"
        />
        <path
          d="M0.386473 0.100503C0.772947 -0.100503 1.25604 0 1.54589 0.40201L2.51208 2.01005C2.02899 2.51256 1.73913 3.11558 1.73913 3.8191C1.73913 3.9196 1.73913 3.9196 1.73913 4.0201L0.0966184 1.30653C-0.0966184 0.904523 0 0.40201 0.386473 0.100503Z"
          fill="currentColor"
        />
        <path
          d="M16.2319 19.1959L12.1739 14.9748L5.79712 6.43213"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.685"
        />
        <path
          d="M10.628 18.8943L7.63281 16.2813L8.1159 12.7637"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.685"
        />
        <path
          d="M3.67139 10.6531H6.56994V7.43701"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.685"
        />
        <path
          d="M7.0531 11.6584C7.34296 11.6584 7.63281 11.5579 7.82605 11.3569C8.01928 11.1559 8.1159 10.8544 8.1159 10.5529V8.94482L9.75841 11.2564C10.1449 11.8594 10.0483 12.6634 9.46856 13.1659C9.27532 13.3669 8.98547 13.4674 8.69561 13.4674C8.30914 13.4674 7.92266 13.2664 7.63281 12.9649L6.85986 11.8594H7.0531V11.6584Z"
          fill="currentColor"
        />
        <path
          d="M5.41064 2.00994C5.41064 2.00994 5.7005 2.21094 5.99035 2.61295C5.99035 2.61295 6.37683 2.51245 7.43963 3.61798C8.50243 4.7235 9.66185 4.623 9.66185 4.623C9.66185 4.623 10.8213 4.422 10.9179 3.51747C10.9179 3.51747 10.2416 4.32149 8.98552 3.21597C7.72949 2.11044 6.95654 1.00491 5.41064 2.00994Z"
          fill="currentColor"
        />
        <path
          d="M5.12083 6.33149C5.12083 6.43199 4.92759 7.03501 4.83097 7.13551C4.63774 7.63802 4.54112 8.54255 5.41068 8.94456C5.89378 9.14556 6.37687 8.84406 6.37687 8.84406L5.41068 6.633C5.41068 6.633 5.41068 6.02998 5.12083 6.33149Z"
          fill="currentColor"
        />
        <path
          d="M7.14965 8.4422C7.14965 8.4422 9.27526 8.84421 10.3381 10.3517C11.4009 11.8593 14.1062 14.0703 16.6183 13.4673C19.1303 12.8643 19.5168 12.3618 19.5168 12.3618L16.7149 8.4422C16.7149 8.4422 13.0434 9.94974 10.0482 7.93969C7.05304 5.92964 5.99023 6.33165 5.99023 6.33165"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1727_9306">
          <rect fill="currentColor" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
