export const MinimapIcon = () => {
  return (
    <svg fill="none" height="25" viewBox="0 0 26 25" width="26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.23829 1.30497L15.2542 6.98285L8.39053 8.45189L6.9204 15.3104L1.23829 1.30497Z"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path d="M5.10718 24.4423L25.4643 3.56494" stroke="currentColor" strokeLinejoin="round" />
      <path d="M13.1428 16.4126L21.1785 24.4423" stroke="currentColor" strokeLinejoin="round" />
      <path d="M18.5 21.7658L25.4643 14.8066" stroke="currentColor" strokeLinejoin="round" />
    </svg>
  );
};
