export const PencilIcon = () => {
  return (
    <svg fill="none" height="12" viewBox="0 0 14 12" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.77917 0.810547L3.05053 6.54071L2.74902 9.37563L5.52289 9.07404L11.2515 3.28356C10.5279 2.62007 10.4676 2.49944 8.77917 0.810547Z"
        stroke="#BCB9BE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.125"
      />
      <path
        d="M12.5176 11.1855H1.48242"
        stroke="#BCB9BE"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.125"
      />
    </svg>
  );
};
