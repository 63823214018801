import { formatBytes } from '../utils';
import { Button, Progress, cn } from '@skand/ui';
import { UploadSession, useUploadSession } from '@skand/uploader';
import {
  FAILURE_STAGES,
  PREPARED_STAGES,
  PREPARING_STAGES,
  UPLOADED_STAGES,
  UPLOADING_STAGES,
} from '../constants';
import { hideUploadDetails, showUploadDetails, useUploadStore } from '@/stores/upload';

export interface UploadOverviewProps {
  uploadSession: UploadSession;
}

export const UploadOverview = ({ uploadSession }: UploadOverviewProps) => {
  const isShowingUploadDetails = useUploadStore(state => state.showUploadDetails);

  const { stage, uploadProgress } = useUploadSession(uploadSession);

  const filesTotal = uploadProgress.numberOfFilesTotal;
  const filesUploaded = uploadProgress.numberOfFilesUploaded;
  const progressOverall = uploadProgress.proportion;
  const speedMeta = formatBytes(uploadProgress.averageSpeed, 1);
  const speed = `${speedMeta.amount} ${speedMeta.unit}/s`;

  return (
    <div className={cn('flex items-center gap-2 justify-between my-3')}>
      <span className="flex-[2-2-auto] color-neutral-800 typo-text-s">
        {filesUploaded} / {filesTotal} file(s) has been uploaded
      </span>

      <div className="flex flex-[1-1-auto] items-center">
        {FAILURE_STAGES.includes(stage) && (
          <span className="color-alert-400 typo-text-s">Upload failed</span>
        )}

        {PREPARING_STAGES.includes(stage) && (
          <span className="color-neutral-500 typo-text-s">Preparing...</span>
        )}
        {PREPARED_STAGES.includes(stage) && (
          <span className="color-success-400 typo-text-s-em">Ready for upload</span>
        )}

        {UPLOADING_STAGES.includes(stage) && (
          <>
            <span className="color-neutral-500 typo-text-s">Uploading</span>
            <Progress className="ml-2 w-80px" progress={progressOverall} />
            <span className="ml-2 color-neutral-500 typo-text-s">{speed}</span>
          </>
        )}

        {UPLOADED_STAGES.includes(stage) && (
          <>
            <span className="color-success-400 typo-text-s">Upload complete</span>
            <span className="ml-2 color-neutral-500 typo-text-s">{speed}</span>
          </>
        )}
      </div>

      <Button
        className="flex flex-[1-1-auto] cursor-pointer items-center gap-1 gap-1"
        onClick={isShowingUploadDetails ? hideUploadDetails : showUploadDetails}
        size="xs"
      >
        {isShowingUploadDetails ? 'hide details' : 'show details'}
        <div
          className={cn(
            'i-skand-dropdown transition-transform',
            isShowingUploadDetails && 'transform-rotate-180',
          )}
        />
      </Button>
    </div>
  );
};
