import { SystemNodeKindInput } from '@/graphql/codegen/graphql';
import { LIST_SYSTEM_NODES_BY_PARENT_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { createSystemNode } from '@/utils/transformers';
import { hasId } from '@/utils/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (
  parentNodeId?: null | string,
  pageSize?: number,
  pageIndex?: number,
  searchTerm?: string,
  nodeKinds?: SystemNodeKindInput[],
) => [
  'LIST_SYSTEM_NODES_BY_PARENT_NODE_ID',
  parentNodeId,
  pageSize,
  pageIndex,
  searchTerm || undefined,
  nodeKinds,
];

export const useSystemNodes = (
  queryParam: {
    nodeKinds: SystemNodeKindInput[];
    pageIndex: number;
    pageSize: number;
    parentNodeId?: null | string;
    searchTerm?: string;
  },
  options?: { keepPreviousData?: boolean },
) => {
  const response = useQuery({
    queryKey: getQueryKey(
      queryParam.parentNodeId,
      queryParam.pageSize,
      queryParam.pageIndex,
      queryParam.searchTerm,
      queryParam.nodeKinds,
    ),
    queryFn: () =>
      request(LIST_SYSTEM_NODES_BY_PARENT_NODE_ID, {
        parentNodeId: queryParam.parentNodeId as null | string,
        pageSize: queryParam.pageSize,
        pageIndex: queryParam.pageIndex,
        searchTerm: queryParam.searchTerm,
        nodeKinds: queryParam.nodeKinds,
      }),
    keepPreviousData: options?.keepPreviousData,
  });

  const systemNodes = useMemo(() => {
    const nodes = response.data?.listSystemNodesByParentNodeId?.data ?? [];
    return nodes.map(item => createSystemNode(item)).filter(hasId);
  }, [response.data?.listSystemNodesByParentNodeId?.data]);

  const itemCount = useMemo(() => {
    return response.data?.listSystemNodesByParentNodeId?.totalNumberOfFiles;
  }, [response.data?.listSystemNodesByParentNodeId?.totalNumberOfFiles]);

  const pageCount = useMemo(() => {
    return response.data?.listSystemNodesByParentNodeId?.totalNumberOfPages;
  }, [response.data?.listSystemNodesByParentNodeId?.totalNumberOfPages]);

  return { response, systemNodes, itemCount, pageCount };
};

useSystemNodes.getQueryKey = getQueryKey;
