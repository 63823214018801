import { Button, cn, Modal } from '@skand/ui';

export interface ConfirmationModalState {
  isOpen: boolean;
  title: string;
  description: string;
  actionButton: string;
  actionFunction: (e: React.MouseEvent<HTMLButtonElement>) => void;
  cancelFunction?: () => void;
}

export type SetOpenConfirmationModal = React.Dispatch<React.SetStateAction<ConfirmationModalState>>;

interface DeleteConfirmationModalProps {
  openConfirmationModal: ConfirmationModalState;
  setOpenConfirmationModal: SetOpenConfirmationModal;
  className?: string;
  buttonContainerClassName?: string;
}

export const DeleteConfirmationModal = ({
  openConfirmationModal,
  setOpenConfirmationModal,
  className,
  buttonContainerClassName,
}: DeleteConfirmationModalProps) => {
  return openConfirmationModal.isOpen ? (
    <Modal className={className ?? className}>
      <div className="z-20 mt-1 flex flex-col gap-2">
        <p className="color-neutral-800 typo-text-m">{openConfirmationModal.title}</p>
        <p className="max-h-128px overflow-auto color-neutral-800 typo-text-s">
          {openConfirmationModal.description}
          <br />
          <span className="color-grey-500 typo-text-s-em" />
        </p>
      </div>
      <div className={cn('w-full flex gap-2', buttonContainerClassName)}>
        <Button
          className="w-full cursor-pointer"
          onClick={() => {
            if (openConfirmationModal.cancelFunction) {
              openConfirmationModal.cancelFunction();
            }

            setOpenConfirmationModal(state => {
              return { ...state, isOpen: false };
            });
          }}
          size="s"
        >
          Cancel
        </Button>
        <Button
          className="w-full cursor-pointer"
          filled
          onClick={openConfirmationModal.actionFunction}
          primary
          size="s"
        >
          {openConfirmationModal.actionButton}
        </Button>
      </div>
    </Modal>
  ) : null;
};
