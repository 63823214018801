export const DownloadIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5298 11.4399L9.97778 14.9919L6.42578 11.4399"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.685"
      />
      <path
        d="M17.957 15.4019V18.1439C17.957 18.6939 17.507 19.1439 16.957 19.1439H3C2.45 19.1439 2 18.6939 2 18.1439V15.4019"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.685"
      />
      <path
        d="M9.99219 14.9918V0.842773"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.685"
      />
    </svg>
  );
};
