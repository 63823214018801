import { COUNT_ANNOTATIONS_BY_TEMPLATE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (id: string) => ['COUNT_ANNOTATIONS_BY_TEMPLATE_ID', id];

export const useFetchCountTemplateAnnotations = (id: string) => {
  const result = useQuery({
    enabled: !!id,
    queryFn: () => request(COUNT_ANNOTATIONS_BY_TEMPLATE_ID, { templateId: id as string }),
    queryKey: getQueryKey(id),
  });

  return useCallback(() => {
    return result.data?.countActiveAnnotationsByTemplateId ?? 0;
  }, [result.data?.countActiveAnnotationsByTemplateId]);
};

useFetchCountTemplateAnnotations.getQueryKey = getQueryKey;
