import {
  DrawIcon,
  IconButton,
  MapIcon,
  OrbitIcon,
  OrthographicIcon,
  PanoGroupIcon,
  PerspectiveIcon,
  SuperheroIcon,
} from '@/components/IconButton';
import { Label } from '@/components/Label';
import { useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import {
  disableViewer2D,
  enableViewer2D,
  startDraw3D,
  startPanoramaWalkthrough,
  stopDraw3D,
  stopPanoramaView,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, toast } from '@skand/ui';
import { useEffect, useState } from 'react';
import { GlobeControlMenu } from '../GlobeControlMenu';
import { SettingsMenu } from '../SettingsMenu';
import { persist } from '@/utils/Persist';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export const ActionButtons = () => {
  const enabled2D = useViewer(state => state.enabled2D);
  const api3D = useViewer(state => state.api3D);
  const enabledDraw3D = useViewer(state => state.enabledDraw3D);
  const enabledDraw2D = useViewer(state => state.enabledDraw2D);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const navigationMode = useViewer(state => state.navigationMode);
  const projectionMode = useViewer(state => state.projectionMode);
  const filteredPanoramas = useViewer(state => state.filteredPanoramas);

  const annotationDraft = useExplore(state => state.annotationDraft);
  const hideLeftSideBarI = useLayout(state => state.hideLeftSideBarI);

  const [navigationModeTooltip, setNavigationModeTooltip] = useState(false);
  const [cameraProjectionModeTooltip, setCameraProjectionModeTooltip] = useState(false);
  const [globeControlMenuTooltip, setIsGlobeControlMenuTooltip] = useState(false);
  const [drawModeTooltip, setDrawModeTooltip] = useState(false);
  const [settingsTooltip, setSettingsTooltip] = useState(false);

  const [enabledGlobeControlMenu, setEnabledGlobeControlMenu] = useState(false);
  const [enabledSettingsMenu, setEnabledSettingsMenu] = useState(false);

  useEffect(() => {
    api3D?.navigation.setNavigationMode(navigationMode);
    api3D?.navigation.setProjectionMode(projectionMode);
  }, [api3D?.navigation, navigationMode, projectionMode]);

  // Toggle the camera navigation controls
  const toggleNavigationMode = () => {
    if (navigationMode === 'orbit') {
      useViewer.setState({ navigationMode: 'superman' });
    } else {
      useViewer.setState({ navigationMode: 'orbit' });
    }
  };

  // Toggle the camera projection mode
  const toggleCameraProjection = () => {
    if (projectionMode === 'perspective') {
      useViewer.setState({ projectionMode: 'orthographic' });
    } else {
      useViewer.setState({ projectionMode: 'perspective' });
    }
  };

  // Toggle the draw tool
  const toggleDrawTool = () => {
    if (!enabledDraw3D) {
      startDraw3D();
    } else {
      stopDraw3D();
    }
  };

  // Toggle the image viewer
  const toggleImageViewer = () => {
    if (enabled2D) {
      disableViewer2D();

      // Clear image from persistent storage
      persist.clear('image');
      persist.url.refreshURL();
    } else {
      enableViewer2D();
      hideLeftSideBarI();
    }
  };

  // Toggle the globe control menu
  const toggleGlobeControlMene = () => {
    setEnabledSettingsMenu(false);
    setEnabledGlobeControlMenu(!enabledGlobeControlMenu);
  };

  // Toggle the settings menu
  const toggleSettingsMenu = () => {
    setEnabledGlobeControlMenu(false);
    setEnabledSettingsMenu(!enabledSettingsMenu);
  };

  // Start the panorama walkthrough at the closest nearby panorama image
  const handlePanoramaWalkthrough = async () => {
    if (api3D && filteredPanoramas.length > 0) {
      const cameraPosition = api3D.navigation.getPosition();
      let minDist = Infinity;
      let target = filteredPanoramas[0];
      for (const panorama of filteredPanoramas) {
        if (panorama.widget) {
          const dist = panorama.widget.getPosition().distanceTo(cameraPosition);
          if (dist < minDist) {
            minDist = dist;
            target = panorama;
          }
        }
      }
      startPanoramaWalkthrough(target);
      persist.set('image', target.id);
      persist.url.refreshURL();
    } else {
      toast({
        type: 'info',
        message:
          'Could not start walkthrough. Enable the layer/s with panoramic images or toggle them in the image viewer.',
        lifespan: 5000,
      });
    }
  };

  return (
    <div className="absolute right-0 top-0 mr-3">
      <div
        className={cn(
          'pt-12px',
          'flex',
          'justify-start',
          'items-center',
          'z-1',
          'pointer-events-auto',
        )}
      >
        <Button
          active={enabled2D}
          className={cn(
            'h-30px mr-10px',
            'b-1px! b-solid! b-neutral-100!',
            'color-neutral-100!',
            'cursor-pointer',
            !enabled2D && 'bg-opacity-0!',
          )}
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.IMAGE_VIEWER}
          filled
          onClick={toggleImageViewer}
          primary={enabled2D}
          size="small"
        >
          Image viewer
        </Button>
        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={ANALYTICS_EVENT_OBJECT.DRAW_TOOL_3D}
            buttonIcon={<DrawIcon />}
            buttonState={enabledDraw3D ? 'active' : 'default'}
            className={cn(
              'h-30px',
              'mr-10px',
              enabledDraw3D || drawModeTooltip ? 'bg-neutral-400' : 'bg-neutral-900 bg-opacity-10',
            )}
            disabled={enabledDraw2D || annotationDraft?.annotation3d !== undefined}
            onClick={toggleDrawTool}
            onMouseEnter={() => setDrawModeTooltip(true)}
            onMouseLeave={() => setDrawModeTooltip(false)}
          />
          {drawModeTooltip && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Draw tool"
                labelType="default"
              />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={
              projectionMode === 'perspective'
                ? ANALYTICS_EVENT_OBJECT.ORTHOGRAPHIC_VIEW
                : ANALYTICS_EVENT_OBJECT.PERSPECTIVE_VIEW
            }
            buttonIcon={
              projectionMode === 'perspective' ? <PerspectiveIcon /> : <OrthographicIcon />
            }
            buttonState="default"
            className={cn(
              'h-30px',
              'mr-10px',
              cameraProjectionModeTooltip ? 'bg-neutral-400' : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleCameraProjection}
            onMouseEnter={() => setCameraProjectionModeTooltip(true)}
            onMouseLeave={() => setCameraProjectionModeTooltip(false)}
          />
          {cameraProjectionModeTooltip && (
            <div className={cn('absolute w-250px', 'left--30px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle={projectionMode === 'perspective' ? 'Orthographic' : 'Perspective'}
                labelType="default"
              />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={
              navigationMode === 'superman'
                ? ANALYTICS_EVENT_OBJECT.ORBIT_MODE
                : ANALYTICS_EVENT_OBJECT.SUPERHERO_MODE
            }
            buttonIcon={navigationMode === 'superman' ? <SuperheroIcon /> : <OrbitIcon />}
            buttonState="default"
            className={cn(
              'h-30px',
              'mr-10px',
              navigationModeTooltip ? 'bg-neutral-400' : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleNavigationMode}
            onMouseEnter={() => setNavigationModeTooltip(true)}
            onMouseLeave={() => setNavigationModeTooltip(false)}
          />
          {navigationModeTooltip && (
            <div className={cn('absolute w-250px', 'left--80px', 'mt-2')}>
              <Label
                ellipsis={false}
                labelTitle={`Switch to ${
                  navigationMode === 'superman' ? 'orbit' : 'superhero'
                } mode here`}
                labelType="info"
                textLength={30}
              />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={ANALYTICS_EVENT_OBJECT.GLOBE_CONTROL_MENU}
            buttonIcon={<MapIcon />}
            buttonState={enabledGlobeControlMenu ? 'active' : 'default'}
            className={cn(
              'h-30px',
              'mr-10px',
              enabledGlobeControlMenu || globeControlMenuTooltip
                ? 'bg-neutral-400'
                : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleGlobeControlMene}
            onMouseEnter={() => setIsGlobeControlMenuTooltip(true)}
            onMouseLeave={() => setIsGlobeControlMenuTooltip(false)}
          />
          {globeControlMenuTooltip && !enabledGlobeControlMenu && (
            <div className={cn('absolute w-250px', 'left--30px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Globe Control Menu"
                labelType="default"
              />
            </div>
          )}
          {enabledGlobeControlMenu && (
            <div className={cn('absolute w-284px', 'h-292px', 'right-0', 'mt-2')}>
              <GlobeControlMenu />
            </div>
          )}
        </div>

        <div className={cn('relative')}>
          <IconButton
            analyticsEventObject={ANALYTICS_EVENT_OBJECT.VIEWER_SETTINGS}
            buttonIcon={<div className="i-skand-gear text-18px" />}
            buttonState="default"
            className={cn(
              'h-30px',
              'mr-10px',
              enabledSettingsMenu || settingsTooltip
                ? 'bg-neutral-400'
                : 'bg-neutral-900 bg-opacity-10',
            )}
            onClick={toggleSettingsMenu}
            onMouseEnter={() => setSettingsTooltip(true)}
            onMouseLeave={() => setSettingsTooltip(false)}
          />
          {settingsTooltip && !enabledSettingsMenu && (
            <div className={cn('absolute w-250px', 'left--20px', 'mt-2')}>
              <Label
                css="bg-neutral-100"
                ellipsis={false}
                labelTitle="Settings"
                labelType="default"
              />
            </div>
          )}
          {enabledSettingsMenu && (
            <div className={cn('absolute w-260px', 'h-524px', 'right-0', 'mt-2')}>
              <SettingsMenu />
            </div>
          )}
        </div>

        <div className={cn('relative flex flex-row')}>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              enabledPanoramaWalkthrough ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-0px',
              'border-t-1',
              'border-b-1',
              'rounded-bl-1',
              'rounded-tl-1',
              'border-solid',
              'fill-none',
              'border-neutral-100',
            )}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.VIEW_3D}
            disabled={!enabledPanoramaWalkthrough}
            onClick={stopPanoramaView}
          >
            <div className={cn('color-neutral-100 typo-text-s')}>3D</div>
          </button>
          <button
            className={cn(
              'transition',
              'duration-300',
              'ease-out',
              'h-[30px]',
              'w-[30px]',
              'flex',
              'justify-center',
              'items-center',
              !enabledPanoramaWalkthrough ? 'bg-transparent' : 'bg-neutral-700',
              'border-l-1',
              'border-r-1',
              'border-t-1',
              'border-b-1',
              'rounded-br-1',
              'rounded-tr-1',
              'border-solid',
              'fill-none',
              'border-neutral-100',
            )}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.VIEW_PANORAMA}
            disabled={enabledPanoramaWalkthrough}
            onClick={handlePanoramaWalkthrough}
          >
            <PanoGroupIcon className="color-neutral-100" />
          </button>
        </div>
      </div>
    </div>
  );
};
