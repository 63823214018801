import { formatBytes } from '../utils';
import { Progress } from '@skand/ui';
import { Uploader, UploadSession, UploadSessionStage, useUploadSession } from '@skand/uploader';
import {
  FAILURE_STAGES,
  PREPARED_STAGES,
  PREPARING_STAGES,
  UPLOADED_STAGES,
  UPLOADING_STAGES,
} from '../constants';
import { FileTreeNodeWithDepth } from './UploadDetails';

const ProgressView = ({ stage, uploader }: { stage: UploadSessionStage; uploader: Uploader }) => {
  const { error, uploadProgress } = uploader.useUploader();

  if (error) return <span className="color-alert-400 typo-text-s">Upload failed</span>;

  const speedMeta = formatBytes(uploadProgress.averageSpeed, 1);
  const speed = `${speedMeta.amount} ${speedMeta.unit}/s`;

  if (UPLOADING_STAGES.includes(stage))
    return (
      <div className="flex items-center gap-2">
        <span className="color-neutral-500 typo-text-s">Uploading</span>
        <Progress className="w-80px" progress={uploadProgress.proportion} />
        <span className="color-neutral-500 typo-text-s">{speed}</span>
      </div>
    );

  if (UPLOADED_STAGES.includes(stage)) {
    return (
      <div className="flex items-center gap-2">
        <span className="color-success-400 typo-text-s">Upload complete</span>
        <span className="color-neutral-500 typo-text-s">{speed}</span>
      </div>
    );
  }

  // uploadSession is in failure stage, but this file has no error, it may be uploading
  if (FAILURE_STAGES.includes(stage)) {
    return (
      <div className="flex items-center gap-2">
        <span className="color-neutral-500 typo-text-s">Uploading</span>
        <Progress className="w-80px" progress={uploadProgress.proportion} />
        <span className="color-neutral-500 typo-text-s">{speed}</span>
      </div>
    );
  }
};

export const UploadStatus = ({
  nodeWithDepth,
  uploadSession,
}: {
  nodeWithDepth: FileTreeNodeWithDepth;
  uploadSession: UploadSession;
}) => {
  const { stage } = useUploadSession(uploadSession);

  if (nodeWithDepth.node.type === 'folder') return null;

  if (PREPARING_STAGES.includes(stage))
    return <span className="color-neutral-500 typo-text-s">Preparing...</span>;

  if (PREPARED_STAGES.includes(stage))
    return <span className="color-success-400 typo-text-s-em">Ready for upload</span>;

  if (nodeWithDepth.node.uploader === null) return null;

  return <ProgressView stage={stage} uploader={nodeWithDepth.node.uploader} />;
};
