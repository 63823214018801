import { ANNOTATION_FIELD_TYPE } from '@/constants/annotation';
import { AnnotationTemplate, AnnotationTemplateSelectField } from '@/graphql/codegen/graphql';
import { editAnnotationSetColor, editAnnotationSetField, useExplore } from '@/stores/explore';
import { cn } from '@/utils/classname';
import { Select } from '@skand/ui';
import { useCallback, useEffect } from 'react';

export interface AnnotationFieldsProps {
  template: AnnotationTemplate;
}

export const FieldSelect = ({
  field,
  disabled = false,
}: {
  field: AnnotationTemplateSelectField;
  disabled: boolean;
}) => {
  const colorFromField = useExplore(state => state.annotationDraft?.template?.colorFromField);
  const annoField = useExplore(state => {
    return state.annotationDraft?.fields?.find(item => item && item.fieldId === field.id);
  });
  const option = field.options?.find(option => option?.id === annoField?.optionId);

  const handleChange = useCallback(
    (key: string) => {
      editAnnotationSetField(field, ANNOTATION_FIELD_TYPE.SELECT, { optionId: key });

      if (colorFromField === field.name) {
        const color = field.options?.find(item => item?.id === key)?.color;
        if (color) {
          editAnnotationSetColor(color);
        }
      }
    },
    [colorFromField, field],
  );

  useEffect(() => {
    // when creating new annotation, set value to first option
    if (option === undefined && annoField !== undefined) {
      handleChange(field?.options?.[0]?.id as string);
    }
  }, [annoField, field?.options, handleChange, option]);
  return (
    <div className="mt-3" key={field.name}>
      <Select
        disabled={disabled}
        label={field.name || ''}
        onChange={handleChange}
        options={
          field.options?.map(option => {
            return {
              key: option?.id || '',
              name: option?.value || '',
              tail: option?.color && (
                <div
                  className={cn(
                    'w-3 h-3 rounded-full',
                    option.color === '#FFFFFF' && 'ring-1 ring-inset ring-neutral-300',
                  )}
                  style={{ background: option.color }}
                />
              ),
            };
          }) || []
        }
        style={{ display: 'block' }}
        value={option?.id}
        width="full"
      />
    </div>
  );
};
