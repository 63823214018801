// @unocss-include

import { FileTree, FileTreeNode, FolderNode } from '@skand/uploader';
import chunk from 'lodash-es/chunk';
import { basename, extname } from 'path-browserify';
import { useCreateProjectNodeFiles, useCreateProjectNodeFolder } from './mutations';

export function assertIsString(examinee: unknown): asserts examinee is string {
  if (typeof examinee !== 'string') throw new Error('examinee is not a string');
}

export const formatBytes = (bytes: number | null | undefined, decimals = 2) => {
  if (!bytes || bytes === 0)
    return {
      amount: 0,
      unit: 'Bytes',
    };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const indexOfUnit = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    amount: (bytes / k ** indexOfUnit).toFixed(dm),
    unit: units[indexOfUnit],
  };
};

const displayError = (error: Error) => {
  if ('message' in error) return error.message;
  return error;
};
export const displayErrors = (errors: unknown) => {
  if (errors instanceof Array) return displayError(errors[0]);
  else return displayError(errors as Error);
};

const CHUNK_THRESHOLD = 500;
export const createProjectNodesFromFileTreeNode = async (
  fileTree: FileTree,
  folderNode: FolderNode,
  projectId: string,
  parentNodeId: null | string,
  createProjectNodeFolder: ReturnType<typeof useCreateProjectNodeFolder>,
  createProjectNodeFiles: ReturnType<typeof useCreateProjectNodeFiles>,
) => {
  for (const childFolderPath of folderNode.childFolderAbsolutePaths) {
    const childFolderNode = fileTree.getNode(childFolderPath);
    if (!childFolderNode) continue;
    if (childFolderNode.type !== 'folder') continue;

    const { createProjectFolderNode: childFolderProjectNode } = await createProjectNodeFolder({
      projectId,
      parentNodeId,
      name: basename(childFolderPath),
    });

    const childFolderProjectNodeId = childFolderProjectNode?.id;
    if (!childFolderProjectNodeId) continue;
    await createProjectNodesFromFileTreeNode(
      fileTree,
      childFolderNode,
      projectId,
      childFolderProjectNodeId,
      createProjectNodeFolder,
      createProjectNodeFiles,
    );
  }

  const childFileSystemNodeIds: string[] = [];
  for (const childFilePath of folderNode.childFileAbsolutePaths) {
    const childFileNode = fileTree.getNode(childFilePath);
    if (!childFileNode) continue;
    if (childFileNode.type !== 'file') continue;
    if (childFileNode.fileSystemNodeId === null) continue;
    childFileSystemNodeIds.push(childFileNode.fileSystemNodeId);
  }

  const chunkedChildFileSystemNodeIds = chunk(childFileSystemNodeIds, CHUNK_THRESHOLD);
  for (const fileSystemNodeIds of chunkedChildFileSystemNodeIds) {
    await createProjectNodeFiles({ projectId, parentNodeId, nodeIds: fileSystemNodeIds });
  }
};

export const EXTENSION_TO_ICON_CLASS: Record<string, string> = {
  DXF: 'color-neutral-500 text-4 i-skand-dxf',
  IFC: 'color-neutral-500 text-4 i-skand-ifc',
  KML: 'color-neutral-500 text-4 i-skand-bim',
  LAS: 'color-neutral-500 text-4 i-skand-pointcloud',
  LAZ: 'color-neutral-500 text-4 i-skand-pointcloud',
  MTL: 'color-neutral-500 text-4 i-skand-obj',
  OBJ: 'color-neutral-500 text-4 i-skand-obj',
  TIF: 'color-neutral-500 text-4 i-skand-ortho',
  TIFF: 'color-neutral-500 text-4 i-skand-ortho',

  DOCX: 'color-neutral-500 text-4 i-custom:docx',
  DWG: 'color-neutral-500 text-4 i-custom:dwg',
  E57: 'color-neutral-500 text-4 i-custom:e57', // or i-skand-pointcloud
  FXL: 'color-neutral-500 text-4 i-custom:fxl',
  LISCAD: 'color-neutral-500 text-4 i-custom:liscad',
  MJF: 'color-neutral-500 text-4 i-custom:mjf',
  PDF: 'color-neutral-500 text-4 i-custom:pdf',
  PNG: 'color-neutral-500 text-4 i-custom:png',
  PPTX: 'color-neutral-500 text-4 i-custom:pptx',
  RCP: 'color-neutral-500 text-4 i-custom:rcp',
  RVT: 'color-neutral-500 text-4 i-custom:rvt',
  XLSX: 'color-neutral-500 text-4 i-custom:xlsx',
  ZIP: 'color-neutral-500 text-4  i-custom:zip', // or i-skand-mesh3d
};

export const getFileTreeNodeIconClass = (fileTreeNode: FileTreeNode) => {
  if (fileTreeNode.type === 'folder') return 'i-skand-files color-neutral-500 text-4';

  const { file } = fileTreeNode;
  if (file.type.includes('video')) return 'i-custom:videos color-neutral-500 text-4';
  if (file.type.includes('image')) return 'i-skand-image color-neutral-500 text-4';

  const extension = extname(file.name).slice(1).toUpperCase();
  const iconClass = EXTENSION_TO_ICON_CLASS[extension];
  if (iconClass) return iconClass;

  return 'i-skand-file color-neutral-500 text-4';
};
