import { canPolicyActionEdit } from '@/constants/policy';
import { useFetchSceneEntityPermissions } from '@/hooks/useFetchSceneEntityPermissions';
import { AnnotationGroup } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { NodeGroupIcon } from '@/components/IconButton';
export interface AnnotationGroupNodeProps {
  group: AnnotationGroup;
}

export const AnnotationGroupNode = ({ group }: AnnotationGroupNodeProps) => {
  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const permission = getSceneEntityPermission(group.sceneEntityId);
  const canEdit = canPolicyActionEdit(permission);
  return (
    <div className={cn('flex justify-between items-center py-1  w-[500px]')}>
      <div className="w-3/6 flex items-center gap-2 overflow-x-scroll">
        <NodeGroupIcon className="w-5 text-3 color-neutral-600" />
        <div className={cn('flex items-center text-neutral-600 ')}>
          <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
        </div>
      </div>
      <div className="w-3/6 flex items-start">
        {canEdit ? (
          <p className={cn('typo-text-small text-success-400 ml-7.5')}>Yes</p>
        ) : (
          <p className={cn('typo-text-small text-alert-400 ml-7.5')}>No (Read-only)</p>
        )}
      </div>
    </div>
  );
};
