import { cn } from '@/utils/classname';
import { Button } from '@skand/ui';
import { useState } from 'react';
import { LayerFilterKey, LayerFilterTab } from './LayerFilterTab';
import { TemplateFilterKey, TemplateFilterTab } from './TemplateFilterTab';

type FilterTab = 'layers' | 'templates';

export interface FilterMenuProps {
  closeMenu: () => void;
  layerFilterKeys: Set<LayerFilterKey>;
  templateFilterKeys: Set<TemplateFilterKey>;
  setLayerFilterKeys: (values: Set<LayerFilterKey>) => void;
  setTemplateFilterKeys: (values: Set<TemplateFilterKey>) => void;
  className?: string;
  tabContainerStyle?: string;
}

export const FilterMenu = ({
  closeMenu,
  setLayerFilterKeys,
  className,
  layerFilterKeys,
  templateFilterKeys,
  setTemplateFilterKeys,
  tabContainerStyle,
}: FilterMenuProps) => {
  const [activeTab, setActiveTab] = useState<FilterTab>('layers');

  return (
    <div className={cn('absolute top-9.5', 'w-full', 'bg-neutral-100', 'z-1', className)}>
      <div className={cn(' overflow-y-auto border-neutral-400 border-1 border-solid rounded-lg')}>
        <div className={cn('flex sticky top-0 bg-neutral-100 z-10')}>
          <div
            className={cn(
              'p-3 flex-1 border cursor-pointer',
              activeTab === 'layers'
                ? 'border-b-solid border-3 border-primary-400'
                : 'border-b-solid border border-neutral-300',
            )}
            onClick={() => setActiveTab('layers')}
          >
            <p
              className={cn(
                activeTab === 'layers'
                  ? 'color-primary-400 typo-text-s-em	'
                  : 'color-neutral-500 typo-text-s',
              )}
            >
              Layer Types
            </p>
          </div>
          <div
            className={cn(
              'p-3 flex-1 border cursor-pointer',
              activeTab === 'templates'
                ? 'border-b-solid border-3 border-primary-400 color-primary-400'
                : 'border-b-solid border border-neutral-300 color-neutral-500',
            )}
            onClick={() => setActiveTab('templates')}
          >
            <p
              className={cn(
                activeTab === 'templates'
                  ? 'color-primary-400 typo-text-s-em	'
                  : 'color-neutral-500 typo-text-s',
              )}
            >
              Annotations
            </p>
          </div>
          <div
            className={cn('cursor-pointer', 'absolute right-3 top-3', ' p-1')}
            onClick={closeMenu}
          >
            <div className={cn('i-skand-close', 'text-3', 'text-neutral-400')} />
          </div>
        </div>
        <div className={cn('h-full flex-1 max-h-60dvh overflow-y-auto')}>
          <div className={cn(tabContainerStyle ? tabContainerStyle : 'm-3 h-570px overflow-auto')}>
            {activeTab === 'layers' ? (
              <LayerFilterTab filterKeys={layerFilterKeys} setFilterKeys={setLayerFilterKeys} />
            ) : (
              <TemplateFilterTab
                filterKeys={templateFilterKeys}
                setFilterKeys={setTemplateFilterKeys}
              />
            )}
          </div>
        </div>
        <div className="p-3">
          <Button
            className={cn('w-full')}
            disabled={!(layerFilterKeys.size + templateFilterKeys.size)}
            onClick={() => {
              setLayerFilterKeys(new Set());
              setTemplateFilterKeys(new Set());
            }}
            size="s"
          >
            Clear filter
          </Button>
        </div>
      </div>
    </div>
  );
};
