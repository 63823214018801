import { queryClient } from '@/graphql/client';
import { TransformSceneEntityMutationVariables } from '@/graphql/codegen/graphql';
import { TRANSFORM_SCENE_ENTITY } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useExplore } from '@/stores/explore';
import { Layer, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, Input, Modal, toast } from '@skand/ui';
import {
  Cartographic,
  Imagery,
  ModelNode,
  Terrain,
  calculateCartographicQuaternion,
  toCartesian,
  toRadians,
} from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { AutoComplete } from './AutoComplete';
import { SiteMap } from './SiteMap';

export interface PlaceLayerModalProps {
  layer: Layer;
  active: boolean;
}

export const PlaceLayerModal = ({ layer, active }: PlaceLayerModalProps) => {
  const projectId = useExplore(state => state.projectId);
  const hidePlaceLayerModal = useExplore(state => state.hidePlaceLayerModal);
  const api3D = useViewer(state => state.api3D);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);

  const [position, setPosition] = useState<google.maps.LatLngLiteral>({
    lat: -37.82321082611154,
    lng: 144.92521917916557,
  });
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');

  useEffect(() => {
    setLat(position.lat.toString());
    setLng(position.lng.toString());
  }, [position]);

  const { mutateAsync } = useMutation({
    mutationFn: (variables: TransformSceneEntityMutationVariables) => {
      return request(TRANSFORM_SCENE_ENTITY, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: `Successfully moved entity '${layer.name}'.`,
        lifespan: 5000,
      });
    },
  });

  const handleSubmit = async () => {
    const cartographic = new Cartographic(toRadians(position.lng), toRadians(position.lat), 0);
    const cartesian = toCartesian(cartographic);
    const rotation = calculateCartographicQuaternion(cartographic);

    if (projectId && !(layer.sceneNode instanceof Imagery || layer.sceneNode instanceof Terrain)) {
      layer.sceneNode.setPosition(cartesian);
      layer.sceneNode.setRotation(rotation);

      if (layer.sceneNode instanceof ModelNode && !enabledPanoramaWalkthrough) {
        api3D?.navigation.lookAt(layer.sceneNode);
      }

      // Store the local position
      const localPosition = layer.sceneNode.getLocalPosition();
      const localRotation = layer.sceneNode.getLocalRotation();
      await mutateAsync({
        sceneEntityId: layer.sceneEntityId,
        projectId,
        position: { x: localPosition.x, y: localPosition.y, z: localPosition.z },
        rotation: {
          x: localRotation.x,
          y: localRotation.y,
          z: localRotation.z,
          w: localRotation.w,
        },
      });
    }
    hidePlaceLayerModal();
  };

  if (active)
    return (
      <Modal>
        <div className={cn('flex', 'items-center', 'justify-between')}>
          <p className={cn('color-neutral-800', 'typo-text-l')}>Place model</p>
        </div>

        <p className={cn('mt-4', 'color-neutral-700 typo-text-medium')}>
          Enter address or choose location
        </p>

        <div className={cn('flex-1')}>
          <div
            className={cn(
              'flex-1',
              'grid overflow-y-auto auto-rows-max m-y-1',
              'scrollbar scrollbar-rounded',
              'h-auto',
            )}
          >
            <div className={cn('flex-1')}>
              <AutoComplete position={position} setPosition={setPosition} />

              <div className={cn('typo-text-xs', 'pt-1', 'color-neutral-600', 'pb-2')}>
                No address for the project? Select any point on the map to associate the project
                with a location.
              </div>
              <SiteMap position={position} setPosition={setPosition} />
              <div className={cn('bottom-4', 'left-4', 'flex', 'flex-row', 'items-center', 'mt-3')}>
                <div className={cn('w-30', 'mr-3')}>
                  <Input
                    className={cn('b-none', 'overflow-hidden', 'h-5', 'text-neutral-500')}
                    onChange={setLat}
                    placeholder="LAT"
                    value={lat}
                  />
                </div>
                <div className={cn('w-30', 'mr-3')}>
                  <Input
                    className={cn('b-none', 'overflow-hidden', 'h-5', 'text-neutral-500')}
                    onChange={setLng}
                    placeholder="LNG"
                    value={lng}
                  />
                </div>
                <Button
                  className={cn('w-15', 'h-9', 'hover:cursor-pointer', 'rounded-1')}
                  filled
                  onClick={() => setPosition({ lat: parseFloat(lat), lng: parseFloat(lng) })}
                  primary
                  size="small"
                >
                  Go
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-auto flex gap-3 pt-3">
          <Button className="flex-1 hover:cursor-pointer" onClick={hidePlaceLayerModal} size="s">
            Cancel
          </Button>
          <Button
            className="flex-1 hover:cursor-pointer"
            filled
            onClick={handleSubmit}
            primary
            size="s"
          >
            Place
          </Button>
        </div>
      </Modal>
    );
};
