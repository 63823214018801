import { ClippingToolIcon } from '@/components/IconButton';
import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { ACTION_TYPE, canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import { RenameSceneEntityMutationVariables } from '@/graphql/codegen/graphql';
import { RENAME_SCENE_ENTITY } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useFetchSceneEntityPermissions } from '@/hooks/useFetchSceneEntityPermissions';
import { useExplore } from '@/stores/explore';
import {
  Annotation,
  AnnotationGroup,
  Layer,
  LayerGroup,
  PhotoGroup,
  useViewer,
} from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Graph } from '@skand/math';
import { Button, Menu, MenuItem, toast } from '@skand/ui';
import { Model3D, ModelNode, SceneNode } from '@skand/viewer-component-v2';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { MoreMenu } from '../MoreMenu';
import { ListItemButton } from './ListItemButton';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export interface LayerNodeProps {
  layer: Layer;
  setLayerSettingsId: (id: Layer['id'] | null) => void;
  setLayerClippingId: (id: Layer['id'] | null) => void;
  setLinkLayerId: (group: Layer['id'] | null) => void;
  clippingTargetId: Layer['id'] | null;
  settingsTargetId: Layer['id'] | AnnotationGroup['id'] | PhotoGroup['id'] | null;
  selected: boolean;
  setEnabledEditNodeId: (id: string) => void;
  enabledEditNodeId: string;
}

export const LayerNode = ({
  layer,
  setLayerSettingsId,
  setLayerClippingId,
  setLinkLayerId,
  clippingTargetId,
  settingsTargetId,
  selected,
  setEnabledEditNodeId,
  enabledEditNodeId,
}: LayerNodeProps) => {
  const projectId = useExplore(state => state.projectId);
  const api3D = useViewer(state => state.api3D);
  const layers = useViewer(state => state.layers);
  const layerGroups = useViewer(state => state.layerGroups);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const enableGlobeUI = useViewer(state => state.enableGlobeUI);
  const visibleLayers = useViewer(state => state.visibleLayers);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const pinnedLayers = useViewer(state => state.pinnedLayers);

  const { getSceneEntityPermission } = useFetchSceneEntityPermissions();
  const permission = getSceneEntityPermission(layer.sceneEntityId);
  const canEdit = canPolicyActionEdit(permission);

  const [editInput, setEditInput] = useState(layer.name);

  // Compute the all layers rooted at this group
  const [members, annotations] = useMemo(() => {
    // Construct the graph
    const entities = [...layerGroups, ...layers, ...annotationGroups];
    const graph = new Graph<Layer | LayerGroup | AnnotationGroup>();
    for (const entity of entities) {
      graph.addNode(entity);
      if (entity.parent) {
        graph.addEdge(entity.parent, entity);
      }
    }

    // Traverse the graph
    const members: Layer[] = [];
    const annotations: Annotation[] = [];

    graph.bfs(layer, node => {
      switch (node.type) {
        case 'layer':
          members.push(node);
          break;
        case 'annotationGroup':
          annotations.push(...node.annotations);
          break;
      }
    });

    return [members, annotations];
  }, [layerGroups, layers, annotationGroups, layer]);

  const isVisible =
    members.some(layer => visibleLayers.has(layer.id)) ||
    annotations.some(annotation => visibleAnnotations.has(annotation.id));
  const disabledToggle =
    (layer.config.type === 'imagery' || layer.config.type === 'terrain') && !enableGlobeUI;
  const disabledClipping = !(
    layer.sceneNode instanceof ModelNode && layer.sceneNode.getModel() instanceof Model3D
  );

  // Update layer mutation
  const updateLayer = useMutation({
    mutationFn: (variables: RenameSceneEntityMutationVariables) =>
      request(RENAME_SCENE_ENTITY, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully updated layer node.',
        lifespan: 5000,
      });
    },
  });

  // Handle keyboard input while renaming the layer
  const handleInputKeypress = (key: string) => {
    if (key === 'Enter') {
      handleSubmitName();
    }
    if (key === 'Escape') {
      setEnabledEditNodeId('');
    }
  };

  // Handle renaming the layer
  const handleSubmitName = async () => {
    if (!projectId) return;
    await updateLayer.mutateAsync({
      sceneEntityId: layer.sceneEntityId,
      projectId,
      name: editInput,
    });
    setEnabledEditNodeId('');
  };

  // Handle toggling the layer and its descendants
  const handleToggle = () => {
    if (isVisible) {
      useViewer.setState(prev => {
        const visibleLayers = new Set(prev.visibleLayers);
        const visibleAnnotations = new Set(prev.visibleAnnotations);
        for (const layer of members) {
          visibleLayers.delete(layer.id);
        }
        for (const annotation of annotations) {
          visibleAnnotations.delete(annotation.id);
        }
        return { visibleLayers, visibleAnnotations };
      });
    } else {
      useViewer.setState(prev => {
        const visibleLayers = new Set(prev.visibleLayers);
        const visibleAnnotations = new Set(prev.visibleAnnotations);
        for (const layer of members) {
          visibleLayers.add(layer.id);
        }
        for (const annotation of annotations) {
          visibleAnnotations.add(annotation.id);
        }
        return { visibleLayers, visibleAnnotations };
      });
    }
  };

  // Handle flying to the layer
  const handleFlyTo = () => {
    if (
      api3D &&
      !(layer.sceneNode instanceof SceneNode && !(layer.sceneNode instanceof ModelNode))
    ) {
      api3D.navigation.lookAt(layer.sceneNode);
    }
  };

  // Handle only showing the layer
  const handleShowOnly = () => {
    useViewer.setState({ visibleLayers: new Set([layer.id]) });
  };

  const iconName = useMemo(() => {
    switch (layer.formatType) {
      case LAYER_FORMAT_TYPE.IFC:
        return 'i-skand-ifc';
      case LAYER_FORMAT_TYPE.OBJ:
        return 'i-skand-obj';
      case LAYER_FORMAT_TYPE.POINT_CLOUD:
        return 'i-skand-pointcloud';
      case LAYER_FORMAT_TYPE.MESH_3D:
        return 'i-skand-mesh3d';
      case LAYER_FORMAT_TYPE.ORTHO_2D:
      case LAYER_FORMAT_TYPE.TERRAIN:
        return 'i-skand-ortho';
      case LAYER_FORMAT_TYPE.IMAGES:
        return 'i-skand-image';
      case LAYER_FORMAT_TYPE.DXF:
        return 'i-skand-dxf';
      case LAYER_FORMAT_TYPE.BIM_CAD_MODEL:
        return 'i-skand-bim';
    }
  }, [layer.formatType]);

  const handlePinLayerNode = async () => {
    const updatedPinnedLayers = new Set<string>(pinnedLayers);
    updatedPinnedLayers.add(layer.id);
    useViewer.setState({ pinnedLayers: updatedPinnedLayers });
  };

  const handleUnpinLayerNode = async () => {
    const updatedPinnedLayers = new Set<string>(pinnedLayers);
    updatedPinnedLayers.delete(layer.id);
    useViewer.setState({ pinnedLayers: updatedPinnedLayers });
  };

  return (
    <>
      {enabledEditNodeId === layer.id ? (
        <div className="flex flex-1">
          <input
            className={cn(
              'px-1',
              'color-neutral-800',
              'typo-text-s-em',
              'rounded',
              'border-1',
              'border-solid',
              'border-primary-400',
              'outline-none',
              'w-full',
            )}
            onChange={e => setEditInput(e.target.value)}
            onKeyDown={e => handleInputKeypress(e.key)}
            value={editInput}
          />
          <Button className="ml-1" filled onClick={handleSubmitName} primary size="xs">
            Save
          </Button>
        </div>
      ) : (
        <>
          <div className={cn('w-5 color-neutral-600 text-3 flex-none', iconName)} />
          <p
            className={cn(
              'group',
              'cursor-pointer',
              'flex-1',
              'whitespace-nowrap',
              selected ? 'typo-text-small-em text-neutral-800' : 'typo-text-small text-neutral-600',
            )}
            onDoubleClick={() => {
              if (canEdit) {
                setEditInput(layer.name);
                setEnabledEditNodeId(layer.id);
              }
            }}
            title={layer.name}
          >
            {layer.name}
          </p>
        </>
      )}

      <div className="w-112px" />
      <div
        className="fixed right-0 h-32px flex flex-none items-center gap-2 bg-neutral-100 pl-2"
        style={{ boxShadow: '-8px 0px 8px -2px rgba(255,255,255,1)' }}
      >
        <ListItemButton
          active={layer.id === clippingTargetId}
          analyticsEventObject={ANALYTICS_EVENT_OBJECT.CLIPPING_TOOL}
          disabled={disabledClipping}
          icon={<ClippingToolIcon />}
          onClick={() => setLayerClippingId(layer.id === clippingTargetId ? null : layer.id)}
        />
        <ListItemButton
          analyticsEventObject={ANALYTICS_EVENT_OBJECT.FLY_TO_LAYER}
          disabled={layer.sceneNode.constructor === SceneNode || enabledPanoramaWalkthrough}
          icon={<div className="i-skand-flyto" />}
          onClick={handleFlyTo}
        />
        <ListItemButton
          active={layer.id === settingsTargetId}
          analyticsEventObject={ANALYTICS_EVENT_OBJECT.LAYERING_TOOL}
          disabled={
            layer.sceneNode.constructor === SceneNode ||
            layer.formatType === LAYER_FORMAT_TYPE.ORTHO_2D ||
            layer.formatType === LAYER_FORMAT_TYPE.TERRAIN
          }
          icon={<div className="i-skand-gear" />}
          onClick={() => setLayerSettingsId(layer.id === settingsTargetId ? null : layer.id)}
        />
        <ListItemButton
          disabled={disabledToggle}
          icon={<div className={cn('i-skand-eye', isVisible ? 'i-skand-show' : 'i-skand-hide')} />}
          onClick={handleToggle}
        />
        <MoreMenu className="cursor-pointer">
          <Menu className="z-2">
            {permission === ACTION_TYPE.ADMIN && (
              <MenuItem className="cursor-pointer" onClick={() => setLinkLayerId(layer.id)}>
                Link to layer
              </MenuItem>
            )}
            <MenuItem className="cursor-pointer" onClick={handleShowOnly}>
              Show only
            </MenuItem>
            {pinnedLayers.has(layer.id) ? (
              <MenuItem className="cursor-pointer" onClick={handleUnpinLayerNode}>
                Unpin
              </MenuItem>
            ) : (
              <MenuItem className="cursor-pointer" onClick={handlePinLayerNode}>
                Pin
              </MenuItem>
            )}
          </Menu>
        </MoreMenu>
      </div>
    </>
  );
};
