import { Model, ModelConfiguration, ViewerAPI } from '@skand/viewer-component-v2';

class ModelCache {
  private cache: Map<string, Model> = new Map();
  private loading: Set<string> = new Set();

  /**
   * Check if a model is currently loading.
   *
   * @param key
   * @returns
   */
  isLoading(key: string): boolean {
    return this.loading.has(key);
  }

  /**
   * Get the model from the cache.
   *
   * @param key
   * @returns
   */
  get(key: string): Model | undefined {
    return this.cache.get(key);
  }

  /**
   * Load a model from the given configuration.
   *
   * @param config
   * @returns
   */
  async load(key: string, api3D: ViewerAPI, config: ModelConfiguration): Promise<Model> {
    // Get existing model
    let model = this.get(key);
    if (model) {
      return model;
    }

    try {
      this.loading.add(key);
      model = await api3D.model.create(config);
      this.cache.set(key, model);
      return model;
    } finally {
      this.loading.delete(key);
    }
  }
}

export const modelCache = new ModelCache();
