import { checkAuth } from '@/utils/auth';
import { getShareLinkToken } from '@/utils/shareLink';
import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

export interface Props {
  fallback: string;
  children?: ReactNode;
}

export const PrivateRoute = ({ fallback, children }: Props) => {
  const isAuthorized = checkAuth();
  const hasShareLinkToken = getShareLinkToken();

  if (!isAuthorized && !hasShareLinkToken) {
    return <Redirect to={fallback} />;
  }

  return children;
};
