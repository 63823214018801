export const PanoGroupIcon = ({ className }: React.SVGAttributes<React.ReactSVGElement>) => {
  return (
    <svg
      className={className}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4955 3.35247V1.02503C2.22206 1.30032 1.40479 1.72075 1.40479 2.19125C1.40479 3.01211 3.90889 3.69282 7.0022 3.69282C10.0955 3.69282 12.5996 3.02712 12.5996 2.19125C12.5996 1.69072 11.7301 1.27529 10.3854 1V3.3775"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5947 2.19141V7.58708C12.5947 8.41294 10.0859 9.08865 6.99732 9.08865C3.90876 9.08865 1.3999 8.42295 1.3999 7.58708V2.19141"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5947 9.5V9.79294C12.5947 10.6188 10.0859 11.2945 6.99732 11.2945C3.90876 11.2945 1.3999 10.6288 1.3999 9.79294V9.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        d="M12.5947 11.2051V11.498C12.5947 12.3239 10.0859 12.9996 6.99732 12.9996C3.90876 12.9996 1.3999 12.3339 1.3999 11.498V11.2051"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        d="M3.07227 8.65309L5.75218 5.18945L7.55945 8.83968"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3755 8.77806L7.96639 5.63477L6.80225 7.01622"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9247 5.77962C10.2002 5.77962 10.4236 5.54432 10.4236 5.25407C10.4236 4.96381 10.2002 4.72852 9.9247 4.72852C9.64916 4.72852 9.42578 4.96381 9.42578 5.25407C9.42578 5.54432 9.64916 5.77962 9.9247 5.77962Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
    </svg>
  );
};
