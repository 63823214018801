import { Checkbox } from '@/components/Checkbox';
import { DropdownSelector, ItemProps } from '@/components/DropdownSelector';
import { ColorPickerMenu } from '@/components/ColorPickerMenu';
import { RadioButton } from '@/components/RadioButton';
import { EXPLORE_ROOT } from '@/constants/paths';
import { canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import { UpdateShareLinkMutationVariables } from '@/graphql/codegen/graphql';
import { UPDATE_SHARE_LINK } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchProjectPermissions } from '@/hooks/useFetchProjectPermissions';
import { useFetchShareLinkPermissions } from '@/hooks/useFetchShareLinkPermissions';
import { useFetchShareLinks } from '@/hooks/useFetchShareLinks';
import { useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import { setSelectedShareLink, useShareLink } from '@/stores/shareLink';
import { addViewerEventListener, removeViewerEventListener, useViewer } from '@/stores/viewer';
import { ExploreState, persist } from '@/utils/Persist';
import { cn } from '@/utils/classname';
import { Button, Input, Switch } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Quaternion, Vector3 } from 'three';
import { isEmpty } from '../../../utils/empty';
import { EPSG } from '@skand/data-3d-loader';
import { epsgCodeList } from '@/pages/ExplorePage/Viewer/Viewer3D/Overlay/InfoBar/epsgCodeList';
import { useTreatments } from '@splitsoftware/splitio-react';
import { GOOGLE_EARTH_USER, GOOGLE_EARTH_ACCOUNT } from '@/utils/split';
import { CameraMotionCallback } from '@skand/viewer-component-v2';

export const ShareLinkSettingsPanel = () => {
  const treatment = useTreatments([GOOGLE_EARTH_USER, GOOGLE_EARTH_ACCOUNT]);
  const googleEarthGlobeFlag =
    treatment[GOOGLE_EARTH_USER].treatment === 'on' ||
    treatment[GOOGLE_EARTH_ACCOUNT].treatment === 'on';

  const projectId = useExplore(state => state.projectId);
  const epsg = useExplore(state => state.epsg);

  const selectedShareLink = useShareLink(state => state.selectedShareLink);
  const { shareLinks } = useFetchShareLinks();

  const api3D = useViewer(state => state.api3D);
  const visibleLayers = useViewer(state => state.visibleLayers);
  const viewerSettings = useViewer(state => state.viewer3DSettings);
  const projection = useViewer(state => state.projectionMode);
  const defaultGlobeMode = useViewer(state => state.globeMode);
  const defaultBackgroundColor = viewerSettings.backgroundColor;
  const defaultPanoramaIconSize = viewerSettings.panoramaIconSize;

  const [shareCameraTransform, setShareCameraTransform] = useState(true);
  const [shareSelectedLayer, setShareSelectedLayer] = useState(true);
  const [globeMode, setGlobeMode] = useState(defaultGlobeMode);
  const [backgroundColor, setBackgroundColor] = useState(defaultBackgroundColor);
  const [panoramaIconSize, setPanoramaIconSize] = useState(defaultPanoramaIconSize);
  const [cameraPosition, setCameraPosition] = useState(
    api3D?.navigation.getPosition() ?? new Vector3(),
  );
  const [cameraRotation, setCameraRotation] = useState(
    api3D?.navigation.getRotation() ?? new Quaternion(),
  );
  const [orthoMatrix, setOrthoMatrix] = useState(api3D?.navigation.getOrthoMatrixParams() ?? []);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const setLeftSideBarII = useLayout(state => state.setLeftSideBarII);
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);

  const [copied, setCopied] = useState(false);
  const [enableEPSGMenu, setEnableEPSGMenu] = useState(false);
  const [currentEpsg, setCurrentEpsg] = useState(epsg);

  const { getProjectPermission } = useFetchProjectPermissions();
  const permission = getProjectPermission(projectId);
  const canEdit = canPolicyActionEdit(permission);

  const epsgString = useMemo(() => {
    const code = currentEpsg.split(':')[1];
    return epsgCodeList.find(key => key.value === code)?.label || '';
  }, [currentEpsg]);

  useEffect(() => {
    if (!isEmpty(selectedShareLink)) {
      const updatedShareLink = shareLinks.find(sharelink => sharelink.id === selectedShareLink?.id);
      if (!isEmpty(updatedShareLink)) {
        setSelectedShareLink(updatedShareLink);
      }
    }
  }, [shareLinks, selectedShareLink]);

  // Listen for changes to the camera position and rotation
  useEffect(() => {
    const cb: CameraMotionCallback = (position, rotation, params) => {
      setCameraPosition(position);
      setCameraRotation(rotation);
      setOrthoMatrix(params);
    };
    addViewerEventListener('onCameraMotion', cb);
    return () => removeViewerEventListener('onCameraMotion', cb);
  }, [api3D?.navigation]);

  const updateShareLink = useMutation({
    mutationFn: (variables: UpdateShareLinkMutationVariables) => {
      return request(UPDATE_SHARE_LINK, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
      if (!isEmpty(selectedShareLink)) {
        queryClient.invalidateQueries(
          useFetchShareLinkPermissions.getQueryKey(selectedShareLink.id),
        );
      }
    },
  });

  // Create the URL string
  const url = useMemo(() => {
    const base = window.location.origin + EXPLORE_ROOT;
    const params: [keyof ExploreState, string][] = [
      ['project', projectId as string],
      ['shareLinkToken', selectedShareLink?.shareToken as string],
      ['globe', globeMode],
      ['panoramaIconSize', panoramaIconSize.toString()],
      ['backgroundColor', backgroundColor.getHexString()],
      ['srs', currentEpsg.toString()],
    ];
    if (shareCameraTransform && api3D) {
      params.push(['cameraPosition', JSON.stringify(cameraPosition.toArray())]);
      params.push(['cameraRotation', JSON.stringify(cameraRotation.toArray())]);
      params.push(['orthoMatrix', JSON.stringify(orthoMatrix)]);
      params.push(['projection', projection]);
    }
    if (shareSelectedLayer) {
      params.push(['layers', JSON.stringify([...visibleLayers.values()])]);
    }

    const imageShareId = persist.get('image');
    if (imageShareId) {
      params.push(['image', imageShareId]);
    }

    const annotationShareId = persist.get('annotation');
    if (annotationShareId) {
      params.push(['annotation', annotationShareId]);
    }
    return `${base}?${params.map(([k, v]) => k + '=' + v).join('&')}`;
  }, [
    projectId,
    selectedShareLink,
    globeMode,
    panoramaIconSize,
    shareCameraTransform,
    api3D,
    shareSelectedLayer,
    backgroundColor,
    cameraPosition,
    cameraRotation,
    orthoMatrix,
    projection,
    visibleLayers,
    currentEpsg,
  ]);

  // Handle copying the final URL
  const handleCopy = async () => {
    await navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  // Handle cancelling
  const handleCancel = () => {
    setLeftSideBarII('');
    hideLeftSideBarII();
    setSelectedShareLink(null);
  };

  // Handle previewing the generated URL
  const handlePreview = () => {
    window.open(url);
  };

  const handleEPSGClick = (item: ItemProps) => {
    setCurrentEpsg(`EPSG:${item.value}` as EPSG);
    setEnableEPSGMenu(false);
  };

  const toggleEPSGMenu = () => {
    setEnableEPSGMenu(prev => !prev);
  };

  const handleToggleActive = async () => {
    if (!isEmpty(selectedShareLink)) {
      if (selectedShareLink.active) {
        updateShareLink.mutate({ shareLinkId: selectedShareLink.id, isActivated: false });
      } else {
        updateShareLink.mutate({ shareLinkId: selectedShareLink.id, isActivated: true });
      }
    }
  };

  return (
    <div className={cn('relative', 'h-full', 'flex', 'flex-col', 'overflow-y-auto')}>
      <div
        className={cn(
          'flex',
          'justify-between',
          'items-end',
          'border-neutral-500',
          'border-1',
          'border-b-solid',
          'pb-3',
          'gap-3',
        )}
      >
        <p className={cn('typo-heading-4', 'color-neutral-800')}>Share link settings</p>
      </div>

      <div className={cn('flex gap-2 mt-3')}>
        <div className={cn('w-full')}>
          <Input
            disabled
            tail={<div className={cn('i-skand-link', 'text-neutral-400', 'text-4')} />}
            value={url}
          />
        </div>
        <Button className={cn('w-20')} filled onClick={handleCopy} primary={!copied} size="s">
          {!copied ? 'Copy' : 'Copied'}
        </Button>
      </div>

      <div
        className={cn(
          'flex',
          'flex-row',
          'rounded-8px',
          'border-solid',
          'border-neutral-400',
          'border-1px',
          'p-12px',
          'mt-4',
          'justify-between',
          'items-center',
        )}
      >
        <p className={cn('typo-text-small-em', 'color-neutral-800')}>Status</p>
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center')}>
          <Switch
            checked={!isEmpty(selectedShareLink) && selectedShareLink.active}
            disabled={!canEdit}
            onChange={handleToggleActive}
          />
          <div className={cn('w-12', 'flex', 'justify-end')}>
            <p className={cn('typo-text-small', 'color-neutral-800')}>
              {selectedShareLink?.active ? 'Active' : 'Inactive'}
            </p>
          </div>
        </div>
      </div>

      <div className={cn('flex', 'flex-col', 'w-full', 'py-3', 'overflow-y-auto')}>
        <p className={cn('typo-text-medium', 'color-neutral-800')}>Customise settings</p>

        <div className="h-full">
          <div
            className={cn(
              'mt-4',
              'flex',
              'flex-col',
              'rounded-8px',
              'border-solid',
              'border-neutral-400',
              'border-1px',
              'p-12px',
            )}
          >
            <p className={cn('typo-text-s-em', 'color-neutral-800')}>Settings</p>

            <div className={cn('flex', 'flex-row', 'items-center', 'mt-3')}>
              <Checkbox
                checked={shareCameraTransform}
                setToggleCheckbox={() => setShareCameraTransform(!shareCameraTransform)}
              />
              <p className={cn('typo-text-small', 'color-neutral-800', 'ml-2')}>
                Share with current camera position and orientation
              </p>
            </div>
            <div className={cn('flex', 'flex-row', 'items-center', 'mt-3')}>
              <Checkbox
                checked={shareSelectedLayer}
                setToggleCheckbox={() => setShareSelectedLayer(!shareSelectedLayer)}
              />
              <p className={cn('typo-text-small', 'color-neutral-800', 'ml-2')}>
                Enable selected layer/s by default on link access
              </p>
            </div>
          </div>

          <div
            className={cn(
              'mt-4',
              'flex',
              'flex-col',
              'rounded-8px',
              'border-solid',
              'border-neutral-400',
              'border-1px',
              'p-12px',
            )}
          >
            <p className={cn('typo-text-s-em', 'color-neutral-800')}>Default coordinate system</p>
            <div
              className={cn(
                'relative',
                'w-340px',
                'rounded-4px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'h-36px',
                'mt-12px',
                'flex items-center',
                'cursor-pointer',
                'justify-between',
              )}
              onClick={toggleEPSGMenu}
            >
              <p className={cn('text-neutral-600 typo-text-small pl-12px')}>{epsgString}</p>
              <div
                className={cn(
                  'i-skand-dropdownarrow',
                  'text-neutral-600',
                  'text-3',
                  'cursor-pointer',
                  'mr-12px',
                )}
              />
            </div>
            {enableEPSGMenu && (
              <div className={cn('relative')}>
                <DropdownSelector
                  containerStyles={cn(
                    'top-0',
                    'absolute',
                    '!bg-neutral-100',
                    'rounded-4px',
                    'border-solid',
                    'border-neutral-400',
                    'border-1px',
                    'p-2',
                    'w-340px',
                  )}
                  enableSearch
                  itemStyles={cn('text-neutral-700')}
                  items={epsgCodeList}
                  onItemClick={handleEPSGClick}
                />
              </div>
            )}
          </div>

          <div
            className={cn(
              'flex',
              'flex-col',
              'rounded-8px',
              'border-solid',
              'border-neutral-400',
              'border-1px',
              'p-12px',
              'mt-2',
            )}
          >
            <p className={cn('typo-text-s-em', 'color-neutral-800')}>Map State</p>

            <div className={cn('flex', 'flex-col', 'items-start', 'mt-3')}>
              <RadioButton
                checked={globeMode === 'default'}
                id="default"
                label="Default"
                onChange={() => setGlobeMode('default')}
                value={'default'}
              />
              <RadioButton
                checked={globeMode === 'imagery'}
                id="imagery"
                label="2D aerial map"
                onChange={() => setGlobeMode('imagery')}
                value={'imagery'}
              />
              <RadioButton
                checked={globeMode === 'terrain'}
                id="terrain"
                label="3D terrain"
                onChange={() => setGlobeMode('terrain')}
                value={'terrain'}
              />
              {googleEarthGlobeFlag && (
                <RadioButton
                  checked={globeMode === 'google'}
                  id="google"
                  label="Google Earth Globe"
                  onChange={() => setGlobeMode('google')}
                  value={'google'}
                />
              )}
            </div>
          </div>

          <div
            className={cn(
              'flex',
              'flex-col',
              'rounded-8px',
              'border-solid',
              'border-neutral-400',
              'border-1px',
              'p-12px',
              'mt-2',
            )}
          >
            <p className={cn('typo-text-s-em', 'color-neutral-800')}>Map background colour</p>

            <div className={cn('flex ml-4px mt-3')}>
              <button
                className={cn(
                  'bg-contain',
                  'h-60px w-60px',
                  'mt-1',
                  'rounded-1',
                  'b-1px b-solid b-neutral-400',
                )}
                onClick={() => setShowColorPicker(!showColorPicker)}
                style={{ backgroundColor: `#${backgroundColor.getHexString()}` }}
              />
            </div>

            {showColorPicker && (
              <ColorPickerMenu
                closeMenu={() => setShowColorPicker(false)}
                color={backgroundColor}
                selectColor={setBackgroundColor}
                x={0}
                y={100}
              />
            )}
          </div>

          <div
            className={cn(
              'flex',
              'flex-col',
              'rounded-8px',
              'border-solid',
              'border-neutral-400',
              'border-1px',
              'p-12px',
              'mt-2',
            )}
          >
            <p className={cn('typo-text-s-em', 'color-neutral-800')}>Panorama icon size</p>

            <div className={cn('flex', 'flex-col', 'items-start', 'mt-3')}>
              <Input
                onChange={value => setPanoramaIconSize(parseFloat(value))}
                type="number"
                value={panoramaIconSize}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={cn('flex', 'flex-1', 'flex-col', 'justify-end')}>
        <div
          className={cn('flex', 'flex-row', 'gap-3', 'justify-between', 'items-center', 'mt-16px')}
        >
          <Button className={cn('w-full')} onClick={handleCancel} size="s">
            Close
          </Button>
          <Button className={cn('w-full')} filled onClick={handlePreview} primary size="s">
            Preview
          </Button>
        </div>
      </div>
    </div>
  );
};
