import { AnnotationFieldType, DND_ITEM_TYPE } from '@/constants/annotation';
import { addField } from '@/stores/template';
import { cn } from '@/utils/classname';
import { Button } from '@skand/ui';
import { HTMLAttributes } from 'react';
import { useDrag } from 'react-dnd';

const FIELD_TYPE_MAP = {
  FILE: {
    name: 'Attachment',
    icon: 'i-skand-add text-3',
    addField: () => addField('FILE'),
  },
  DATE: {
    name: 'Date',
    icon: 'i-skand-date text-3',
    addField: () => addField('DATE'),
  },
  SELECT: {
    name: 'Dropdown',
    icon: 'i-skand-dropdown text-3',
    addField: () => addField('SELECT'),
  },
  URL: {
    name: 'Link',
    icon: 'i-skand-link text-4',
    addField: () => addField('URL'),
  },
  TEXT: {
    name: 'Text',
    icon: 'i-skand-textfield text-3',
    addField: () => addField('TEXT'),
  },
  IMAGE: {
    name: 'Image',
    icon: 'i-skand-image text-3',
    addField: () => addField('IMAGE'),
  },
};

export interface ChipProps extends HTMLAttributes<HTMLButtonElement> {
  type: AnnotationFieldType;
}

export const Chip = ({ type, className, ...props }: ChipProps) => {
  const { name, icon, addField } = FIELD_TYPE_MAP[type];

  const [, drag] = useDrag(() => ({
    type: DND_ITEM_TYPE.CHIP,
    item: { type },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) addField();
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <Button
      className={cn(
        'flex items-center gap-3',
        'px-3 py-2',
        'rounded-2',
        'cursor-pointer',
        className,
      )}
      onClick={addField}
      ref={drag}
      size="s"
      {...props}
    >
      {name}
      <div className={cn('color-neutral-600', icon)} />
    </Button>
  );
};
