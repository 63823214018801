import { Project, ProjectGroup } from '@/utils/project';
import { create } from 'zustand';

interface ProjectState {
  table: {
    foldState: Record<string | symbol, boolean>;
    search: string;
  };

  createGroup: {
    /** CreateGroup selected project ids */
    projectIds: string[];
  };

  editGroup: {
    group: null | ProjectGroup;
  };

  editProject: {
    project: null | Project;
  };

  moveProject: {
    project: null | Project;
  };

  moveMultipleProjects: {
    projects: string[];
  };
}

export const useProjectStore = create<ProjectState>()(() => ({
  table: {
    foldState: {},
    search: '',
  },

  createGroup: {
    projectIds: [],
  },

  editGroup: { group: null },

  editProject: { project: null },

  moveProject: { project: null },

  moveMultipleProjects: { projects: [] },
}));

export const expandGroup = (groupId: string | symbol) => {
  useProjectStore.setState(state => {
    return {
      table: {
        ...state.table,
        foldState: {
          ...state.table.foldState,
          [groupId]: false,
        },
      },
    };
  });
};

export const searchGroupAndProject = (search: string) => {
  useProjectStore.setState(state => {
    return {
      table: {
        ...state.table,
        search,
      },
    };
  });
};

export const foldGroup = (groupId: string | symbol) => {
  useProjectStore.setState(state => {
    return {
      table: {
        ...state.table,
        foldState: {
          ...state.table.foldState,
          [groupId]: true,
        },
      },
    };
  });
};

export const unFoldGroup = (groupId: string | symbol) => {
  useProjectStore.setState(state => {
    return {
      table: {
        ...state.table,
        foldState: {
          ...state.table.foldState,
          [groupId]: false,
        },
      },
    };
  });
};

export const toggleGroup = (groupId: string | symbol) => {
  useProjectStore.setState(state => {
    return {
      table: {
        ...state.table,
        foldState: {
          ...state.table.foldState,
          [groupId]: !state.table.foldState[groupId],
        },
      },
    };
  });
};

export const selectCreateGroupProject = (projectId: string) => {
  useProjectStore.setState(state => {
    return {
      createGroup: {
        ...state.createGroup,
        projectIds: [...state.createGroup.projectIds, projectId],
      },
    };
  });
};

export const deselectCreateGroupProject = (projectId: string) => {
  useProjectStore.setState(state => {
    return {
      createGroup: {
        ...state.createGroup,
        projectIds: state.createGroup.projectIds.filter(id => id !== projectId),
      },
    };
  });
};

export const toggleCreateGroupProject = (projectId: string) => {
  useProjectStore.setState(state => {
    const selected = state.createGroup.projectIds.includes(projectId);

    if (selected) {
      return {
        createGroup: {
          ...state.createGroup,
          projectIds: state.createGroup.projectIds.filter(id => id !== projectId),
        },
      };
    }

    return {
      createGroup: {
        ...state.createGroup,
        projectIds: [...state.createGroup.projectIds, projectId],
      },
    };
  });
};

export const setEditGroupGroup = (group: null | SolidId<ProjectGroup>) => {
  useProjectStore.setState(state => {
    return {
      editGroup: { ...state.editGroup, group },
    };
  });
};

export const closeEditGroup = () => setEditGroupGroup(null);
export const closeEditProject = () => setEditProjectProject(null);

export const setEditProjectProject = (project: null | SolidId<Project>) => {
  useProjectStore.setState(state => {
    return {
      editProject: { ...state.editProject, project },
    };
  });
};

export const setMoveProjectProject = (project: null | SolidId<Project>) => {
  useProjectStore.setState(state => {
    return {
      moveProject: { ...state.moveProject, project },
    };
  });
};

export const setMoveMultipleProjectsProjects = (projects: string[]) => {
  useProjectStore.setState(state => {
    return {
      moveMultipleProjects: { ...state.moveMultipleProjects, projects },
    };
  });
};
