import { Menu, MenuItem } from '@skand/ui';
import { SortKey } from './TemplateTab';

export const SortByModal = ({
  setSortKey,
  setIsSortByModalOpen,
}: {
  setSortKey: (key: SortKey) => void;
  setIsSortByModalOpen: (isOpen: boolean) => void;
}) => {
  return (
    <Menu className="absolute right-0 top-5 z1 w-28">
      <MenuItem
        className="cursor-pointer"
        onClick={() => {
          setSortKey('name');
          setIsSortByModalOpen(false);
        }}
        size="s"
      >
        NAME
      </MenuItem>
      <MenuItem
        className="cursor-pointer"
        onClick={() => {
          setSortKey('createdAt');
          setIsSortByModalOpen(false);
        }}
        size="s"
      >
        DATE CREATED
      </MenuItem>
    </Menu>
  );
};
