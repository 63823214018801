import { RadioButton } from '@/components/RadioButton';
import { setViewer3DSettings, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { RENDERER_STATISTICS_FLAG } from '@/utils/split';
import { CheckBox, Input, Switch } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';

export const SettingsMenu = () => {
  const treatment = useTreatments([RENDERER_STATISTICS_FLAG]);
  const statisticsTabFlag = treatment[RENDERER_STATISTICS_FLAG].treatment === 'on';

  const viewerSettings = useViewer(state => state.viewer3DSettings);

  return (
    <div
      className={cn(
        'w-full',
        'top-0',
        'absolute',
        'flex',
        'flex-col',
        'bg-neutral-100',
        'p-12px',
        'rounded-4px',
        'bg-neutral-100',
        'z-1',
      )}
    >
      <span className={cn('typo-text-medium', 'text-neutral-800')}>Viewer settings</span>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Tile memory budget</span>
        <div>
          <RadioButton
            checked={viewerSettings.tileMemoryBudget === 1024}
            id="tileBudget1"
            label="Low"
            onChange={() => setViewer3DSettings({ tileMemoryBudget: 1024 })}
            value={''}
          />
          <RadioButton
            checked={viewerSettings.tileMemoryBudget === 999999}
            id="tileBudget2"
            label="High"
            onChange={() => setViewer3DSettings({ tileMemoryBudget: 999999 })}
            value={''}
          />
        </div>
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>User local cache</span>
        <Switch
          checked={viewerSettings.localCacheEnabled}
          onChange={value => setViewer3DSettings({ localCacheEnabled: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Network request sorting</span>
        <Switch
          checked={viewerSettings.networkRequestSorting}
          onChange={value => setViewer3DSettings({ networkRequestSorting: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Tile request cancelling</span>
        <Switch
          checked={viewerSettings.tileRequestCancelling}
          onChange={value => setViewer3DSettings({ tileRequestCancelling: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800', 'mb-2')}>
          Max network requests
        </span>
        <div className={cn('w-64px')}>
          <Input
            min={1}
            onChange={(val: string) => setViewer3DSettings({ maxNetworkRequests: parseInt(val) })}
            step={1}
            type="number"
            value={viewerSettings.maxNetworkRequests}
          />
        </div>
      </div>
      <div className={cn('border-1px', 'border-solid', 'border-b-neutral-500', 'mt-12px')} />
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Near plane clipping</span>
        <Switch
          checked={viewerSettings.orthoNearPlaneClipping}
          onChange={value => setViewer3DSettings({ orthoNearPlaneClipping: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Eye dome lighting</span>
        <Switch
          checked={viewerSettings.eyeDomeLighting}
          onChange={value => setViewer3DSettings({ eyeDomeLighting: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Point size attenuation</span>
        <Switch
          checked={viewerSettings.pointSizeAttenuation}
          onChange={value => setViewer3DSettings({ pointSizeAttenuation: value })}
        />
      </div>
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Globe clipping</span>
        <Switch
          checked={viewerSettings.globeClipping}
          onChange={value => setViewer3DSettings({ globeClipping: value })}
        />
      </div>
      {statisticsTabFlag && (
        <>
          <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
            <span className={cn('typo-text-s-em', 'color-neutral-800')}>Show statistics</span>
            <Switch
              checked={viewerSettings.enabledStatistics}
              onChange={value => setViewer3DSettings({ enabledStatistics: value })}
            />
          </div>
          <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
            <span className={cn('typo-text-s-em', 'color-neutral-800')}>
              Show tile request priorities
            </span>
            <Switch
              checked={viewerSettings.showRequestPriorities}
              onChange={value => setViewer3DSettings({ showRequestPriorities: value })}
            />
          </div>
        </>
      )}
      <div className={cn('border-1px', 'border-solid', 'border-b-neutral-500', 'mt-12px')} />
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800', 'mb-2')}>
          Panorama icon size
        </span>
        <div className={cn('w-64px')}>
          <Input
            min={0}
            onChange={(val: string) => setViewer3DSettings({ panoramaIconSize: parseFloat(val) })}
            step={0.1}
            type="number"
            value={viewerSettings.panoramaIconSize}
          />
        </div>
      </div>
      <div className={cn('border-1px', 'border-solid', 'border-b-neutral-500', 'mt-12px')} />
      <div className={cn('flex', 'flex-row', 'justify-between', 'items-start', 'mt-12px')}>
        <span className={cn('typo-text-s-em', 'color-neutral-800')}>Label visibility</span>
        <div className={cn('flex', 'flex-col', 'items-start')}>
          <div className={cn('flex', 'flex-row', 'items-center')}>
            <CheckBox
              checked={viewerSettings.annotationNameVisibility}
              onChange={event =>
                setViewer3DSettings({ annotationNameVisibility: event.target.checked })
              }
            />
            <span className={cn('color-neutral-800', 'typo-text-s', 'ml-2')}>Name labels</span>
          </div>
          <div className={cn('flex', 'flex-row', 'items-center', 'mt-0.5')}>
            <CheckBox
              checked={viewerSettings.annotationMeasurementVisibility}
              onChange={event =>
                setViewer3DSettings({ annotationMeasurementVisibility: event.target.checked })
              }
            />
            <span className={cn('color-neutral-800', 'typo-text-s', 'ml-2')}>
              Measurement labels
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
