import { DropdownSelector, ItemProps } from '@/components/DropdownSelector';
import { ColorPickerMenu } from '@/components/ColorPickerMenu';
import { RadioButton } from '@/components/RadioButton';
import { Label } from '@/components/Label';
import { EXPLORE_ROOT } from '@/constants/paths';
import { canPolicyActionEdit } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import {
  PolicyObjectTypeInput,
  PolicyActionTypeInput,
  PolicySubjectTypeInput,
  UpdateShareLinkMutationVariables,
  PermissionPolicyInput,
  PermissionPolicyWithOutSubjectInput,
  CreateShareLinkMutationVariables,
} from '@/graphql/codegen/graphql';
import { CREATE_SHARE_LINK, UPDATE_SHARE_LINK } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchProjectPermissions } from '@/hooks/useFetchProjectPermissions';
import { useFetchShareLinkPermissions } from '@/hooks/useFetchShareLinkPermissions';
import { useFetchShareLinks } from '@/hooks/useFetchShareLinks';
import { useExplore } from '@/stores/explore';
import { setSelectedShareLink, useShareLink, Settings } from '@/stores/shareLink';
import {
  addViewerEventListener,
  removeViewerEventListener,
  useViewer,
  Photo,
  disableViewer2D,
  setImageViewerSettings,
  enableViewer2D,
} from '@/stores/viewer';
import { ExploreState, persist } from '@/utils/Persist';
import { cn } from '@/utils/classname';
import { Button, CheckBox, Input, Switch, Toast, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Quaternion, Vector3 } from 'three';
import { isEmpty } from '../../../utils/empty';
import { EPSG } from '@skand/data-3d-loader';
import { epsgCodeList } from '@/pages/ExplorePage/Viewer/Viewer3D/Overlay/InfoBar/epsgCodeList';
import { useTreatments } from '@splitsoftware/splitio-react';
import { GOOGLE_EARTH_USER, GOOGLE_EARTH_ACCOUNT } from '@/utils/split';
import { CameraMotionCallback } from '@skand/viewer-component-v2';
import { ShareLinkContent } from '../ShareLinkContent';
import { AnnotationSelector } from '../AnnotationSelector';
import { useAccount } from '@/hooks/useAccount';
import { Thumbnail } from '../ImageSelector';
import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import { getAllSceneEntityIds } from '@/utils/sceneTree';
import { uniq } from 'lodash-es';
import dayjs from 'dayjs';
import { useOnClickOutside } from '@/utils/useOnClickOutside';
import { IconButton } from '@/components/IconButton';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export const ShareLinkSettingsPanel = () => {
  const treatment = useTreatments([GOOGLE_EARTH_USER, GOOGLE_EARTH_ACCOUNT]);
  const googleEarthGlobeFlag =
    treatment[GOOGLE_EARTH_USER].treatment === 'on' ||
    treatment[GOOGLE_EARTH_ACCOUNT].treatment === 'on';

  const defaultAnnotationContentRef = useRef<HTMLInputElement>(null);
  const defaultImageContentRef = useRef<HTMLInputElement>(null);

  const enabledOutsideClick = useOnClickOutside(
    defaultAnnotationContentRef || defaultImageContentRef,
  );

  const projectId = useExplore(state => state.projectId);
  const selectedShareLink = useShareLink(state => state.selectedShareLink);
  const shareLinkTab = useShareLink(state => state.shareLinkTab);
  const shareLinkSettings = useShareLink(state => state.settings);
  const selectedAnnotationIdByLayers = useShareLink(state => state.selectedAnnotationIdByLayers);
  const selectedImageIdByLayers = useShareLink(state => state.selectedImageIdByLayers);
  const selectedImageIdByImageViewer = useShareLink(state => state.selectedImageIdByImageViewer);
  const visibleLayerIds = useShareLink(state => state.visibleLayerIds);

  const { shareLinks } = useFetchShareLinks();
  const permissionObjectIDs = useFetchShareLinkPermissions();

  const api3D = useViewer(state => state.api3D);
  const visibleLayers = useViewer(state => state.visibleLayers);
  const projection = useViewer(state => state.projectionMode);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const layerGroups = useViewer(state => state.layerGroups);
  const layers = useViewer(state => state.layers);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const enabled2D = useViewer(state => state.enabled2D);
  const viewerSettings = useViewer(state => state.viewer3DSettings);
  const defaultGlobeMode = useViewer(state => state.globeMode);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const defaultBaseMapType = useViewer(state => state.baseMapType);
  const defaultBackgroundColor = viewerSettings.backgroundColor;
  const defaultPanoramaIconSize = viewerSettings.panoramaIconSize;
  const defaultAnnotationMeasurementVisibility = viewerSettings.annotationMeasurementVisibility;
  const defaultAnnotationNameVisibility = viewerSettings.annotationNameVisibility;

  const accountId = useAccount().data?.accountByContext?.id;
  const epsg = useExplore(state => state.epsg);

  let cachedBackgroundColor;
  let cachedGlobeMode;
  let cachedCurrentEpsg;
  let cachedCameraTransform;
  let cachedPanoramaIconSize;
  let cachedBaseMapType;
  let cachedAnnotationMeasurementVisibility;
  let cachedAnnotationNameVisibility;

  if (selectedShareLink && selectedShareLink.id) {
    const settings = shareLinkSettings.get(selectedShareLink.id);
    cachedBackgroundColor = settings?.backgroundColor;
    cachedGlobeMode = settings?.globeMode;
    cachedCurrentEpsg = settings?.currentEpsg;
    cachedCameraTransform = settings?.shareCameraTransform;
    cachedPanoramaIconSize = settings?.panoramaIconSize;
    cachedBaseMapType = settings?.baseMapType;
    cachedAnnotationMeasurementVisibility = settings?.annotationMeasurementVisibility;
    cachedAnnotationNameVisibility = settings?.annotationNameVisibility;
  }

  const [cameraPosition, setCameraPosition] = useState(
    api3D?.navigation.getPosition() ?? new Vector3(),
  );
  const [cameraRotation, setCameraRotation] = useState(
    api3D?.navigation.getRotation() ?? new Quaternion(),
  );
  const [orthoMatrix, setOrthoMatrix] = useState(api3D?.navigation.getOrthoMatrixParams() ?? []);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedAnnotationId, setSelectedAnnotationId] = useState<string | null>();
  const [selectedImageId, setSelectedImageId] = useState<string | null>();
  const [selectedImageName, setSelectedImageName] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<Photo | null>(null);
  const [focusedAnnotationContent, setFocusedAnnotationContent] = useState<boolean>(
    selectedAnnotationIdByLayers !== null,
  );
  const [focusedImageContent, setFocusedImageContent] = useState<boolean>(
    selectedImageIdByLayers !== null,
  );
  const [enabledInfoToolTip, setEnabledInfoToolTip] = useState(false);

  const [copied, setCopied] = useState(false);
  const [googleEarthTooltip, setGoogleEarthTooltip] = useState(false);
  const [enableEPSGMenu, setEnableEPSGMenu] = useState(false);
  const [name, setName] = useState(selectedShareLink?.name ?? '');
  const [sceneEntityIds, setSceneEntityIds] = useState<string[]>([]);

  const [enabledAnnotationSelector, setEnabledAnnotationSelector] = useState(false);
  const [enabledEmptyImagesTooltip, setEnabledEmptyImagesTooltip] = useState(false);
  const [enabledEmptyAnnoTooltip, setEnabledEmptyAnnoTooltip] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState(
    cachedBackgroundColor ? cachedBackgroundColor : defaultBackgroundColor,
  );
  const [panoramaIconSize, setPanoramaIconSize] = useState(
    cachedPanoramaIconSize ? cachedPanoramaIconSize : defaultPanoramaIconSize,
  );
  const [globeMode, setGlobeMode] = useState(cachedGlobeMode ? cachedGlobeMode : defaultGlobeMode);
  const [currentEpsg, setCurrentEpsg] = useState(cachedCurrentEpsg ? cachedCurrentEpsg : epsg);
  const [baseMapType, setBaseMapType] = useState(
    cachedBaseMapType ? cachedBaseMapType : defaultBaseMapType,
  );
  const [annotationNameVisibility, setAnnotationNameVisibility] = useState(
    cachedAnnotationNameVisibility
      ? cachedAnnotationNameVisibility
      : defaultAnnotationNameVisibility,
  );
  const [annotationMeasurementVisibility, setAnnotationMeasurementVisibility] = useState(
    cachedAnnotationMeasurementVisibility
      ? cachedAnnotationMeasurementVisibility
      : defaultAnnotationMeasurementVisibility,
  );

  const [shareCameraTransform, setShareCameraTransform] = useState<boolean>(
    cachedCameraTransform ? cachedCameraTransform : true,
  );

  const { getProjectPermission } = useFetchProjectPermissions();
  const fetchSelectedImageUrl = useFetchImageUrls(selectedImageId ? [selectedImageId] : [], false);
  const fetchSelectedImageThumbnailUrl = useFetchImageUrls(
    selectedImageId ? [selectedImageId] : [],
    true,
  );
  const permission = getProjectPermission(projectId);
  const canEdit = canPolicyActionEdit(permission);
  const annotationShareId = persist.get('annotation');
  const imageShareId = persist.get('image');

  const createShareLink = useMutation({
    mutationFn: (variables: CreateShareLinkMutationVariables) =>
      request(CREATE_SHARE_LINK, variables),
    onSuccess: async createShareLinkResponse => {
      toast({
        type: 'success',
        message: 'Share link created and copied to your clipboard',
        lifespan: 5000,
        clickToDismiss: true,
      });
      const url = generateUrl(createShareLinkResponse?.createShareLink?.shareToken ?? '');
      if (url) {
        await navigator.clipboard.writeText(url);
        window.open(url);
      }
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
    },
  });

  // Generate URL string
  const generateUrl = useCallback(
    (tokenId?: string) => {
      const base = window.location.origin + EXPLORE_ROOT;
      if (!panoramaIconSize || !backgroundColor || !currentEpsg || !globeMode) return;
      const params: [keyof ExploreState, string][] = [
        ['project', projectId as string],
        ['globe', globeMode],
        ['panoramaIconSize', panoramaIconSize.toString()],
        ['backgroundColor', backgroundColor.getHexString()],
        ['srs', currentEpsg.toString()],
        ['imageryBase', baseMapType],
        ['showLabels', JSON.stringify(annotationNameVisibility)],
        ['showMeasurements', JSON.stringify(annotationMeasurementVisibility)],
      ];

      if (tokenId) params.push(['shareLinkToken', tokenId]);
      if (selectedShareLink && selectedShareLink.shareToken)
        params.push(['shareLinkToken', selectedShareLink.shareToken]);
      if (shareCameraTransform && api3D) {
        params.push(['cameraPosition', JSON.stringify(cameraPosition.toArray())]);
        params.push(['cameraRotation', JSON.stringify(cameraRotation.toArray())]);
        params.push(['orthoMatrix', JSON.stringify(orthoMatrix)]);
        params.push(['projection', projection]);
      }

      if (selectedImageId) {
        params.push(['image', selectedImageId]);
      }
      if (visibleLayerIds) {
        params.push(['layers', JSON.stringify([...visibleLayerIds.values()])]);
      }
      if (selectedAnnotationId) {
        params.push(['annotation', selectedAnnotationId]);
      }
      return `${base}?${params.map(([k, v]) => k + '=' + v).join('&')}`;
    },
    [
      panoramaIconSize,
      backgroundColor,
      currentEpsg,
      globeMode,
      projectId,
      baseMapType,
      annotationNameVisibility,
      annotationMeasurementVisibility,
      selectedShareLink,
      shareCameraTransform,
      api3D,
      selectedImageId,
      visibleLayerIds,
      selectedAnnotationId,
      cameraPosition,
      cameraRotation,
      orthoMatrix,
      projection,
    ],
  );

  // Get selected annotation's scene entity id
  const getSelectedAnnotationSEId = useCallback(
    (anntationId: string) => {
      let sceneEntityId: null | string = null;
      for (const annotationGroup of annotationGroups) {
        for (const annotation of annotationGroup.annotations) {
          if (annotation.id === anntationId) {
            sceneEntityId = annotation.group.sceneEntityId;
          }
        }
      }

      return sceneEntityId;
    },
    [annotationGroups],
  );

  // Get selected image's scene entity id, name and type
  const getSelectedImageInfo = useCallback(
    (imageId: string) => {
      let sceneEntityId: null | string = null;
      let imageName = null;
      const imageGroups = [...photo2DGroups, ...panoramaGroups];
      for (const imageGroup of imageGroups) {
        for (const photo of imageGroup.photos) {
          if (photo.id === imageId) {
            imageName = photo.name;
            sceneEntityId = photo.group.sceneEntityId;
          }
        }
      }
      return {
        sceneEntityId,
        imageName,
      };
    },
    [panoramaGroups, photo2DGroups],
  );

  // Jump to specific container
  const handleScrollToContainer = useCallback(() => {
    if (selectedImageIdByLayers && defaultImageContentRef.current && focusedImageContent) {
      defaultImageContentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else if (
      selectedAnnotationIdByLayers &&
      defaultAnnotationContentRef.current &&
      focusedAnnotationContent
    ) {
      defaultAnnotationContentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [
    focusedAnnotationContent,
    focusedImageContent,
    selectedAnnotationIdByLayers,
    selectedImageIdByLayers,
  ]);

  const epsgString = useMemo(() => {
    if (!currentEpsg) return;
    const code = currentEpsg.split(':')[1];
    return epsgCodeList.find(key => key.value === code)?.label || '';
  }, [currentEpsg]);

  // Get sceneEntityId of selected annotation
  const selectedAnnotationSEId = useMemo(() => {
    if (selectedAnnotationId) return getSelectedAnnotationSEId(selectedAnnotationId);
  }, [getSelectedAnnotationSEId, selectedAnnotationId]);

  const visibleLayersSEIds = useMemo(() => {
    const sceneEntityIds = [];
    const visibleLayersClone = new Set(visibleLayers);
    const layerElements = [...layerGroups, ...layers];

    for (const layerId of visibleLayersClone) {
      for (const layer of layerElements) {
        if (layerId === layer.id) {
          let node = layer;
          while (node) {
            visibleLayersClone.add(node.sceneEntityId);
            if (node.parent) {
              node = node.parent;
            } else {
              break;
            }
          }
        }
      }
    }

    for (const layer of layerElements) {
      let node = layer;
      while (node) {
        if (visibleLayersClone.has(node.sceneEntityId)) {
          sceneEntityIds.push(layer.sceneEntityId);
        }
        if (node.parent) {
          node = node.parent;
        } else {
          break;
        }
      }
    }

    for (const annotationGroup of annotationGroups) {
      if (annotationGroup.parent?.id) {
        if (visibleLayersClone.has(annotationGroup.parent?.id)) {
          sceneEntityIds.push(annotationGroup.sceneEntityId);
        }
      } else {
        sceneEntityIds.push(annotationGroup.sceneEntityId);
      }
    }

    const imageGroups = [...photo2DGroups, ...panoramaGroups];

    for (const imageGroup of imageGroups) {
      if (imageGroup.parent?.id) {
        if (visibleLayersClone.has(imageGroup.parent?.id)) {
          sceneEntityIds.push(imageGroup.sceneEntityId);
        }
      } else {
        sceneEntityIds.push(imageGroup.sceneEntityId);
      }
    }

    useShareLink.setState({
      visibleLayerIds: new Set(visibleLayers),
    });

    return sceneEntityIds;
  }, [annotationGroups, layerGroups, layers, panoramaGroups, photo2DGroups, visibleLayers]);

  // Get sceneEntityId of selected image and set image name and type
  const selectedImageSEId = useMemo(() => {
    if (selectedImageId) {
      const { sceneEntityId, imageName } = getSelectedImageInfo(selectedImageId);
      setSelectedImageName(imageName);
      return sceneEntityId;
    }
  }, [getSelectedImageInfo, selectedImageId]);

  // Get all sceneEntityIds of selected annotation group including ascendants
  const selectedAnnoGroupSEIds = useMemo(() => {
    const entities = [...layerGroups, ...layers, ...annotationGroups];
    const filteredEntities = entities.filter(group => sceneEntityIds.includes(group.sceneEntityId));

    return uniq(getAllSceneEntityIds(filteredEntities));
  }, [annotationGroups, layerGroups, layers, sceneEntityIds]);

  // Get name of selected annotation
  const selectedAnnotationName = useMemo(() => {
    let annotationName = 'None selected';
    for (const annotationGroup of annotationGroups) {
      for (const annotation of annotationGroup.annotations) {
        if (annotation.id === selectedAnnotationId) {
          annotationName = annotation.name;
        }
      }
    }

    return annotationName;
  }, [annotationGroups, selectedAnnotationId]);

  // Check if there's any image in selected scene entities.
  const imageAvailability = useMemo(() => {
    let imageAvailability = false;
    const imageGroups = [...photo2DGroups, ...panoramaGroups];
    const filteredGroups = imageGroups.filter(group => sceneEntityIds.includes(group.id));
    if (filteredGroups.length) {
      imageAvailability = true;
    }
    return imageAvailability;
  }, [panoramaGroups, photo2DGroups, sceneEntityIds]);

  // Remove selected photo group ids when unmount
  useEffect(
    () => () =>
      useShareLink.setState({
        selectedPhotoGroupIds: null,
      }),
    [],
  );

  useEffect(() => {
    useShareLink.setState({
      selectedSceneEntityIds: sceneEntityIds,
    });
  }, [sceneEntityIds]);

  useEffect(() => {
    if (shareLinkTab === 'create') setName(getCurrentDateTime());
  }, [shareLinkTab]);

  useEffect(() => {
    if (!isEmpty(selectedShareLink)) {
      const updatedShareLink = shareLinks.find(sharelink => sharelink.id === selectedShareLink?.id);
      if (!isEmpty(updatedShareLink)) {
        setSelectedShareLink(updatedShareLink);
      }
    }
  }, [shareLinks, selectedShareLink]);

  // Set selected annotation and image
  useEffect(() => {
    if (shareLinkTab !== 'settings') {
      if (selectedAnnotationIdByLayers) {
        handleScrollToContainer();
        setSelectedAnnotationId(selectedAnnotationIdByLayers);
      }
      if (selectedImageIdByLayers) {
        handleScrollToContainer();
        setSelectedImageId(selectedImageIdByLayers);
      }

      if (annotationShareId) {
        setSelectedAnnotationId(annotationShareId);
      }
      if (imageShareId) {
        setSelectedImageId(imageShareId);
      }
    }
    if (selectedImageIdByImageViewer) {
      setSelectedImageId(selectedImageIdByImageViewer);
    }
  }, [
    selectedImageIdByImageViewer,
    selectedImageIdByLayers,
    selectedAnnotationIdByLayers,
    annotationShareId,
    imageShareId,
    handleScrollToContainer,
    shareLinkTab,
  ]);

  // Listen for changes to the camera position and rotation
  useEffect(() => {
    const cb: CameraMotionCallback = (position, rotation, params) => {
      setCameraPosition(position);
      setCameraRotation(rotation);
      setOrthoMatrix(params);
    };
    addViewerEventListener('onCameraMotion', cb);
    return () => removeViewerEventListener('onCameraMotion', cb);
  }, [api3D?.navigation]);

  const updateShareLink = useMutation({
    mutationFn: (variables: UpdateShareLinkMutationVariables) => {
      return request(UPDATE_SHARE_LINK, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
      if (!isEmpty(selectedShareLink)) {
        queryClient.invalidateQueries(
          useFetchShareLinkPermissions.getQueryKey(selectedShareLink.id),
        );
      }
    },
  });

  // Get selected image urls
  useEffect(() => {
    const fetchSelectedImageUrls = async () => {
      let image = null;
      const imageGroups = [...photo2DGroups, ...panoramaGroups];

      for (const imageGroup of imageGroups) {
        for (const photo of imageGroup.photos) {
          if (photo.id === selectedImageId) {
            image = photo;
          }
        }
      }
      if (selectedImageId) {
        const urls = await fetchSelectedImageUrl();
        const thumbnailUrls = await fetchSelectedImageThumbnailUrl();
        const url = urls.get(selectedImageId);
        const thumbnailUrl = thumbnailUrls.get(selectedImageId);
        if (image) {
          image.url = url;
          image.thumbnailUrl = thumbnailUrl;
        }
      }
      setSelectedImage(image);
    };
    fetchSelectedImageUrls();
  }, [
    fetchSelectedImageThumbnailUrl,
    fetchSelectedImageUrl,
    panoramaGroups,
    photo2DGroups,
    selectedImageId,
  ]);

  // Set fetched permission ids as sceneEntityIds
  useEffect(() => {
    if (permissionObjectIDs) {
      setSceneEntityIds(permissionObjectIDs);
    }
  }, [permissionObjectIDs]);

  useEffect(() => {
    if (shareLinkTab !== 'settings') {
      if (visibleLayersSEIds) setSceneEntityIds(visibleLayersSEIds);
    }
  }, [shareLinkTab, visibleLayersSEIds]);

  // Set current annotation's and current image's scene entity ids as sceneEntityIds
  useEffect(() => {
    if (shareLinkTab !== 'settings') {
      const sceneEntityIds: string[] = [];
      if (annotationShareId) sceneEntityIds.push(annotationShareId);
      if (imageShareId) sceneEntityIds.push(imageShareId);
      if (selectedImageSEId) sceneEntityIds.push(selectedImageSEId);
      if (selectedAnnotationSEId) sceneEntityIds.push(selectedAnnotationSEId);
      setSceneEntityIds(prev => uniq([...prev, ...sceneEntityIds]));
    }
  }, [
    selectedAnnotationSEId,
    selectedImageSEId,
    shareLinkTab,
    selectedAnnotationIdByLayers,
    selectedImageIdByLayers,
    annotationShareId,
    imageShareId,
    permissionObjectIDs,
  ]);

  // Cache share link settings
  useEffect(() => {
    const addOrUpdateSettings = (id: string, newSettings: Settings) => {
      const settings = shareLinkSettings;
      if (settings.has(id)) {
        const existingSettings = settings.get(id);
        settings.set(id, { ...existingSettings, ...newSettings });
      } else {
        settings.set(id, newSettings);
      }

      useShareLink.setState({ settings: settings });
    };

    if (selectedShareLink && selectedShareLink.id) {
      const settings = {
        backgroundColor,
        panoramaIconSize,
        globeMode,
        currentEpsg,
        shareCameraTransform,
        baseMapType,
        annotationNameVisibility,
        annotationMeasurementVisibility,
      };
      addOrUpdateSettings(selectedShareLink.id, settings);
    }
  }, [
    annotationMeasurementVisibility,
    annotationNameVisibility,
    backgroundColor,
    baseMapType,
    currentEpsg,
    globeMode,
    panoramaIconSize,
    selectedShareLink,
    shareCameraTransform,
    shareLinkSettings,
  ]);

  useEffect(() => {
    if (enabledOutsideClick) {
      setFocusedAnnotationContent(false);
      setFocusedImageContent(false);
    }
  }, [setFocusedAnnotationContent, setFocusedImageContent, enabledOutsideClick]);

  // Handle copying the final URL
  const handleCopy = async () => {
    const url = generateUrl();
    if (!url) return;
    await navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
    toast({
      type: 'success',
      message: 'Link copied!',
      lifespan: 5000,
      clickToDismiss: true,
    });
  };

  // Handle cancelling
  const handleCancel = () => {
    if (!enabled2D) {
      persist.clear('image');
      persist.url.refreshURL();
    }
    useShareLink.setState({
      shareLinkTab: 'card',
      selectedAnnotationIdByLayers: null,
      selectedImageIdByLayers: null,
      selectedImageIdByImageViewer: null,
      visibleLayerIds: new Set(),
    });
  };

  const handleEPSGClick = (item: ItemProps) => {
    setCurrentEpsg(`EPSG:${item.value}` as EPSG);
    setEnableEPSGMenu(false);
  };

  const toggleEPSGMenu = () => {
    setEnableEPSGMenu(prev => !prev);
  };

  const handleToggleActive = async () => {
    if (!isEmpty(selectedShareLink)) {
      if (selectedShareLink.active) {
        updateShareLink.mutate({ shareLinkId: selectedShareLink.id, isActivated: false });
      } else {
        updateShareLink.mutate({ shareLinkId: selectedShareLink.id, isActivated: true });
      }
    }
  };

  const handlePreview = async () => {
    if (selectedShareLink) {
      const permissions = sceneEntityIds.map(id => ({
        subjectId: selectedShareLink.id,
        subjectType: PolicySubjectTypeInput.ShareLink,
        accountId: accountId,
        objectType: PolicyObjectTypeInput.SceneEntity,
        objectId: id,
        actionType: PolicyActionTypeInput.Read,
      })) as PermissionPolicyInput[];
      await updateShareLink.mutateAsync({
        shareLinkId: selectedShareLink.id,
        name,
        permissions,
      });

      window.open(generateUrl());
    } else if (projectId) {
      const permissions = sceneEntityIds.map(id => ({
        subjectType: PolicySubjectTypeInput.ShareLink,
        accountId: accountId,
        objectType: PolicyObjectTypeInput.SceneEntity,
        objectId: id,
        actionType: PolicyActionTypeInput.Read,
      })) as PermissionPolicyWithOutSubjectInput[];
      await createShareLink.mutateAsync({ projectId, name, permissions });
    }

    useShareLink.setState({
      shareLinkTab: 'card',
      selectedAnnotationIdByLayers: null,
      selectedImageIdByLayers: null,
      selectedImageIdByImageViewer: null,
      visibleLayerIds: new Set(),
    });
  };

  const handleCloseThumbnail = () => {
    useShareLink.setState({ selectedImageIdByLayers: null, selectedImageIdByImageViewer: null });
    setSelectedImageId(null);
    disableViewer2D();
    // Clear image from persistent storage
    persist.clear('image');
    persist.url.refreshURL();
  };

  // Apply default settings
  const applyDefaultSettings = () => {
    setSelectedAnnotationId(annotationShareId);
    setSelectedImageId(imageShareId);
    setGlobeMode(defaultGlobeMode);
    setBackgroundColor(defaultBackgroundColor);
    setCurrentEpsg(epsg);
    setPanoramaIconSize(defaultPanoramaIconSize);
    setBaseMapType(defaultBaseMapType);
    setAnnotationMeasurementVisibility(defaultAnnotationMeasurementVisibility);
    setAnnotationNameVisibility(defaultAnnotationNameVisibility);

    const sceneEntityIds: string[] = [];
    if (annotationShareId) {
      const annotationSEId = getSelectedAnnotationSEId(annotationShareId);
      if (annotationSEId) sceneEntityIds.push(annotationSEId);
    }
    if (imageShareId) {
      const { sceneEntityId, imageName } = getSelectedImageInfo(imageShareId);
      setSelectedImageName(imageName);
      if (sceneEntityId) sceneEntityIds.push(sceneEntityId);
    }
    if (visibleLayersSEIds) sceneEntityIds.push(...visibleLayersSEIds);
    setSceneEntityIds(sceneEntityIds);
    useShareLink.setState({
      selectedAnnotationIdByLayers: null,
      selectedImageIdByLayers: null,
      selectedImageIdByImageViewer: null,
      visibleLayerIds: new Set(visibleLayers),
    });
  };

  // Toggle the image viewer
  const toggleImageViewer = () => {
    if (enabled2D) {
      disableViewer2D();
    } else {
      const photo2dGroupIds = photo2DGroups
        .filter(group => sceneEntityIds.includes(group.id))
        .map(group => group.id);

      const panoramaGroupIds = panoramaGroups
        .filter(group => sceneEntityIds.includes(group.id))
        .map(group => group.id);

      useShareLink.setState({ selectedPhotoGroupIds: [...photo2dGroupIds, ...panoramaGroupIds] });
      setImageViewerSettings({ photoGroupIds: photo2dGroupIds }, 'photo2D');
      setImageViewerSettings({ photoGroupIds: panoramaGroupIds }, 'panorama');
      enableViewer2D();
    }
  };

  const getCurrentDateTime = () => {
    return dayjs().format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <div className={cn('relative', 'h-full', 'flex', 'flex-col', 'overflow-y-auto')}>
      <div
        className={cn(
          'flex',
          'items-center',
          'justify-center',
          'h-6',
          'bg-info-100',
          'mb-3',
          'border-[1px]',
          'border-info-400',
          'rounded',
          'border-solid',
        )}
      >
        <p className={cn('typo-text-s', 'color-info-400')}>
          Currently in {enabledPanoramaWalkthrough ? 'Panorama' : '3D'} view mode.
        </p>
      </div>
      <div>
        <Input disabled={!canEdit} label="Name" onChange={setName} value={name} />
      </div>

      {shareLinkTab !== 'create' && (
        <div className={cn('flex gap-2 mt-3')}>
          <div className={cn('w-[290px]')}>
            <Input
              disabled
              tail={<div className={cn('i-skand-link', 'text-neutral-400', 'text-4')} />}
              value={generateUrl()}
            />
          </div>
          <Button className={cn('w-[64px]')} filled onClick={handleCopy} primary size="s">
            {!copied ? 'Copy' : 'Copied'}
          </Button>
        </div>
      )}

      {shareLinkTab !== 'create' && (
        <div
          className={cn(
            'flex',
            'flex-row',
            'rounded-8px',
            'border-solid',
            'border-neutral-400',
            'border-1px',
            'p-12px',
            'mt-3',
            'justify-between',
            'items-center',
          )}
        >
          <p className={cn('typo-text-small-em', 'color-neutral-800')}>Active status</p>
          <div className={cn('flex', 'flex-row', 'justify-between', 'items-center')}>
            <Switch
              checked={!isEmpty(selectedShareLink) && selectedShareLink.active}
              disabled={!canEdit}
              onChange={handleToggleActive}
            />
          </div>
        </div>
      )}

      <div
        className={cn(
          'flex',
          'flex-row',
          'rounded-8px',
          'border-solid',
          'border-neutral-400',
          'border-1px',
          'p-12px',
          'mt-3',
          'justify-between',
          'items-center',
          'relative',
        )}
      >
        <div className="relative flex flex-row items-center gap-2">
          <p className={cn('typo-text-small-em', 'color-neutral-800')}>Set to current view</p>
          <span
            className={cn('i-skand-info', 'color-neutral-500', 'text-3', 'cursor-pointer')}
            onMouseEnter={() => setEnabledInfoToolTip(true)}
            onMouseLeave={() => setEnabledInfoToolTip(false)}
          />
        </div>
        <div
          className={cn(
            enabledInfoToolTip ? 'flex' : 'hidden',
            'absolute left-0 z-100 mt-18 bg-red-300',
          )}
        >
          <Label
            css="py-3 w-[320px]"
            ellipsis={false}
            labelTitle="Current view includes 3D/panoramic mode, opening to an annotation/image, and layer visibility."
            labelType="info"
            textLength={120}
          />
        </div>
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'gap-3')}>
          <Button
            className={cn('h-7 w-[159px] typo-text-s normal-case')}
            onClick={applyDefaultSettings}
            size="xs"
          >
            Apply content and settings
          </Button>
        </div>
      </div>

      <div>
        <ShareLinkContent
          disabled={!canEdit}
          sceneEntityIds={sceneEntityIds}
          selectedAnnotationId={selectedAnnotationId}
          selectedImageId={selectedImageId}
          setSceneEntityIds={setSceneEntityIds}
          unselectableLayerTypes={['annotation', 'panorama', 'photo2D']}
        />
      </div>
      <div className={cn('flex', 'flex-col', 'w-full', 'pb-6')}>
        <div className={cn('flex', 'flex-col', 'w-full')}>
          <p className={cn('typo-text-medium', 'color-neutral-800', 'my-3')}>Customise settings</p>
          <div className="h-full">
            <div
              className={cn(
                'flex',
                'flex-row',
                'rounded-8px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'p-12px',
                'justify-between',
                'items-center',
              )}
            >
              <p className={cn('typo-text-small-em', 'color-neutral-800')}>
                Share with current camera position and orientation
              </p>
              <div className={cn('flex', 'flex-row', 'justify-between', 'items-center')}>
                <Switch
                  checked={shareCameraTransform}
                  onChange={() => setShareCameraTransform(!shareCameraTransform)}
                />
              </div>
            </div>

            <div
              className={cn(
                'mt-3',
                'flex',
                'flex-col',
                'rounded-8px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'p-12px',
              )}
            >
              <p className={cn('typo-text-s-em', 'color-neutral-800')}>Default coordinate system</p>
              <div
                className={cn(
                  'relative',
                  'w-340px',
                  'rounded-4px',
                  'border-solid',
                  'border-neutral-400',
                  'border-1px',
                  'h-36px',
                  'mt-12px',
                  'flex items-center',
                  'cursor-pointer',
                  'justify-between',
                )}
                onClick={toggleEPSGMenu}
              >
                <p className={cn('text-neutral-600 typo-text-small pl-12px')}>{epsgString}</p>
                <div
                  className={cn(
                    'i-skand-dropdownarrow',
                    'text-neutral-600',
                    'text-3',
                    'cursor-pointer',
                    'mr-12px',
                  )}
                />
              </div>
              {enableEPSGMenu && (
                <div className={cn('relative')}>
                  <DropdownSelector
                    containerStyles={cn(
                      'top-0',
                      'absolute',
                      '!bg-neutral-100',
                      'rounded-4px',
                      'border-solid',
                      'border-neutral-400',
                      'border-1px',
                      'p-2',
                      'w-340px',
                      'z-1',
                    )}
                    enableSearch
                    itemStyles={cn('text-neutral-700')}
                    items={epsgCodeList}
                    onItemClick={handleEPSGClick}
                  />
                </div>
              )}
            </div>

            <div
              className={cn(
                'flex',
                'flex-col',
                'rounded-8px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'p-12px',
                'mt-3',
              )}
            >
              <p className={cn('typo-text-s-em', 'color-neutral-800')}>Map State</p>

              <div className={cn('flex', 'flex-col', 'items-start', 'mt-3')}>
                <RadioButton
                  checked={globeMode === 'default'}
                  id="default"
                  label="Default"
                  onChange={() => setGlobeMode('default')}
                  value={'default'}
                />
                <RadioButton
                  checked={globeMode === 'imagery'}
                  id="imagery"
                  label="2D Aerial Map"
                  onChange={() => setGlobeMode('imagery')}
                  value={'imagery'}
                />
                <RadioButton
                  checked={globeMode === 'terrain'}
                  id="terrain"
                  label="3D Terrain"
                  onChange={() => setGlobeMode('terrain')}
                  value={'terrain'}
                />
                {googleEarthGlobeFlag && (
                  <div className="relative w-full flex flex-row items-center">
                    <RadioButton
                      checked={globeMode === 'google'}
                      id="google"
                      label="Google Earth Globe"
                      onChange={() => setGlobeMode('google')}
                      value={'google'}
                    />
                    <IconButton
                      buttonIcon={
                        <span
                          className={cn(
                            'i-skand-info',
                            'color-neutral-500',
                            'ml-3',
                            'text-3.5',
                            'mb-12px',
                            'cursor-pointer',
                          )}
                        />
                      }
                      buttonState="default"
                      className={cn('bg-transparent')}
                      onClick={() => setGoogleEarthTooltip(!googleEarthTooltip)}
                      onMouseEnter={() => setGoogleEarthTooltip(true)}
                      onMouseLeave={() => setGoogleEarthTooltip(false)}
                    />
                    {googleEarthTooltip && (
                      <div className="absolute top-6">
                        <Toast
                          clickToDismiss
                          dismiss={() => {}}
                          message="Orthophoto and terrain layers will not be visible in this mode."
                          type="info"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div
              className={cn(
                'flex',
                'flex-col',
                'rounded-8px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'p-12px',
                'mt-3',
              )}
            >
              <p className={cn('typo-text-s-em', 'color-neutral-800')}>Map type</p>

              <div className={cn('flex', 'flex-col', 'items-start', 'mt-3')}>
                <RadioButton
                  checked={baseMapType === 'street'}
                  id="street"
                  label="Street"
                  onChange={() => setBaseMapType('street')}
                  value={'street'}
                />
                <RadioButton
                  checked={baseMapType === 'satellite'}
                  id="satellite"
                  label="Satellite"
                  onChange={() => setBaseMapType('satellite')}
                  value={'satellite'}
                />
              </div>
            </div>

            <div
              className={cn(
                'flex',
                'flex-col',
                'rounded-8px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'p-12px',
                'mt-3',
              )}
            >
              <p className={cn('typo-text-s-em', 'color-neutral-800')}>Map background colour</p>

              <div className={cn('flex ml-4px mt-3')}>
                <button
                  className={cn(
                    'bg-contain',
                    'h-60px w-60px',
                    'mt-1',
                    'rounded-1',
                    'b-1px b-solid b-neutral-400',
                  )}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                  style={{ backgroundColor: `#${backgroundColor.getHexString()}` }}
                />
              </div>
            </div>
            <div
              className={cn(
                'flex',
                'flex-col',
                'rounded-8px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'p-12px',
                'mt-3',
              )}
            >
              <p className={cn('typo-text-s-em', 'color-neutral-800')}>Panorama icon size</p>

              <div className={cn('flex', 'flex-col', 'items-start', 'mt-3')}>
                <Input
                  onChange={value => setPanoramaIconSize(parseFloat(value))}
                  type="number"
                  value={panoramaIconSize}
                />
              </div>
            </div>

            <div
              className={cn(
                'flex',
                'flex-col',
                'rounded-8px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'p-12px',
                'mt-3',
              )}
            >
              <p className={cn('typo-text-s-em', 'color-neutral-800')}>Label visibility</p>

              <div className={cn('flex', 'flex-col', 'items-start', 'mt-3')}>
                <div className={cn('flex', 'flex-col', 'items-start')}>
                  <div className={cn('flex', 'flex-row', 'items-center')}>
                    <CheckBox
                      checked={annotationNameVisibility}
                      onChange={event => setAnnotationNameVisibility(event.target.checked)}
                    />
                    <span className={cn('color-neutral-800', 'typo-text-s', 'ml-2')}>
                      Name labels
                    </span>
                  </div>
                  <div className={cn('flex', 'flex-row', 'items-center', 'mt-2')}>
                    <CheckBox
                      checked={annotationMeasurementVisibility}
                      onChange={event => setAnnotationMeasurementVisibility(event.target.checked)}
                    />
                    <span className={cn('color-neutral-800', 'typo-text-s', 'ml-2')}>
                      Measurement labels
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          <div
            className={cn(
              'mt-3',
              'flex',
              'flex-col',
              'rounded-8px',
              'border-solid',
              focusedAnnotationContent
                ? 'border-primary-400 mx-2px shadow-[0px_0px_0px_2px_rgba(178,198,255,1)]'
                : 'border-neutral-400',
              'border-1px',
              'p-12px',
            )}
          >
            <div className={cn('flex', 'flex-row', 'items-center', 'gap-1')}>
              <p
                className={cn('typo-text-s-em', 'color-neutral-800')}
                ref={defaultAnnotationContentRef}
              >
                Default annotation
              </p>
              <p className={cn('typo-text-s-em', 'color-neutral-400')}>(Optional)</p>
            </div>

            <p className={cn('typo-text-s', 'color-neutral-500', 'mt-2')}>
              This will be the first annotation showing when link is opened.
            </p>

            <div
              className={cn(
                'mt-3',
                'flex',
                'flex-row',
                'items-center',
                'justify-between',
                'rounded-4px',
                'border-solid',
                'border-neutral-400',
                'border-1px',
                'h-9',
                'px-3',
                !selectedAnnoGroupSEIds.length && 'bg-neutral-300',
                'cursor-pointer',
              )}
              onClick={() => selectedAnnoGroupSEIds.length && setEnabledAnnotationSelector(true)}
              onMouseEnter={() => setEnabledEmptyAnnoTooltip(true)}
              onMouseLeave={() => setEnabledEmptyAnnoTooltip(false)}
            >
              <p className={cn('typo-text-s', 'color-neutral-600')}>{selectedAnnotationName}</p>
              <div className={cn('i-skand-dropdown', 'color-neutral-400', 'text-3')} />
            </div>
            <div
              className={cn(
                !selectedAnnoGroupSEIds.length && enabledEmptyAnnoTooltip ? 'flex' : 'hidden',
                'absolute right-0 z-100 mt-23',
              )}
            >
              <Label
                css="h-36px "
                ellipsis={false}
                labelTitle="No annotations available in the layers you have selected."
                labelType="info"
                textLength={80}
              />
            </div>
          </div>

          <div
            className={cn(
              'mt-3',
              'flex',
              'flex-col',
              'rounded-8px',
              'border-solid',
              'border-1px',
              'p-12px',
              'mb-4',
              focusedImageContent
                ? 'border-primary-400 mx-2px shadow-[0px_0px_0px_2px_rgba(178,198,255,1)]'
                : 'border-neutral-400',
            )}
          >
            <div className={cn('flex', 'flex-row', 'items-center', 'gap-1')}>
              <p className={cn('typo-text-s-em', 'color-neutral-800')} ref={defaultImageContentRef}>
                Default image
              </p>
              <p className={cn('typo-text-s-em', 'color-neutral-400')}>(Optional)</p>
            </div>

            <p className={cn('typo-text-s', 'color-neutral-500', 'mt-2')}>
              This will be the first image showing when link is opened.
            </p>
            {!selectedImage && (
              <div
                className={cn(
                  'mt-3',
                  'flex',
                  'flex-row',
                  'items-center',
                  'justify-center',
                  'rounded-4px',
                  'border-solid',
                  'border-neutral-400',
                  'border-1px',
                  'h-[200px]',
                  'px-3',
                  'cursor-pointer',
                )}
              >
                <div
                  onMouseEnter={() => setEnabledEmptyImagesTooltip(true)}
                  onMouseLeave={() => setEnabledEmptyImagesTooltip(false)}
                >
                  <Button
                    className="p-x-2"
                    disabled={!imageAvailability}
                    filled
                    onClick={toggleImageViewer}
                    primary
                    size="xs"
                  >
                    SELECT AN IMAGE
                  </Button>
                </div>
                <div
                  className={cn(
                    !imageAvailability && enabledEmptyImagesTooltip ? 'flex' : 'hidden',
                    'absolute right-0 z-100 mt-18 bg-red-300',
                  )}
                >
                  <Label
                    css="h-36px "
                    ellipsis={false}
                    labelTitle="No images available in the layers you have selected."
                    labelType="info"
                    textLength={80}
                  />
                </div>
              </div>
            )}

            {selectedImage && (
              <>
                <Thumbnail
                  className="w-full"
                  disableSelection={selectedImage !== null}
                  onClose={handleCloseThumbnail}
                  onSelectImage={toggleImageViewer}
                  photo={selectedImage}
                />
                <p className={cn('mt-2', 'typo-text-xs', 'color-neutral-800')}>
                  {selectedImageName}
                </p>
              </>
            )}
          </div>
        </>
      </div>

      <AnnotationSelector
        availableAnnotations={enabledAnnotationSelector}
        sceneEntityIds={selectedAnnoGroupSEIds}
        selectedAnnotationId={selectedAnnotationId}
        setEnabledAnnotationSelector={setEnabledAnnotationSelector}
        setSelectedAnnotationId={setSelectedAnnotationId}
      />

      {showColorPicker && (
        <ColorPickerMenu
          closeMenu={() => setShowColorPicker(false)}
          color={backgroundColor}
          selectColor={setBackgroundColor}
          x={100}
          y={400}
        />
      )}

      <div
        className={cn(
          'flex',
          'flex-1',
          'flex-col',
          'sticky',
          'bottom-0',
          'w-[364px]',
          'bg-neutral-100',
        )}
      >
        <div
          className={cn(
            'flex',
            'flex-row',
            'gap-3',
            'justify-between',
            'items-center',
            'mt-16px',
            'w-[363px]',
          )}
        >
          <Button className={cn('w-full')} onClick={handleCancel} size="s">
            Close
          </Button>
          <Button
            className={cn('w-full')}
            data-analytics-event-object={ANALYTICS_EVENT_OBJECT.SAVE_SHARE_LINK}
            filled
            onClick={handlePreview}
            primary
            size="s"
          >
            {shareLinkTab === 'create' ? 'Save and copy link' : 'Save and preview'}
          </Button>
        </div>
      </div>
    </div>
  );
};
