import {
  GoogleMap,
  useJsApiLoader,
  Libraries,
  MarkerClusterer,
  InfoBox,
} from '@react-google-maps/api';
import MapClusterDefault from '../../../assets/mapCluster.svg';
import { ExploreMapStyles } from './ExploreMapStyles';
import { useEffect, useRef, useState } from 'react';
import { GOOGLE_MAP_API_KEY } from '@/constants/env';
import { Cartographic, toDegrees, toRadians } from '@skand/viewer-component-v2';
import { Project } from '@/utils/project';
import { ProjectInfoModal } from './ProjectInfoModal';
import { ProjectMarker } from './ProjectMarker';
import { Vector2 } from 'three';

const libraries: Libraries = ['places'];
const center = { lat: 0, lng: 0 };

export interface ExploreMapProps {
  projects: Solid<Project, 'location'>[];
}

export const ExploreMap = ({ projects }: ExploreMapProps) => {
  const initialized = useRef(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [enableProjectInfo, setEnableProjectInfo] = useState(false);
  const [selected, setSelected] = useState<Solid<Project, 'location'>[]>([]);
  const [clustered, setClustered] = useState<Solid<Project, 'location'>[]>([]);
  const [multipleProjects, setMultipleProjects] = useState<Solid<Project, 'location'>[] | null>([]);

  const { isLoaded } = useJsApiLoader({
    id: 'siteMap',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  // Function to calculate distance to point
  const calculateDisplacement = (a: Cartographic, b: Cartographic) => {
    const result = new Vector2();

    const zoom = map?.getZoom();
    const p1 = map?.getProjection()?.fromLatLngToPoint({
      lat: toDegrees(a.latitude),
      lng: toDegrees(a.longitude),
    });
    const p2 = map?.getProjection()?.fromLatLngToPoint({
      lat: toDegrees(b.latitude),
      lng: toDegrees(b.longitude),
    });

    if (p1 && p2 && zoom !== undefined) {
      const pixelSize = Math.pow(2, -zoom);
      result.set(p2.x - p1.x, p2.y - p1.y).divideScalar(pixelSize);
    }
    return result;
  };

  // Calculate the x position of the info box
  const calculateInfoBoxX = (location: Cartographic) => {
    const displacement = calculateDisplacement(
      location,
      new Cartographic(toRadians(179), location.latitude),
    );
    return displacement.x <= 388 ? -388 : 25;
  };

  // Center the map on load
  useEffect(() => {
    if (map && projects.length && !initialized.current) {
      const bounds = new google.maps.LatLngBounds();

      for (const project of projects) {
        bounds.extend({
          lat: toDegrees(project.location.latitude),
          lng: toDegrees(project.location.longitude),
        });
      }
      map.fitBounds(bounds);
      map.setCenter(bounds.getCenter()); // https://stackoverflow.com/questions/54712021/map-fitbounds-works-weird-when-map-has-restriction-option-set
      initialized.current = true;
    }
  }, [map, projects]);

  if (isLoaded) {
    return (
      <div className="h-full w-full">
        <GoogleMap
          center={center}
          mapContainerStyle={{
            width: '100%',
            height: `100%`,
          }}
          onClick={() => setSelected([])}
          onLoad={setMap}
          options={{
            disableDoubleClickZoom: true,
            disableDefaultUI: true,
            clickableIcons: false,
            rotateControl: false,
            panControl: false,
            scaleControl: false,
            minZoom: 3,
            styles: ExploreMapStyles,
            restriction: {
              latLngBounds: {
                north: 85,
                south: -85,
                west: -179,
                east: 179,
              },
              strictBounds: false,
            },
          }}
          zoom={5}
        >
          <MarkerClusterer
            averageCenter
            onClick={cluster => {
              const markers = cluster.markers;
              const projects = markers.map(
                marker => marker.get('project') as Solid<Project, 'location'>,
              );
              setEnableProjectInfo(true);
              setSelected(projects);
              setMultipleProjects(projects);
            }}
            onClusteringEnd={clusterer => {
              const clusters = clusterer.getClusters();
              const markers = clusters
                .filter(cluster => cluster.markers.length > 1)
                .flatMap(cluster => cluster.markers);
              const projects = markers.map(
                marker => marker.get('project') as Solid<Project, 'location'>,
              );
              setClustered(projects);
            }}
            styles={[
              {
                url: MapClusterDefault,
                textColor: 'white',
                height: 28,
                width: 28,
                textSize: 10,
                anchorText: [0, 0],
              },
            ]}
            zoomOnClick={false}
          >
            {clusterer => (
              <>
                {projects.map(project => (
                  <ProjectMarker
                    clusterer={clusterer}
                    isClustered={clustered.includes(project)}
                    key={project.id}
                    onSelect={() => {
                      setEnableProjectInfo(true);
                      setSelected([project]);
                      setMultipleProjects(null);
                    }}
                    project={project}
                  />
                ))}
              </>
            )}
          </MarkerClusterer>
          {enableProjectInfo && selected.length && (
            <InfoBox
              options={{
                pixelOffset: new window.google.maps.Size(
                  calculateInfoBoxX(selected[0].location),
                  -20,
                ),
                closeBoxURL: ``,
                enableEventPropagation: true,
                zIndex: 2,
              }}
              position={
                new google.maps.LatLng(
                  toDegrees(selected[0].location.latitude),
                  toDegrees(selected[0].location.longitude),
                )
              }
            >
              <ProjectInfoModal
                closeModal={() => setEnableProjectInfo(false)}
                multipleProjects={multipleProjects}
                projects={selected}
                setProject={project => setSelected(project)}
              />
            </InfoBox>
          )}
        </GoogleMap>
      </div>
    );
  }
};
