import { FindIcon } from '@/components/IconButton';
import { SceneEntity, SceneEntityTree } from '@/components/SceneEntityTree';
import { DraggableMenu } from '@/components/DraggableMenu';
import { cn } from '@/utils/classname';
import { Button, Input, TreeNodeProps } from '@skand/ui';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnnotationGroupNode } from './AnnotationGroupNode';
import { AnnotationNode } from './AnnotationNode';
import { LayerGroupNode } from './LayerGroupNode';
import { LayerNode } from './LayerNode';
import { useOnClickOutside } from '@/utils/useOnClickOutside';
import { useShareLink } from '@/stores/shareLink';

interface AnnotationSelectorProps {
  availableAnnotations: boolean;
  sceneEntityIds: string[];
  setSelectedAnnotationId: (value: string | null) => void;
  selectedAnnotationId: string | null | undefined;
  setEnabledAnnotationSelector: (value: boolean) => void;
}

export const AnnotationSelector = ({
  availableAnnotations,
  sceneEntityIds,
  setSelectedAnnotationId,
  selectedAnnotationId,
  setEnabledAnnotationSelector,
}: AnnotationSelectorProps) => {
  const [searchKey, setSearchKey] = useState('');
  const modalRef = useRef<HTMLInputElement>(null);
  const enabledOutsideClick = useOnClickOutside(modalRef);

  useEffect(() => {
    if (enabledOutsideClick) {
      setEnabledAnnotationSelector(false);
    }
  }, [setEnabledAnnotationSelector, enabledOutsideClick]);

  // Debounced search handler
  const handleSearch = useMemo(() => {
    return debounce(setSearchKey, 300);
  }, [setSearchKey]);

  // Clean up the handler
  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  // Define Tree node component
  const TreeNode = useCallback(
    ({ data, isLeaf, depth, setOpen, isOpen }: TreeNodeProps<SceneEntity>) => {
      return (
        <div className={cn('w-full h-full')}>
          <div
            className={cn('flex', 'flex-row', 'items-center', 'flex-1', 'mr-2', 'w-full')}
            style={{ marginLeft: `${depth * 0.5}rem` }}
          >
            <div
              className={cn(
                !isLeaf ? 'i-skand-dropdownarrow cursor-pointer' : 'h-1em',
                'min-w-1em',
                'text-neutral-400',
                'text-3',
                'mr-2',
                'ml-2',
                !isOpen && 'rotate-270',
              )}
              onClick={() => setOpen(!isOpen)}
            />
            {data.entity.type === 'layerGroup' && <LayerGroupNode group={data.entity} />}
            {data.entity.type === 'layer' && <LayerNode layer={data.entity} />}
            {data.entity.type === 'annotation' && (
              <AnnotationNode
                annotation={data.entity}
                selectedAnnotationId={selectedAnnotationId}
                setSelectedAnnotationId={setSelectedAnnotationId}
              />
            )}
            {data.entity.type === 'annotationGroup' && <AnnotationGroupNode group={data.entity} />}
          </div>
        </div>
      );
    },
    [selectedAnnotationId, setSelectedAnnotationId],
  );

  const handleRemove = () => {
    setSelectedAnnotationId(null);
    useShareLink.setState({ selectedAnnotationIdByLayers: null });
  };

  if (!availableAnnotations) return;

  return (
    <DraggableMenu
      containerStyles={cn(
        'w-[387px]',
        'flex',
        'bg-neutral-100',
        'rounded-2',
        'border-1px',
        'border-solid',
        'border-neutral-400',
      )}
      disableCloseButton
      y={100}
    >
      <div className={cn('w-full', 'flex', 'flex-col', 'flex-1', 'h-full', 'p-3')} ref={modalRef}>
        <div className="flex flex-row items-center justify-between">
          <p className={cn('typo-text-s', 'color-neutral-800')}>Select Annotation</p>
          <div
            className="i-skand-close cursor-pointer text-3 color-neutral-400"
            onClick={() => setEnabledAnnotationSelector(false)}
          />
        </div>

        <div className={cn('flex gap-2 relative')}>
          <div className={cn('w-full my-3')}>
            <Input
              data-testid="input"
              label="Search"
              onChange={handleSearch}
              tail={<FindIcon />}
              value={searchKey}
            />
          </div>
        </div>

        <div className={cn('flex flex-col h-100')}>
          <div className="h-80 flex-1 overflow-y-scroll">
            <SceneEntityTree
              sceneEntityIds={sceneEntityIds}
              searchKey={searchKey}
              skippedEntities={['photoGroup']}
            >
              {TreeNode}
            </SceneEntityTree>
          </div>
          <Button className={cn('w-full mt-3')} onClick={handleRemove} size="s">
            Remove annotation
          </Button>
        </div>
      </div>
    </DraggableMenu>
  );
};
