export const SUBJECT_TYPE = {
  USER: 'USER',
  GROUP: 'GROUP',
  ROLE: 'ROLE',
} as const;
export type SubjectType = keyof typeof SUBJECT_TYPE;

export const OBJECT_TYPE = {
  ALL: '*',
  PROJECT: 'PROJECT',
  SYSTEM_NODE: 'SYSTEM_NODE',
  PROJECT_NODE: 'PROJECT_NODE',
  PROJECT_GROUP: 'PROJECT_GROUP',
  ANNOTATION_GROUP: 'ANNOTATION_GROUP',
  SCENE_ENTITY: 'SCENE_ENTITY',
} as const;
export type ObjectType = keyof typeof OBJECT_TYPE;

export const ACTION_TYPE = {
  ADMIN: 'ADMIN',
  EDIT: 'EDIT',
  EDIT_REQUESTER: 'EDIT_REQUESTER',
  READ: 'READ',
  LIST: 'LIST',
} as const;
export type ActionType = keyof typeof ACTION_TYPE;

export const OBJECT_ID = {
  ALL: '*',
} as const;

export interface Policy {
  type: 'policy';
  objectId: string;
  objectType: (typeof OBJECT_TYPE)[ObjectType];
  actionType: (typeof ACTION_TYPE)[ActionType];
}

export const canPolicyActionEdit = (actionType: (typeof ACTION_TYPE)[ActionType] | null) => {
  return actionType === ACTION_TYPE.ADMIN || actionType === ACTION_TYPE.EDIT;
};
