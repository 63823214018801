import { GestureControl } from '@skand/viewer-component-v2';
import { Vector2 } from 'three';
import { Transform2 } from '../Transform2';

/**
 * Editor camera states for translation and zoom
 */
interface MoveStates {
  left: boolean;
  right: boolean;
  up: boolean;
  down: boolean;
  in: boolean;
  out: boolean;
}

/**
 * Implement navigation controls
 */
export class NavigationController {
  private canvas: HTMLCanvasElement;
  private gestures: GestureControl;
  private transform: Transform2;

  private moveStates: MoveStates;
  private minZoom: number;

  constructor(canvas: HTMLCanvasElement, transform: Transform2) {
    this.canvas = canvas;
    this.gestures = new GestureControl(canvas);
    this.transform = transform;

    this.moveStates = {
      left: false,
      right: false,
      up: false,
      down: false,
      in: false,
      out: false,
    };
    this.minZoom = 0.001;
  }

  /**
   * Set the minimum zoom level.
   */
  setMinZoom(minZoom: number) {
    this.minZoom = minZoom;
  }

  /**
   * Start listening from user input for navigation controls.
   */
  start() {
    this.gestures.register('swipe', gesture => {
      const { position, prevPosition } = gesture;

      // Panning
      this.transform.translate(position.x - prevPosition.x, position.y - prevPosition.y);
    });

    this.gestures.register('zoom', gesture => {
      const { position, direction } = gesture;

      // Zooming
      const delta = -direction.y * 0.0005;
      this.transform.zoomToPoint(delta, position, this.minZoom);
    });

    this.gestures.register('keytap', gesture => {
      const { key } = gesture;
      if (key === 'w' || key === 'W') {
        this.moveStates.up = true;
      } else if (key === 'a' || key === 'A') {
        this.moveStates.left = true;
      } else if (key === 's' || key === 'S') {
        this.moveStates.down = true;
      } else if (key === 'd' || key === 'D') {
        this.moveStates.right = true;
      } else if (key === 'q' || key === 'Q') {
        this.moveStates.in = true;
      } else if (key === 'e' || key === 'E') {
        this.moveStates.out = true;
      }
    });

    this.gestures.register('keyrelease', gesture => {
      const { key } = gesture;
      if (key === 'w' || key === 'W') {
        this.moveStates.up = false;
      } else if (key === 'a' || key === 'A') {
        this.moveStates.left = false;
      } else if (key === 's' || key === 'S') {
        this.moveStates.down = false;
      } else if (key === 'd' || key === 'D') {
        this.moveStates.right = false;
      } else if (key === 'q' || key === 'Q') {
        this.moveStates.in = false;
      } else if (key === 'e' || key === 'E') {
        this.moveStates.out = false;
      }
    });
  }

  /**
   * Disable navigation controls.
   */
  stop() {
    this.gestures.unregisterAll();
  }

  /**
   * Per-frame updates.
   */
  update() {
    const translationSpeed = 1 / this.transform.getZoom();
    const zoomSpeed = 0.01;
    const canvasCenter = new Vector2(this.canvas.width / 2, this.canvas.height / 2);
    if (this.moveStates.left) {
      this.transform.translate(translationSpeed, 0);
    } else if (this.moveStates.right) {
      this.transform.translate(-translationSpeed, 0);
    }
    if (this.moveStates.up) {
      this.transform.translate(0, translationSpeed);
    } else if (this.moveStates.down) {
      this.transform.translate(0, -translationSpeed);
    }
    if (this.moveStates.in) {
      this.transform.zoomToPoint(zoomSpeed, canvasCenter, this.minZoom);
    } else if (this.moveStates.out) {
      this.transform.zoomToPoint(-zoomSpeed, canvasCenter, this.minZoom);
    }
  }
}
