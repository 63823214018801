import { Project } from '@/utils/project';
import { deselectCreateGroupProject } from '@/stores/project';
import { useCallback } from 'react';

interface ProjectItemSelectedProps {
  project: Project;
}

export const ProjectItemSelected = ({ project }: ProjectItemSelectedProps) => {
  const handleClick = useCallback(() => {
    if (project.id) deselectCreateGroupProject(project.id);
  }, [project.id]);

  return (
    <div className="mt-2 flex" key={project.id}>
      <span className="flex-[0_0_50%] truncate color-neutral-800 typo-text-s">{project.name}</span>
      <span className="flex-[0_0_25%] color-neutral-800 typo-text-s">
        {project.createdAt.toLocaleDateString()}
      </span>
      <div className="flex flex-[0_0_25%] items-center justify-end">
        <div
          className="i-skand-close cursor-pointer text-10px color-neutral-400"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};
