import { cn } from '@/utils/classname';
import { Project } from '@/utils/project';
import { InfoBox, Marker, MarkerProps } from '@react-google-maps/api';
import { colorPrimary400 } from '@skand/variables';
import { toDegrees } from '@skand/viewer-component-v2';
import { useMemo } from 'react';

interface ProjectMarkerProps {
  project: Solid<Project, 'location'>;
  clusterer: MarkerProps['clusterer'];
  isClustered: boolean;
  onSelect: () => void;
}

export const ProjectMarker = ({
  project,
  clusterer,
  isClustered,
  onSelect,
}: ProjectMarkerProps) => {
  const position = useMemo(
    () =>
      new google.maps.LatLng(
        toDegrees(project.location.latitude),
        toDegrees(project.location.longitude),
      ),
    [project.location.latitude, project.location.longitude],
  );

  return (
    <Marker
      clusterer={clusterer}
      icon={{
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: colorPrimary400,
        fillOpacity: 1,
        strokeWeight: 0,
      }}
      onClick={onSelect}
      onLoad={marker => marker.set('project', project)}
      options={{
        optimized: true,
      }}
      position={position}
    >
      {!isClustered && (
        <InfoBox
          options={{
            closeBoxURL: '',
            pixelOffset: new google.maps.Size(-project.name.length * 2.5 - 9.5, -32),
            enableEventPropagation: true,
            disableAutoPan: true,
          }}
          position={new google.maps.LatLng(position)}
        >
          <div
            className={cn(
              'bg-neutral-100',
              'p-y-4px',
              'p-x-8px',
              'b-solid',
              'border-neutral-400',
              'border-1px',
              'border-rounded-4px',
              'whitespace-nowrap',
              'text-neutral-600',
            )}
          >
            {project.name}
          </div>
        </InfoBox>
      )}
    </Marker>
  );
};
