import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { cn } from '@/utils/classname';
import { Editor, Viewer2DAPI } from '@/utils/Viewer2D';
import { useViewer } from '@/stores/viewer';
import { Overlay } from './Overlay';
import { Vector2 } from 'three';
import { toast } from '@skand/ui';
import { Panorama } from '@skand/viewer-component-v2';
import { useFetchImageUrls } from '@/hooks/useFetchImageUrls';
import { Progress } from '@skand/ui';

const Viewer2DCore = (_: unknown, ref: React.Ref<Viewer2DAPI | null>) => {
  const api2D = useViewer(state => state.api2D);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const targetAnnotation2D = useViewer(state => state.targetAnnotation2D);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const [element, setElement] = useState<HTMLCanvasElement | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [targetReady, setTargetReady] = useState(false);

  const fetchUrl = useFetchImageUrls([targetPhoto?.id as string], false);

  // Expose Viewer2D API object via ref
  useImperativeHandle(
    ref,
    () => {
      if (element === null) return null;
      const editor = new Editor(element);

      const resizeObserver = new ResizeObserver(() => {
        const size = new Vector2(element.offsetWidth, element.offsetHeight);
        editor.setSize(size);
      });
      resizeObserver.observe(element);

      // Main loop
      const update = () => {
        requestAnimationFrame(update);
        editor.update();
      };
      update();

      return { editor };
    },
    [element],
  );

  // Listen for changes in the target photo
  useEffect(() => {
    if (api2D === null || targetPhoto === null) return;
    // Render target photo onto canvas
    const showTarget = async () => {
      api2D.editor.hideSketches();
      setTargetReady(false);
      if (targetPhoto.url === undefined) {
        try {
          const result = await fetchUrl();
          targetPhoto.url = result.get(targetPhoto.id);
          if (targetPhoto.url) {
            if (targetPhoto.type === 'panorama') {
              const panorama = targetPhoto.widget?.getModel() as Panorama | undefined;
              panorama?.setUrl(targetPhoto.url);
            }
            await api2D.editor.setPhoto(targetPhoto.id, targetPhoto.url, setDownloadProgress);
          }
        } catch (error) {
          toast({ type: 'warn', message: (error as Error).message, lifespan: 10000 });
        }
      } else {
        await api2D.editor.setPhoto(targetPhoto.id, targetPhoto.url, setDownloadProgress);
      }
      setTargetReady(true);
      api2D.editor.showSketches();

      // Update visible sketches
      for (const group of annotationGroups) {
        for (const annotation of group.annotations) {
          if (annotation.sketch2D) {
            if (
              annotation.photo === targetPhoto &&
              visibleAnnotations.has(annotation.id) &&
              targetAnnotation2D !== annotation
            ) {
              annotation.sketch2D.show();
            } else {
              annotation.sketch2D.hide();
            }
          }
        }
      }
    };
    showTarget();
  }, [annotationGroups, api2D, targetPhoto, fetchUrl, visibleAnnotations, targetAnnotation2D]);

  return (
    <div className={cn('h-full', 'w-full', 'flex', 'justify-center', 'items-center')}>
      <Overlay />
      {!targetReady && (
        <div
          className={cn(
            'h-full',
            'w-full',
            'flex',
            'flex-col',
            'items-center',
            'justify-center',
            'z-1',
            'pointer-events-none',
          )}
        >
          <p className={cn('color-neutral-200', 'typo-text-small')}>Select an image to view here</p>

          {downloadProgress > 0 && downloadProgress < 1 && (
            <div className="p-t-4">
              <Progress className="w-153px" progress={downloadProgress * 100} />
            </div>
          )}
        </div>
      )}
      <canvas className={cn('absolute', 'h-full', 'w-full', 'bg-neutral-800')} ref={setElement} />
    </div>
  );
};

export const Viewer2D = forwardRef(Viewer2DCore);
