import { FilterOptions, LayerFilterCategory, LayerFilterKey } from './LayerFilterKey';
import { LayerFilterGroup } from './LayerFilterGroup';

export interface LayerFilterTabProps {
  filterKeys: Set<LayerFilterKey>;
  setFilterKeys: (values: Set<LayerFilterKey>) => void;
}

export const LayerFilterTab = ({ filterKeys, setFilterKeys }: LayerFilterTabProps) => {
  return (
    <div className="h-full">
      <div className="">
        {Object.keys(FilterOptions).map(key => (
          <LayerFilterGroup
            category={key as LayerFilterCategory}
            filterKeys={filterKeys}
            key={key}
            setFilterKeys={setFilterKeys}
          />
        ))}
      </div>
    </div>
  );
};
