import { Photo } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { downloadBlob } from '@/utils/download';
import { Button, Progress, toast } from '@skand/ui';
import { useEffect, useState } from 'react';
import { ImagePreview } from '@/components/ImagePreview';

interface ThumbanailProps {
  photo: Photo;
  setCurrentImageId?: (value: string) => void;
  currentImageId?: string | null;
  className?: string;
  onSelectImage?: () => void;
  disableSelection?: boolean;
  onClose?: () => void;
}

export const Thumbnail = ({
  photo,
  setCurrentImageId,
  onSelectImage,
  currentImageId,
  className,
  onClose,
  disableSelection = true,
}: ThumbanailProps) => {
  const [objectUrl, setObjectUrl] = useState<string | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [enabledViewer, setEnabledViewer] = useState(false);
  // Fetch the url and download the blob to render the thumbnail image
  useEffect(() => {
    const downloadThumbnail = async () => {
      try {
        // Fallback to the main url if the thumbnail is not available
        let url = photo.thumbnailUrl;
        if (!url) {
          url = photo.url;
        }
        if (url) {
          const imgBlob = await downloadBlob(url, setDownloadProgress);
          setObjectUrl(window.URL.createObjectURL(imgBlob));
        }
      } catch (error) {
        toast({ type: 'warn', message: `${error}`, lifespan: 10000 });
      }
    };
    downloadThumbnail();
  }, [photo.thumbnailUrl, photo.url]);

  const isTargetPhotoLoading = downloadProgress > 0 && downloadProgress < 1 && objectUrl === null;

  return (
    <div
      className={cn(className, 'relative', 'w-[244px]', 'cursor-pointer')}
      onClick={() => setCurrentImageId && setCurrentImageId(photo.id)}
    >
      <div
        className={cn(
          'w-full',
          'h-200px',
          'bg-zinc-800',
          'box-border',
          'b-rounded-1',
          'overflow-hidden',
          'b-primary-400',
          'b-2px',
          currentImageId === photo.id && 'b-solid',
        )}
      >
        {onClose && (
          <div
            className="absolute right-2 top-2 h-5 w-5 flex items-center justify-center border-1px border-neutral-500 rounded-4px border-solid bg-neutral-100"
            onClick={onClose}
          >
            <div className="i-skand-close cursor-pointer text-10px color-neutral-500 hover:color-primary-400" />
          </div>
        )}

        {isTargetPhotoLoading && (
          <div
            className={cn('flex', 'h-full', 'absolute', 'w-full', 'justify-center', 'items-center')}
          >
            <Progress className="w-153px" progress={downloadProgress * 100} />
          </div>
        )}

        <div
          className={cn(
            disableSelection ? 'hidden' : 'flex',
            'absolute',
            'gap-2',
            'top-1/2',
            'left-1/2',
            'transform -translate-x-1/2',
            '-translate-y-1/2',
          )}
        >
          <Button className="p-x-2" filled onClick={onSelectImage} primary size="xs">
            SELECT AN IMAGE
          </Button>
        </div>

        {objectUrl && (
          <img
            className={cn('w-full', 'h-full', 'object-cover', 'cursor-pointer')}
            src={objectUrl}
          />
        )}
      </div>
      {enabledViewer && objectUrl && (
        <ImagePreview
          close={() => {
            setEnabledViewer(false);
          }}
          disableDownload
          handleDownload={() => {}}
          url={objectUrl}
          width={550}
        />
      )}
    </div>
  );
};
