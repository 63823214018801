import { queryClient } from '@/graphql/client';
import { UpdateProcessStepMutationVariables } from '@/graphql/codegen/graphql';
import { UPDATE_PROCESS_STEP } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useFetchProcesses } from '@/hooks/useFetchProcesses';
import {
  ConfirmationModalState,
  DeleteConfirmationModal,
} from '@/pages/ProjectPage/DeleteConfirmationModal';
import { enableViewer2D, setImageViewerTab, useViewer } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Process } from '@/utils/process';
import { Button } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { FinishedTag } from './FinishedTag';
import { InProgressTag } from './InProgressTag';
import { useExplore } from '@/stores/explore';

interface ProcessCardProps {
  process: Process;
}

export const ProcessCard = ({ process }: ProcessCardProps) => {
  const targetProcess = useViewer(state => state.targetProcess);
  const layers = useViewer(state => state.layers);
  const projectId = useExplore(state => state.projectId);

  const parentLayer = useMemo(
    () => layers.find(layer => layer.id === process.layerId),
    [layers, process.layerId],
  );
  const checkedImages = useMemo(
    () => [...process.images.values()].filter(image => image.status !== 'created'),
    [process.images],
  );

  const [openConfirmationModal, setOpenConfirmationModal] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const addProcessStep = useMutation({
    mutationFn: (params: UpdateProcessStepMutationVariables) =>
      request(UPDATE_PROCESS_STEP, params),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchProcesses.getQueryKey(projectId));
    },
  });

  // Hanadle starting the process
  const handleToggleActive = () => {
    if (targetProcess !== process) {
      useViewer.setState({ targetProcess: process });
      enableViewer2D();
      if (process.kind === 'panorama') {
        setImageViewerTab('panorama');
      }
    } else {
      useViewer.setState({ targetProcess: null });
    }
  };

  // Handle submitting the process
  const handleSubmit = async () => {
    if (process.step === 'created') {
      setOpenConfirmationModal({
        isOpen: true,
        title: 'Submit Process',
        description: `Are you sure you want to submit the process ${process.id}?`,
        actionButton: 'Confirm',
        actionFunction: async () => {
          addProcessStep.mutateAsync({
            processId: process.id,
            stepName: 'DONE',
          });
          setOpenConfirmationModal(state => {
            return { ...state, isOpen: false };
          });
        },
      });
    } else {
      setOpenConfirmationModal({
        isOpen: true,
        title: 'Reopen Process',
        description: `Are you sure you want to reopen the process ${process.id}?`,
        actionButton: 'Confirm',
        actionFunction: async () => {
          addProcessStep.mutateAsync({
            processId: process.id,
            stepName: 'CREATED',
          });
          setOpenConfirmationModal(state => {
            return { ...state, isOpen: false };
          });
        },
      });
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
      />
      <div
        className={cn(
          targetProcess === process ? 'border-primary-400 border-2' : 'border-neutral-400 border-1',
          'rounded-8px',
          'border-solid',
          'p-12px',
          'w-full',
          'mb-3',
        )}
      >
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mb-2')}>
          {process.step === 'done' ? <FinishedTag /> : <InProgressTag />}
        </div>
        <p className="color-neutral-800 typo-text-medium-em">{process.name}</p>
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
          <p className="color-neutral-600 typo-text-small-em">Process ID</p>
          <p className="color-neutral-600 typo-text-small">{process.id}</p>
        </div>
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
          <p className="color-neutral-600 typo-text-small-em">Kind</p>
          <p className="color-neutral-600 typo-text-small">{process.kind.toUpperCase()}</p>
        </div>
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
          <p className="color-neutral-600 typo-text-small-em">Date created</p>
          <p className="color-neutral-600 typo-text-small">
            {process.createdAt.toLocaleDateString()}
          </p>
        </div>
        {parentLayer && (
          <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
            <p className="color-neutral-600 typo-text-small-em">Layer</p>
            <p className="color-neutral-600 typo-text-small">{parentLayer.name}</p>
          </div>
        )}
        {process.kind === 'annotation' && (
          <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
            <p className="color-neutral-600 typo-text-small-em">Annotations</p>
            <p className="color-neutral-600 typo-text-small">{process.annotationIds.size}</p>
          </div>
        )}
        <div className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-8px')}>
          <p className="color-neutral-600 typo-text-small-em">Checked Images</p>
          <p className="color-neutral-600 typo-text-small">
            {checkedImages.length} / {process.images.size}
          </p>
        </div>
        <div
          className={cn('flex', 'flex-row', 'justify-between', 'items-center', 'mt-16px', 'gap-3')}
        >
          <Button
            className={cn('w-164px')}
            disabled={process.step === 'done'}
            filled={process !== targetProcess}
            onClick={handleToggleActive}
            primary={process !== targetProcess}
            size="s"
          >
            {process === targetProcess ? 'Stop' : 'Start'}
          </Button>
          <Button
            className={cn('w-164px')}
            disabled={targetProcess === process}
            onClick={handleSubmit}
            primary
            size="s"
          >
            {process.step === 'created' ? 'Submit' : 'Reopen'}
          </Button>
        </div>
      </div>
    </>
  );
};
