import { Project } from '@/utils/project';
import { cn } from '@skand/ui';

interface SearchResultsSummaryProps {
  filteredProjects: Project[];
  searchKey: string;
}

export const ProjectsSearchResultsSummary = ({
  filteredProjects,
  searchKey,
}: SearchResultsSummaryProps) => {
  return (
    <p
      className={cn(
        'typo-text-small color-neutral-600 whitespace-nowrap flex flex-row gap-1 items-center justify-end max-w-full',
      )}
    >
      Showing
      <span className="color-primary-400 typo-text-small-em">{filteredProjects.length} </span>
      {filteredProjects.length > 1 ? 'results' : 'result'} for
      <span
        className="inline-block truncate color-primary-400 typo-text-small-em"
        title={searchKey}
      >
        &#34;{searchKey}&#34;
      </span>
    </p>
  );
};
