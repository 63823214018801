import { useExplore } from '@/stores/explore';
import { useViewer } from '@/stores/viewer';
import { Button, Draggable } from '@skand/ui';

export interface ScreenshotToolProps {
  onCancel: () => void;
  onScreenshot: (image: string) => void;
}

export const ScreenshotTool = ({ onCancel, onScreenshot }: ScreenshotToolProps) => {
  const api3D = useViewer(state => state.api3D);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const annotationGroups = useViewer(state => state.annotationGroups);
  const annotationDraft = useExplore(state => state.annotationDraft);

  const handleTakePhoto = async () => {
    // Hide all annotations except the selected one
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        if (annotationDraft && annotation.id !== annotationDraft.annotationId) {
          await annotation.sketch3D?.hide();
        }
      }
    }

    // Take the screenshot
    if (api3D) {
      await api3D.utility.nextFrame();
      const image = api3D.utility.captureScene();
      onScreenshot(image);
    }

    // Restore visibility state
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        if (visibleAnnotations.has(annotation.id)) {
          await annotation.sketch3D?.show();
        } else {
          await annotation.sketch3D?.hide();
        }
      }
    }
  };

  return (
    api3D && (
      <Draggable className="pointer-events-auto fixed z-4" x={100} y={100}>
        <div className="h-52px w-204px flex flex-row gap-3 b-1 b-neutral-400 b-rounded-2 b-solid bg-neutral-100 p-3">
          <Button className="w-full cursor-pointer" onClick={onCancel} size="s">
            Cancel
          </Button>
          <Button
            className="w-full cursor-pointer"
            filled
            onClick={handleTakePhoto}
            primary
            size="s"
          >
            Take photo
          </Button>
        </div>
      </Draggable>
    )
  );
};
