/* eslint-disable @typescript-eslint/no-explicit-any */

import { close, open } from '@/stores/upload';

if (!(window as any).__SKAND_MODULES__) {
  (window as any).__SKAND_MODULES__ = {};
}

(window as any).__SKAND_MODULES__.openUploadModal = open;
(window as any).__SKAND_MODULES__.closeUploadModal = close;
