import { useState, useRef, MouseEvent, useCallback } from 'react';
import { Handle } from './Handle';
import { Pane } from './Pane';
import { cn } from '../../utils/classname';

interface SplitViewProps {
  topPane: React.ReactNode;
  bottomPane: React.ReactNode;
  enabledSplit: boolean;
}

export const SplitView = ({ topPane, bottomPane, enabledSplit }: SplitViewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Sizes are in percentages
  const [topSize, setTopSize] = useState<number>(50);
  const [bottomSize, setBottomSize] = useState<number>(50);

  // Interaction states
  const [dragging, setDragging] = useState<boolean>(false);

  // Handler for starting dragging
  const startDragging = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  // Handler for stopping dragging
  const stopDragging = useCallback(() => {
    setDragging(false);
    setTopSize(100 - bottomSize);
  }, [bottomSize]);

  // Handler for the actual drag behavior
  const handleDrag = useCallback(
    (event: MouseEvent) => {
      const parent = containerRef.current;
      if (dragging && parent) {
        const { y, height } = parent.getBoundingClientRect();

        // Calculate the new percentage (relative to container size)
        const bottomSize = (100 * (y + height - event.clientY)) / height;
        setBottomSize(Math.min(90, Math.max(10, bottomSize)));
      }
    },
    [dragging],
  );

  return (
    <div
      className={cn(
        'w-full',
        'h-full',
        'flex',
        'flex-row',
        'relative',
        dragging && 'cursor-grabbing',
      )}
      onMouseLeave={stopDragging}
      onMouseMove={handleDrag}
      onMouseUp={stopDragging}
      ref={containerRef}
    >
      <Pane size={enabledSplit ? topSize : 100} top={true}>
        {topPane}
      </Pane>
      {enabledSplit && (
        <Handle bottomSize={bottomSize} dragging={dragging} startDragging={startDragging} />
      )}
      <Pane size={enabledSplit ? bottomSize : 0} top={false}>
        {bottomPane}
      </Pane>
    </div>
  );
};
