import { ANNOTATION_FIELD_TYPE } from '@/constants/annotation';
import { AnnotationTemplateField, AnnotationTemplateFieldInput } from '@/graphql/codegen/graphql';
import { WithMode, editField, removeField, saveField } from '@/stores/template';
import { Button } from '@skand/ui';

export const FieldAction = ({ field }: { field: WithMode<AnnotationTemplateField> }) => {
  const hasEmptyFields = (field: WithMode<AnnotationTemplateFieldInput>) => {
    if (field.options && field.options.length !== 0) {
      return (
        field.options.some(item => item?.value === '') ||
        !field.name ||
        field?.options?.some(item => item?.color === undefined)
      );
    }

    return false;
  };

  const disabled =
    field.type === ANNOTATION_FIELD_TYPE.SELECT ? hasEmptyFields(field) : !field.name;

  return (
    <div className="flex justify-end gap-2">
      {field.mode === 'view' ? (
        <Button onClick={() => editField(field)} size="s">
          Edit
        </Button>
      ) : (
        <Button disabled={disabled} filled onClick={() => saveField(field)} primary size="s">
          Save
        </Button>
      )}

      <Button onClick={() => removeField(field)} size="s">
        Delete
      </Button>
    </div>
  );
};
