import {
  LIST_LAYERS_BY_PROJECT_ID,
  LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES,
} from '@/graphql/queries';
import { request } from '@/graphql/request';
import { Layer } from '@/stores/viewer';
import { isEmpty } from '@/utils/empty';
import { createLayer } from '@/utils/transformers';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getLayerQueryKey = (projectId: string | null) => ['LIST_LAYERS_BY_PROJECT_ID', projectId];
const getSceneEntityQueryKey = (projectId: string | null) => [
  'LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES',
  projectId,
];

export const useFetchLayers = (projectId: null | string) => {
  const sceneEntitiesResult = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES, {
        projectId: projectId as string,
        rendererTypes: [],
      }),
    queryKey: getLayerQueryKey(projectId as string),
  });
  const layersResult = useQuery({
    enabled: !!projectId,
    queryFn: () => request(LIST_LAYERS_BY_PROJECT_ID, { projectId: projectId as string }),
    queryKey: getSceneEntityQueryKey(projectId as string),
  });

  return useCallback(async () => {
    const layers: Layer[] = [];
    for (const layerQuery of layersResult.data?.listLayersByProjectId ?? []) {
      if (!isEmpty(layerQuery) && !isEmpty(layerQuery.mainSceneEntityId)) {
        const sceneEntity =
          sceneEntitiesResult.data?.listSceneEntitiesByProjectIdAndRendererTypes?.find(
            entity => entity?.id === layerQuery.mainSceneEntityId,
          );
        if (!isEmpty(sceneEntity)) {
          const layer = createLayer(sceneEntity, layerQuery, undefined);
          if (layer?.type === 'layer') {
            layers.push(layer);
          }
        }
      }
    }
    return layers;
  }, [layersResult.data, sceneEntitiesResult.data]);
};

useFetchLayers.getLayerQueryKey = getLayerQueryKey;
useFetchLayers.getSceneEntityQueryKey = getSceneEntityQueryKey;
