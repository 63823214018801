import { queryClient } from '@/graphql/client';
import { CREATE_SHARE_LINK, UPDATE_SHARE_LINK } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccount } from '@/hooks/useAccount';
import { useFetchShareLinkPermissions } from '@/hooks/useFetchShareLinkPermissions';
import { useFetchShareLinks } from '@/hooks/useFetchShareLinks';
import { useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import { setSelectedShareLink, useShareLink } from '@/stores/shareLink';
import { cn } from '@/utils/classname';
import { Button, Input, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { ShareLinkContent } from '../ShareLinkContent';

import { EXPLORE_ROOT } from '@/constants/paths';
import { canPolicyActionEdit } from '@/constants/policy';
import {
  CreateShareLinkMutationVariables,
  PermissionPolicyInput,
  PermissionPolicyWithOutSubjectInput,
  PolicyActionTypeInput,
  PolicyObjectTypeInput,
  PolicySubjectTypeInput,
  UpdateShareLinkMutationVariables,
} from '@/graphql/codegen/graphql';
import { useFetchProjectPermissions } from '@/hooks/useFetchProjectPermissions';
import { ExploreState } from '@/utils/Persist';

export const ShareLinkContentPanel = () => {
  const projectId = useExplore(state => state.projectId);
  const selectedShareLink = useShareLink(state => state.selectedShareLink);

  const accountId = useAccount().data?.accountByContext?.id;
  const permissionObjectIDs = useFetchShareLinkPermissions();

  const setLeftSideBarII = useLayout(state => state.setLeftSideBarII);
  const hideLeftSideBarII = useLayout(state => state.hideLeftSideBarII);

  const [name, setName] = useState(selectedShareLink?.name ?? '');
  const [sceneEntityIds, setSceneEntityIds] = useState<string[]>([]);

  const { getProjectPermission } = useFetchProjectPermissions();
  const permission = getProjectPermission(projectId);
  const canEdit = canPolicyActionEdit(permission);

  const generateUrl = useCallback(
    (tokenId: string) => {
      const base = window.location.origin + EXPLORE_ROOT;
      const params: [keyof ExploreState, string][] = [
        ['project', projectId as string],
        ['shareLinkToken', tokenId as string],
      ];

      return `${base}?${params.map(([k, v]) => k + '=' + v).join('&')}`;
    },
    [projectId],
  );

  const createShareLink = useMutation({
    mutationFn: (variables: CreateShareLinkMutationVariables) =>
      request(CREATE_SHARE_LINK, variables),
    onSuccess: async createShareLinkResponse => {
      toast({
        type: 'success',
        message: 'Share link created and copied to your clipboard',
        lifespan: 5000,
        clickToDismiss: true,
      });
      const url = generateUrl(createShareLinkResponse?.createShareLink?.shareToken ?? '');
      await navigator.clipboard.writeText(url);
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
    },
  });
  const updateShareLink = useMutation({
    mutationFn: (variables: UpdateShareLinkMutationVariables) =>
      request(UPDATE_SHARE_LINK, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchShareLinks.getQueryKey(projectId));
      queryClient.invalidateQueries(
        useFetchShareLinkPermissions.getQueryKey(selectedShareLink?.id ?? null),
      );
    },
  });

  useEffect(() => {
    if (permissionObjectIDs) {
      setSceneEntityIds(permissionObjectIDs);
    }
  }, [permissionObjectIDs]);

  const handleSave = async () => {
    if (selectedShareLink) {
      const permissions = sceneEntityIds.map(id => ({
        subjectId: selectedShareLink.id,
        subjectType: PolicySubjectTypeInput.ShareLink,
        accountId: accountId,
        objectType: PolicyObjectTypeInput.SceneEntity,
        objectId: id,
        actionType: PolicyActionTypeInput.Read,
      })) as PermissionPolicyInput[];
      await updateShareLink.mutateAsync({
        shareLinkId: selectedShareLink.id,
        name,
        permissions,
      });
    } else if (projectId) {
      const permissions = sceneEntityIds.map(id => ({
        subjectType: PolicySubjectTypeInput.ShareLink,
        accountId: accountId,
        objectType: PolicyObjectTypeInput.SceneEntity,
        objectId: id,
        actionType: PolicyActionTypeInput.Read,
      })) as PermissionPolicyWithOutSubjectInput[];
      await createShareLink.mutateAsync({ projectId, name, permissions });
    }
    setLeftSideBarII('');
    hideLeftSideBarII();
  };

  const handleCancel = () => {
    setLeftSideBarII('');
    hideLeftSideBarII();
    setSelectedShareLink(null);
  };

  return (
    <div className={cn('relative h-full flex flex-col flex-1 ')}>
      <div
        className={cn(
          'flex',
          'justify-between',
          'items-end',
          'border-neutral-500',
          'border-1',
          'border-b-solid',
          'pb-3',
          'gap-3',
        )}
      >
        <p className={cn('typo-heading-4', 'color-neutral-800')}>
          {selectedShareLink ? 'Edit' : 'Create'} share link
        </p>
      </div>
      <p className={cn('typo-text-medium', 'color-neutral-800', 'mt-4')}>Details</p>
      <div className={cn('mt-3')}>
        <Input disabled={!canEdit} label="Name" onChange={setName} value={name} />
      </div>
      <p className={cn('typo-text-medium', 'color-neutral-800', 'mt-4')}>Select content</p>
      <div className="h-1px flex flex-1 flex-col">
        <ShareLinkContent
          disabled={!canEdit}
          sceneEntityIds={sceneEntityIds}
          setSceneEntityIds={setSceneEntityIds}
          unselectableLayerTypes={['annotation', 'panorama', 'photo2D']}
        />
      </div>
      <div
        className={cn('flex', 'flex-row', 'gap-3', 'justify-between', 'items-center', 'mt-16px')}
      >
        <Button className={cn('w-full')} onClick={handleCancel} size="s">
          Cancel
        </Button>
        <Button
          className={cn('w-full')}
          disabled={!canEdit}
          filled
          onClick={handleSave}
          primary
          size="s"
        >
          {selectedShareLink ? 'Save' : 'Save and copy link'}
        </Button>
      </div>
    </div>
  );
};
