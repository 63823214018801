import { cn } from '@/utils/classname';
import { useState, useRef, ChangeEventHandler, useMemo, useEffect } from 'react';
import { AnnotationGroup } from '@/stores/viewer';
import { DraggableMenu } from '@/components/DraggableMenu';
import {
  Annotation,
  AnnotationTemplate,
  CreateManyAnnotationsMutationVariables,
} from '@/graphql/codegen/graphql';
import { epsgCodeList } from '@/pages/ExplorePage/Viewer/Viewer3D/Overlay/InfoBar/epsgCodeList';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { toast, Button, Dropdown, DropdownItem, Toast, Menu, MenuItem } from '@skand/ui';
import { useExplore } from '@/stores/explore';
import {
  AnnotationLoader,
  GeoJsonLoader,
  spatialReferenceSystems,
  EsriCsvLoader,
  EPSG,
  TemplateField,
  TerraExplorerGeoJsonLoader,
  EsriTemplateField,
} from '@skand/data-3d-loader';
import { createCSVAnnotation } from '@/utils/transformers';
import { useMutation } from '@tanstack/react-query';
import { request } from '@/graphql/request';
import { CREATE_MANY_ANNOTATIONS } from '@/graphql/mutations';
import { useFetchAnnotations, useRefetchAnnotations } from '@/hooks/useFetchAnnotations';
import { queryClient } from '@/graphql/client';
import { useFetchProcesses } from '@/hooks/useFetchProcesses';
import { CreateCSVAnnotation } from '@/utils/annotation';
import { DropdownSelector, ItemProps } from '@/components/DropdownSelector';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useOnClickOutside } from '@/utils/useOnClickOutside';
import { TERRA_GEO_JSON_FLAG, ESRI_CSV_FLAG } from '@/utils/split';
import { useTreatments } from '@splitsoftware/splitio-react';
import { chunk } from 'lodash-es';
interface AnnotationUploadMenuProps {
  closeMenu: () => void;
  groupId: AnnotationGroup['id'];
}

export const AnnotationUploadMenu = ({ closeMenu, groupId }: AnnotationUploadMenuProps) => {
  const { refetch: refetchAnnotations } = useRefetchAnnotations();
  const { templates } = useAnnotationTemplates();
  const esri_treatment = useTreatments([ESRI_CSV_FLAG]);
  const terra_treatment = useTreatments([TERRA_GEO_JSON_FLAG]);

  const esriFlag = esri_treatment[ESRI_CSV_FLAG].treatment === 'on';
  const terraFlag = terra_treatment[TERRA_GEO_JSON_FLAG].treatment === 'on';

  const inputRef = useRef<HTMLInputElement>(null);
  const fileTypeRef = useRef<HTMLLabelElement>(null);
  const epsgFilterRef = useRef<HTMLInputElement>(null);
  const templateFilterRef = useRef<HTMLInputElement>(null);

  const enabledEpsgOutsideClick = useOnClickOutside(epsgFilterRef);
  const enabledTemplateOutsideClick = useOnClickOutside(templateFilterRef);

  const projectId = useExplore(state => state.projectId);

  const [template, setTemplate] = useState<AnnotationTemplate | null>(null);
  const [enableEpsgFilter, setEnableEpsgFilter] = useState(false);
  const [fileName, setFileName] = useState('');
  const [enabledTemplateFilter, setEnabledTemplateFilter] = useState(false);
  const [errorAlert, setErrorAlert] = useState<null | string>(null);
  const [fileType, setFileType] = useState('');
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [enabledFileTypeSelector, setEnabledFileTypeSelector] = useState(false);
  const [currentEpsg, setCurrentEpsg] = useState<EPSG>();

  const SKAND_CSV = 'skand_csv';
  const ESRI_CSV = 'esri_csv';
  const TERRA_GEO_JSON = 'terra_geo_json';
  const SKAND_GEO_JSON = 'skand_geo_json';

  const fileTypesRequireEPSG = [ESRI_CSV];
  const hasFileSelected = !!fileName;

  useEffect(() => {
    if (enabledEpsgOutsideClick) {
      setEnableEpsgFilter(false);
    }
    if (enabledTemplateOutsideClick) {
      setEnabledTemplateFilter(false);
    }
  }, [enabledEpsgOutsideClick, enabledTemplateOutsideClick]);

  const createAnnotations = useMutation({
    mutationFn: (variables: CreateManyAnnotationsMutationVariables) =>
      request(CREATE_MANY_ANNOTATIONS, variables),
    onSuccess: data => {
      if (data.createManyAnnotations) {
        queryClient.invalidateQueries(useFetchAnnotations.getQueryKey(groupId));
        refetchAnnotations([groupId]);
      }
      queryClient.invalidateQueries(useFetchProcesses.getQueryKey(projectId));
      toast({
        message: 'Annotations are created successfully',
        lifespan: 5000,
        clickToDismiss: true,
      });
    },
    onError: error => {
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = async e => {
    if (!e.target.files) return;
    e.preventDefault();

    const inputFile = e.target.files[0];
    setFileName(inputFile.name);
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      setUploadedFile(target?.result as string);
    };
    reader.readAsText(inputFile);
  };

  const handleUpload = () => {
    setErrorAlert(null);
    inputRef?.current?.click();
  };

  const handleUploadFileType = (type: string) => {
    setErrorAlert(null);
    setFileType(type);
    setEnabledFileTypeSelector(false);
    inputRef?.current?.click();
  };

  const templateOptions = useMemo(() => {
    const options =
      templates.map(template => {
        return {
          value: template?.id || '',
          label: template?.name || '',
        };
      }) ?? [];

    return options;
  }, [templates]);

  const fileExtension = useMemo(() => {
    switch (fileType) {
      case ESRI_CSV:
      case SKAND_CSV:
        return 'CSV';
      case TERRA_GEO_JSON:
      case SKAND_GEO_JSON:
        return 'GeoJson';
    }
  }, [fileType]);

  const fileTypeLabel = useMemo(() => {
    switch (fileType) {
      case ESRI_CSV:
        return 'Esri CSV';
      case SKAND_CSV:
        return 'Skand CSV';
      case TERRA_GEO_JSON:
        return 'TerraExplorer GeoJson';
      case SKAND_GEO_JSON:
        return 'Skand GeoJson';
      default:
        return '';
    }
  }, [fileType]);

  // Change annotation template
  const handleChangeTemplate = async (item: ItemProps) => {
    const selectedTemplate = templates.find(temp => temp?.id === item.value);
    if (!selectedTemplate) return;
    setTemplate(selectedTemplate);
    setEnabledTemplateFilter(false);
    setErrorAlert(null);

    const { namesOfMissingFields } = (await validateTemplateWithAnnotations(
      selectedTemplate,
    )) as CreateCSVAnnotation;
    if (namesOfMissingFields.length) {
      setErrorAlert(
        `${namesOfMissingFields[0]} field is missing from the uploaded ${fileExtension} file.`,
      );
    }
  };

  const validateTemplateWithAnnotations = async (template: AnnotationTemplate) => {
    const templateFields = template?.fields as (EsriTemplateField & TemplateField)[];
    let transformedAnnotations;
    try {
      if (fileType === SKAND_GEO_JSON) {
        const loader = new GeoJsonLoader();
        transformedAnnotations = await loader.read(uploadedFile as string);
      }
      if (fileType === SKAND_CSV) {
        const loader = new AnnotationLoader();
        transformedAnnotations = await loader.read(uploadedFile as string);
      }
      if (fileType === TERRA_GEO_JSON) {
        const loader = new TerraExplorerGeoJsonLoader();
        const terraTransformedAnnotations = await loader.read(
          uploadedFile as string,
          templateFields,
        );

        const Geoloader = new GeoJsonLoader();
        transformedAnnotations = await Geoloader.read(JSON.stringify(terraTransformedAnnotations));
      }
      if (fileType === ESRI_CSV) {
        const loader = new EsriCsvLoader();
        const projection = spatialReferenceSystems[currentEpsg as EPSG]?.proj4;

        transformedAnnotations = await loader.read(
          uploadedFile as string,
          templateFields,
          projection,
        );
      }

      const { annotations, namesOfMissingFields, incompatibleAnnotations } = createCSVAnnotation(
        transformedAnnotations,
        templateFields,
        projectId,
        template?.id,
        groupId,
      ) as CreateCSVAnnotation;

      return {
        annotations,
        namesOfMissingFields,
        incompatibleAnnotations,
      };
    } catch {
      setErrorAlert('Incompatible file format');
    }
  };

  const handleCreate = async () => {
    if (!template) return;
    const { annotations, namesOfMissingFields, incompatibleAnnotations } =
      (await validateTemplateWithAnnotations(template)) as CreateCSVAnnotation;

    if (incompatibleAnnotations.length) {
      const ifields = incompatibleAnnotations.map(el => el.name).join(', ');
      toast({
        type: 'warn',
        message: `${ifields} ${
          incompatibleAnnotations.length === 1 ? 'has' : 'have'
        } incompatible values`,
        lifespan: 5000,
        clickToDismiss: true,
      });
    }

    const chunkSize = 1000;
    const annotationChunks = chunk(annotations, chunkSize);

    if (namesOfMissingFields.length === 0) {
      await Promise.all(
        annotationChunks.map(async (annotations: Annotation[]) => {
          await createAnnotations.mutateAsync({
            annotations,
          });
        }),
      );

      closeMenu();
    }
  };

  const handleEPSGClick = (item: ItemProps) => {
    setCurrentEpsg(`EPSG:${item.value}` as EPSG);
    setEnableEpsgFilter(false);
  };

  const handleFileType = (type: string) => {
    setFileType(type);
    fileTypeRef.current?.focus();
    fileTypeRef.current?.blur();
  };
  return (
    <DraggableMenu
      closeMenu={closeMenu}
      containerStyles={cn(
        'w-339px',
        'flex',
        'bg-neutral-100',
        'p-3',
        'rounded-2',
        'border-1px',
        'border-solid',
        'border-neutral-400',
      )}
      disableCloseButton
      x={590}
      y={300}
    >
      <div className="w-full">
        <div className={cn('flex', 'flex-col')}>
          <div className={cn('mb-1 flex flex-row items-center justify-between')}>
            <p className={cn('color-neutral-700 typo-text-small')}>Import annotations</p>
            <div
              className={cn('text-3 text-right text-neutral-400 i-skand-close cursor-pointer')}
              onClick={closeMenu}
            />
          </div>

          <p className={cn('color-neutral-600 typo-text-small mb-2')}>
            Upload a file with matching fields to an existing template.
          </p>
        </div>

        {!hasFileSelected && (
          <DropdownMenu.Root
            onOpenChange={setEnabledFileTypeSelector}
            open={enabledFileTypeSelector}
          >
            <DropdownMenu.Trigger asChild>
              <Button className="relative w-full" filled primary size="s">
                Upload File
                <div className="i-skand-dropdown absolute right-2 top-2" />
              </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content asChild>
                <Menu className="relative z-10 w-310px">
                  <DropdownMenu.Item
                    asChild
                    className={cn(esriFlag || terraFlag ? 'hidden' : 'block')}
                  >
                    <MenuItem
                      className="cursor-pointer outline-none"
                      onClick={() => {
                        handleUploadFileType(SKAND_CSV);
                      }}
                    >
                      Skand CSV
                    </MenuItem>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    asChild
                    className={cn(esriFlag || terraFlag ? 'hidden' : 'block')}
                  >
                    <MenuItem
                      className="cursor-pointer outline-none"
                      onClick={() => {
                        handleUploadFileType(SKAND_GEO_JSON);
                      }}
                    >
                      Skand GeoJson
                    </MenuItem>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item asChild className={cn(esriFlag ? 'block' : 'hidden')}>
                    <MenuItem
                      className="cursor-pointer outline-none"
                      onClick={() => {
                        handleUploadFileType(ESRI_CSV);
                      }}
                    >
                      Esri CSV
                    </MenuItem>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item asChild className={cn(terraFlag ? 'block' : 'hidden')}>
                    <MenuItem
                      className="cursor-pointer outline-none"
                      onClick={() => {
                        handleUploadFileType(TERRA_GEO_JSON);
                      }}
                    >
                      TerraExplorer GeoJson
                    </MenuItem>
                  </DropdownMenu.Item>
                </Menu>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        )}

        <input className="hidden" onChange={handleChange} ref={inputRef} type="File" />

        {hasFileSelected && (
          <>
            <div className="h-[1px] w-full bg-neutral-400" />
            <p className={cn('color-neutral-600 typo-text-s mt-2 mb-1')}>File type</p>
            <Dropdown className="w-full" label={fileTypeLabel} ref={fileTypeRef} width="full">
              <DropdownItem
                className={cn(esriFlag || terraFlag ? 'hidden' : 'block')}
                onClick={() => handleFileType(SKAND_CSV)}
              >
                Skand CSV
              </DropdownItem>
              <DropdownItem
                className={cn(esriFlag || terraFlag ? 'hidden' : 'block')}
                onClick={() => handleFileType(SKAND_GEO_JSON)}
              >
                Skand GeoJSON
              </DropdownItem>
              <DropdownItem
                className={cn(esriFlag ? 'block' : 'hidden')}
                onClick={() => handleFileType(ESRI_CSV)}
              >
                Esri CSV
              </DropdownItem>
              <DropdownItem
                className={cn(terraFlag ? 'block' : 'hidden')}
                onClick={() => handleFileType(TERRA_GEO_JSON)}
              >
                TerraExplorer GeoJson
              </DropdownItem>
            </Dropdown>

            <div
              className={cn(
                'h-36px',
                'b-neutral-400',
                'w-full',
                'flex',
                'flex-row',
                'items-center',
                'p-8px',
                'justify-between',
              )}
            >
              <div className={cn('flex', 'flex-row', 'items-center')}>
                <p className={cn('color-neutral-600 typo-text-s my-12px mr-8px')}>
                  {fileName.slice(0, 30)}
                </p>
              </div>
              <p
                className={cn('color-neutral-800', ' typo-text-s', 'cursor-pointer', 'underline')}
                onClick={handleUpload}
              >
                Change
              </p>
            </div>

            {fileTypesRequireEPSG.includes(fileType) && (
              <>
                <p className={cn('color-neutral-600 typo-text-s mb-2')}>EPSG</p>
                <div
                  className={cn(
                    'h-36px',
                    'b-rounded-4px',
                    'b-neutral-400',
                    'w-full',
                    'b-solid',
                    'b-1px',
                    'flex',
                    'flex-row',
                    'items-center',
                    'p-8px',
                    'justify-between',
                    'cursor-pointer',
                  )}
                  onClick={() => setEnableEpsgFilter(true)}
                >
                  <div className={cn('flex', 'flex-row', 'items-center')}>
                    <p className={cn('color-neutral-600 typo-text-s my-12px mr-8px')}>
                      {currentEpsg ? currentEpsg : 'Select EPSG'}
                    </p>
                  </div>

                  <div
                    className={cn(
                      'i-skand-dropdown cursor-pointer',
                      'color-neutral-400',
                      'text-3',
                      'mr-2',
                    )}
                  />
                </div>
              </>
            )}
            {enableEpsgFilter && (
              <div ref={epsgFilterRef}>
                <DropdownSelector
                  containerStyles={cn(
                    'z-1 h-fit mt-2 bg-neutral-100 px-2 py-2 absolute w-[315px] rounded-4px  border-solid border-neutral-400 border-1px',
                  )}
                  enableSearch
                  itemContainerStyles={cn('h-196px', 'px-6px')}
                  itemStyles={cn('text-neutral-700')}
                  items={epsgCodeList}
                  onItemClick={handleEPSGClick}
                  placeholder="Search for EPSG code"
                />
              </div>
            )}

            <p className={cn('color-neutral-600 typo-text-s my-1')}>Annotation template:</p>

            <div
              className={cn(
                'h-36px',
                'b-rounded-4px',
                'b-neutral-400',
                'w-full',
                'b-solid',
                'b-1px',
                'flex',
                'flex-row',
                'items-center',
                'p-8px',
                'justify-between',
                'cursor-pointer',
              )}
              onClick={() => setEnabledTemplateFilter(true)}
            >
              <div className={cn('flex', 'flex-row', 'items-center')}>
                <p className={cn('color-neutral-600 typo-text-s my-12px mr-8px')}>
                  {template ? template.name?.slice(0, 100) : 'Select template'}
                </p>
              </div>

              <div
                className={cn(
                  'i-skand-dropdown cursor-pointer',
                  'color-neutral-400',
                  'text-3',
                  'mr-2',
                )}
              />
            </div>
            {enabledTemplateFilter && (
              <div ref={templateFilterRef}>
                <DropdownSelector
                  containerStyles={cn(
                    'h-fit mt-2 bg-neutral-100 px-2 py-2 absolute w-[315px] rounded-4px  border-solid border-neutral-400 border-1px z-1',
                  )}
                  enableSearch
                  itemContainerStyles={cn('h-196px', 'px-6px')}
                  itemStyles={cn('color-neutral-800')}
                  items={templateOptions}
                  onItemClick={handleChangeTemplate}
                  placeholder="Search for template"
                  selectedItem={template?.id as string}
                />
              </div>
            )}
          </>
        )}
        {errorAlert && (
          <div className={cn('mt-12px')}>
            <Toast clickToDismiss message={errorAlert} type="warn" />
          </div>
        )}

        {hasFileSelected && (
          <Button
            className={cn('cursor-pointer w-full mt-12px')}
            disabled={!(template && hasFileSelected) || errorAlert !== null}
            filled
            onClick={handleCreate}
            primary
            size="s"
          >
            Create Annotations
          </Button>
        )}
      </div>
    </DraggableMenu>
  );
};
