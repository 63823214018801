import { NodeGroupIcon } from '@/components/IconButton';
import { queryClient } from '@/graphql/client';
import {
  CreateAnnotationGroupInput,
  SetParentSceneEntityMutationVariables,
} from '@/graphql/codegen/graphql';
import { CREATE_ANNOTATION_GROUP, SET_PARENT_SCENE_ENTITY } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccount } from '@/hooks/useAccount';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useExplore } from '@/stores/explore';
import { Layer, LayerGroup } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { Button, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

export interface CreateAnnotationGroupProps {
  cancel: () => void;
  targetLayer: Layer | LayerGroup;
}

export const CreateAnnotationGroup = ({ cancel, targetLayer }: CreateAnnotationGroupProps) => {
  const projectId = useExplore(state => state.projectId);
  const accountId = useAccount().data?.accountByContext?.id;

  const [name, setName] = useState('New Annotation Group');

  const createAnnotationGroup = useMutation({
    mutationFn: (annotationGroup: CreateAnnotationGroupInput) =>
      request(CREATE_ANNOTATION_GROUP, { annotationGroup }),
    onSuccess: async ({ createAnnotationGroup }) => {
      await queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));

      if (createAnnotationGroup && createAnnotationGroup.id && targetLayer) {
        await handleLinkLayer(createAnnotationGroup.id);
      } else {
        cancel();
      }

      toast({
        type: 'success',
        message: 'Successfully created annotation group node.',
        lifespan: 5000,
      });
    },
  });
  // Mutation to link to a parent
  const setParentSceneEntity = useMutation({
    mutationFn: (variables: SetParentSceneEntityMutationVariables) =>
      request(SET_PARENT_SCENE_ENTITY, variables),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
    },
  });

  // Handle linking the group to a parent layer
  const handleLinkLayer = async (id: string) => {
    if (!id || !projectId) return;
    await setParentSceneEntity.mutateAsync({
      sceneEntityId: id,
      parentSceneEntityId: targetLayer.sceneEntityId,
      projectId,
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0, w: 1 },
    });
    cancel();
  };

  // Handle input mode keyboard shortcuts
  const handleInputKeypress = (key: string) => {
    if (key === 'Enter') {
      handleSubmitName();
    }
    if (key === 'Escape') {
      cancel();
    }
  };

  // Handle submitting the new name
  const handleSubmitName = async () => {
    if (!accountId || !projectId) return;
    await createAnnotationGroup.mutateAsync({
      projectId,
      name,
    });
    cancel();
  };

  return (
    <div className="mt-1 flex flex-row items-center">
      <NodeGroupIcon className={cn('text-3 color-neutral-600 w-5')} />
      <div className="flex flex-1 gap-2">
        <input
          className={cn(
            'px-1',
            'ml-1',
            'color-neutral-800',
            'typo-text-s-em',
            'rounded',
            'border-1',
            'border-solid',
            'border-primary-400',
            'outline-none',
            'w-[214px]',
            'h-7',
          )}
          onChange={e => setName(e.target.value)}
          onKeyDown={e => handleInputKeypress(e.key)}
          value={name}
        />
        <Button className="w-[41px]" filled onClick={handleSubmitName} primary size="xs">
          Save
        </Button>
        <Button className="w-[54px]" onClick={cancel} size="xs">
          Cancel
        </Button>
      </div>
    </div>
  );
};
