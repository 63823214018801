// @unocss-include

import { cn } from '@/utils/classname';

export const dialog = cn(
  'b-1 b-neutral-600 b-solid',
  'bg-neutral-100',
  'fixed',
  'flex-col',
  'flex',
  'inset-y-12',
  'overflow-auto',
  'p-6',
  'rounded-2',
  'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
  'left-50% transform-translate-x--50%',
  'w-640px',
  'z-1',
);

export const island = 'b-1 b-neutral-400 rounded-1 b-solid p-3';
