import { Select, SelectItem } from '@skand/ui';
import { ChunkSizeOption } from './constants';

const CHUNK_OPTIONS: SelectItem<ChunkSizeOption>[] = [
  { key: '5M', name: '5 MB' },
  { key: '50M', name: '50 MB' },
  { key: '500M', name: '500 MB (default)' },
  { key: '5G', name: '5 GB' },
];

export const OptionSection = ({
  chunkSizeOption,
  setChunkSizeOption,
  isDisabled,
}: {
  chunkSizeOption: ChunkSizeOption;
  setChunkSizeOption: (size: ChunkSizeOption) => void;
  isDisabled: boolean;
}) => {
  return (
    <div className="mt-3 b-1 b-neutral-400 rounded-2 b-solid p-3">
      <div className="flex items-center justify-between">
        <p className="mr-1 color-neutral-500 typo-text-s">
          Split files into small chunks for uploading, set the maximum size of each chunk to be:
        </p>
        <Select
          className="w-144px"
          disabled={isDisabled}
          onChange={setChunkSizeOption}
          options={CHUNK_OPTIONS}
          placeholder="chunk size"
          value={chunkSizeOption}
          width="full"
          yPlacement="top"
        />
      </div>
    </div>
  );
};
