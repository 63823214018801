import { queryClient } from '@/graphql/client';
import { cn } from '@/utils/classname';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@skand/ui';
import { UploadStage, useUploader } from '@skand/uploader';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FileSection } from './FileSection/FileSection';
import { OptionSection } from './OptionSection';
import { TargetSection } from './TargetSection';
import {
  useCreateProjectNodeFiles,
  useCreateProjectNodeFolder,
  useCreateSystemNodeFolder,
} from './mutations';
import {
  accumulateFiles,
  incrementFilesUploaded,
  resetBeforeClose,
  resetUploadStore,
  setFileGraph,
  setIsOpen,
  setProgressOverall,
  setTargetNodeId,
  updateUploadProgress,
  useUploadStore,
} from '@/stores/upload';
import {
  ChunkOptions,
  NodeItem,
  assertIsString,
  buildTree,
  createProjectNodes,
  displayErrors,
} from './utils';
export const UploadLegacy = () => {
  const isOpen = useUploadStore(state => state.isOpen);
  const projectId = useUploadStore(state => state.projectId);
  const projectName = useUploadStore(state => state.projectName);
  const projectParentNodeId = useUploadStore(state => state.projectParentNodeId);
  const targetNodeId = useUploadStore(state => state.targetNodeId);
  const willCreateSystemFolder = useUploadStore(state => state.willCreateSystemFolder);
  const beforeClose = useUploadStore(state => state.beforeClose);
  const fileGraph = useUploadStore(state => state.fileGraph);

  // error includes uploadError
  const [error, setError] = useState<null | Error>(null);
  const [uploadError, setUploadError] = useState<null | Error>(null);
  const [isProjectError, setIsProjectError] = useState<boolean>(false);

  // block errors before upload gets initialized
  const shouldBlockErrorsRef = useRef(true);

  const [chunkOption, setChunkOption] = useState<ChunkOptions>('500M');
  const chunkSize = useMemo(() => {
    const _1M = 1024 * 1024;
    const _1G = 1024 * _1M;
    if (chunkOption === '5M') return 5 * _1M;
    if (chunkOption === '50M') return 50 * _1M;
    if (chunkOption === '500M') return 500 * _1M;
    if (chunkOption === '5G') return 5 * _1G;
    return undefined;
  }, [chunkOption]);

  /**
   * canAddFiles: allow drop files in
   * canCancel: cancel button is clickable
   * canUpload: upload button is clickable
   * canSave: save button is visible and clickable
   * canSaveToProject: project section is interactive
   *
   * initial:
   *   - canAddFiles: true
   *   - canCancel: true
   *   - canUpload: false
   *   - canSave: false
   *   - canSaveToProject: true
   * after addFiles (preparing):
   *   - canAddFiles: false
   *   - canCancel: true
   *   - canUpload: false
   *   - canSave: false
   *   - canSaveToProject: true
   * after fileNodesImporting (prepared)
   *   - canAddFiles: false
   *   - canCancel: true
   *   - canUpload: true
   *   - canSave: false
   *   - canSaveToProject: true
   * after click upload (uploading)
   *   - canAddFiles: false
   *   - canCancel: false
   *   - canUpload: false
   *   - canSave: false
   *   - canSaveToProject: true
   * after fileNodesImported (uploaded) (upload complete)
   *   - canAddFiles: false
   *   - canCancel: false
   *   - canUpload: false
   *   - canSave: true
   *   - canSaveToProject: false
   */
  const [stage, setStage] = useState<
    'initial' | 'preparing' | 'prepared' | 'uploading' | 'uploaded'
  >('initial');

  const canAddFiles = stage === 'initial';
  const canCancel = stage !== 'uploaded';
  const canUpload = stage === 'prepared';
  const canSave = stage === 'uploaded';
  const canChangeOptions = stage !== 'uploading' && stage !== 'uploaded';

  const { addFiles, on, off, upload, uploadState, reset, retry, uploadRetry, emit } = useUploader({
    featureType: 'FILE_SYSTEM',
    persistType: 'IMMEDIATE',
    systemFolderNodeId: targetNodeId,
    chunkSize,
    emitDirCreatingManually: true,
  });

  const createSystemNodeFolder = useCreateSystemNodeFolder();
  const createProjectNodeFiles = useCreateProjectNodeFiles();
  const createProjectNodeFolder = useCreateProjectNodeFolder();
  const createProject = useCallback(async () => {
    try {
      const root = uploadState.current.graph?.get('/');
      if (root) {
        assertIsString(projectId);
        await createProjectNodes(
          uploadState.current.graph,
          root,
          projectId,
          projectParentNodeId,
          createProjectNodeFolder,
          createProjectNodeFiles,
        );
      }
    } catch (e) {
      setIsProjectError(true);
      setError(e as Error);
      throw e;
    }
  }, [
    createProjectNodeFiles,
    createProjectNodeFolder,
    projectId,
    projectParentNodeId,
    uploadState,
  ]);

  const handleClose = useCallback(() => {
    if (beforeClose) {
      beforeClose();
    }

    setIsOpen(false);
    resetBeforeClose();
  }, [beforeClose]);

  const handleReset = useCallback(() => {
    reset();
    resetUploadStore();
    setStage('initial');
    setUploadError(null);
    setError(null);
  }, [reset]);

  const handleCancel = useCallback(() => {
    shouldBlockErrorsRef.current = true;
    handleClose();
    handleReset();
  }, [handleClose, handleReset]);

  const handleUpload = useCallback(async () => {
    setStage('uploading');
    upload();
  }, [upload]);

  const handleRetry = useCallback(async () => {
    if (uploadError) {
      setUploadError(null);
      setError(null);
      uploadRetry();
    } else if (error) {
      if (isProjectError) {
        setIsProjectError(false);
        setError(null);
        await createProject();
        setStage('uploaded');
      } else {
        setError(null);
        retry();
      }
    }
  }, [createProject, error, isProjectError, retry, uploadError, uploadRetry]);

  useEffect(() => {
    const onAddFiles = (files: { file: File }[]) => {
      shouldBlockErrorsRef.current = false;
      setStage('preparing');
      accumulateFiles(files.map(file => file.file));
    };

    const onUpdateStage = async (uploadStage: UploadStage) => {
      if (uploadStage !== 'dirCreating') return;
      if (willCreateSystemFolder) {
        assertIsString(projectName);
        const response = await createSystemNodeFolder({ name: projectName, parentNodeId: null });
        const id = response.createSystemFolderNode?.id;
        if (id) setTargetNodeId(id);
      }
      emit('dirCreating', null);
    };

    const onFileTracked = () => {
      setStage('prepared');
      setFileGraph(prevItems => {
        return prevItems.map(item => ({ ...item, status: 'prepared' }));
      });
    };

    const onFileImported = async () => {
      queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      await createProject();
      setStage('uploaded');
    };

    const onProgress = async ({ progress }: { progress: number }) => {
      setProgressOverall(progress);
    };

    const onUpdateGraph = () => {
      setFileGraph(prev => {
        const nodes: NodeItem[] = [];
        const root = uploadState.current.graph?.get('/');
        buildTree(uploadState.current.graph, root, 0, nodes, prev);
        return nodes;
      });
    };

    const onUploadProgress = ({
      bytesTotal,
      bytesUploaded,
      fileId,
      progress,
    }: {
      bytesTotal: number;
      bytesUploaded: number;
      fileId: string;
      progress: number;
    }) => {
      updateUploadProgress(bytesTotal, bytesUploaded, fileId, progress);
    };

    const onUploadSuccess = ({ fileId }: { fileId: string }) => {
      setFileGraph(prevItems => {
        const newItems: NodeItem[] = [];

        for (const item of prevItems) {
          if (item.fileId !== fileId) newItems.push(item);
          else newItems.push({ ...item, status: 'uploaded' });
        }

        return newItems;
      });

      incrementFilesUploaded(1);
    };

    const onError = ({ error }: { error: Error }) => {
      if (shouldBlockErrorsRef.current) return;
      setError(error);
      throw error;
    };

    const onUploadError = ({ fileId, error }: { fileId: string; error: Error }) => {
      if (shouldBlockErrorsRef.current) return;
      setUploadError(error);
      setFileGraph(prevItems => {
        const newItems: NodeItem[] = [];

        for (const item of prevItems) {
          if (item.fileId !== fileId) newItems.push(item);
          else newItems.push({ ...item, status: 'failed' });
        }

        return newItems;
      });
    };

    on('addFiles', onAddFiles);
    on('updateStage', onUpdateStage);
    on('fileTracked', onFileTracked);
    on('fileImported', onFileImported);
    on('progress', onProgress);
    on('updateGraph', onUpdateGraph);
    on('uploadProgress', onUploadProgress);
    on('uploadSuccess', onUploadSuccess);
    on('uploadError', onUploadError);
    on('error', onError);
    return () => {
      off('addFiles', onAddFiles);
      off('updateStage', onUpdateStage);
      off('fileTracked', onFileTracked);
      off('fileImported', onFileImported);
      off('progress', onProgress);
      off('updateGraph', onUpdateGraph);
      off('uploadProgress', onUploadProgress);
      off('uploadSuccess', onUploadSuccess);
      off('uploadError', onUploadError);
      off('error', onError);
    };
  }, [
    createProject,
    createSystemNodeFolder,
    emit,
    off,
    on,
    projectName,
    uploadState,
    willCreateSystemFolder,
  ]);

  return (
    <Dialog.Root onOpenChange={setIsOpen} open={isOpen}>
      {/* <Dialog.Trigger asChild>
        <Button active={isOpen} className="w-30" disabled={disabled} filled primary size="s" >
          Upload
        </Button>
      </Dialog.Trigger> */}

      <Dialog.Portal>
        <Dialog.Content
          className={cn(
            'b-1 b-neutral-600 b-solid',
            'bg-neutral-100',
            'fixed',
            'flex-col',
            'flex',
            'inset-t-50% inset-l-50%',
            'p-6',
            'rounded-2',
            'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
            'transform-translate--50%',
            'w-700px',
            'mt-auto mb-auto',
            'max-h-90vh',
            'overflow-auto',
            '[&_p]:mt-unset [&_p]:mb-unset [&_input]:transition-unset',
          )}
          onPointerDownOutside={e => e.preventDefault()}
        >
          <Dialog.Title className="color-neutral-800 typo-text-l">Upload new files</Dialog.Title>

          <TargetSection />
          <FileSection
            addFiles={addFiles}
            canAddFiles={canAddFiles}
            fileGraph={fileGraph}
            stage={stage}
          />
          <OptionSection
            chunkSize={chunkOption}
            isDisabled={!canChangeOptions}
            setChunkSize={setChunkOption}
          />

          {/* <ChooseSection /> */}

          <div className="mt-3">
            {error && (
              <p className="text-right color-alert-400 typo-text-s">
                Something went wrong during uploading. Please retry. <br />
                {displayErrors(error)}
              </p>
            )}
          </div>

          <div className="mt-3 flex flex-none justify-end gap-3">
            {canCancel && (
              <Dialog.Close asChild>
                <Button className="flex-1 cursor-pointer" onClick={handleCancel} size="s">
                  Cancel
                </Button>
              </Dialog.Close>
            )}

            {!error && !canSave && (
              <Button
                className="flex-1 cursor-pointer"
                disabled={!canUpload}
                filled
                onClick={handleUpload}
                primary
                size="s"
              >
                Upload files
              </Button>
            )}

            {!error && canSave && (
              <Button
                className="flex-1 cursor-pointer"
                filled
                onClick={handleCancel}
                primary
                size="s"
              >
                Save and close
              </Button>
            )}

            {error && (
              <Button
                className="flex-1 cursor-pointer"
                filled
                onClick={handleRetry}
                primary
                size="s"
              >
                Retry
              </Button>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
