export const Controls3DIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.35742 5.52936V10.7032L7.98587 13.148V8.25842L3.35742 5.52936Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M7.98633 13.148L13.6433 10.1346V5.24506L7.98633 8.25839"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M13.6429 5.24514L8.82156 2.80035L3.35742 5.52941"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        clipRule="evenodd"
        d="M13.5 1H14.5C15.0523 1 15.5 1.44772 15.5 2V3H16.5V2C16.5 0.895431 15.6046 0 14.5 0H13.5V1ZM3.5 1V0H2.5C1.39543 0 0.5 0.895431 0.5 2V3H1.5V2C1.5 1.44772 1.94772 1 2.5 1H3.5ZM1.5 13H0.5V14C0.5 15.1046 1.39543 16 2.5 16H3.5V15H2.5C1.94772 15 1.5 14.5523 1.5 14V13ZM13.5 15V16H14.5C15.6046 16 16.5 15.1046 16.5 14V13H15.5V14C15.5 14.5523 15.0523 15 14.5 15H13.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
