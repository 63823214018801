import { cn } from '@/utils/classname';
import { LayeringTab } from './LayeringTab';
import { Layer, useViewer } from '@/stores/viewer';
import { TilesetStatisticsTab } from './TilesetStatisticsTab';
import { useTreatments } from '@splitsoftware/splitio-react';
import { RENDERER_STATISTICS_FLAG } from '@/utils/split';
import { useEffect, useState } from 'react';
import { ModelNode, Tileset } from '@skand/viewer-component-v2';

export interface Mesh3DSettingsMenuProps {
  layer: Layer;
}

export const Mesh3DSettingsMenu = ({ layer }: Mesh3DSettingsMenuProps) => {
  const treatment = useTreatments([RENDERER_STATISTICS_FLAG]);
  const statisticsTabFlag = treatment[RENDERER_STATISTICS_FLAG].treatment === 'on';

  const viewerSettings = useViewer(state => state.viewer3DSettings);
  const enabledStatistics = viewerSettings.enabledStatistics;

  const hasStatistics = (layer.sceneNode as ModelNode).getModel() instanceof Tileset;

  const [currentTab, setCurrentTab] = useState('layering');

  useEffect(() => {
    if (!enabledStatistics) {
      setCurrentTab('layering');
    }
  }, [enabledStatistics]);

  return !statisticsTabFlag || !hasStatistics ? (
    <div className={cn('w-full', 'flex', 'flex-col')}>
      <div
        className={cn(
          'items-center',
          'pb-2',
          'flex',
          'h-auto',
          'justify-start',
          'w-full',
          'mt-1',
          'b-solid',
          'b-neutral-400',
          'border-b-1',
          'border-l-0',
          'border-r-0',
          'border-t-0',
        )}
      >
        <p className={cn('typo-text-small', 'text-neutral-800')}>Layering</p>
      </div>
      <LayeringTab layer={layer} />
    </div>
  ) : (
    <div className={cn('w-full flex flex-col')}>
      <div
        className={cn(
          'items-center pb-2 flex h-auto justify-around w-full mt-1 b-solid b-neutral-400 border-b-1 border-l-0 border-r-0 border-t-0',
        )}
      >
        <button
          className={cn('bg-transparent', 'b-none', 'cursor-pointer', 'flex', 'flex-1')}
          onClick={() => setCurrentTab('layering')}
        >
          <p
            className={cn(
              'typo-text-small',
              currentTab === 'layering' ? 'text-neutral-800' : 'text-neutral-400',
            )}
          >
            Layering
          </p>
        </button>
        <button
          className={cn(
            enabledStatistics ? 'flex' : 'hidden',
            'bg-transparent',
            'b-none',
            'cursor-pointer',
            'flex-1',
            'b-neutral-400',
          )}
          onClick={() => setCurrentTab('statistics')}
        >
          <p
            className={cn(
              'typo-text-small',
              currentTab === 'statistics' ? 'text-neutral-800' : 'text-neutral-400',
            )}
          >
            Statistics
          </p>
        </button>
      </div>
      {currentTab === 'statistics' ? (
        <TilesetStatisticsTab layer={layer} />
      ) : (
        <LayeringTab layer={layer} />
      )}
    </div>
  );
};
