import { cn } from '@/utils/classname';
import { InputSlider } from '../InputSlider';
import { TilesetStyle } from '@skand/viewer-component-v2';

export interface RGBSettingsProps {
  style: TilesetStyle;
  setStyle: (style: TilesetStyle) => void;
}

export const RGBSettings = ({ style, setStyle }: RGBSettingsProps) => {
  return (
    <div className={cn('flex flex-col')}>
      <InputSlider
        max={1}
        min={0}
        setValue={gamma => setStyle({ ...style, gamma })}
        title="Gamma"
        value={style.gamma}
      />
      <InputSlider
        max={1}
        min={0}
        setValue={brightness => setStyle({ ...style, brightness })}
        title="Brightness"
        value={style.brightness}
      />
      <InputSlider
        max={1}
        min={0}
        setValue={contrast => setStyle({ ...style, contrast })}
        title="Contrast"
        value={style.contrast}
      />
    </div>
  );
};
