import { ColorPicker } from '@skand/ui';
import { Color } from 'three';
import { useCallback } from 'react';
import { ColorResult } from 'react-color';

export interface ColorPickerMenuProps {
  closeMenu: () => void;
  color: Color;
  selectColor: (color: Color) => void;
  x?: number;
  y?: number;
}

export const ColorPickerMenu = ({
  color,
  selectColor,
  closeMenu,
  x = 0,
  y = 30,
}: ColorPickerMenuProps) => {
  const handleCustomColor = useCallback(
    (colorHex: ColorResult) => {
      selectColor(new Color(colorHex.hex));
    },
    [selectColor],
  );

  return (
    <ColorPicker
      closeMenu={closeMenu}
      color={color.getHexString()}
      colorPickerHeader="Custom colour"
      openingCoordinates={{ x, y }}
      setColor={handleCustomColor}
    />
  );
};
