import { toCartographic, calculateCartographicBasis, toDegrees } from '@skand/viewer-component-v2';
import { Vector3, Quaternion, Matrix4 } from 'three';

/**
 * Convert a cartesian coordinate to Google Maps compatible LatLng
 *
 * @param position
 * @returns
 */
function toLatLng(position: Vector3) {
  const cartographic = toCartographic(position);
  return {
    lng: toDegrees(cartographic.longitude),
    lat: toDegrees(cartographic.latitude),
  };
}

/**
 * Convert a quaternion rotation to a heading angle in degrees
 *
 * @param position
 * @param rotation
 * @returns
 */
function toHeading(position: Vector3, rotation: Quaternion) {
  const x = new Vector3(); // Camera right
  const y = new Vector3(); // Camera up
  const z = new Vector3(); // Camera forward
  new Matrix4().makeRotationFromQuaternion(rotation).extractBasis(x, y, z);

  const up = new Vector3(); // Globe surface normal
  const tangent = new Vector3();
  const bitangent = new Vector3();
  calculateCartographicBasis(toCartographic(position), up, tangent, bitangent);

  const projected = x.projectOnPlane(up);
  const crossed = new Vector3().crossVectors(bitangent, projected);

  return projected.angleTo(bitangent) * Math.sign(up.dot(crossed));
}

export { toLatLng, toHeading };
