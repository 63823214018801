import { PhotoGroup } from '@/stores/viewer';
import { cn } from '@/utils/classname';

export interface PhotoGroupNodeProps {
  group: PhotoGroup;
}
export const PhotoGroupNode = ({ group }: PhotoGroupNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{group.name}</p>
      </div>
    </div>
  );
};
