import { LocalStorageStore } from './LocalStorage';
import { ExploreState } from './Store';
import { URLStore } from './URL';

/**
 * Persistent storage for Explore state that synchronizes multiple stores.
 */
export class Persist {
  url: URLStore;
  localStorage: LocalStorageStore;

  constructor() {
    this.url = new URLStore();
    this.localStorage = new LocalStorageStore();
  }

  /**
   * Read a field from the store.
   *
   * @param field
   * @returns
   */
  public get<T extends keyof ExploreState>(field: T): ExploreState[T] | null {
    // URL takes precedence over local storage.
    const result = this.url.get(field);
    if (result !== null) {
      return result;
    }
    return this.localStorage.get(field);
  }

  /**
   * Write a field to store.
   *
   * @param field
   * @param value
   */
  public set<T extends keyof ExploreState>(field: T, value: ExploreState[T]) {
    this.url.set(field, value);
    this.localStorage.set(field, value);
  }

  /**
   * Clear a field from the store.
   *
   * @param field
   */
  public clear<T extends keyof ExploreState>(field: T) {
    this.url.clear(field);
    this.localStorage.clear(field);
  }
}
