import { cn } from '@/utils/classname';
import { ReactNode } from 'react';

interface PaneProps {
  top: boolean;
  size: number;
  children: ReactNode;
}

export const Pane = ({ top, size, children }: PaneProps) => {
  return (
    <div
      className={cn('absolute', 'w-100%', size === 0 && 'display-none', top ? `top-0` : `bottom-0`)}
      style={{ height: `${size}%` }}
    >
      {children}
    </div>
  );
};
