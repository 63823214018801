import { useExplore } from '@/stores/explore';
import { Photo } from '@/stores/viewer';
import { cn } from '@/utils/classname';
import { lengthToString, toCartographic, toDegrees } from '@skand/viewer-component-v2';

export interface InfoBarProps {
  photo: Photo;
}

export const InfoBar = ({ photo }: InfoBarProps) => {
  const measurementUnit = useExplore(state => state.measurementUnit);
  const modelPosition = photo.widget?.getPosition();
  const cartographic = modelPosition && toCartographic(modelPosition);

  return (
    cartographic && (
      <div
        className={cn(
          'bg-neutral-800',
          'bg-opacity-50',
          'color-neutral-400',
          'typo-text-s',
          'flex flex-wrap',
          'py-0.5 px-1',
          'absolute',
          'bottom-0',
          'left-0',
          'b-rounded-tr-1',
        )}
      >
        <p className="whitespace-nowrap pr-2">{`LAT ${toDegrees(cartographic.latitude).toFixed(
          6,
        )}º`}</p>
        <p className="whitespace-nowrap pr-2">{`LNG ${toDegrees(cartographic.longitude).toFixed(
          6,
        )}º`}</p>
        <p className="whitespace-nowrap">{`ELEVATION ${lengthToString(
          cartographic.height,
          measurementUnit,
          2,
        )}`}</p>
      </div>
    )
  );
};
