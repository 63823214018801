import { useState, useRef, MouseEvent, useCallback } from 'react';
import { Handle } from './Handle';
import { Pane } from './Pane';
import { cn } from '../../utils/classname';

interface VerticalSplitViewProps {
  leftPane: React.ReactNode;
  rightPane: React.ReactNode;
  enabledSplit: boolean;
}

export const VerticalSplitView = ({
  leftPane,
  rightPane,
  enabledSplit,
}: VerticalSplitViewProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Sizes are in percentages
  const [rightSize, setRightSize] = useState<number>(50);

  // Interaction states
  const [dragging, setDragging] = useState<boolean>(false);

  // Handler for starting dragging
  const startDragging = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  // Handler for stopping dragging
  const stopDragging = useCallback(() => {
    setDragging(false);
  }, []);

  // Handler for the actual drag behavior
  const handleDrag = useCallback(
    (event: MouseEvent) => {
      const parent = containerRef.current;
      if (dragging && parent) {
        const { x, width } = parent.getBoundingClientRect();

        // Calculate the new percentage (relative to container size)
        const rightSize = (100 * (x + width - event.clientX)) / width;
        setRightSize(Math.min(90, Math.max(10, rightSize)));
      }
    },
    [dragging],
  );

  return (
    <div
      className={cn(
        'w-full',
        'h-full',
        'flex',
        'flex-row',
        'relative',
        dragging && 'cursor-grabbing',
      )}
      onMouseLeave={stopDragging}
      onMouseMove={handleDrag}
      onMouseUp={stopDragging}
      ref={containerRef}
    >
      <Pane left={true} size={enabledSplit ? 100 - rightSize : 100}>
        {leftPane}
      </Pane>
      {enabledSplit && (
        <Handle dragging={dragging} rightSize={rightSize} startDragging={startDragging} />
      )}
      <Pane left={false} size={enabledSplit ? rightSize : 0}>
        {rightPane}
      </Pane>
    </div>
  );
};
