import { NodeGroupIcon } from '@/components/IconButton';
import { queryClient } from '@/graphql/client';
import {
  CreateAnnotationGroupInput,
  CreateSceneEntityGroupMutationVariables,
} from '@/graphql/codegen/graphql';
import { CREATE_ANNOTATION_GROUP, CREATE_SCENE_ENTITY_GROUP } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccount } from '@/hooks/useAccount';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { useExplore } from '@/stores/explore';
import { cn } from '@/utils/classname';
import { Button, toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { CreateNode as CreateNodeEntity } from '../SceneEntityTree/SceneEntityTree';

export interface CreateNodeProps {
  cancel: () => void;
  mode: CreateNodeEntity['mode'];
}

export const CreateNode = ({ cancel, mode }: CreateNodeProps) => {
  const projectId = useExplore(state => state.projectId);
  const accountId = useAccount().data?.accountByContext?.id;

  const [name, setName] = useState(
    mode === 'annotationGroup' ? 'New Annotation Group' : 'New Layer Group',
  );

  const createAnnotationGroup = useMutation({
    mutationFn: (annotationGroup: CreateAnnotationGroupInput) =>
      request(CREATE_ANNOTATION_GROUP, { annotationGroup }),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully created annotation group node.',
        lifespan: 5000,
      });
    },
  });

  const createSceneEntityGroup = useMutation({
    mutationFn: (sceneEntityGroup: CreateSceneEntityGroupMutationVariables) =>
      request(CREATE_SCENE_ENTITY_GROUP, sceneEntityGroup),
    onSuccess: () => {
      queryClient.invalidateQueries(useFetchSceneEntities.getSceneEntityQueryKey(projectId));
      toast({
        type: 'success',
        message: 'Successfully created scene entity group node.',
        lifespan: 5000,
      });
    },
  });

  // Handle input mode keyboard shortcuts
  const handleInputKeypress = (key: string) => {
    if (key === 'Enter') {
      handleSubmitName();
    }
    if (key === 'Escape') {
      cancel();
    }
  };

  // Handle submitting the new name
  const handleSubmitName = async () => {
    if (!accountId || !projectId) return;
    switch (mode) {
      case 'annotationGroup':
        await createAnnotationGroup.mutateAsync({
          projectId,
          name,
        });
        break;
      case 'layerGroup':
        await createSceneEntityGroup.mutateAsync({
          projectId,
          accountId,
          name,
          parentSceneEntityId: null,
        });
        break;
    }
    cancel();
  };

  return (
    <>
      {mode === 'annotationGroup' ? (
        <NodeGroupIcon className="w-5 text-3 color-neutral-600" />
      ) : (
        <div className="i-skand-layers w-5 text-3 color-neutral-600" />
      )}
      <div className="flex flex-1">
        <input
          className={cn(
            'px-1',
            'color-neutral-800',
            'typo-text-s-em',
            'rounded',
            'border-1',
            'border-solid',
            'border-primary-400',
            'outline-none',
            'w-full',
          )}
          onChange={e => setName(e.target.value)}
          onKeyDown={e => handleInputKeypress(e.key)}
          value={name}
        />
        <Button className="ml-1" filled onClick={handleSubmitName} primary size="xs">
          Save
        </Button>
        <Button className="ml-1" onClick={cancel} size="xs">
          Cancel
        </Button>
      </div>
    </>
  );
};
