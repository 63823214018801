import { GRAPHQL_URL } from '@/constants/env';
import { getHeaders } from '@/utils/headers';
import logger from '@/utils/logger';
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { toast } from '@skand/ui';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  return { headers: { ...headers, ...getHeaders() } };
});

// HACK: Please see https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-676886539
const omitTypeName = (key: string, value: unknown) => (key === '__typename' ? undefined : value);
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypeName);
  }

  return forward(operation).map(data => {
    return data;
  });
});

export const client = new ApolloClient({
  link: ApolloLink.from([cleanTypeName, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },

  queryCache: new QueryCache({
    onError: error => {
      logger.error(error);
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  }),

  mutationCache: new MutationCache({
    onError: error => {
      logger.error(error);
      toast({
        type: 'warn',
        message: `${error}`,
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  }),
});
